import React from 'react'
import "./holidaylist.css"

export default function HolidayList(props) {
    return (
        <div className='holiday'>

            {/* <div className="icons">
                <i className="fas fa-gifts"></i>
            </div> */}

            <div className="holiday_content">

                <div className="holiday_name">
                    {/* Christmas */}
                    {props.name}
                </div>

                <div className="holiday_date">
                    <p>{props.date}</p>
                    {/* <p>25<sup>th</sup> Dec<span>&nbsp;-&nbsp;</span>30<sup>th</sup> Dec</p> */}
                    {/* {this.props.holiday_date} */}
                </div>

            </div>


        </div>
    )
}
