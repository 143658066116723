import React, { useEffect } from "react";
import "./Funnel.css";
import FunnelGraph from "funnel-graph-js";
const Funnel = ({ Funneldata }) => {
  useEffect(() => {
    let LabelData = [];
    let CountData = [];
    if (Funneldata) {
      const data = {
        labels: Funneldata?.name,
        colors: [
          "#F08391",
          "#F18C95",
          "#F39599",
          "#F59E9D",
          "#F6A8A2",
          "#F8B2A6",
          "#FABCAB",
          "#FCC6AF",
          "#FECFB3",
        ],
        values: Funneldata?.count,
      };

      const graph = new FunnelGraph({
        container: ".Appp",
        gradientDirection: "vertical",
        data: data,
        displayPercent: false,
        direction: "vertical",
        width: 350,
        height: 630,
        subLabelValue: "raw",
      });

      setTimeout(() => {
        LabelData = Funneldata?.name;
        CountData = Funneldata?.count;
        let Funnel_graph = document.querySelectorAll(
          ".svg-funnel-js__container"
        );

        if (Funnel_graph?.length > 1) {
          for (let i = 0; i < Funnel_graph?.length; i++) {
            if (Funnel_graph[i + 1]) {
              Funnel_graph[i + 1].style.display = "none";
            }
          }
        }
        let funnel_label = document.querySelectorAll(".svg-funnel-js__labels");
        if (funnel_label) {
          for (let i = 0; i < funnel_label?.length - 1; i++) {
            if (funnel_label[i]) {
              funnel_label[i].style.display = "none";
            }
          }
        }
        document.querySelectorAll(".svg-funnel-js__label").forEach((item) => {
          item.style.height = "100px";
        });
      }, 100);
      graph.draw();
    }
  }, [Funneldata]);
  return <div className="Appp" />;
  // <div className="funnel_container">
  //   <div className="funnel_btn">
  //     <p>Conversion</p>
  //     <select name="" id="">
  //       <option value="">Month</option>
  //       <option value="">Year</option>
  //       <option value="">Week</option>
  //     </select>
  //   </div>

  //   <div className="funnel_card_container">
  //     <FunnelCard
  //       bg={"#F08391"}
  //       height="100%"
  //       name={"Parent questionnaire incomplete"}
  //       score="200"
  //     />
  //     <FunnelCard
  //       bg={"#F18C95"}
  //       height="90%"
  //       name="Level 1 not started"
  //       score="190"
  //     />
  //     <FunnelCard
  //       bg={"#F39599"}
  //       height="80%"
  //       name="Quit during Level 1"
  //       score="180"
  //     />
  //     <FunnelCard bg={"#F59E9D"} height="70%" name="Level 1 completed" />
  //     <FunnelCard bg={"#F6A8A2"} height="60%" name="Level 2 slot filled" />
  //     <FunnelCard bg={"#F8B2A6"} height="50%" name="Level 2 scheduled" />
  //     <FunnelCard bg={"#FABCAB"} height="30%" name="Level 2 completed" />
  //     <FunnelCard
  //       bg={"#FCC6AF"}
  //       height="20%"
  //       name="Screening report shared"
  //     />
  //     <FunnelCard bg={"#FECFB3"} height="10%" name="9-Active intervention" />
  //   </div>
  // </div>
};
function FunnelCard({ bg, height, name, score }) {
  return (
    <div className="funnel_card">
      <div className="funnel_card_content">
        <p>{name}</p>
        <h4>{score || "20"}</h4>
      </div>
      <div className="funnel_bg_container">
        <div
          className="funnel_bg"
          style={{ height: height, backgroundColor: bg }}
        ></div>
      </div>
    </div>
  );
}
export default Funnel;
