import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { URL } from "../../../DROPDOWNDATA";
import { useSelector } from "react-redux";

const Groupreschedule = ({
  closeRescheduleModal,
  RescheduleData,
  Send_Reschedule_DB,
}) => {
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  const [DateValid, setDateValid] = useState(null);
  const [TimeValid, setTimeValid] = useState(null);
  const [EnabletList, setEnabletList] = useState([]);
  const [ConvertTime, setConvertTime] = useState(null);
  const [StoreEnabler, setStoreEnabler] = useState([]);
  const Popup_Screen = useSelector((store) => store.GroupschedulePopup);

  const [SendReschedule_Data, setSendReschedule_Data] = useState({
    meeting_id: RescheduleData.si_no,
    rescheduled_date: "",
    rescheduled_time: "",
    rescheduled_enabler: "",
    is_child_side_reschedule: "",
    is_enabler_side_reschudle: "",
    reschedule_reason: "",
  });
  function convertTime12To24(time) {
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === "PM" && hours < 12) hours = hours + 12;
    if (AMPM === "AM" && hours === 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
  }
  const Handle_Enabler_Input = (event, value) => {
    if (value != null || value != undefined || value != "") {
      setStoreEnabler([value]);
      setSendReschedule_Data(
        SendReschedule_Data,
        (SendReschedule_Data.rescheduled_enabler = value.users_id)
      );
    }
  };

  async function getEnablerList(meeting_id) {
    await fetch(URL + "/reschedule_enabler_list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify({ meeting_id }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setEnabletList(obj.body);
      });
  }
  const sameEnabler = (event) => {
    setSendReschedule_Data(
      SendReschedule_Data,
      (SendReschedule_Data.rescheduled_enabler = RescheduleData.enabler_id)
    );
    if (event.target.checked) {
      setEnabletList([
        {
          users_id: RescheduleData.enabler_id,
          users_firstname: RescheduleData.enabler_name,
        },
      ]);
      setStoreEnabler([
        {
          users_id: RescheduleData.enabler_id,
          users_firstname: RescheduleData.enabler_name,
        },
      ]);
    } else {
      getEnablerList(RescheduleData?.si_no);
      setSendReschedule_Data(
        SendReschedule_Data,
        (SendReschedule_Data.rescheduled_enabler = EnabletList[0].users_id)
      );
    }
  };
  function ElementComponent(props) {
    return (
      <div className="select-element">
        <p>{props.element_name}</p>

        <Autocomplete
          disablePortal
          id="combo-box"
          options={EnabletList}
          getOptionLabel={(option) => {
            return option.users_firstname;
          }}
          onChange={Handle_Enabler_Input}
          value={StoreEnabler[0]}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Select Enabler" />
          )}
        />
      </div>
    );
  }
  let reschedule_Date = document.querySelector("#reschedule_Date");
  let reschedule_Time = document.querySelector("#reschedule_Time");
  let samedateandtime = document.querySelector("#samedateandtime");

  const setSameDate_time = () => {
    setTimeValid(ConvertTime);
    // reschedule_Time.value = ConvertTime;
    reschedule_Date.value = RescheduleData?.meeting_date
      .split("-")
      .reverse()
      .join("-");
    setDateValid(RescheduleData?.meeting_date.split("-").reverse().join("-"));
    setSendReschedule_Data(
      SendReschedule_Data,
      (SendReschedule_Data.rescheduled_date = RescheduleData?.meeting_date
        .split("-")
        .reverse()
        .join("-")),
      (SendReschedule_Data.rescheduled_time = ConvertTime)
    );
  };
  const dateChange = (event) => {
    setDateValid(event.target.value);
    setSendReschedule_Data(
      SendReschedule_Data,
      (SendReschedule_Data.rescheduled_date = event.target.value)
    );
    if (samedateandtime.checked) {
      samedateandtime.checked = false;
    }
  };
  const timeChange = (event) => {
    setTimeValid(event.target.value);
    setSendReschedule_Data(
      SendReschedule_Data,
      (SendReschedule_Data.rescheduled_time = event.target.value)
    );
    if (samedateandtime.checked) {
      samedateandtime.checked = false;
    }
  };
  const RescheduleReason_input = (event) => {
    setSendReschedule_Data(
      SendReschedule_Data,
      (SendReschedule_Data.reschedule_reason = event.target.value)
    );
  };
  let reason_for_reschedule = document.getElementsByName("reason_for_cancel");
  useEffect(() => {
    if (!Popup_Screen?.Bulkaction) {
      setConvertTime(convertTime12To24(RescheduleData?.meeting_time));
      getEnablerList(RescheduleData?.si_no);
    }
  }, []);
  const sendReport = () => {
    if (Popup_Screen?.Bulkaction) {
      if (
        !reason_for_reschedule[0].checked &&
        !reason_for_reschedule[1].checked &&
        !reason_for_reschedule[2].checked
      ) {
        toast.error("Kindly fill the fields");
        toast.clearWaitingQueue();
      } else {
        setSendReschedule_Data(
          SendReschedule_Data,
          (SendReschedule_Data.is_child_side_reschedule =
            reason_for_reschedule[0]?.checked ? 1 : 0),
          (SendReschedule_Data.is_enabler_side_reschudle =
            reason_for_reschedule[1]?.checked ? 1 : 0)
        );

        Send_Reschedule_DB(SendReschedule_Data);
      }
    } else {
      if (
        !reason_for_reschedule[0].checked &
          !reason_for_reschedule[1].checked &
          !reason_for_reschedule[2].checked ||
        SendReschedule_Data.rescheduled_time == "" ||
        SendReschedule_Data.rescheduled_date == "" ||
        SendReschedule_Data.rescheduled_enabler == ""
      ) {
        toast.error("Kindly fill the fields");
        toast.clearWaitingQueue();
      } else {
        setSendReschedule_Data(
          SendReschedule_Data,
          (SendReschedule_Data.is_child_side_reschedule =
            reason_for_reschedule[0]?.checked ? 1 : 0),
          (SendReschedule_Data.is_enabler_side_reschudle =
            reason_for_reschedule[1]?.checked ? 1 : 0)
        );

        Send_Reschedule_DB(SendReschedule_Data);
      }
    }
  };
  return (
    <div className="reschedule_container">
      <div className="reschedule_session_Layout">
        <p className="heading">Reschedule session</p>

        {!Popup_Screen?.Bulkaction && (
          <div className="block currentDate">
            {" "}
            <p>
              <strong>Date:</strong> {RescheduleData?.meeting_date}
            </p>
            <p>
              {" "}
              <strong>Time:</strong> {RescheduleData?.meeting_time}
            </p>
          </div>
        )}

        {!Popup_Screen?.Bulkaction && (
          <div className="block enterDate">
            <div>
              <p>Enter new date</p>
              <input
                type="date"
                className="timeInput"
                id="reschedule_Date"
                onInput={(event) => dateChange(event)}
                value={DateValid}
                // defaultValue={RescheduleData?.meeting_date
                //   .split("-")
                //   .reverse()
                //   .join("-")}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div>
              <p>Enter new time</p>
              <input
                type="time"
                className="timeInput"
                id="reschedule_Time"
                value={TimeValid}
                // defaultValue={convertTime12To24(RescheduleData?.meeting_time)}
                onInput={(event) => timeChange(event)}
              />
            </div>
            <div className="same_date_time">
              <input
                type="checkbox"
                name="same_date_time"
                id="samedateandtime"
                onInput={setSameDate_time}
              />
              <label htmlFor="samedateandtime">Same date and time</label>
            </div>
          </div>
        )}

        <div
          className={`block   ${
            Popup_Screen?.Bulkaction ? "!grid-cols-1 w-[650px]" : ""
          }`}
        >
          <div className="radio_btn_container">
            <p className="heading">Remarks</p>

            <div className="radio_btn_holder">
              <input name="reason_for_cancel" type="radio" id="child_side" />
              <label htmlFor="child_side">Child side reschedule</label>
            </div>

            <div className="radio_btn_holder">
              <input name="reason_for_cancel" type="radio" id="enabler_side" />
              <label htmlFor="enabler_side">Enabler side reschedule</label>
            </div>

            <div className="radio_btn_holder">
              <input name="reason_for_cancel" type="radio" id="other_side" />
              <label htmlFor="other_side">Other :</label>
            </div>
            <input
              type="text"
              name=""
              id=""
              onInput={(event) => RescheduleReason_input(event)}
              className="otherInputTextBx"
              placeholder="Enter reason for reschedule"
            />
          </div>

          {!Popup_Screen?.Bulkaction && (
            <div className="enabler_same">
              <div className="enabler_checkbox">
                <input
                  type="checkbox"
                  name="enabler"
                  id="enablersame"
                  onInput={(event) => sameEnabler(event)}
                />
                <label htmlFor="enablersame">Same enabler</label>
              </div>
              <ElementComponent element_name="Select Enablers" />
            </div>
          )}
        </div>

        <div className="create_reschedule_btns_holder">
          <button className="btn btn1" onClick={closeRescheduleModal}>
            Cancel
          </button>
          <button className="btn btn2" onClick={sendReport}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Groupreschedule;
