import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { URL } from "../DROPDOWNDATA";

const useEditall = () => {
  const Location = useNavigate();
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  let data;
  const [editAll, setEditAll] = useState();
  async function getEdit_All_Data(value) {
    await fetch(URL + "/view_child_scheduled_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify((data = { child_view_id: value })),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setEditAll(obj.body);
      });
  }

  // useEffect(() => {
  //   getEdit_All_Data(value);
  // }, [value]);
  return [editAll, setEditAll, getEdit_All_Data];
};

export default useEditall;
