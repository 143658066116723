import React from "react";
import "./Courseitem.css";

const Courseitem = ({ item, SelectedCourse, setSelectedCourse, index }) => {
  return (
    <div
      className={
        SelectedCourse == index
          ? "course_list_block active"
          : "course_list_block"
      }
      onClick={() => {
        setSelectedCourse(index);
      }}
      key={index}
    >
      <div className="star_icon">
        <img src={item?.image} alt="" />
        <span>{item?.course_name}</span>
      </div>
      <div>
        <p className="discount_amount">{item?.discountAmount}</p>
        <p className="amount">
          {" "}
          <i>&#8377;</i> {item?.course_price}
        </p>
        <p className="duration">{item?.duration} Months</p>
        <p className="class_count">{item?.class_count} Classes</p>
      </div>
    </div>
  );
};

export default Courseitem;
