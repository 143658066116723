import React, { useState, useEffect } from "react";
import { URL } from "../DROPDOWNDATA";
const useEnablerList = () => {
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  let data;
  const [Enabler_List, setEnabler_List] = useState(null);

  async function getProgramme() {
    await fetch(URL + "/pending_child_enabler_list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setEnabler_List(obj.body?.enabler_list);
      });
  }

  useEffect(() => {
    getProgramme();
  }, []);
  return [Enabler_List, setEnabler_List, getProgramme];
};

export default useEnablerList;
