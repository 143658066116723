import React, { useEffect, useState } from "react";
import Graph from "../../../images/graph.png";
import useGet from "../../../Hooks/useGet";
import { URL } from "../../../DROPDOWNDATA";
import usePost from "../../../Hooks/usePost";
const Mircosoft = () => {
  const [SelectedData, setSelectedData] = useState({
    state: "",
    district: "",
    school: "",
  });
  const StateGet = usePost(`${URL}/state_list`);
  const DistrictPost = usePost(`${URL}/district_list`);
  const SchoolPost = usePost(`${URL}/school_by_district`);
  const AnalysisData = usePost(`${URL}/microsoft_analysis`);

  const StateHandler = (e) => {
    setSelectedData((prev) => ({
      ...prev,
      state: e.target.value,
      district: "",
      school: "",
    }));
    if (e.target.value) {
      DistrictPost.Postcall({
        state_id: e.target.value,
        data_view_type: "microsoft",
      });
    } else {
      // setSelectedData((prev) => ({ ...prev,  }));
    }

    AnalysisPost(e.target.value, "", "");
  };
  const DistrictHandler = (e) => {
    setSelectedData((prev) => ({
      ...prev,
      district: e.target.value,
      school: "",
    }));
    if (e.target.value) {
      SchoolPost.Postcall({ district_id: e.target.value });
    } else {
      // setSelectedData((prev) => ({ ...prev, school: "" }));
    }
    AnalysisPost(SelectedData?.state, e.target.value, "");
  };
  const SchoolHandler = (e) => {
    setSelectedData((prev) => ({ ...prev, school: e.target.value }));
    AnalysisPost(SelectedData?.state, SelectedData?.district, e.target.value);
  };
  const ClearHandler = () => {
    setSelectedData((prev) => ({
      ...prev,
      school: "",
      state: "",
      district: "",
    }));
    AnalysisPost("", "", "");
  };
  const AnalysisPost = (state, district, school_id) => {
    AnalysisData.Postcall({ state, district, school_id });
  };
  useEffect(() => {
    if (StateGet.Postdata != null) {
    } else {
      StateGet.Postcall({ data_view_type: "microsoft" });
    }
  }, [StateGet.Postdata]);

  useEffect(() => {
    AnalysisPost("", "", "");
  }, []);

  return (
    <div className="w-full">
      {/* DROPDOWN START*/}
      <div className="flex items-center justify-end">
        <div className="flex flex-col mx-2">
          <label htmlFor="state" className="font-semibold mb-2 text-[#007872]">
            Select state
          </label>
          <select
            name="state"
            id="state"
            value={SelectedData?.state}
            onChange={StateHandler}
            className="py-2 px-1 border-2 outline-none border-[#007872] rounded-lg w-[300px]"
          >
            <option value="">All</option>
            {StateGet.Postdata?.body?.map((item, index) => {
              return (
                <option key={item?.state_id} value={item?.state_id}>
                  {item?.state_name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex flex-col mx-2">
          <label
            htmlFor="district"
            className="font-semibold mb-2 text-[#007872]"
          >
            Select district
          </label>
          <select
            name="district"
            id="district"
            value={SelectedData?.district}
            onChange={DistrictHandler}
            className="py-2 px-1 border-2 outline-none border-[#007872] rounded-lg w-[300px]"
          >
            <option value="">All</option>
            {SelectedData?.state != "" &&
              DistrictPost.Postdata?.body?.map((item, index) => {
                return (
                  <option key={item?.district_id} value={item?.district_id}>
                    {item?.district_name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="flex flex-col mx-2">
          <label htmlFor="school" className="font-semibold mb-2 text-[#007872]">
            Select school
          </label>
          <select
            name="school"
            id="school"
            value={SelectedData?.school}
            onChange={SchoolHandler}
            className="py-2 px-1 border-2 outline-none border-[#007872] rounded-lg w-[300px]"
          >
            <option value="">All</option>
            {SelectedData?.district != "" &&
              SchoolPost.Postdata?.body?.map((item, index) => {
                return (
                  <option key={item?.school_id} value={item?.school_id}>
                    {item?.school_name}
                  </option>
                );
              })}
          </select>
        </div>
        <button
          className={`mt-5 font-semibold underline text-[#007872] ${
            SelectedData?.state != "" ||
            SelectedData?.district != "" ||
            SelectedData?.school != ""
              ? ""
              : "pointer-events-none opacity-50"
          }`}
          onClick={ClearHandler}
        >
          Clear all
        </button>
      </div>
      {/* DROPDOWN END*/}

      <div>
        {/* COUNTS */}
        <div className="grid grid-cols-3 place-items-stretch  gap-5 mt-10 w-[70%] mx-auto">
          <div className="bg-[#FFDAC1] justify-between flex w-[350px] ">
            <div className="flex flex-col justify-between">
              <div>
                <h3 className="pl-2 mt-1 text-[#007872] font-bold text-[22px]">
                  State
                </h3>
                <p className="pl-2 text-[#007872] font-bold text-[36px]">
                  {SelectedData?.state == ""
                    ? StateGet.Postdata?.body?.length
                    : 1}
                </p>
              </div>
              <div>
                <img src={Graph} alt="" className="w-[150px] mt-6" />
              </div>
            </div>
            <div className="flex items-center justify-center px-3">
              <i className="fa-solid fa-earth-americas text-[54px] text-[#007872]"></i>
            </div>
          </div>
          <div className="bg-[#FFDAC1] justify-between flex w-[350px] ">
            <div className="flex flex-col justify-between">
              <div>
                <h3 className="pl-2 mt-1 text-[#007872] font-bold text-[22px]">
                  Districts
                </h3>
                <p className="pl-2 text-[#007872] font-bold text-[36px]">
                  {SelectedData?.district == ""
                    ? AnalysisData?.Postdata?.body?.total_district || 0
                    : 1}
                </p>
              </div>
              <div>
                <img
                  src={Graph}
                  alt=""
                  className="w-[150px] mt-6 hue-rotate-[90deg]"
                />
              </div>
            </div>
            <div className="flex items-center justify-center px-3">
              <i className="fa-solid fa-map-location-dot text-[54px] text-[#007872]"></i>
            </div>
          </div>
          <div className="bg-[#FFDAC1] justify-between flex w-[350px] ">
            <div className="flex flex-col justify-between">
              <div>
                <h3 className="pl-2 mt-1 text-[#007872] font-bold text-[22px]">
                  Schools
                </h3>
                <p className="pl-2 text-[#007872] font-bold text-[36px]">
                  {AnalysisData?.Postdata?.body?.school_count}
                </p>
              </div>
              <div>
                <img
                  src={Graph}
                  alt=""
                  className="w-[150px] mt-6 hue-rotate-[230deg]"
                />
              </div>
            </div>
            <div className="flex items-center justify-center px-3">
              <i className="fa-solid fa-school text-[54px] text-[#007872]"></i>
            </div>
          </div>
          <div className="bg-[#FFDAC1] justify-between flex w-[350px] ">
            <div className="flex flex-col justify-between">
              <div>
                <h3 className="pl-2 mt-1 text-[#007872] font-bold text-[22px]">
                  No of Groups
                </h3>
                <p className="pl-2 text-[#007872] font-bold text-[36px]">
                  {AnalysisData?.Postdata?.body?.total_groups}
                </p>
              </div>
              <div>
                <img
                  src={Graph}
                  alt=""
                  className="w-[150px] mt-6 hue-rotate-[183deg]"
                />
              </div>
            </div>
            <div className="flex items-center justify-center px-3">
              {/* <i className="fa-solid fa-users"></i> */}
              <i className="fa-solid fa-users text-[54px] text-[#007872]"></i>
            </div>
          </div>
          <div className="bg-[#FFDAC1] justify-between flex w-[350px] ">
            <div className="flex flex-col justify-between">
              <div>
                <h3 className="pl-2 mt-1 text-[#007872] font-bold text-[22px]">
                  No of Students in groups
                </h3>
                <p className="pl-2 text-[#007872] font-bold text-[36px]">
                  {AnalysisData?.Postdata?.body?.total_students}
                </p>
              </div>
              <div>
                <img
                  src={Graph}
                  alt=""
                  className="w-[150px] mt-6 hue-rotate-[160deg]"
                />
              </div>
            </div>
            <div className="flex items-center justify-center px-3">
              <i className="fa-solid fa-user-graduate text-[54px] text-[#007872]"></i>
            </div>
          </div>
          <div className="bg-[#FFDAC1] justify-between flex w-[350px] ">
            <div className="flex flex-col justify-between">
              <div>
                <h3 className="pl-2 mt-1 text-[#007872] font-bold text-[22px]">
                  No of Group Classes completed
                </h3>
                <p className="pl-2 text-[#007872] font-bold text-[36px]">
                  {AnalysisData?.Postdata?.body?.classes_completed}
                </p>
              </div>
              <div>
                <img
                  src={Graph}
                  alt=""
                  className="w-[150px] mt-6 hue-rotate-[44deg]"
                />
              </div>
            </div>
            <div className="flex items-center justify-center px-3">
              <i className="fa-solid fa-chalkboard-user text-[54px] text-[#007872]"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mircosoft;
