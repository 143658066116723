import React, { useEffect, useState } from "react";
import Topbar from "../childPage/topMenu/TopMenu";
import { useParams } from "react-router-dom";
import "./Childjournal.css";
import { toast } from "react-toastify";
import { URL as Path } from "../../DROPDOWNDATA";
import { ReactImageCarouselViewer } from "react-image-carousel-viewer";
import Loader from "../../components/Loader/Loader";
const ChildJournal = (props) => {
  const { id, childname } = useParams();
  const CHILD_ID = atob(id);

  // COMMENT DETAILS
  const [CommentData, setCommentData] = useState([]);
  // ENABLER LIST FOR FILTER
  const [EnablerList, setEnablerList] = useState([]);
  // LOADER
  const [Is_Loading, setIs_Loading] = useState(false);

  // IMAGES ARRAY
  const [ImagesArray, setImagesArray] = useState([]);

  const [viewtype, setviewtype] = useState(1);
  const [OpenJournal, setOpenJournal] = useState(false);
  const [DeleteModal, setDeleteModal] = useState(false);
  const [StoreEntryID, setStoreEntryID] = useState("");
  const [Is_editable, setIs_editable] = useState(false);
  const [InputValue, setInputValue] = useState("");

  // IMAGE PREVIEW
  const [Is_open, setIs_open] = useState(false);
  const [PreviewArray, setPreviewArray] = useState([]);

  async function getChild_data() {
    // let item = {
    //   child_view_id: CHILD_ID,
    // };
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("loggedToken")
    );

    var form = new FormData();
    form.append("child_view_id", CHILD_ID);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form,
      redirect: "follow",
    };

    fetch(Path + "/child_journal", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCommentData(result?.comments);
        setEnablerList(result?.enabler_list);
      })
      .catch((error) => {
        setIs_Loading(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  useEffect(() => {
    props.setBreadcrumItem([
      { name: "Student List", link: `/` },
      {
        name: localStorage.getItem("childName"),
        link: `/child_journal/${id}`,
      },
    ]);
    getChild_data();
  }, []);
  async function SendData(item) {
    // let item = {
    //   child_view_id: CHILD_ID,
    //   comment: InputValue,
    //   action: action_type,
    // };
    let imagesName = [];
    ImagesArray?.forEach((item_image, index) => {
      if (item_image?.name) {
        imagesName.push(
          `${item?.child_view_id}_${index}_${Math.round(+new Date() / 1000)}.${
            item_image?.name?.split(".")[
              item_image?.name?.split(".").length - 1
            ]
          }`
        );
      } else {
        imagesName.push(
          item_image?.split("/")[item_image?.split("/").length - 1]
        );
      }
    });
    setIs_Loading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("loggedToken")
    );

    var form = new FormData();
    form.append("action", item?.action);

    form.append("child_view_id", item?.child_view_id);

    form.append("comment_id", item?.comment_id);
    form.append("comment", item?.comment);

    form.append("images_url", imagesName?.join());
    ImagesArray?.forEach((item, index) => {
      if (item?.name) {
        form.append("images", item, imagesName[index]);
      }
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form,
      redirect: "follow",
    };

    fetch(Path + "/child_journal", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        toast.success("Added successfully");
        toast.clearWaitingQueue();
        setIs_Loading(false);
        setOpenJournal(false);
        setInputValue("");
        setIs_editable(false);
        setDeleteModal(false);
        getChild_data();
        setImagesArray([]);
      })
      .catch((error) => {
        setIs_Loading(false);
        toast.error("Failed to upload");
        toast.clearWaitingQueue();
      });
  }
  async function FilterData(enabler_id) {
    let item = {
      child_view_id: CHILD_ID,
      user_id: enabler_id,
      action: "filter_data",
    };

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("loggedToken")
    );

    var form = new FormData();
    form.append("child_view_id", CHILD_ID);
    form.append("user_id", enabler_id);
    form.append("action", "filter_data");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form,
      redirect: "follow",
    };

    fetch(Path + "/child_journal", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCommentData(result);
        setIs_Loading(false);
        // setEnablerList(result?.enabler_list);
      })
      .catch((error) => {
        setIs_Loading(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }
  const ViewChange = (view) => {
    setviewtype(view);
  };
  const Open_journal_entry = () => {
    setOpenJournal(true);
    setIs_editable(false);
    setInputValue("");
  };
  const send_entry = () => {
    if (Is_editable) {
      SendData({
        child_view_id: CHILD_ID,
        comment_id: StoreEntryID,
        comment: InputValue,
        action: "update_data",
      });
    } else {
      SendData({
        child_view_id: CHILD_ID,
        comment_id: StoreEntryID,
        comment: InputValue,
        action: "insert_data",
      });
    }
  };
  const Expand = (e) => {
    if (!e.target.closest(".f_card").classList.contains("expand")) {
      document.querySelectorAll(".f_card").forEach((item) => {
        item.classList.remove("expand");
      });

      e.target.closest(".f_card").classList.add("expand");
      e.target.className = "fa-solid fa-down-left-and-up-right-to-center";

      return;
    }
    document.querySelectorAll(".f_card").forEach((item) => {
      item.classList.remove("expand");
      e.target.className = "fa-solid fa-up-right-and-down-left-from-center";
    });
  };
  const Open_deleteModal = (id) => {
    setStoreEntryID(id);
    setDeleteModal(true);
  };
  const delete_card = () => {
    SendData({
      child_view_id: CHILD_ID,
      comment_id: StoreEntryID,
      comment: InputValue,
      action: "delete_data",
    });
  };
  const EditEntry = (id, text) => {
    setIs_editable(true);
    setStoreEntryID(id);
    setInputValue(text);
    setOpenJournal(true);
  };

  const AddBorder = (e) => {
    // console.log(e);
    if (e.target.scrollTop > 50) {
      e.target.style.borderTop = "1px solid black";
    } else {
      e.target.style.borderTop = "1px solid transparent";
    }
  };
  const filterComment = (e) => {
    FilterData(e.target.value);
  };

  if (Is_Loading) {
    return <Loader />;
  }

  return (
    <div>
      <Topbar id={id} childname={childname} />

      <div className="child_journal_container">
        <div className="child_jouranl_top">
          <div className="child_journal_filter">
            <select name="" id="" onChange={(event) => filterComment(event)}>
              <option value="">All</option>
              {EnablerList.map((item, index) => {
                return (
                  <option key={index} value={item.enabler_id}>
                    {item.enabler_name}
                  </option>
                );
              })}
            </select>
          </div>

          {/* <div className="child_journal_viewtype">
            <button
              className={viewtype == 1 ? "gridactive" : ""}
              onClick={() => ViewChange(1)}
            >
              <i class="fa-solid fa-table-cells-large"></i>
            </button>
            <button
              className={viewtype == 2 ? "gridactive" : ""}
              onClick={() => ViewChange(2)}
            >
              <i class="fa-solid fa-grip-vertical"></i>
            </button>
            <button
              className={viewtype == 3 ? "gridactive" : ""}
              onClick={() => ViewChange(3)}
            >
              <i class="fa-solid fa-list"></i>
            </button>
          </div> */}

          <div className="child_journal_addnew">
            <button onClick={Open_journal_entry}>Add new entry</button>
          </div>
        </div>

        <div
          className="child_journal_list_container"
          onScroll={(event) => AddBorder(event)}
        >
          {CommentData?.map((item, index) => {
            // let comment = item?.enabler_comment;
            // comment = comment?.replace("'", "'");
            return (
              <div
                key={index}
                class={
                  viewtype == 1
                    ? "feedback_card f_card"
                    : viewtype == 2
                    ? "two_grid f_card"
                    : viewtype == 3
                    ? "gridlist f_card"
                    : ""
                }
              >
                <div class="edit_btn_container">
                  {item.editable && (
                    <button
                      title="edit"
                      class="showedit"
                      onClick={() => {
                        EditEntry(item.comment_id, item?.enabler_comment);
                        setImagesArray(item?.images_url);
                      }}
                    >
                      <i class="fa-solid fa-pen"></i>
                    </button>
                  )}
                  <button class="expandArrow" title="expand">
                    <i
                      onClick={(event) => Expand(event)}
                      class="fa-solid fa-up-right-and-down-left-from-center"
                    ></i>
                  </button>
                  {item.editable && (
                    <button
                      onClick={() => Open_deleteModal(item.comment_id)}
                      title="delete"
                      class="showedit"
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </button>
                  )}
                </div>

                <div class="feedback_comment">
                  <p>{item.enabler_comment}</p>
                </div>

                <div style={{ height: "13%" }}>
                  {item?.images_url?.length > 0 && (
                    <div className="child_attachview_container">
                      {item?.images_url?.map((image, index3) => {
                        let viewImageArray = [];
                        let extension;
                        extension =
                          image?.split(".")[image?.split(".")?.length - 1];
                        if (extension != "pdf") {
                          viewImageArray.push({
                            src: image,
                            description: index3,
                          });
                        }
                        if (viewImageArray?.length > 0) {
                          return (
                            <button
                              key={index3}
                              onClick={() => {
                                let iArray = [];
                                for (
                                  let i = 0;
                                  i < item?.images_url.length;
                                  i++
                                ) {
                                  let extension;
                                  extension =
                                    item?.images_url[i]?.split(".")[
                                      item?.images_url[i]?.split(".")?.length -
                                        1
                                    ];
                                  if (extension != "pdf") {
                                    iArray.push({
                                      src: item?.images_url[i],
                                      description: "",
                                    });
                                  }
                                }
                                setPreviewArray(iArray);
                                setIs_open(true);
                              }}
                            >
                              View images
                            </button>
                          );
                        }
                      })}
                      <div className="child_journal_pdf_view">
                        {item?.images_url?.map((pdf, index1) => {
                          let extension;
                          extension =
                            pdf?.split(".")[pdf?.split(".")?.length - 1];
                          if (extension == "pdf") {
                            return (
                              <a
                                key={index1}
                                href={pdf}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Pdf view
                              </a>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div class="feedback_enabler">
                  <div class="enaber_avator">
                    <i class="fa-solid fa-user"></i>
                  </div>
                  <div class="enabler_nameDate">
                    <div class="name">{item.enabler_name}</div>
                    <div class="date">{item.enabler_comment_create_date}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <ReactImageCarouselViewer
          open={Is_open}
          images={PreviewArray}
          startIndex={0}
          onClose={() => setIs_open(false)}
          disableScroll={true}
        />
        {OpenJournal && (
          <Add_Edit_journal
            setOpenJournal={setOpenJournal}
            InputValue={InputValue}
            setInputValue={setInputValue}
            send_entry={send_entry}
            Is_editable={Is_editable}
            setImagesArray={setImagesArray}
            ImagesArray={ImagesArray}
          />
        )}
        {DeleteModal && (
          <DeleteCard
            setDeleteModal={setDeleteModal}
            delete_card={delete_card}
          />
        )}
      </div>
    </div>
  );
};

function Add_Edit_journal({
  setOpenJournal,
  InputValue,
  setInputValue,
  send_entry,
  Is_editable,
  setImagesArray,
  ImagesArray,
}) {
  function AttachmentHandle(e) {
    setImagesArray((prev) => {
      return [...e.target.files, ...prev];
    });
  }

  function DeleteAttchment(id) {
    const UpdatedAttchment = ImagesArray?.filter((item, index) => {
      return id != index;
    });
    setImagesArray(UpdatedAttchment);
  }

  return (
    <div className="child_journal_overlay">
      <div className="child_journal_add">
        <h3>{Is_editable ? "Edit journal entry" : "New Journal Entry"}</h3>
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Pen your entry here..."
          value={InputValue}
          onInput={(event) => setInputValue(event.target.value)}
        ></textarea>

        <div className="child_journal_attachment_container">
          <input
            type="file"
            name=""
            id="child_journal_attachment_container"
            accept=".png,.pdf,.jpeg,.jpg"
            multiple
            onChange={AttachmentHandle}
          />
          <label htmlFor="child_journal_attachment_container">
            Add attachment
          </label>
        </div>

        <div className="child_attachment_list_container">
          {ImagesArray?.map((item, index) => {
            let Extension;
            if (item?.name) {
              Extension =
                item?.name?.split(".")[item?.name?.split(".")?.length - 1];
            } else {
              Extension = item?.split(".")[item?.split(".")?.length - 1];
            }
            if (Extension == "pdf") {
              return (
                <div className="pdf_attachement" key={index}>
                  <p>PDF</p>

                  <div className="attatchment_delete_btn">
                    <button
                      onClick={() => {
                        DeleteAttchment(index);
                      }}
                    >
                      <i class="fa-solid fa-circle-xmark"></i>
                    </button>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="image_attachement" key={index}>
                  {item?.name ? (
                    <img src={URL.createObjectURL(item)} alt="" />
                  ) : (
                    <img src={item} alt="" />
                  )}

                  <div className="attatchment_delete_btn">
                    <button
                      onClick={() => {
                        DeleteAttchment(index);
                      }}
                    >
                      <i class="fa-solid fa-circle-xmark"></i>
                    </button>
                  </div>
                </div>
              );
            }
          })}

          {/* <div className="pdf_attachement">
            <p>PDF</p>

            <div className="attatchment_delete_btn">
              <button>
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>
          </div> */}
        </div>

        <div className="child_journal_add_btn">
          <button
            onClick={() => {
              setOpenJournal(false);
              setInputValue("");
              setImagesArray([]);
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (InputValue.trim() != "") {
                send_entry();
              } else {
                toast.warn("Kindly fill the fields");
                toast.clearWaitingQueue();
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
function DeleteCard({ setDeleteModal, delete_card }) {
  return (
    <div className="child_journal_overlay">
      <div className="delete_container">
        <h2>Are you sure to delete this entry?</h2>
        <div>
          <button onClick={() => setDeleteModal(false)}>No</button>
          <button onClick={() => delete_card()}>Yes</button>
        </div>
      </div>
    </div>
  );
}
export default ChildJournal;
