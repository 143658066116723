import React, { useState } from "react";
import "./Pie.css";
import Chart from "react-apexcharts";
import { useEffect } from "react";
const Pie = ({ labelData, ChartData }) => {
  const [IsValid, setIsValid] = useState(true);
  const [PiechartData, setPiechartData] = useState(ChartData);
  useEffect(() => {
    setPiechartData([0, 0, 0, 0, 0, 0, 0, 0]);

    for (let i = 0; i < ChartData?.length; i++) {
      if (ChartData[i] == 0) {
        setIsValid(false);
        setPiechartData([0, 0, 0, 0, 0, 0, 0, 0]);
      } else {
        setIsValid(true);
        setPiechartData(ChartData);
        return;
      }
    }
  }, [ChartData]);

  return (
    <div className="pie_container">
      <div className="pie_btn">
        <p>Grade distribution</p>
        {/* <select name="" id="">
          <option value="">Month</option>
          <option value="">Year</option>
          <option value="">Week</option>
        </select> */}
      </div>

      {!IsValid && (
        <div className="empty_grade">
          <p>
            0<span>%</span>
          </p>
        </div>
      )}

      <Chart
        options={{
          chart: {
            type: "pie",
          },

          legend: {
            position: "right",
          },
          labels: labelData,
          colors: [
            "#ADDAC8",
            "#9FC7C5",
            "#91B7C3",
            "#84A8C1",
            "#789ABF",
            "#6D8DBC",
            "#6380B9",
            "#5875B8",
          ],
          responsive: [
            {
              breakpoint: 980,
              options: {
                chart: {
                  width: 200,
                },
              },
            },
          ],
          tooltip: {
            enabled: IsValid,
          },
          plotOptions: {
            pie: {
              expandOnClick: true,
              dataLabels: {
                offset: 0,
                minAngleToShowLabel: 1,
              },
            },
          },
          dataLabels: {
            // formatter: function (val, opts) {
            //   console.log(val, opts);
            //   return opts.w.config.series[opts.seriesIndex];
            // },
          },
        }}
        series={PiechartData}
        updateSeries={PiechartData}
        type="pie"
        width={320}
      />
    </div>
  );
};

export default Pie;
