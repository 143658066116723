import React from 'react'
import EnrollmentPayment from './EnrollmentPayment/EnrollmentPayment'
import TopMenu from './topMenu/TopMenu'

export default function ChildPage() {
    return (
        <div>

            <TopMenu />


        </div>
    )
}