import React, { useState, forwardRef, useLayoutEffect } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { URL } from "../../../DROPDOWNDATA";
import "./PTMtemplate.css";

const SQC = forwardRef(
  (
    {
      Data,
      SelectedClass,
      setCurrentQuestionIndex,
      CurrentQuestionIndex,
      reloadQuestion,
      setLoader,
    },
    ref
  ) => {
    const [is_otherSelected, setis_otherSelected] = useState(false);
    const [OtherInput, setOtherInput] = useState("");
    const [FirstFive, setFirstFive] = useState([]);
    const [Lastone, setLastone] = useState([]);
    let firstfive = document.getElementsByName("firstfive");
    let lastone = document.getElementsByName("lastone");
    const SubmitAnswer = async () => {
      let First_value = [];
      let Last_value = [];
      let data;
      for (let i = 0; i < firstfive.length; i++) {
        if (firstfive[i]?.checked) {
          First_value.push(firstfive[i]?.value);
        }
      }
      for (let i = 0; i < lastone.length; i++) {
        if (lastone[i]?.checked) {
          Last_value.push(lastone[i]?.value);
        }
      }
      if (Last_value?.length) {
        if (OtherInput == "") {
          toast.error("Kindly fill the other field");
          toast.clearWaitingQueue();
          return;
        }
        data = {
          view_no: SelectedClass.id,
          q_a: [
            {
              question_id: Data?.question_id,
              given_answer: Last_value?.join(),
              others: OtherInput,
            },
          ],
        };
      } else {
        data = {
          view_no: SelectedClass.id,
          q_a: [
            {
              question_id: Data?.question_id,
              given_answer: First_value?.join(),
              others: "",
            },
          ],
        };
      }
      setLoader(true);
      await fetch(`${URL}/add_update_analysis`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify(data),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          console.log(obj.body);
          setCurrentQuestionIndex(CurrentQuestionIndex + 1);
          reloadQuestion();
        })
        .catch((err) => {
          setLoader(false);
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        });
    };

    useEffect(() => {
      Data?.option_list?.map((item, index) => {
        if (item?.option_text == "Others") {
          setLastone((prev) => [...prev, item]);
        } else {
          setFirstFive((prev) => [...prev, item]);
        }
      });
      if (Data?.others != "") {
        setis_otherSelected(true);
        setOtherInput(Data?.others);
      }
    }, [Data]);
    if (FirstFive && Lastone) {
      return (
        <div className="single_question_checkbox">
          <h3>{Data?.main_question}</h3>

          <div className="Ptm_add_checkbox_container">
            <div
              className={
                is_otherSelected
                  ? "ptm_add_checkbox_type1_container active"
                  : "ptm_add_checkbox_type1_container"
              }
            >
              {FirstFive?.map((item, index) => {
                return (
                  <div key={index} className="ptm_add_checkbox_block">
                    <input
                      type="checkbox"
                      name="firstfive"
                      id={item?.option_id}
                      defaultValue={item?.option_id}
                      defaultChecked={item?.is_selected == 1 ? true : false}
                    />
                    <label htmlFor={item?.option_id}>{item?.option_text}</label>
                  </div>
                );
              })}
            </div>
            {Lastone?.map((item, index) => {
              return (
                <div key={index} className="ptm_add_checkbox_type2_container">
                  <div className="ptm_add_checkbox_block">
                    <input
                      type="checkbox"
                      name="lastone"
                      id={item?.option_id}
                      defaultValue={item?.option_id}
                      onChange={(e) => {
                        setis_otherSelected(!is_otherSelected);
                      }}
                      defaultChecked={item?.is_selected == 1 ? true : false}
                    />
                    <label htmlFor={item?.option_id}>{item?.option_text}</label>
                  </div>
                  {is_otherSelected && (
                    <textarea
                      name=""
                      id=""
                      value={OtherInput}
                      onInput={(e) => {
                        setOtherInput(e.target.value);
                      }}
                      cols="30"
                      rows="10"
                    ></textarea>
                  )}
                </div>
              );
            })}
          </div>
          <button
            ref={ref}
            onClick={() => {
              SubmitAnswer();
            }}
          ></button>
          {/* <div className="PTM_add_question_btn_container">
        <button>Prev</button>
        <button>Next</button>
      </div> */}
        </div>
      );
    }
  }
);

export default SQC;
