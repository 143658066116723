import React, { memo } from "react";

const GroupcCassAndName = ({
  ClassList,
  SelectedClass,
  ClassHandler,
  GroupList,
  SelectedGroup,
  GroupSelectHandler,
  ShowState,
  SelectedSchool,
  CreateNewGroup,
}) => {
  return (
    <div className="group_class_name_list_container">
      {ShowState != "preview" && (
        <div className="group_class_item">
          <label htmlFor="groupclass">Select class</label>
          <select
            name=""
            id="groupclass"
            value={SelectedClass}
            onChange={ClassHandler}
          >
            <option value="">Select class</option>
            {ClassList?.map((item, index) => {
              return (
                <option value={item?.class_id} key={index} id={item?.class_id}>
                  {item?.class_name}
                </option>
              );
            })}
          </select>
        </div>
      )}
      {GroupList?.length > 0 && ShowState != "create" && (
        <div className="group_name_item">
          <label htmlFor="groupname">Select group</label>
          <select
            name=""
            id="groupname"
            value={SelectedGroup}
            onChange={GroupSelectHandler}
            disabled={
              ShowState == "edit" ||
              ShowState == "renew" ||
              ShowState == "addnew"
                ? true
                : false
            }
          >
            <option value="">Select group</option>
            {GroupList?.map((item, index) => {
              return (
                <option key={index} value={item?.group_id}>
                  {item?.group_name}
                </option>
              );
            })}
          </select>
        </div>
      )}
      {SelectedSchool?.school_id != "" && ShowState == "preview" && (
        <div className="group_name_item">
          <button onClick={CreateNewGroup}>Create new group</button>
        </div>
      )}
    </div>
  );
};

export default memo(GroupcCassAndName);
