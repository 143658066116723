import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "../../DROPDOWNDATA";
import usePost from "../../Hooks/usePost";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/Loader/Loader";
import { Button, Modal, Select } from "antd";
import { toast } from "react-toastify";
import Modelbox from "./Modelbox";
const { Option } = Select;
const Listview = ({ setBreadcrumItem }) => {
  const { type, id, school_role } = useParams();
  const Navigate = useNavigate();
  const [Listdata, setListdata] = useState([]);
  const [ModalDetails, setModalDetails] = useState({
    is_modal: false,
    id: "",
    type: "",
    selectvalue: "",
  });
  const [Searchvalue, setSearchvalue] = useState("");
  const Postdata = usePost(`${URL}/manage_enabler_view_enabler_list`);
  const Disable_Enabler = usePost(`${URL}/enabler_list_disable`);
  const Delete_enabler = usePost(`${URL}/delete_enabler`);
  const Toggle_enabler = usePost(`${URL}/enable_disable_enabler`);

  const SearchHandler = (e) => {
    setSearchvalue(e.target.value);
    const FilterData = Postdata?.Postdata?.body?.filter((item, index) => {
      return item?.Users_FirstName?.toLowerCase()?.includes(
        e.target.value?.toLowerCase()
      );
    });
    setListdata(FilterData);
  };

  const ManageRole = (user_role) => {
    if (school_role == "0") {
      Navigate(`/addrole/${type}/${btoa(user_role)}/1`);
    } else {
      Navigate(`/addrole/${type}/${btoa(user_role)}/2`);
    }
  };
  const DisableHandler = (id, is_valid) => {
    Disable_Enabler?.Postcall({ enabler_id: id });
    setModalDetails((prev) => ({
      ...prev,
      is_modal: true,
      id,
      selectvalue: "",
      type: is_valid == 1 ? "DISABLE" : "ENABLE",
    }));
  };
  const DeleteHandler = (id) => {
    Disable_Enabler?.Postcall({ enabler_id: id });
    setModalDetails((prev) => ({
      ...prev,
      is_modal: true,
      id,
      selectvalue: "",
      type: "DELETE",
    }));
  };
  const SelectHandler = (e, option, arr) => {
    setModalDetails((prev) => ({ ...prev, selectvalue: e }));
  };
  const handleCancel = () => {
    setModalDetails((prev) => ({
      ...prev,
      is_modal: false,
      id: "",
      type: "",
      selectvalue: "",
    }));
  };
  const handleOk = () => {
    if (ModalDetails?.type != "ENABLE" && ModalDetails?.selectvalue == "") {
      toast.warn("Kindly select enabler");
      toast.clearWaitingQueue();
      return;
    }

    if (ModalDetails?.type == "DELETE") {
      Delete_enabler.Postcall({
        enabler_id: ModalDetails?.id,
        alternate_enabler: ModalDetails?.selectvalue,
      });
    } else {
      Toggle_enabler.Postcall({
        enabler_id: ModalDetails?.id,
        state: ModalDetails?.type == "DISABLE" ? 0 : 1,
        alternate_enabler:
          ModalDetails?.type == "DISABLE"
            ? ModalDetails?.selectvalue
            : ModalDetails?.id,
      });
    }
  };

  useEffect(() => {
    if (school_role == "0") {
      setBreadcrumItem([
        { name: "Manage role", link: `/addrole/all/list/0` },
        { name: atob(type), link: `/listview/${type}/${id}/${school_role}` },
      ]);
    } else {
      setBreadcrumItem([
        { name: "Manage role", link: `/addrole/all/list/0` },
        {
          name: "School Admin list",
          link: `/listview/${type}/list/0`,
        },
        {
          name: "School Admin role",
          link: `/addrole/${type}/${id}/${school_role}`,
        },
        {
          name: atob(school_role),
          link: `/listview/${type}/${id}/${school_role}`,
        },
      ]);
    }
    Postdata.Postcall({
      enabler_role: id == "list" ? atob(type) : atob(school_role),
      user_id: id == "list" ? "" : atob(id),
      search: "",
    });

    return () => {
      setListdata([]);
      setSearchvalue("");
    };
  }, [type, school_role, id]);

  useEffect(() => {
    if (Postdata.Postdata != null) {
      setListdata(Postdata.Postdata?.body);
    }
  }, [Postdata?.Postdata]);

  // DELETE ENABLER
  useEffect(() => {
    if (Delete_enabler?.Postdata != null) {
      toast.success("Successfully deleted ");
      toast.clearWaitingQueue();
      Postdata.Postcall({
        enabler_role: id == "list" ? atob(type) : atob(school_role),
        user_id: id == "list" ? "" : atob(id),
        search: "",
      });
      handleCancel();
    }
  }, [Delete_enabler?.Postdata]);
  // TOGGLE ENABLER
  useEffect(() => {
    if (Toggle_enabler?.Postdata != null) {
      toast.success("Status changed successfully");
      toast.clearWaitingQueue();
      Postdata.Postcall({
        enabler_role: id == "list" ? atob(type) : atob(school_role),
        user_id: id == "list" ? "" : atob(id),
        search: "",
      });
      handleCancel();
    }
  }, [Toggle_enabler?.Postdata]);

  return (
    <>
      <div className="flex items-center justify-end">
        <input
          type="text"
          placeholder="Search profile"
          value={Searchvalue}
          onChange={SearchHandler}
          className="outline-0 border-2 border-[#007872] px-2 py-2 rounded-md pr-6 mb-3"
        />
      </div>
      <div className="grid grid-cols-4 gap-4 overflow-y-auto max-h-[100%]">
        {Listdata?.map((item, index) => {
          return (
            <div
              key={item?.Users_id}
              className="rounded-xl overflow-hidden border-2 border-[#007872]  mx-2 my-2 "
            >
              <div className="p-2 bg-[#FFDAC1] flex">
                <div className="w-[150px] h-[150px] bg-[white] rounded-xl">
                  <img
                    className="w-full h-full object-contain"
                    src={
                      item?.profile_img == null
                        ? "https://static.thenounproject.com/png/4035887-200.png"
                        : item?.profile_img
                    }
                    alt=""
                  />
                </div>
                <div className="w-[250px] flex flex-col justify-between">
                  <div className="flex items-center justify-end">
                    <button
                      className="mx-2 text-xl"
                      onClick={() =>
                        DisableHandler(item?.Users_id, item?.Users_Valid)
                      }
                    >
                      <i class="fa-solid fa-ban"></i>
                    </button>
                    <button
                      className="mx-2 text-xl"
                      onClick={() => DeleteHandler(item?.Users_id)}
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </button>
                  </div>
                  <p className="px-2 text-lg font-medium">
                    {item?.Users_FirstName}
                  </p>
                </div>
              </div>
              <div
                className={`bg-[white] p-2 ${
                  item?.Users_Valid == 0 ? "grayscale pointer-events-none" : ""
                } `}
              >
                <p className="font-semibold">{item?.enabler_role}</p>
                <p>
                  Email Id : <span>{item?.Users_Email}</span>
                </p>
                <p>
                  Mobile No : <span>{item?.Users_Mobile}</span>
                </p>
                <div className="my-2 flex items-center justify-between">
                  <button
                    onClick={() => Navigate(`/viewenabler/${item?.Users_id}`)}
                    className="bg-[#FF9AA2] text-[white] font-medium text-[15px] rounded-3xl px-5 py-2"
                  >
                    View profile
                  </button>
                  {atob(type) == "School admin" && school_role == "0" && (
                    <button
                      onClick={() => ManageRole(item?.Users_id)}
                      className="bg-[#FF9AA2] text-[white] font-medium text-[15px] rounded-3xl px-5 py-2"
                    >
                      Manage role
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <Modelbox
          ModalDetails={ModalDetails}
          handleCancel={handleCancel}
          handleOk={handleOk}
          SelectHandler={SelectHandler}
          Disable_Enabler={Disable_Enabler}
        />
        {Postdata.LoadingPost ||
          Disable_Enabler?.LoadingPost ||
          Delete_enabler?.LoadingPost ||
          (Toggle_enabler?.LoadingPost && <Loader />)}
      </div>
    </>
  );
};

export default Listview;
