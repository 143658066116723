import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import DataTable from "../../../../components/DataTable/DataTable";
import TopMenu from "../../topMenu/TopMenu";
import "./InstallmentDetails.css";
import { NavLink } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";
import { ToastContainer, toast } from 'react-toastify';
import { URL } from "../../../../DROPDOWNDATA";
import { useParams } from "react-router-dom";
import GenerateLink from "../EnrollmentComponents/GenerateLink";
const installment_details = [];
// const installment_details = [
//     {
//         "si_no": "payment",
//         "due_date": "25-02-2022",
//         "total_amount": "1000",
//         "amount_due": "500",
//         "amount_paid": "500",
//         "paid_on": "26/04/2022",
//         "is_complete": 0
//     }
// ]


function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export default function InstallmentDetails(props) {
    const { id, childname } = useParams()
    // const CHILD_ID = localStorage.getItem('childID')?.replace('DL', '')
    // const CHILD_ID = localStorage.getItem('childID')
    const CHILD_ID = atob(id)

    const [addtransactionDate, setTransactionDateRef] = useState(null);

    const [isDownpayment, setisDownpayment] = useState("")

    // const transactionDateRef = useRef()
    const transactionPaymentAmountRef = useRef()
    const transactionReferenceRef = useRef()
    const transactionCommentRef = useRef()

    // popup window state
    const [installmentDetailsValue, setInstallment_details] = useState(
        {
            state: installment_details.length > 0,
            payment_id: null,
            isCurrentPaymentIsComplete: false,
            totalBalanceForEachTransaction: 0
        }
    );

    const [showAddInstallmentTransactionDetails, setAddInstallmentTransactionDetailsToogle] = useState({
        addElements: false,
        AddBtn: true,
    });

    const [errorFieldMessages, setErrorFieldMessages] = useState({
        error__showTransactionDate: false,
        error__showTransactionReference: false,
        error__showTransactionComment: false,
        error__showPaymentAmount: false,

        error__showPaymentAmountMessage: null
    })


    const [EMI_details, setEMI_details] = useState([])
    const [paymentTransactionDetails, setPaymentTransactionDetails] = useState([])

    const [loader, setLoader] = useState(false)
    const [OpenGenerateModal, setOpenGenerateModal] = useState(false);
    const [copyLink, setcopyLink] = useState();
    async function getEMIDetails() {

        setLoader(true)
        let item = {
            "child_id": CHILD_ID
        }
        await fetch(URL + '/view_emi', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('loggedToken')
            },
            body: JSON.stringify(item)
        }).then(r => r.json().then(data => ({ status: r.status, body: data })))
            .then(obj => {
                setLoader(false)


                setEMI_details(obj.body)
            });
    }

    useEffect(() => {
        getEMIDetails()

        props.setBreadcrumItem(
            [{ name: 'Student List', link: "/" },
            { name: atob(childname), link: `/child_enrollment_payment/${childname}/${id}` },
            { name: 'EMI Details', link: `/installment_details/${childname}/${id}` }]
        )
    }, [])

    async function getPaymentTransactionDetails(payment_id) {


        setLoader(true)
        let item = {
            "transaction_id": payment_id
        }
        await fetch(URL + '/view_installment_list', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('loggedToken')
            },
            body: JSON.stringify(item)
        }).then(r => r.json().then(data => ({ status: r.status, body: data })))
            .then(obj => {
                setLoader(false)

                setPaymentTransactionDetails(obj.body)
                // setEMI_details(obj.body)
            });
    }

    async function AddPaymentTransactionDetails(transactionDate, transactionReference, transactionComment) {


        // setLoader(true)
        let item = {
            "transaction_id": installmentDetailsValue.payment_id,
            "transaction_date": formatDate(transactionDate),
            "transaction_reference": transactionReference,
            "transaction_comment": transactionComment,
            "transaction_amount": transactionPaymentAmountRef.current.value,
            "is_complete": ((installmentDetailsValue.totalBalanceForEachTransaction - transactionPaymentAmountRef.current.value) == 0) ? 1 : 0,
            "balance": (installmentDetailsValue.totalBalanceForEachTransaction - transactionPaymentAmountRef.current.value)
        }
        await fetch(URL + '/add_installment', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('loggedToken')
            },
            body: JSON.stringify(item)
        }).then(r => r.json().then(data => ({ status: r.status, body: data })))
            .then(obj => {
                // setLoader(false)

                // setPaymentTransactionDetails(obj.body)
                // setEMI_details(obj.body)

                getPaymentTransactionDetails(installmentDetailsValue.payment_id)

                setInstallment_details({
                    ...installmentDetailsValue,
                    totalBalanceForEachTransaction: item.balance
                })

            });
    }


    const Emi_payment_link = async (id) => {
        setLoader(true)
        await fetch(URL + '/copy_emi_link', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('loggedToken')
            },
            body: JSON.stringify({
                child_view_id: CHILD_ID,
                transaction_id: id
            })
        }).then(r => r.json().then(data => ({ status: r.status, body: data })))
            .then(obj => {
                setLoader(false)

                setcopyLink(obj.body);
                setOpenGenerateModal(true)

            }).catch((err) => {
                setLoader(false)
            })
    }

    if (loader) {
        return (<Loader />)
    }

    return (
        <div className="installment_details_mainContainer">
            <TopMenu id={id} childname={childname} />

            <div className="installment_details_container">
                <p className="heading">Installment Details</p>

                <div className="tableContainer">
                    <div className="ltr">
                        <table className="mytable">
                            <thead>
                                <tr>
                                    <th>Payment</th>
                                    <th>Due Date</th>
                                    <th>Amount due</th>
                                    <th>Amount paid</th>
                                    <th>Paid on</th>
                                    <th>Action</th>
                                    <th>Payment link</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    EMI_details.map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td>{item.payment_id}</td>
                                                <td>{item.due_date}</td>
                                                <td>{item.amount_due}</td>
                                                <td>{item.amount_paid}</td>
                                                <td>{item.total_paid_on}</td>
                                                <td className={"action_table_item"}>
                                                    <span
                                                        className={item.is_locked == 1 ? "disabled" : ""}
                                                        onClick={() => {
                                                            setInstallment_details({
                                                                ...installmentDetailsValue,
                                                                state: true,
                                                                payment_id: item.si_no,
                                                                isCurrentPaymentIsComplete: item.is_complete,
                                                                totalBalanceForEachTransaction: item.total_balance
                                                            })

                                                            getPaymentTransactionDetails(item.si_no)
                                                            setisDownpayment(item.payment_id)
                                                        }}>
                                                        <i className="fas fa-eye"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    {item.is_locked == 0 && item.is_complete == 0 && item.amount_paid == 0 && <button onClick={() => Emi_payment_link(item.si_no)} className="emi_link">link</button>}


                                                </td>
                                            </tr>
                                        )

                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

                <NavLink to={`/child_enrollment_payment/${childname}/${id}`} className="btn btn2">
                    <i className="fas fa-angle-left"></i> Back
                </NavLink>

            </div>

            <div className={installmentDetailsValue.state
                ? "view_transaction_details_container active"
                : "view_transaction_details_container"}>

                <div className="view_transaction_details">
                    <div className="header">
                        <p className="heading">Installment Details</p>
                        <span
                            onClick={() => {
                                setInstallment_details({
                                    ...installmentDetailsValue,
                                    state: false
                                });
                            }}>

                            <span onClick={() => {
                                //come here
                                getEMIDetails()
                                setAddInstallmentTransactionDetailsToogle({
                                    addElements: false,
                                    AddBtn: true,
                                });

                            }}>&times;</span>
                        </span>
                    </div>


                    <div className="tableContainer">
                        <div className="ltr">
                            <table className="mytable">
                                <thead>
                                    <tr>
                                        <th>Amount paid</th>
                                        <th>Paid on</th>
                                        <th>Transaction Reference</th>
                                        <th>Comment</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        paymentTransactionDetails.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.transaction_amount}</td>
                                                    <td>{item.transaction_date}</td>
                                                    <td>{item.transaction_reference}</td>
                                                    <td>{item.transaction_comment}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <span className={paymentTransactionDetails.length > 0 ? "no_record none" : "no_record"}>
                        No Entry available
                    </span>

                    <div className="add_installment_transaction_details">
                        <div className={showAddInstallmentTransactionDetails.addElements ? "add_installment_transaction_details_elements" : "add_installment_transaction_details_elements none"
                        }>

                            <TextField
                                type="number"
                                autoComplete='off'
                                id="outlined-basic"
                                label="Payment Amount"
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: installmentDetailsValue.totalBalanceForEachTransaction
                                    }
                                }}
                                disabled={isDownpayment == 'Down Payment' ? true : false}
                                defaultValue={isDownpayment == 'Down Payment' && EMI_details[0]?.amount_due}
                                inputRef={transactionPaymentAmountRef}
                                error={errorFieldMessages.error__showPaymentAmount}
                                helperText={errorFieldMessages.error__showPaymentAmount ? errorFieldMessages.error__showPaymentAmountMessage : ''}

                                onChange={(newValue) => {

                                    if (newValue.target.value == "") {
                                        setErrorFieldMessages({
                                            ...errorFieldMessages,
                                            error__showPaymentAmount: true,
                                            error__showPaymentAmountMessage: "Payment Amount cant be empty"
                                        })
                                    }
                                    else if (newValue.target.value == 0) {
                                        setErrorFieldMessages({
                                            ...errorFieldMessages,
                                            error__showPaymentAmount: true,
                                            error__showPaymentAmountMessage: "Payment Amount can't be 0"
                                        })
                                    }
                                    else if (newValue.target.value < 0) {
                                        setErrorFieldMessages({
                                            ...errorFieldMessages,
                                            error__showPaymentAmount: true,
                                            error__showPaymentAmountMessage: "Payment Amount can't be negative values"
                                        })

                                    } else if (newValue.target.value > installmentDetailsValue.totalBalanceForEachTransaction) {

                                        setErrorFieldMessages({
                                            ...errorFieldMessages,
                                            error__showPaymentAmount: true,
                                            error__showPaymentAmountMessage: "Payment Amount can't be greater than balance amount"
                                        })

                                    } else {
                                        setErrorFieldMessages({
                                            ...errorFieldMessages,
                                            error__showPaymentAmount: false
                                        })
                                    }

                                }}

                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    disableFuture
                                    label="Enter transaction date"
                                    inputFormat={'dd/MM/yyyy'}

                                    // inputFormat={'yyyy/mm/dd'}
                                    // inputRef={transactionDateRef}
                                    value={addtransactionDate}
                                    onChange={(newValue) => {

                                        setTransactionDateRef(newValue);
                                    }}

                                    onError={(error) => {
                                        if (error) {
                                            setErrorFieldMessages({
                                                ...errorFieldMessages,
                                                error__showTransactionDate: true
                                            })
                                        } else {
                                            setErrorFieldMessages({
                                                ...errorFieldMessages,
                                                error__showTransactionDate: false
                                            })
                                        }
                                    }}

                                    renderInput={
                                        (params) => <TextField autoComplete='off'
                                            {...params} helperText={params?.inputProps?.placeholder} />
                                    }
                                />
                            </LocalizationProvider>

                            <TextField
                                autoComplete="off"
                                className="textfield"
                                id="outlined-basic"
                                label="Enter transaction reference"
                                variant="outlined"
                                error={errorFieldMessages.error__showTransactionReference}
                                helperText={errorFieldMessages.error__showTransactionReference ? 'Cant use Special chars!' : ''}

                                onChange={(newValue) => {

                                    if (newValue.target.value.match(/[^a-zA-Z0-9 ]+/)) {
                                        setErrorFieldMessages({
                                            ...errorFieldMessages,
                                            error__showTransactionReference: true
                                        })
                                    } else {
                                        setErrorFieldMessages({
                                            ...errorFieldMessages,
                                            error__showTransactionReference: false
                                        })
                                    }
                                }}

                                inputRef={transactionReferenceRef}
                            />
                            <TextField
                                autoComplete="off"
                                className="textfield"
                                id="outlined-basic"
                                label="Enter Comment"
                                variant="outlined"
                                error={errorFieldMessages.error__showTransactionComment}
                                helperText={errorFieldMessages.error__showTransactionComment ? 'Cant use Special chars!' : ''}
                                onChange={(newValue) => {

                                    if (newValue.target.value.match(/[^a-zA-Z ]+/)) {
                                        setErrorFieldMessages({
                                            ...errorFieldMessages,
                                            error__showTransactionComment: true
                                        })
                                    } else {
                                        setErrorFieldMessages({
                                            ...errorFieldMessages,
                                            error__showTransactionComment: false
                                        })
                                    }
                                }}
                                inputRef={transactionCommentRef}
                            />

                            <button
                                className="btn btn2"
                                onClick={() => {

                                    if (transactionPaymentAmountRef.current.value == "") {
                                        toast.warn('Please Transaction Payment Amount');
                                        toast.clearWaitingQueue()
                                        return
                                    } else if (addtransactionDate == null) {
                                        toast.warn('Please Enter Transaction Date');
                                        toast.clearWaitingQueue()
                                        return
                                    } else if (transactionReferenceRef.current.value == "") {
                                        toast.warn('Please Enter Transaction Reference');
                                        toast.clearWaitingQueue()
                                        return
                                    } else if (transactionCommentRef.current.value == "") {
                                        toast.warn('Please Enter Transaction Comment');
                                        toast.clearWaitingQueue()
                                        return
                                    }


                                    if (errorFieldMessages.error__showPaymentAmount
                                        || errorFieldMessages.error__showTransactionDate
                                        || errorFieldMessages.error__showTransactionReference
                                        || errorFieldMessages.error__showTransactionComment) {

                                        toast.warn('Fields contains error');
                                        toast.clearWaitingQueue()
                                        return
                                    } else {

                                        toast.success('Transaction Added Successfully');
                                        toast.clearWaitingQueue()
                                        setAddInstallmentTransactionDetailsToogle({
                                            addElements: false,
                                            AddBtn: (installmentDetailsValue.totalBalanceForEachTransaction == transactionPaymentAmountRef.current.value) ? false : true,
                                        });



                                        AddPaymentTransactionDetails(addtransactionDate, transactionReferenceRef.current.value, transactionCommentRef.current.value)

                                        getEMIDetails() // Calling this -> To refresh the emi details


                                    }


                                }}>
                                Done <i className="fas fa-paper-plane"></i>
                            </button>
                        </div>

                        <div className={showAddInstallmentTransactionDetails.AddBtn
                            ? (!installmentDetailsValue.totalBalanceForEachTransaction <= 0) ? "action_btn" : "action_btn none"
                            : "action_btn none"}>
                            <button className={installmentDetailsValue.isCurrentPaymentIsComplete ? "btn btn2 none" : "btn btn2 show"}
                                onClick={() => {
                                    setAddInstallmentTransactionDetailsToogle({
                                        addElements: true,
                                        AddBtn: false,
                                    });
                                }}>
                                Add <i className="fas fa-plus-circle"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div
                className={
                    OpenGenerateModal
                        ? "generate_link_container"
                        : "generate_link_container hide"
                }
            >
                <GenerateLink
                    setOpenGenerateModal={setOpenGenerateModal}
                    copyLink={copyLink?.url}
                    id={id}
                    childname={childname}
                />
            </div>
        </div >
    );
}
