import React, { useState } from "react";
import "./topbar.css";
import profileAvatar from "../../../images/boy.png";
import { Drawer } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const { confirm } = Modal;

export default function Topbar(props) {
  const UserRole = useSelector((store) => store.privilege);
  const History = useNavigate();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const LogoutModal = () => {
    confirm({
      centered: true,
      title: "Do you Want to Logout?",
      icon: <ExclamationCircleFilled />,
      content: "",
      okButtonProps: {
        type: "primary",
        style: { backgroundColor: "#007872", color: "#fff" },
      },
      onOk() {
        document.querySelectorAll(".nav li a").forEach((item) => {
          item.classList.remove("active");
        });
        // document.querySelectorAll('.nav li a')[1].classList.add('active')
        History("/login", {});
        localStorage.clear();
        window.localStorage.clear();
        window.location.reload();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <div className="topbar">
      <div className="hamburger_menu">
        <span className="welcomeText">Admin Portal</span>
      </div>
      <button className="mr-10 text-[32px]" onClick={showDrawer}>
        <i class="fa-solid fa-bars"></i>
      </button>
      {/* <div className="profile">
        <ul>
          <li> */}
      {/* <img
              // onClick={() => {
              //   props.setLogoutState(true);
              // }}
              onClick={showDrawer}
              src={profileAvatar}
              alt=""
              width="100%"
            /> */}
      {/* <button onClick={showDrawer}>
              <i class="fa-solid fa-bars"></i>
            </button>
          </li>
        </ul>
      </div> */}

      <Drawer
        title="Admin profile"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div className="flex flex-col items-start justify-between h-full">
          <div>
            <div className="bg-[#ccc] rounded-full overflow-hidden w-[80px] h-[80px] p-2 mb-5">
              <img
                className="w-full h-full"
                src="https://static.thenounproject.com/png/4035887-200.png"
                alt=""
              />
            </div>
            <p className="text-[16px] font-semibold text-[#000] underline">
              {UserRole?.role == 'schooladmin' ? 'School admin' : UserRole?.role}
            </p>
            <p className="text-[22px] font-medium mb-3">{UserRole?.username}</p>
            <p className="text-[16px] font-medium">Email : {UserRole?.email}</p>
            {/* <p className="text-[16px] font-medium">
              Mobile no : {UserRole?.contact_number}
            </p> */}
          </div>

          <button
            onClick={LogoutModal}
            className="text-left mt-[30px] py-4 px-2 text-[20px] text-[tomato] w-full hover:bg-[#ccc8]"
          >
            <i class="fa-solid fa-arrow-right-to-bracket mr-3"></i> Logout
          </button>
        </div>
      </Drawer>
    </div>
  );
}
