import React, { useEffect, useState } from "react";
import "./EnrollDonut.css";
import Chart from "react-apexcharts";
const Donut = ({ Label, data, FilterValue, total_count }) => {
  const [isvalid, setisvalid] = useState(false);
  useEffect(() => {
    for (let i = 0; i < data.length; i++) {
      if (data[i] == 0) {
        setisvalid(false);
        // console.log(overAll[SorceData]?.count_data[i]);
      } else {
        setisvalid(true);
        return;
      }
    }
    setTimeout(() => {
      document.querySelectorAll(".apexcharts-pie-area").forEach((item) => {
        item.style.stroke = `${item.getAttribute("fill")}`;
        item.style.filter = `drop-shadow(0px 0px 8px ${item.getAttribute(
          "fill"
        )}`;
        item.style.strokeWidth = 9;
      });
    }, 1000);
  }, [data]);

  return (
    <div className="donut_container">
      {isvalid && (
        <div className="child_count_donut enrollment_count">
          <h3>{total_count}</h3>
          <p>
            overall <br /> Enrollment
          </p>
        </div>
      )}
      <div className="donut_btn">
        <p>Enrollment</p>
        <span>
          {FilterValue?.category == 4
            ? "Yearly"
            : FilterValue?.category == 3
            ? "Monthly"
            : "This data  shows Monthly"}
        </span>
      </div>
      {!isvalid && (
        <div className="empty_grade_donut">
          <p>
            0<span>%</span>
          </p>
        </div>
      )}
      {data && (
        <Chart
          options={{
            chart: {
              type: "donut",
            },
            legend: {
              position: "bottom",
            },
            labels: Label,
            colors: ["#5875B8", "#F08391", "#ADDAC8", "#FECFB3"],
            responsive: [
              {
                breakpoint: 980,
                options: {
                  chart: {
                    width: 200,
                  },
                },
              },
            ],
            dataLabels: {
              // formatter: function (val, opts) {
              //   return opts.w.config.series[opts.seriesIndex];
              // },
            },
          }}
          series={data}
          type="donut"
        />
      )}
    </div>
  );
};

export default Donut;
