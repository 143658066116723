import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Select from "react-select";
import OutsideClickHandler from "react-outside-click-handler";
import { URL } from "../../../DROPDOWNDATA";
import { Dropdown, Selection } from "react-dropdown-now";
import "react-dropdown-now/style.css";
import Loader from "../../../components/Loader/Loader";
import FileSaver from "file-saver";
const Enablerscreening = ({ AssessorID, Fiterdate }) => {
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  const [Enabler_List, setEnabler_List] = useState([
    { users_firstname: "name", users_id: 1 },
    { users_firstname: "name 1", users_id: 2 },
    { users_firstname: "name 2", users_id: 3 },
  ]);
  const [ActiveDropdown, setActiveDropdown] = useState(0);
  const [SearchValue, setSearchValue] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const [EnablerData, setEnablerData] = useState([]);
  const [loader, setloader] = useState(false);

  const [DownloadReport_date, setDownloadReport_date] = useState({
    from_day: "",
    from_month: "",
    from_year: "",
    to_day: "",
    to_month: "",
    to_year: "",
  });
  const [ShowDownload, setShowDownload] = useState(false);
  const [FilterData, setFilterData] = useState({
    sent_to_parent: 0,

    approved: 0,

    pass_to_accessor: 0,

    pass_to_approver: 0,

    report_started: 0,
    yet_to_start: 0,
  });

  const ReportStatus = (e) => {
    setFilterData(FilterData, (FilterData[e.value] = e.checked ? 1 : 0));

    Get_Enabler_data2();
  };

  async function Get_Enabler_data(pg) {
    setloader(true);
    let data;
    await fetch(`${URL}/enabler_child_screening_analysis?page_no=${pg}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify(
        (data = {
          enabler_id: AssessorID,
          search: SearchValue,
          start_date: Fiterdate?.start,
          end_date: Fiterdate?.end,
          filter_data: {
            sent_to_parent: FilterData.sent_to_parent,

            approved: FilterData.approved,

            pass_to_accessor: FilterData.pass_to_accessor,

            pass_to_approver: FilterData.pass_to_approver,

            report_started: FilterData.report_started,
            yet_to_start: FilterData.yet_to_start,
          },
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setloader(false);
        setEnablerData(obj.body);
        // setOverallEnabler_Data(obj.body);
      })
      .catch((err) => {
        setloader(false);
      });
  }
  async function Get_Enabler_data2() {
    setCurrentPage(1);
    let data;
    await fetch(`${URL}/enabler_child_screening_analysis?page_no=${1}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify(
        (data = {
          enabler_id: AssessorID,
          search: SearchValue,
          start_date: Fiterdate?.start,
          end_date: Fiterdate?.end,
          filter_data: {
            sent_to_parent: FilterData.sent_to_parent,

            approved: FilterData.approved,

            pass_to_accessor: FilterData.pass_to_accessor,

            pass_to_approver: FilterData.pass_to_approver,

            report_started: FilterData.report_started,
            yet_to_start: FilterData.yet_to_start,
          },
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setEnablerData(obj.body);
        // setOverallEnabler_Data(obj.body);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    let enabler_IS = true;

    if (enabler_IS) {
      setEnablerData([]);
      Get_Enabler_data(CurrentPage);
    }

    return () => {
      enabler_IS = false;
      setEnablerData([]);
    };
  }, [AssessorID]);

  useEffect(() => {
    let enabler_IS = true;

    if (enabler_IS) {
      setEnablerData([]);
      Get_Enabler_data2();
    }

    return () => {
      enabler_IS = false;
      setEnablerData([]);
    };
  }, [SearchValue]);
  const DownloadReport = async () => {
    let data;
    await fetch(`${URL}/enabler_child_screening_analysis_download`, {
      method: "POST",
      headers: {
        Authorization: authKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        (data = {
          enabler_id: AssessorID,
          search: "",
          start_date: Fiterdate?.start,
          end_date: Fiterdate?.end,
          filter_data: {
            sent_to_parent: FilterData.sent_to_parent,

            approved: FilterData.approved,

            pass_to_accessor: FilterData.pass_to_accessor,

            pass_to_approver: FilterData.pass_to_approver,

            report_started: FilterData.report_started,
            yet_to_start: FilterData.yet_to_start,
          },
        })
      ),
    })
      .then((r) => r.blob())
      .then((obj) => {
        FileSaver.saveAs(obj, "fime.xlsx");
      })
      .catch((err) => {});
  };

  if (loader) {
    return <Loader />;
  }
  return (
    <div className="enabler_screening_container">
      {ShowDownload && (
        <div className="download_report_container">
          <div className="download_heading">
            <h3>Download report</h3>
            <button onClick={() => setShowDownload(false)}>
              <i className="fa-solid fa-circle-xmark"></i>
            </button>
          </div>

          <div className="download_report_date_range">
            <div className="from_date_container">
              <p>From date</p>

              <div className="from_date_input_container">
                <input type="date" name="" id="" />
              </div>
            </div>
            <div className="to_date_container">
              <p>To date</p>
              <div className="from_date_input_container">
                <input type="date" name="" id="" />
              </div>
            </div>
          </div>

          <button
            className="download_report_btn_filter"
            onClick={() => console.log(DownloadReport_date)}
          >
            Download
          </button>
        </div>
      )}

      <div className="enabler_screening_filter">
        <div style={{ position: "relative" }}>
          {SearchValue.length > 0 && (
            <button
              className="clear"
              style={{
                position: "absolute",
                right: "5%",
                top: "50%",
                transform: "translatey(-50%)",
                backgroundColor: "transparent",
                color: "grey",
                border: "none",
                outline: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                setSearchValue("");
              }}
            >
              <i className="fa-solid fa-circle-xmark"></i>
            </button>
          )}
          <input
            type="text"
            placeholder="child name"
            value={SearchValue}
            onInput={(e) => {
              setSearchValue(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>
        <button
          className="download_report_btn"
          onClick={() => {
            DownloadReport();
          }}
        >
          <i className="fa-solid fa-download"></i> Download
        </button>
      </div>

      <table className="enabler_report_table">
        <thead>
          <tr>
            <th>Child name</th>
            <th>Level 1 completed on</th>
            <th>Level 2 completed on</th>
            <th>Report submitted for review</th>
            <th
              className="enabler_table_dropdown"
              onClick={() => {
                if (ActiveDropdown == 1) {
                  setActiveDropdown(0);
                } else {
                  setActiveDropdown(1);
                }
              }}
            >
              {" "}
              <OutsideClickHandler
                onOutsideClick={() => {
                  setActiveDropdown(0);
                }}
              >
                <p
                  onClick={() => {
                    if (ActiveDropdown == 1) {
                      setActiveDropdown(0);
                    } else {
                      setActiveDropdown(1);
                    }
                  }}
                >
                  {" "}
                  Report status
                  <i className="fa-solid fa-caret-down"></i>
                </p>

                <div
                  className={
                    ActiveDropdown == 1
                      ? "enabler_screeing_dropdown_list"
                      : "enabler_screeing_dropdown_list active"
                  }
                >
                  <div className="input_container">
                    <input
                      type="checkbox"
                      id="enablerscreening_approver"
                      name="cancelled"
                      className="DD_Input"
                      value={"approved"}
                      onChange={(e) => ReportStatus(e.target)}
                    />
                    <label
                      htmlFor="enablerscreening_approver"
                      className="DD_Label"
                    >
                      <i className="fa-solid fa-check"></i>
                      <p>Approved</p>
                    </label>
                  </div>
                  <div className="input_container">
                    <input
                      type="checkbox"
                      id="enablerscreening_pending_accessor"
                      name="cancelled"
                      className="DD_Input"
                      value={"pass_to_accessor"}
                      onChange={(e) => ReportStatus(e.target)}
                    />
                    <label
                      htmlFor="enablerscreening_pending_accessor"
                      className="DD_Label"
                    >
                      <i className="fa-solid fa-check"></i>
                      <p>Pending with assessor</p>
                    </label>
                  </div>
                  <div className="input_container">
                    <input
                      type="checkbox"
                      id="enablerscreening_pending_approver"
                      name="cancelled"
                      className="DD_Input"
                      value={"pass_to_approver"}
                      onChange={(e) => ReportStatus(e.target)}
                    />
                    <label
                      htmlFor="enablerscreening_pending_approver"
                      className="DD_Label"
                    >
                      <i className="fa-solid fa-check"></i>
                      <p>Pending with approver</p>
                    </label>
                  </div>
                  <div className="input_container">
                    <input
                      type="checkbox"
                      id="enablerscreeningReport"
                      name="cancelled"
                      className="DD_Input"
                      value={"report_started"}
                      onChange={(e) => ReportStatus(e.target)}
                    />
                    <label
                      htmlFor="enablerscreeningReport"
                      className="DD_Label"
                    >
                      <i className="fa-solid fa-check"></i>
                      <p>Report shared</p>
                    </label>
                  </div>
                  <div className="input_container">
                    <input
                      type="checkbox"
                      id="enablerscreeningsendtoparent"
                      name="cancelled"
                      className="DD_Input"
                      value={"sent_to_parent"}
                      onChange={(e) => ReportStatus(e.target)}
                    />
                    <label
                      htmlFor="enablerscreeningsendtoparent"
                      className="DD_Label"
                    >
                      <i className="fa-solid fa-check"></i>
                      <p>Sent to parent</p>
                    </label>
                  </div>
                  <div className="input_container">
                    <input
                      type="checkbox"
                      id="enablerscreeningsendtoparentyet_to_start"
                      name="cancelled"
                      className="DD_Input"
                      value={"yet_to_start"}
                      onChange={(e) => ReportStatus(e.target)}
                    />
                    <label
                      htmlFor="enablerscreeningsendtoparentyet_to_start"
                      className="DD_Label"
                    >
                      <i className="fa-solid fa-check"></i>
                      <p>Yet to start</p>
                    </label>
                  </div>
                </div>
              </OutsideClickHandler>
            </th>
            <th>Approved on</th>
            <th>Approver name</th>
            <th>Sent to parent on</th>
          </tr>
        </thead>
        <tbody>
          {EnablerData?.details?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item?.child_name}</td>
                <td>
                  {item?.level_1_completed_on != null
                    ? item?.level_1_completed_on
                    : "-"}
                </td>
                <td>
                  {item?.level_2_completed_on != null
                    ? item?.level_2_completed_on
                    : "-"}
                </td>
                <td>
                  {item?.first_time_sent_for_approval != null
                    ? item?.first_time_sent_for_approval
                    : "-"}
                </td>
                <td>{item?.report_status}</td>
                <td>{item?.approved_on != null ? item?.approved_on : "-"}</td>
                <td>
                  {item?.approver_name != null ? item?.approver_name : "-"}
                </td>
                <td>
                  {item?.send_report_to_parent != null
                    ? item?.send_report_to_parent
                    : "-"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        activePage={CurrentPage}
        itemsCountPerPage={10}
        totalItemsCount={EnablerData?.count}
        pageRangeDisplayed={10}
        itemClass="paginationLi_new"
        prevPageText="Prev"
        nextPageText="Next"
        activeLinkClass="pagLi_sc"
        activeClass="pagLi_sc"
        itemClassFirst="go_first"
        itemClassLast="go_first"
        itemClassPrev="prev_btn"
        itemClassNext="next_btn"
        onChange={(e) => {
          console.log(e);
          setCurrentPage(e);
          Get_Enabler_data(e);
        }}
      />
    </div>
  );
};

export default Enablerscreening;
