import React from 'react'
import IndividualSchedule from '../scheduleSession/IndividualSchedule/IndividualSchedule'
import { Autocomplete, TextField } from '@mui/material';
// import { Calendar } from 'react-calendar';import AdapterDateFns from '@mui/lab/AdapterDateFns';
import "./reschedule.css"

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const top100Films = [
    { label: 'g', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 }
];

function ElementComponent(props) {

    return (
        <div className="select-element">
            <p>{props.element_name}</p>

            <Autocomplete
                disablePortal
                id="combo-box"
                options={top100Films}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label={props.element_name} />}
            />

        </div>
    )
}


export default function Reschedule() {
    return (

        <div className='reschedule_container' >


            <div className="reschedule_session_Layout">

                <p className='heading'>Reschedule Session</p>

                <div className='block'>
                    < ElementComponent element_name="Current Day" />
                    < ElementComponent element_name="Current Time" />
                </div>

                <div className='block'>
                    < ElementComponent element_name="Enter New Date" />
                    < ElementComponent element_name="Enter New Time" />
                </div>

                <div className='block'>

                    <div className='radio_btn_container'>

                        <p className='heading'>Remarks</p>

                        <div className="radio_btn_holder">

                            <input name='reason_for_cancel' type="radio" id='child_side' />
                            <label htmlFor="child_side">Child side cancellation</label>
                        </div>

                        <div className="radio_btn_holder">

                            <input name='reason_for_cancel' type="radio" id='enabler_side' />
                            <label htmlFor="enabler_side">Enabler side cancellation</label>
                        </div>

                        <div className="radio_btn_holder">

                            <input name='reason_for_cancel' type="radio" id='other_side' checked />
                            <label htmlFor="other_side">Other :</label>
                        </div>
                        <input type="text" name="" id="" className='otherInputTextBx' placeholder='Enter reason for reschedule' />

                    </div>

                    < ElementComponent element_name="Select Enablers" />
                </div>


                <div className="create_reschedule_btns_holder">
                    <button className='btn btn1'>Cancel</button>
                    <button className='btn btn2'>Save</button>
                </div>

            </div>



        </div>



    )
}
