import Navbar from "./components/Navbar/Navbar";
import Topbar from "./components/Navbar/topbar/Topbar";
import "./app.css"
import { useState } from "react";
import { BrowserRouter, Routes, HashRouter, Route, Switch, useNavigate, Redirect, useLocation } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Scheduling from "./pages/scheduling/Scheduling";
import ScheduleSession from "./pages/scheduleSession/ScheduleSession";
import ViewSchedule from "./pages/ViewSchedule/ViewSchedule";
import RescheduleScreen from "./pages/Reschedule/Reschedule";
import Breadcrumbs from "./components/Navbar/Breadcrumbs/Breadcrumbs";
import StudentList from "./pages/StudentList/StudentList";
import ChildPage from "./pages/childPage/ChildPage";
import EnrollmentPayment from "./pages/childPage/EnrollmentPayment/EnrollmentPayment";
import InstallmentDetails from "./pages/childPage/EnrollmentPayment/InstallmentDetails/InstallmentDetails";
import LoginPage from "./pages/loginPage/LoginPage";
import Logout from "./components/ModalBox/Logout/Logout";
import IndividualScheduleList from "./components/individualSchedule/IndividualScheduleList";
import ScheduleList from "./components/scheduleList/ScheduleList";
import ChildJournal from './pages/Childjournal/ChildJournal'
import ChildDetails from "./pages/ChildDetails/ChildDetails"
import HttpsRedirect from 'react-https-redirect';
import Screeningreport from "./pages/Screeningprocess/Screeningreport";
import Screeningprocess from "./pages/Screeningreport/Screeningprocess";
import Notification from './pages/Notification/Notification'
import Manageschool from "./pages/ManageSchool/Manageschool";
import Detailview from "./pages/ManageSchool/Detailview/Detailview";
import Gradeddetails from "./pages/Gradeddetails/Gradeddetails";
import Manageenabler from "./pages/Manageenabler/Manageenabler";
import Addenabler from "./pages/Manageenabler/Addenabler/Addenabler";
import Viewenabler from "./pages/Manageenabler/Viewenabler/Viewenabler";
import PTM from "./pages/PTM/PTM";
import RenewEnroll from "./pages/Renewenroll/RenewEnroll";
import Groupenrollment from "./pages/GroupEnrollment/Groupenrollment";
import Groupschedule from "./pages/Groupschedule/Groupschedule";
import Individualgroupdetail from "./pages/GroupindividualDetails/Individualgroupdetail";
import Individualenrollment from "./pages/IndividualEnrollment/Individualenrollment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Update_user_details } from "./Redux/Action/Useraction";
import Schooldashboard from "./pages/SchoolDashboard/Schooldashboard";
import { URL } from "./DROPDOWNDATA";
import Addrole from "./pages/Addrole/Addrole";
import Listview from "./pages/Addrole/Listview";
import Courses from "./pages/Courses/Courses";
import Coursedetails from "./pages/Courses/Coursedetails/Coursedetails";
import Parentportal from "./pages/Parentportal";
function App() {

  const History = useNavigate()
  const Locations = useLocation()
  const Dispatch = useDispatch()
  const UserRole = useSelector((store) => store.privilege);

  const [mainActive, setMainActive] = useState(true)
  const [navActive, setNavActive] = useState(true)
  const [isLoggedIn, setIsLogged] = useState(localStorage.getItem('loggedToken') ? true : false) // false -> logouut // true -> loggedIn

  const [logoutState, setLogoutState] = useState(false)
  const [EditAll, setEditAll] = useState([])
  const [ScheduleType, setScheduleType] = useState(false)
  // const [pageName, setPageName] = useState({
  //   name: "Dashboard",
  //   icon: "fa-chart-line"
  // })

  const [breadCrumbs_item, setBreadcrumItem] = useState([{ name: 'Dashboard', link: '/' }]);



  const [Reschedule, setReschedule] = useState(null);
  const [MainBlurContainer, setMainBlurContainer] = useState(true);
  const [RescheduleModalbox, setRescheduleModalbox] = useState(false);

  const [CancellationModalbox, setCancellationModalbox] = useState(false);
  const [MeetingID, setMeetingID] = useState(null);
  function toogleNavbar() {
    // setNavActive(!navActive)
    setMainActive(!mainActive)
  }

  async function Get_UserRoles() {
    await fetch(URL + "/user_access_details", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        Dispatch(Update_user_details({
          role: obj.body.user_role,
          notification: obj.body.user_role == 'schooladmin' ? obj.body?.admin_priveledge?.includes('notifications') : true,
          schedule: obj.body.user_role == 'schooladmin' ? obj.body?.admin_priveledge?.includes('schedule') : true,
          contact_number: obj.body?.contact_number,
          email: obj.body?.email,
          username: obj.body?.username,
        }))
      })
      .catch((err) => {
        setIsLogged(false);
      });
  }

  useEffect(() => {
    if (!isLoggedIn) {
      History('/login')
    } else {
      Get_UserRoles()
    }
    // Dispatch(Update_user_details({
    //   role: 'schooladmin',
    //   notification: false,
    //   schedule: true
    // }))
  }, [])
  // if (!isLoggedIn) {
  //   return (
  //     <HashRouter basename="/">
  //       <LoginPage setIsLogged={setIsLogged} />
  //     </HashRouter>
  //   )
  // }


  return (
    <>
      {/* <HttpsRedirect> */}

      {/* <Dashboard /> */}
      {Locations?.pathname != "/login" && <Navbar toogleClick={navActive} setBreadcrumItem={setBreadcrumItem} />}

      <div className={mainActive ? "main active" : "main"}>

        {Locations?.pathname != "/login" && <div className="nav-container">
          <Topbar setLogoutState={setLogoutState} toogleClick={toogleNavbar} />
          <Breadcrumbs breadCrumbs_item={breadCrumbs_item} />
        </div>}

        <div className="main-content">

          <Routes>

            <Route path="/viewSchedule" element={<ViewSchedule breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            {UserRole?.is_schedule && <Route path="/scheduling" element={<Scheduling breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} ScheduleType={ScheduleType} setScheduleType={setScheduleType} />} />
            }

            <Route path="/scheduleSession" element={<ScheduleSession EditAll={EditAll} setEditAll={setEditAll} ScheduleType={ScheduleType} setScheduleType={setScheduleType} />} />


            <Route path="/Reschedule" element={<RescheduleScreen />} />


            <Route path="/" element={<StudentList breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/child" element={<ChildPage />} />




            <Route path="/child_teacher_evaluation" element={<ChildPage />} />


            <Route path="/child_parent_evaluation" element={<ChildPage />} />


            <Route path="/child_screening_1" element={<ChildPage />} />


            <Route path="/child_screening_2" element={<ChildPage />} />


            <Route path="/child_screening_report" element={<ChildPage />} />


            <Route path="/child_post_screening" element={<ChildPage />} />


            <Route path="/child_enrollment_payment/:childname/:id" element={<EnrollmentPayment breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/installment_details/:childname/:id" element={<InstallmentDetails setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/login" element={<LoginPage setIsLogged={setIsLogged} />} />


            <Route path="/dashboard" element={<Dashboard breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/individualSchedulestudent/:id/:childname" element={<IndividualScheduleList breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} EditAll={EditAll} setEditAll={setEditAll} setScheduleType={setScheduleType}
              MainBlurContainer={MainBlurContainer}
              setMainBlurContainer={setMainBlurContainer}
              RescheduleModalbox={RescheduleModalbox}
              setRescheduleModalbox={setRescheduleModalbox}
              Reschedule={Reschedule}
              setReschedule={setReschedule}
              show={true}
              setCancellationModalbox={setCancellationModalbox}
              CancellationModalbox={CancellationModalbox}
              setMeetingID={setMeetingID}
              MeetingID={MeetingID}
            />} />

            <Route path="/individualSchedule/:id" element={<IndividualScheduleList breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} EditAll={EditAll} setEditAll={setEditAll} setScheduleType={setScheduleType}
              MainBlurContainer={MainBlurContainer}
              setMainBlurContainer={setMainBlurContainer}
              RescheduleModalbox={RescheduleModalbox}
              setRescheduleModalbox={setRescheduleModalbox}
              Reschedule={Reschedule}
              setReschedule={setReschedule}
              show={false}
              setCancellationModalbox={setCancellationModalbox}
              CancellationModalbox={CancellationModalbox}
              setMeetingID={setMeetingID}
              MeetingID={MeetingID}
            />} />

            <Route path="/scheduleList/:type/:user_id/:page_no" element={<ScheduleList breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} setScheduleType={setScheduleType} />} />




            <Route path="/child_journal/:childname/:id" element={<ChildJournal breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/child_details/:childname/:id" element={<ChildDetails breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />
            <Route path="/parentreport/:childname/:id" element={<Parentportal breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />
            <Route path="/screeningreport/:childname/:id" element={<Screeningreport breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/screeningprocess/:childname/:id" element={<Screeningprocess breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/gradeddetails/:childname/:id" element={<Gradeddetails breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/PTM/:childname/:id" element={<PTM breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/renewenroll/:childname/:id" element={<RenewEnroll breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/individualenrollment/:childname/:id" element={<Individualenrollment breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />


            {/* NOTIFICATIONS */}

            {UserRole?.is_notification && <Route path="/Task" element={<Notification breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem}

              MainBlurContainer={MainBlurContainer}
              setMainBlurContainer={setMainBlurContainer}
              RescheduleModalbox={RescheduleModalbox}
              setRescheduleModalbox={setRescheduleModalbox}
              Reschedule={Reschedule}
              setReschedule={setReschedule}
              setCancellationModalbox={setCancellationModalbox}
              CancellationModalbox={CancellationModalbox}
              setMeetingID={setMeetingID}
              MeetingID={MeetingID}
            />} />
            }








            {/* MANAGE SCHOOL */}
            <Route path="/manageschool/:user_id" element={<Manageschool breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/schoolDetails/:id/:user_id" element={<Detailview breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />


            {/* MANAGE ENABLER */}
            <Route path="/manageenabler" element={<Manageenabler breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/addenabler/:id" element={<Addenabler breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            <Route path="/viewenabler/:id" element={<Viewenabler breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />


            {/* GROUP ENROLLMENT */}
            <Route path="/groupenrollment" element={<Groupenrollment breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            {/* GROUP SCHEDULE */}
            <Route path="/groupschedule/:group_id/:schedule_id" element={<Groupschedule breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

            {/* GROUP SCHEDULE INDIVIDUAL LIST*/}
            <Route path="/Individualgroupdetail/:id/:scheduleBox/:cancelBox" element={<Individualgroupdetail breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />


            <Route path="/addrole/:type/:id/:school_role" element={<Addrole breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />
            <Route path="/listview/:type/:id/:school_role" element={<Listview breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />


            <Route path="/courses" element={<Courses breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />
            <Route path="/courses/:id/:pg_no" element={<Coursedetails breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />
            <Route path="*" element={<StudentList breadCrumbs_item={breadCrumbs_item} setBreadcrumItem={setBreadcrumItem} />} />

          </Routes>



        </div>

      </div>



      <Logout logoutState={logoutState} setLogoutState={setLogoutState} setIsLogged={setIsLogged} />





      {/* </HttpsRedirect> */}
    </>

  );
}

export default App;
