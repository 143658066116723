import React, { useEffect, useState, useRef } from "react";
import "./IndividualScheduleList.css";
import { useParams, useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { DAYS, URL } from "../../DROPDOWNDATA";
import useFetch from "../../customHooks/useFetch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useEditALL from "../../customHooks/useEditall";
import useEnablerList from "../../customHooks/useEnablerList";
import Select from "react-select";
import Loader from "../Loader/Loader";
import ReactPaginate from "react-paginate";
import Pagination from "react-js-pagination";
import Legend from "../../images/legend.png";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TopMenu from "../../pages/childPage/topMenu/TopMenu";
import FileSaver from "file-saver";
import Graphpie from "../Graphpie/Graphpie";
import Bulkaction from "./Bulkaction";
import usePost from "../../Hooks/usePost";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const IndividualSchedule = ({
  MainBlurContainer,
  setMainBlurContainer,
  RescheduleModalbox,
  setRescheduleModalbox,
  Reschedule,
  setReschedule,
  show,
  setCancellationModalbox,
  CancellationModalbox,
  setMeetingID,
  MeetingID,
  ...props
}) => {
  let data;
  const { id } = useParams();
  const { childname } = useParams();
  const Location = useNavigate();
  const [Enabler_List] = useEnablerList();
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  const [uniqueChild, setUniqueChild] = useState();
  const [Modalbox, setModalbox] = useState(true);

  const [Feedback, setFeedback] = useState(false);
  const [comments, setComments] = useState(false);
  const [RescheduledModal, setRescheduledModal] = useState(false);
  const [suspendedModal, setSuspendedModal] = useState(false);
  const [BulkModal, setBulkModal] = useState(false);

  const [ViewFeedbackData, setViewFeedbackData] = useState(null);
  const [ViewRescheduleData, setViewRescheduleData] = useState(null);

  const [SuspendedData, setSuspendedData] = useState({
    child_id: atob(id),

    cancellation_reason: "",
  });

  const [Data, setData, getProgramme] = useFetch(atob(id), 1);

  const BulkClasspost = usePost(`${URL + "/update_selected_status"}`);
  const Get_BulkClass = usePost(`${URL + "/get_selected_class"}`);

  const [dropdown, setDropdown] = useState(true);
  const [valueDropdown, setvalueDropdown] = useState(0);

  const [FilterData, setFilterData] = useState({
    completed: 0,

    cancelled: 0,

    rescheuled: 0,

    upcoming: 0,

    aborted: 0,

    enabler_id: "",

    child_id: atob(id),
  });

  // LOADER
  const [loading, setloading] = useState(false);

  // PAGINATION
  const [pg_no, setpg_no] = useState(false);
  // let paginationLi = document.querySelectorAll(".paginationLink");

  const [new_pagin, setnew_pagin] = useState(1);
  const [Archive_pagination, setArchive_pagination] = useState(1);
  // EDIT ALL DATA FROM CUSTOM HOOK
  // const [editAll_Data, setEditAll_Data, getEdit_All_Data] = useEditALL();

  // TAB SWITCH
  const [Tabswitch, setTabswitch] = useState(0);

  // ACCHEIVE DATA
  const [ArcheiveData, setArcheiveData] = useState([]);

  // HIDE EDITALL AND MARK AS
  const [HIdeEditALL, setHIdeEditALL] = useState(true);

  const [AttendanceView, setAttendanceView] = useState(false);
  const [AssignmentView, setAssignmentView] = useState(false);
  const [Blendedfeedback_view, setBlendedfeedback_view] = useState(false);
  useEffect(() => {
    // getProgramme(atob(id));
    // getProgramme(atob(id), 1);
    if (show) {
      props.setBreadcrumItem([
        { name: "Student List", link: "/" },

        {
          name: atob(childname) || "",
          link: `individualSchedulestudent/${id}`,
        },
      ]);
    } else {
      props.setBreadcrumItem([
        { name: "Scheduling", link: "/scheduling" },
        { name: "Schedule List", link: -1 },
        { name: Data?.child_name || "", link: `individualSchedule/${id}` },
      ]);
    }
  }, [id, Modalbox, Data]);
  const openModal = () => {
    setModalbox(false);
  };

  const modal_YES = () => {
    async function mark_complete(value) {
      setloading(true);
      await fetch(URL + "/mark_all_programme_completed", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify((data = { child_view_id: value })),
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          if (obj.body.message == "error") {
            setloading(false);
            toast.error("something went wrong");
            toast.clearWaitingQueue();
          } else {
            setloading(false);
            toast.success("Classes marked completed");
            toast.clearWaitingQueue();
            getProgramme(atob(id), 1);
            setModalbox(true);
          }
        });
    }
    mark_complete(atob(id));
  };
  const modal_NO = () => {
    setModalbox(true);
    document.getElementsByName("completed")[0].checked = false;
  };
  const closeRescheduleModal = () => {
    setMainBlurContainer(true);
    setRescheduleModalbox(false);
    setBulkModal(false);
  };
  const openReschedule = (reschedule) => {
    setReschedule(reschedule);
    setMainBlurContainer(false);
    setRescheduleModalbox(true);
  };
  const openCancelModel = (id) => {
    setMeetingID(id);
    setMainBlurContainer(false);
    setCancellationModalbox(true);
  };
  const submitCancel = () => {
    setBulkModal(false);
    setMainBlurContainer(true);
    setCancellationModalbox(false);
  };
  const submitSend = (cancelData) => {
    let data;
    async function sendCancel_DB() {
      setloading(true);

      await fetch(URL + "/cancel_class", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify((data = cancelData)),
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          if (obj.body.message == "error") {
            toast.error("something went wrong");
            toast.clearWaitingQueue();
          } else {
            setloading(false);
            setnew_pagin(1);
            toast.success("Class cancelled successfully");
            toast.clearWaitingQueue();
            setMainBlurContainer(true);
            getProgramme(atob(id), 1);
            setCancellationModalbox(false);
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error("oops! Something went wrong");
          toast.clearWaitingQueue();
        });
    }
    async function Sendbulkcancel() {
      setloading(true);
      await fetch(URL + "/bulk_edit_schedule", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify(
          (data = {
            child_view_id: atob(id),
            is_cancellation: 1,
            ...cancelData,
          })
        ),
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          if (obj.body.message == "error") {
            toast.error("something went wrong");
            toast.clearWaitingQueue();
          } else {
            setloading(false);
            setnew_pagin(1);
            toast.success("Class cancelled successfully");
            toast.clearWaitingQueue();
            setMainBlurContainer(true);
            getProgramme(atob(id), 1);
            setBulkModal(false);
            setCancellationModalbox(false);
            Get_BulkClass.Postcall({
              child_id: atob(id),
              group_id: "",
            });
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error("oops! Something went wrong");
          toast.clearWaitingQueue();
        });
    }
    if (BulkModal) {
      Sendbulkcancel();
    } else {
      sendCancel_DB();
    }
  };
  const Send_Reschedule_DB = (RescheduleData) => {
    let data;
    async function send_to_DB() {
      setloading(true);
      await fetch(URL + "/reschedule_class", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify((data = RescheduleData)),
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          if (obj.body.message == "error") {
            toast.error("something went wrong");
            toast.clearWaitingQueue();
            setloading(false);
          } else {
            setloading(false);
            setnew_pagin(1);
            toast.success("Class Rescheduled Successfully");
            toast.clearWaitingQueue();
            getProgramme(atob(id), 1);
            setMainBlurContainer(true);
            setRescheduleModalbox(false);
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error("oops! Something went wrong");
          toast.clearWaitingQueue();
        });
    }
    async function SendBulkschedule() {
      setloading(true);
      await fetch(URL + "/bulk_edit_schedule", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify(
          (data = {
            child_view_id: atob(id),
            is_reschedule: 1,
            ...RescheduleData,
          })
        ),
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          if (obj.body.message == "error") {
            toast.error("something went wrong");
            toast.clearWaitingQueue();
            setloading(false);
          } else {
            setloading(false);
            setnew_pagin(1);
            toast.success("Class Rescheduled Successfully");
            toast.clearWaitingQueue();
            getProgramme(atob(id), 1);
            setBulkModal(false);
            setMainBlurContainer(true);
            setRescheduleModalbox(false);
            Get_BulkClass.Postcall({
              child_id: atob(id),
              group_id: "",
            });
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error("oops! Something went wrong");
          toast.clearWaitingQueue();
        });
    }
    if (BulkModal) {
      SendBulkschedule();
    } else {
      send_to_DB();
    }
  };

  const Edit_All_handle = () => {
    async function getEdit_All_Data(value) {
      await fetch(URL + "/view_child_scheduled_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify((data = { child_view_id: value })),
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          props.setEditAll(obj.body);
          props.setScheduleType(true);
          Location(`/scheduleSession`);
        })
        .catch((err) => {
          toast.error("something went wrong");
          toast.clearWaitingQueue();
        });
    }

    getEdit_All_Data(atob(id));
  };
  const toggleDropdown = (value) => {
    setDropdown(false);
    setvalueDropdown(value);

    if (value == valueDropdown) {
      setDropdown(true);
      setvalueDropdown(0);
    }
  };
  // paginationLi[0]?.addEventListener("click", (e) => {
  //   paginationLi[0].classList.add("pagLi_sc");
  // });

  async function Archeive_render(id) {
    await fetch(URL + "/archeive_class", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify(
        (data = {
          child_id: id,
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setArcheiveData(obj.body);
        setArchive_pagination(1);
      });
  }

  async function filterToDB(data) {
    await fetch(URL + "/child_programme_filter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify((data = data)),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setpg_no(true);
        // PaginationRender();

        // paginationLi.forEach((item) => {
        //   item.classList.remove("pagLi_sc");
        // });
        // paginationLi[0].classList.add("pagLi_sc");
        setnew_pagin(1);
        setData(obj.body);
      });
  }

  const enablerFilter = (event) => {
    let ARRAY_ENABLERDATA = [];
    const FT_enabler = event.map((item) => {
      ARRAY_ENABLERDATA.push(item.value);
    });
    setFilterData(
      FilterData,
      (FilterData.enabler_id = ARRAY_ENABLERDATA.join())
    );

    filterToDB(FilterData);
  };
  const cp_Status_input = (event) => {
    if (event.target.name == "completed") {
      setFilterData(
        FilterData,
        (FilterData.completed = event.target.checked ? 1 : 0)
      );
    } else if (event.target.name == "upcoming") {
      setFilterData(
        FilterData,
        (FilterData.upcoming = event.target.checked ? 1 : 0)
      );
    } else if (event.target.name == "cancelled") {
      setFilterData(
        FilterData,
        (FilterData.cancelled = event.target.checked ? 1 : 0)
      );
    } else if (event.target.name == "rescheduled") {
      setFilterData(
        FilterData,
        (FilterData.rescheuled = event.target.checked ? 1 : 0)
      );
    } else {
      setFilterData(
        FilterData,
        (FilterData.aborted = event.target.checked ? 1 : 0)
      );
    }
    filterToDB(FilterData);
  };

  const ViewFeedback = (id) => {
    async function Viewfeedback_data(data) {
      let dataID = {
        meeting_record_id: data,
      };
      await fetch(URL + "/view_feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify((data = dataID)),
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          setViewFeedbackData(obj.body);
          setFeedback(true);
          setMainBlurContainer(false);
        });
    }
    Viewfeedback_data(id);
  };
  const ViewCancelComment = (id) => {
    async function ViewRescheule(data) {
      let dataID = {
        meeting_id: data,
      };
      await fetch(URL + "/view_comment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify((data = dataID)),
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          setViewRescheduleData(obj.body);
          setMainBlurContainer(false);
          setComments(true);
        });
    }
    ViewRescheule(id);
  };
  const viewRescheduledComments = (id) => {
    async function ViewRescheule(data) {
      let dataID = {
        meeting_id: data,
      };
      await fetch(URL + "/view_comment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify((data = dataID)),
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          setViewRescheduleData(obj.body);
          setMainBlurContainer(false);
          setRescheduledModal(true);
        })
        .catch((err) => {
          toast.error("oops! Something went wrong");
          toast.clearWaitingQueue();
        });
    }
    ViewRescheule(id);
  };
  const suspendedHandle = (e) => {
    setMainBlurContainer(false);
    setSuspendedModal(true);
    e.target.checked = false;
  };
  const Send_Suspended = () => {
    setloading(true);
    async function Suspended_DB(data) {
      await fetch(URL + "/mark_as_suspended", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify((data = data)),
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          setloading(false);
          setMainBlurContainer(true);
          setSuspendedModal(false);
          toast.success("Classes Suspended Successfully");
          getProgramme(atob(id), 1);
          toast.clearWaitingQueue();
        });
    }
    Suspended_DB(SuspendedData);
  };

  const Download_FeedbackReport = async () => {
    let data = {
      child_id: atob(id),
    };

    setloading(true);
    await fetch(URL + "/view_feedback_download", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.blob())
      .then((obj) => {
        setloading(false);
        FileSaver.saveAs(obj, `${Data?.child_name}Feedback_report.xlsx`);
      })
      .catch((err) => {
        setloading(false);
        toast.error("oops! Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  // BULK ACTIONS
  const CheckboxHandler = (si_no, status) => {
    BulkClasspost.Postcall({
      class_status: status,
      si_no: si_no,
    });
  };
  useEffect(() => {
    if (BulkClasspost?.Postdata != null) {
      Get_BulkClass.Postcall({
        child_id: atob(id),
        group_id: "",
      });
    }
  }, [BulkClasspost.Postdata]);
  useEffect(() => {
    if (Get_BulkClass?.Postdata === null) {
      Get_BulkClass.Postcall({
        child_id: atob(id),
        group_id: "",
      });
    } else {
    }
  }, [Get_BulkClass.Postdata]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {show && (
        <div style={{ marginBottom: "10px" }}>
          <TopMenu id={id} childname={childname || ""} />
        </div>
      )}

      {Data?.child_name ? (
        <div className="main_container_list individual-schedule-list-container">
          <div className="table_container">
            <div className="child_name_and_edit">
              <h3 className="childName">
                {Data?.child_name || ""}'s Intervention programme
              </h3>

              <div
                className={
                  HIdeEditALL ? "edit_checkbox" : "edit_checkbox hide_editall"
                }
              >
                {Data?.edit_all && (
                  <div className="checkbox_container">
                    <input
                      type="checkbox"
                      name="suspend"
                      id="suspend"
                      onChange={(events) => suspendedHandle(events)}
                    />
                    <label htmlFor="suspend">Mark as suspended</label>
                  </div>
                )}
                {Data?.edit_all && (
                  <div className="checkbox_container">
                    <input
                      type="checkbox"
                      name="completed"
                      id="completed"
                      onChange={openModal}
                      value="empl"
                    />
                    <label htmlFor="completed">Mark as completed</label>
                  </div>
                )}

                <button
                  className={Data?.edit_all ? "edit_btn" : "edit_btn disabled"}
                  onClick={Edit_All_handle}
                >
                  <i className="fa-solid fa-pen"></i>
                  <p>Edit all</p>
                </button>
              </div>
            </div>

            <Box>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={Tabswitch}
                  onChange={(event, newValue) => {
                    setTabswitch(newValue);
                  }}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Current"
                    {...a11yProps(0)}
                    onClick={() => {
                      setHIdeEditALL(true);
                    }}
                  />
                  <Tab
                    label="Archived"
                    {...a11yProps(1)}
                    onClick={() => {
                      setHIdeEditALL(false);
                      Archeive_render(atob(id));
                    }}
                  />

                  {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                </Tabs>
                <button
                  className="download_feedback_report_btn"
                  onClick={Download_FeedbackReport}
                >
                  <i className="fa-solid fa-download"></i> Download feedback
                </button>
                {Get_BulkClass?.Postdata?.body?.length > 0 && (
                  <button
                    className="download_feedback_report_btn"
                    onClick={() => {
                      setMainBlurContainer(false);
                      setBulkModal(true);
                    }}
                  >
                    <i className="fa-solid fa-pencil"></i> Bulk action
                  </button>
                )}
              </Box>
              <TabPanel value={Tabswitch} index={0}>
                <table className="individual-schedule-table-data">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Class No</th>
                      <th>Date</th>
                      <th>Day</th>
                      <th>Time</th>
                      <th className="dropdown_container">
                        <p
                          className="filterName"
                          onClick={() => toggleDropdown(1)}
                        >
                          Enabler Name<i className="fas fa-filter"></i>
                        </p>

                        <div
                          className={
                            dropdown
                              ? "dropDownActive"
                              : valueDropdown == 1
                              ? "dropdownList"
                              : "dropDownActive"
                          }
                        >
                          <div className="enablerSelect_container">
                            <Select
                              defaultMenuIsOpen={true}
                              className="select_options"
                              closeMenuOnSelect={false}
                              options={Enabler_List?.map((e) => ({
                                label: e.users_firstname,
                                value: e.users_id,
                              }))}
                              isMulti
                              onChange={enablerFilter}
                              placeholder="Select Enabler"
                            />
                          </div>
                        </div>
                      </th>
                      <th className="dropdown_container">
                        <p
                          className="filterName"
                          onClick={() => toggleDropdown(2)}
                        >
                          Status<i className="fas fa-filter"></i>
                        </p>

                        <div
                          className={
                            dropdown
                              ? "dropDownActive"
                              : valueDropdown == 2
                              ? "dropdownList"
                              : "dropDownActive"
                          }
                        >
                          <div className="input_container">
                            <input
                              type="checkbox"
                              id="Completedchildprogramme"
                              className="DD_Input"
                              name="completed"
                              value={"completed"}
                              onChange={cp_Status_input}
                            />

                            <label
                              htmlFor="Completedchildprogramme"
                              className="DD_Label"
                            >
                              <i className="fa-solid fa-check"></i>
                              <p>Completed</p>
                            </label>
                          </div>
                          <div className="input_container">
                            <input
                              type="checkbox"
                              id="Upcomingchildprogramme"
                              className="DD_Input"
                              value={"upcoming"}
                              name="upcoming"
                              onChange={cp_Status_input}
                            />

                            <label
                              htmlFor="Upcomingchildprogramme"
                              className="DD_Label"
                            >
                              <i className="fa-solid fa-check"></i>
                              <p>Upcoming</p>
                            </label>
                          </div>
                          <div className="input_container">
                            <input
                              type="checkbox"
                              id="Cancelledcp"
                              name="cancelled"
                              onChange={cp_Status_input}
                              className="DD_Input"
                              value={"cancelled"}
                            />
                            <label htmlFor="Cancelledcp" className="DD_Label">
                              <i className="fa-solid fa-check"></i>
                              <p>Cancelled</p>
                            </label>
                          </div>
                          <div className="input_container">
                            <input
                              type="checkbox"
                              id="Rescheduledcp"
                              className="DD_Input"
                              name="rescheduled"
                              onChange={cp_Status_input}
                              value={"rescheduled"}
                            />
                            <label htmlFor="Rescheduledcp" className="DD_Label">
                              <i className="fa-solid fa-check"></i>
                              <p>Rescheduled</p>
                            </label>
                          </div>
                          <div className="input_container">
                            <input
                              type="checkbox"
                              id="Abortedcp"
                              className="DD_Input"
                              onChange={cp_Status_input}
                              name="aborted"
                              value={"aborted"}
                            />
                            <label htmlFor="Abortedcp" className="DD_Label">
                              <i className="fa-solid fa-check"></i>
                              <p>Mark as completed</p>
                            </label>
                          </div>
                        </div>
                      </th>
                      <th>Scheduled to</th>
                      <th>Call Link</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data &&
                      Data?.scheduled_class?.map((item, index) => {
                        return (
                          <tr key={item.si_no}>
                            <td>
                              <input
                                type="checkbox"
                                name=""
                                id=""
                                disabled={item.meeting_status !== "Upcoming"}
                                className="w-[20px] h-[20px]"
                                defaultChecked={
                                  item?.is_selected ? true : false
                                }
                                onChange={(e) =>
                                  CheckboxHandler(
                                    item.si_no,
                                    e.target.checked ? true : false
                                  )
                                }
                              />
                            </td>
                            <td>{item.class_no}</td>
                            <td>{item.meeting_date}</td>
                            <td>{item.meeting_day}</td>
                            <td>{item.meeting_time}</td>
                            <td>{item.enabler_name}</td>
                            <td>{item.meeting_status}</td>
                            <td>{item.rescheduled_date || "-"}</td>
                            <td
                              className={
                                item.meeting_status == "Upcoming"
                                  ? "call"
                                  : "call lock"
                              }
                            >
                              <a
                                href={item.call_link}
                                target="_blank"
                                style={{ color: "#718ae5" }}
                              >
                                <i className="fas fa-phone"></i>
                              </a>
                            </td>
                            <td>
                              <div className="feedback">
                                {item.meeting_status == "Completed" && (
                                  <button
                                    onClick={() => ViewFeedback(item.si_no)}
                                  >
                                    <i className="fas fa-comment-alt"></i>{" "}
                                    <p>Feedback</p>
                                  </button>
                                )}

                                {item.meeting_status == "Cancelled" && (
                                  <button
                                    onClick={() =>
                                      ViewCancelComment(item.si_no)
                                    }
                                  >
                                    <i className="fas fa-comment-alt"></i>{" "}
                                    <p>comments</p>
                                  </button>
                                )}
                                {item.meeting_status == "Upcoming" && (
                                  <div style={{ display: "flex" }}>
                                    <button
                                      onClick={() => openReschedule(item)}
                                    >
                                      <i className="fas fa-redo"></i>{" "}
                                      <p>Reschedule </p>
                                    </button>
                                    <button
                                      onClick={() =>
                                        openCancelModel(item.si_no)
                                      }
                                    >
                                      <i className="fas fa-times"></i>{" "}
                                      <p>Cancel </p>
                                    </button>
                                  </div>
                                )}
                                {item.meeting_status == "Rescheduled" && (
                                  <button
                                    onClick={() =>
                                      viewRescheduledComments(item.si_no)
                                    }
                                  >
                                    <i className="fas fa-comment-alt"></i>{" "}
                                    <p>Comments </p>
                                  </button>
                                )}
                                {item.meeting_status ==
                                  "Marked as completed" && (
                                  <button
                                    onClick={() =>
                                      ViewCancelComment(item.si_no)
                                    }
                                  >
                                    <i className="fas fa-comment-alt"></i>{" "}
                                    <p>Comments </p>
                                  </button>
                                )}
                                {item.meeting_status == "Suspended" && (
                                  <button
                                    onClick={() =>
                                      ViewCancelComment(item.si_no)
                                    }
                                  >
                                    <i className="fas fa-comment-alt"></i>{" "}
                                    <p>Comments </p>
                                  </button>
                                )}
                                {/* {item.meeting_status == "Rescheduled Meeting" && (
                          <div style={{ display: "flex" }}>
                            <button onClick={() => openReschedule(item)}>
                              <i className="fas fa-redo"></i> <p>Reschedule </p>
                            </button>
                            <button onClick={() => openCancelModel(item.si_no)}>
                              <i className="fas fa-times"></i> <p>Cancel </p>
                            </button>
                          </div>
                        )} */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {/* {PaginationRender()} */}

                <div className="legend_pagination">
                  <img src={Legend} alt="" className="legend_img" />
                  <Pagination
                    activePage={new_pagin}
                    itemsCountPerPage={10}
                    totalItemsCount={Math.ceil(Data?.total_count)}
                    pageRangeDisplayed={10}
                    itemClass="paginationLi_new"
                    prevPageText="Prev"
                    nextPageText="Next"
                    activeLinkClass="pagLi_sc"
                    activeClass="pagLi_sc"
                    itemClassFirst="go_first"
                    itemClassLast="go_first"
                    itemClassPrev="prev_btn"
                    itemClassNext="next_btn"
                    onChange={(e) => {
                      setnew_pagin(e);
                      async function getProgramme(value, pg_noo) {
                        await fetch(
                          URL + `/child_programme_filter?page_no=${pg_noo}`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Accept: "application/json",
                              Authorization: authKey,
                            },
                            body: JSON.stringify((data = FilterData)),
                          }
                        )
                          .then((r) =>
                            r.json().then((data) => ({
                              status: r.status,
                              body: data,
                            }))
                          )
                          .then((obj) => {
                            // console.log(obj.body);
                            // if (pg_no) {
                            //   paginationLi[0].classList.remove("pagLi_sc");
                            //   setpg_no(false);
                            // }
                            setData(obj.body);
                          });
                      }
                      getProgramme(atob(id), e);
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel value={Tabswitch} index={1}>
                <table className="archive_table">
                  <thead>
                    <tr>
                      <th>Class no</th>
                      <th>Date</th>
                      <th>Day</th>
                      <th>Time</th>
                      <th>Enabler name</th>
                      <th>Status</th>
                      <th>Scheduled to</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ArcheiveData?.scheduled_class?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.class_no}</td>
                          <td>{item?.meeting_date}</td>
                          <td>{item?.meeting_day}</td>
                          <td>{item?.meeting_time}</td>
                          <td>{item?.enabler_name}</td>
                          <td>{item?.meeting_status}</td>
                          <td>{item?.rescheduled_date || "-"}</td>
                          <td>
                            <div className="feedback">
                              {item.meeting_status == "Completed" && (
                                <button
                                  onClick={() => ViewFeedback(item.si_no)}
                                >
                                  <i className="fas fa-comment-alt"></i>{" "}
                                  <p>Feedback</p>
                                </button>
                              )}

                              {item.meeting_status == "Cancelled" && (
                                <button
                                  onClick={() => ViewCancelComment(item.si_no)}
                                >
                                  <i className="fas fa-comment-alt"></i>{" "}
                                  <p>comments</p>
                                </button>
                              )}
                              {item.meeting_status == "Upcoming" && (
                                <div style={{ display: "flex" }}>
                                  <button onClick={() => openReschedule(item)}>
                                    <i className="fas fa-redo"></i>{" "}
                                    <p>Reschedule </p>
                                  </button>
                                  <button
                                    onClick={() => openCancelModel(item.si_no)}
                                  >
                                    <i className="fas fa-times"></i>{" "}
                                    <p>Cancel </p>
                                  </button>
                                </div>
                              )}
                              {item.meeting_status == "Rescheduled" && (
                                <button
                                  onClick={() =>
                                    viewRescheduledComments(item.si_no)
                                  }
                                >
                                  <i className="fas fa-comment-alt"></i>{" "}
                                  <p>Comments </p>
                                </button>
                              )}
                              {item.meeting_status == "Marked as completed" && (
                                <button
                                  onClick={() => ViewCancelComment(item.si_no)}
                                >
                                  <i className="fas fa-comment-alt"></i>{" "}
                                  <p>Comments </p>
                                </button>
                              )}
                              {item.meeting_status == "Suspended" && (
                                <button
                                  onClick={() => ViewCancelComment(item.si_no)}
                                >
                                  <i className="fas fa-comment-alt"></i>{" "}
                                  <p>Comments </p>
                                </button>
                              )}
                              {/* {item.meeting_status == "Rescheduled Meeting" && (
                          <div style={{ display: "flex" }}>
                            <button onClick={() => openReschedule(item)}>
                              <i className="fas fa-redo"></i> <p>Reschedule </p>
                            </button>
                            <button onClick={() => openCancelModel(item.si_no)}>
                              <i className="fas fa-times"></i> <p>Cancel </p>
                            </button>
                          </div>
                        )} */}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="legend_pagination">
                  <img src={Legend} alt="" className="legend_img" />
                  <Pagination
                    activePage={Archive_pagination}
                    itemsCountPerPage={10}
                    totalItemsCount={Math.ceil(ArcheiveData.total_count)}
                    pageRangeDisplayed={10}
                    itemClass="paginationLi_new"
                    prevPageText="Prev"
                    nextPageText="Next"
                    activeLinkClass="pagLi_sc"
                    activeClass="pagLi_sc"
                    itemClassFirst="go_first"
                    itemClassLast="go_first"
                    itemClassPrev="prev_btn"
                    itemClassNext="next_btn"
                    onChange={(e) => {
                      setArchive_pagination(e);
                      async function getProgramme(value, pg_noo) {
                        await fetch(URL + `/archeive_class?page_no=${pg_noo}`, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorization: authKey,
                          },
                          body: JSON.stringify((data = FilterData)),
                        })
                          .then((r) =>
                            r.json().then((data) => ({
                              status: r.status,
                              body: data,
                            }))
                          )
                          .then((obj) => {
                            // console.log(obj.body);
                            // if (pg_no) {
                            //   paginationLi[0].classList.remove("pagLi_sc");
                            //   setpg_no(false);
                            // }
                            setArcheiveData(obj.body);
                          });
                      }
                      getProgramme(atob(id), e);
                    }}
                  />
                </div>
              </TabPanel>
            </Box>
          </div>
          <div
            className={
              HIdeEditALL
                ? "classes_container"
                : "classes_container hide_sidebar"
            }
          >
            {/* <ClassCount title="Total classes" value={Data?.total_classes} />
            <ClassCount
              title="Completed classes"
              value={Data?.completed_class}
            />
            <ClassCount
              title="Cancelled classes"
              value={Data?.cancelled_class}
            /> */}

            <div className="pie-chart-container">
              <p className="class-count">
                Total classes - {Data?.total_classes}
              </p>
              <p className="class-count">
                Completed classes - {Data?.completed_class}
              </p>
              <Graphpie
                totalcount={Data?.cancelled_class}
                heading={`Total cancelled classes - ${Data?.cancelled_class}`}
                data={[
                  {
                    y: Data?.enabler_side_cancellation_count,
                    label: "Enabler",
                    indexLabelFontSize:
                      Data?.enabler_side_cancellation_count == 0 ? 0 : 16,
                    color: "#F79221",
                  },
                  {
                    y: Data?.no_show_count,
                    label: "No Show",
                    indexLabelFontSize: Data?.no_show_count == 0 ? 0 : 16,
                    color: "#2DA3DC",
                  },
                  {
                    y: Data?.child_side_cancellation_count,
                    label: "Child Side",
                    indexLabelFontSize:
                      Data?.child_side_cancellation_count == 0 ? 0 : 16,
                    color: "#718ae5",
                  },
                  {
                    y: Data?.other_cancellation_count,
                    label: "Others",
                    indexLabelFontSize:
                      Data?.other_cancellation_count == 0 ? 0 : 16,
                    color: "#b5ead7",
                  },
                ]}
              />
              <Graphpie
                totalcount={
                  Data?.child_side_reschedule_count +
                  Data?.enabler_side_reschedule_count +
                  Data?.other_reschedule_reason
                }
                heading={`Total Rescheduled Classes - ${
                  Data?.child_side_reschedule_count +
                  Data?.enabler_side_reschedule_count +
                  Data?.other_reschedule_reason
                }`}
                data={[
                  {
                    y: Data?.enabler_side_reschedule_count,
                    label: "Enabler Side",
                    indexLabelFontSize:
                      Data?.enabler_side_reschedule_count == 0 ? 0 : 16,
                    color: "#F79221",
                  },
                  {
                    y: Data?.child_side_reschedule_count,
                    label: "Child Side",
                    indexLabelFontSize:
                      Data?.child_side_reschedule_count == 0 ? 0 : 16,
                    color: "#2DA3DC",
                  },
                  {
                    y: Data?.other_reschedule_reason,
                    label: "Others",
                    indexLabelFontSize:
                      Data?.other_reschedule_reason == 0 ? 0 : 16,
                    color: "#718ae5",
                  },
                ]}
              />
            </div>
          </div>

          <div className={Modalbox ? "hide" : "confirm_modal_box"}>
            <div className="content_holder">
              <h3>Are you sure want to proceed?</h3>
              <div className="btn_holder">
                <button className="yes_btn" onClick={modal_YES}>
                  Yes
                </button>
                <button className="no_btn" onClick={modal_NO}>
                  No
                </button>
              </div>
              <p
                style={{
                  color: "grey",
                  fontWeight: "600",
                  textAlign: "center",
                  fontSize: "13px",
                }}
              >
                ** Once this action is performed cannot be reverted back
              </p>
            </div>
          </div>

          <div
            className={
              MainBlurContainer
                ? "rescheduleModalBox"
                : "rescheduleModalBoxShow"
            }
          >
            {RescheduleModalbox && (
              <ResheduleModal
                closeRescheduleModal={closeRescheduleModal}
                RescheduleData={Reschedule}
                Send_Reschedule_DB={Send_Reschedule_DB}
                BulkModal={BulkModal}
              />
            )}

            {CancellationModalbox && (
              <CancelModal
                submitCancel={submitCancel}
                meetingID={MeetingID}
                submitSend={submitSend}
              />
            )}

            {Feedback && (
              <Feedbackview
                setFeedback={setFeedback}
                setMainBlurContainer={setMainBlurContainer}
                ViewFeedbackData={ViewFeedbackData}
                setAttendanceView={setAttendanceView}
                AttendanceView={AttendanceView}
                setAssignmentView={setAssignmentView}
                AssignmentView={AssignmentView}
                setBlendedfeedback_view={setBlendedfeedback_view}
                Blendedfeedback_view={Blendedfeedback_view}
              />
            )}
            {comments && (
              <ViewComments
                ViewRescheduleData={ViewRescheduleData}
                setMainBlurContainer={setMainBlurContainer}
                setComments={setComments}
              />
            )}
            {RescheduledModal && (
              <RescheduledComments
                ViewRescheduleData={ViewRescheduleData}
                setRescheduledModal={setRescheduledModal}
                setMainBlurContainer={setMainBlurContainer}
              />
            )}

            {suspendedModal && (
              <Suspended
                setSuspendedModal={setSuspendedModal}
                setMainBlurContainer={setMainBlurContainer}
                setSuspendedData={setSuspendedData}
                SuspendedData={SuspendedData}
                Send_Suspended={Send_Suspended}
              />
            )}
            {BulkModal && (
              <Bulkaction
                setMainBlurContainer={setMainBlurContainer}
                setBulkModal={setBulkModal}
                setCancellationModalbox={setCancellationModalbox}
                setRescheduleModalbox={setRescheduleModalbox}
                Data={Get_BulkClass?.Postdata?.body}
              />
            )}
          </div>
        </div>
      ) : (
        <p
          style={{
            textAlign: "center",
            fontSize: "22px",
            fontWeight: "500",
            marginTop: "30px",
          }}
        >
          No schedule added
        </p>
      )}
    </div>
  );
};
function ClassCount({ title, value }) {
  return (
    <div className="classes">
      <p className="total_class">{title}</p>
      <div className="class_count">{(value = "" ? 0 : value)}</div>
    </div>
  );
}

function ResheduleModal({
  closeRescheduleModal,
  RescheduleData,
  Send_Reschedule_DB,
  BulkModal,
}) {
  // let URL = "http://20.120.84.12:5588";
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  const [DateValid, setDateValid] = useState(null);
  const [TimeValid, setTimeValid] = useState(null);
  const [EnabletList, setEnabletList] = useState([]);
  const [ConvertTime, setConvertTime] = useState(null);
  const [StoreEnabler, setStoreEnabler] = useState([]);

  const [SendReschedule_Data, setSendReschedule_Data] = useState({
    meeting_id: RescheduleData?.si_no || null,
    rescheduled_date: "",
    rescheduled_time: "",
    rescheduled_enabler: "",
    is_child_side_reschedule: "",
    is_enabler_side_reschudle: "",
    reschedule_reason: "",
  });
  function convertTime12To24(time) {
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === "PM" && hours < 12) hours = hours + 12;
    if (AMPM === "AM" && hours === 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
  }
  const Handle_Enabler_Input = (event, value) => {
    if (value != null || value != undefined || value != "") {
      setStoreEnabler([value]);
      setSendReschedule_Data(
        SendReschedule_Data,
        (SendReschedule_Data.rescheduled_enabler = value.users_id)
      );
    }
  };

  async function getEnablerList(meeting_id) {
    await fetch(URL + "/reschedule_enabler_list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify({ meeting_id }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setEnabletList(obj.body);
      });
  }
  const sameEnabler = (event) => {
    setSendReschedule_Data(
      SendReschedule_Data,
      (SendReschedule_Data.rescheduled_enabler = RescheduleData.enabler_id)
    );
    if (event.target.checked) {
      setEnabletList([
        {
          users_id: RescheduleData.enabler_id,
          users_firstname: RescheduleData.enabler_name,
        },
      ]);
      setStoreEnabler([
        {
          users_id: RescheduleData.enabler_id,
          users_firstname: RescheduleData.enabler_name,
        },
      ]);
    } else {
      getEnablerList(RescheduleData?.si_no);
      setSendReschedule_Data(
        SendReschedule_Data,
        (SendReschedule_Data.rescheduled_enabler = EnabletList[0].users_id)
      );
    }
  };
  function ElementComponent(props) {
    return (
      <div className="select-element">
        <p>{props.element_name}</p>

        <Autocomplete
          disablePortal
          id="combo-box"
          options={EnabletList}
          getOptionLabel={(option) => {
            return option.users_firstname;
          }}
          onChange={Handle_Enabler_Input}
          value={StoreEnabler[0]}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Select Enabler" />
          )}
        />
      </div>
    );
  }
  let reschedule_Date = document.querySelector("#reschedule_Date");
  let reschedule_Time = document.querySelector("#reschedule_Time");
  let samedateandtime = document.querySelector("#samedateandtime");

  const setSameDate_time = () => {
    setTimeValid(ConvertTime);
    // reschedule_Time.value = ConvertTime;
    reschedule_Date.value = RescheduleData?.meeting_date
      .split("-")
      .reverse()
      .join("-");
    setDateValid(RescheduleData?.meeting_date.split("-").reverse().join("-"));
    setSendReschedule_Data(
      SendReschedule_Data,
      (SendReschedule_Data.rescheduled_date = RescheduleData?.meeting_date
        .split("-")
        .reverse()
        .join("-")),
      (SendReschedule_Data.rescheduled_time = ConvertTime)
    );
  };
  const dateChange = (event) => {
    setDateValid(event.target.value);
    setSendReschedule_Data(
      SendReschedule_Data,
      (SendReschedule_Data.rescheduled_date = event.target.value)
    );
    if (samedateandtime.checked) {
      samedateandtime.checked = false;
    }
  };
  const timeChange = (event) => {
    setTimeValid(event.target.value);
    setSendReschedule_Data(
      SendReschedule_Data,
      (SendReschedule_Data.rescheduled_time = event.target.value)
    );
    if (samedateandtime.checked) {
      samedateandtime.checked = false;
    }
  };
  const RescheduleReason_input = (event) => {
    setSendReschedule_Data(
      SendReschedule_Data,
      (SendReschedule_Data.reschedule_reason = event.target.value)
    );
  };
  let reason_for_reschedule = document.getElementsByName("reason_for_cancel");
  useEffect(() => {
    if (!BulkModal) {
      setConvertTime(convertTime12To24(RescheduleData?.meeting_time));
      getEnablerList(RescheduleData.si_no);
    }
  }, []);
  const sendReport = () => {
    if (BulkModal) {
      if (
        !reason_for_reschedule[0].checked &&
        !reason_for_reschedule[1].checked &&
        !reason_for_reschedule[2].checked
      ) {
        toast.error("Kindly fill the fields");
        toast.clearWaitingQueue();
      } else {
        setSendReschedule_Data(
          SendReschedule_Data,
          (SendReschedule_Data.is_child_side_reschedule =
            reason_for_reschedule[0]?.checked ? 1 : 0),
          (SendReschedule_Data.is_enabler_side_reschudle =
            reason_for_reschedule[1]?.checked ? 1 : 0)
        );

        Send_Reschedule_DB(SendReschedule_Data);
      }
    } else {
      if (
        !reason_for_reschedule[0].checked &
          !reason_for_reschedule[1].checked &
          !reason_for_reschedule[2].checked ||
        SendReschedule_Data.rescheduled_time == "" ||
        SendReschedule_Data.rescheduled_date == "" ||
        SendReschedule_Data.rescheduled_enabler == ""
      ) {
        toast.error("Kindly fill the fields");
        toast.clearWaitingQueue();
      } else {
        setSendReschedule_Data(
          SendReschedule_Data,
          (SendReschedule_Data.is_child_side_reschedule =
            reason_for_reschedule[0]?.checked ? 1 : 0),
          (SendReschedule_Data.is_enabler_side_reschudle =
            reason_for_reschedule[1]?.checked ? 1 : 0)
        );

        Send_Reschedule_DB(SendReschedule_Data);
      }
    }
  };
  return (
    <div className="reschedule_container">
      <div className="reschedule_session_Layout">
        <p className="heading">Reschedule session</p>

        {!BulkModal && (
          <div className="block currentDate">
            {" "}
            <p>
              <strong>Date:</strong> {RescheduleData?.meeting_date}
            </p>
            <p>
              {" "}
              <strong>Time:</strong> {RescheduleData?.meeting_time}
            </p>
          </div>
        )}

        {!BulkModal && (
          <div className="block enterDate">
            <div>
              <p>Enter new date</p>
              <input
                type="date"
                className="timeInput"
                id="reschedule_Date"
                onInput={(event) => dateChange(event)}
                value={DateValid}
                // defaultValue={RescheduleData?.meeting_date
                //   .split("-")
                //   .reverse()
                //   .join("-")}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div>
              <p>Enter new time</p>
              <input
                type="time"
                className="timeInput"
                id="reschedule_Time"
                value={TimeValid}
                // defaultValue={convertTime12To24(RescheduleData?.meeting_time)}
                onInput={(event) => timeChange(event)}
              />
            </div>
            <div className="same_date_time">
              <input
                type="checkbox"
                name="same_date_time"
                id="samedateandtime"
                onInput={setSameDate_time}
              />
              <label htmlFor="samedateandtime">Same date and time</label>
            </div>
          </div>
        )}

        <div className={`block   ${BulkModal ? "!grid-cols-1 w-[650px]" : ""}`}>
          <div className="radio_btn_container">
            <p className="heading">Remarks</p>

            <div className="radio_btn_holder">
              <input name="reason_for_cancel" type="radio" id="child_side" />
              <label htmlFor="child_side">Child side reschedule</label>
            </div>

            <div className="radio_btn_holder">
              <input name="reason_for_cancel" type="radio" id="enabler_side" />
              <label htmlFor="enabler_side">Enabler side reschedule</label>
            </div>

            <div className="radio_btn_holder">
              <input name="reason_for_cancel" type="radio" id="other_side" />
              <label htmlFor="other_side">Other :</label>
            </div>
            <input
              type="text"
              name=""
              id=""
              onInput={(event) => RescheduleReason_input(event)}
              className="otherInputTextBx"
              placeholder="Enter reason for reschedule"
            />
          </div>

          {!BulkModal && (
            <div className="enabler_same">
              <div className="enabler_checkbox">
                <input
                  type="checkbox"
                  name="enabler"
                  id="enablersame"
                  onInput={(event) => sameEnabler(event)}
                />
                <label htmlFor="enablersame">Same enabler</label>
              </div>
              <ElementComponent element_name="Select Enablers" />
            </div>
          )}
        </div>

        <div className="create_reschedule_btns_holder">
          <button className="btn btn1" onClick={closeRescheduleModal}>
            Cancel
          </button>
          <button className="btn btn2" onClick={sendReport}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

function CancelModal({ submitCancel, meetingID, submitSend }) {
  const [Cancellation, setCancellation] = useState({
    meeting_id: meetingID,
    child_side_cancellation: 1,
    enabler_side_cancellation: 0,
    no_show: 0,
    other: 0,
    reason: "",
  });

  // const getRadioValue = () => {
  let radioContainer = document.getElementsByName("cancellation");

  // };
  const checkRadioSelected = () => {
    // console.log(radioContainer[0].checked);
    if (
      radioContainer[0]?.checked ||
      radioContainer[1]?.checked ||
      radioContainer[2]?.checked ||
      radioContainer[3]?.checked
    ) {
      setCancellation(
        Cancellation,
        (Cancellation.meeting_id = meetingID),
        (Cancellation.child_side_cancellation = radioContainer[0]?.checked
          ? 1
          : 0),
        (Cancellation.enabler_side_cancellation = radioContainer[1]?.checked
          ? 1
          : 0),
        (Cancellation.no_show = radioContainer[2]?.checked ? 1 : 0),
        (Cancellation.other = radioContainer[3]?.checked ? 1 : 0)
      );
      submitSend(Cancellation);
    } else {
      toast.warn("Kindly fill the fields");
      toast.clearWaitingQueue();
    }
  };
  return (
    <div className="Schedule_cancel_container">
      <div className="canecl_content_container">
        <h2 className="title">Select reason for cancellation</h2>

        <div className="cancel_radio_container">
          <div className="radio_btn">
            <input type="radio" name="cancellation" id="childside" />
            <label htmlFor="childside">Child side cancellation</label>
          </div>
          <div className="radio_btn">
            <input type="radio" name="cancellation" id="enablerside" />
            <label htmlFor="enablerside">Enabler side cancellation</label>
          </div>
          <div className="radio_btn">
            <input type="radio" name="cancellation" id="noshow" />
            <label htmlFor="noshow">No show</label>
          </div>

          <div className="radio_btn">
            <input type="radio" name="cancellation" id="cancelreason" />
            <label htmlFor="cancelreason">Other</label>
          </div>
          <div className="cancel_textarea_save">
            <textarea
              id="cancelreason"
              placeholder="Enter reason here"
              onInput={(events) => {
                setCancellation(
                  Cancellation,
                  (Cancellation.reason = events.target.value)
                );
              }}
            ></textarea>
            <div>
              <button onClick={submitCancel}>Cancel</button>
              <button onClick={checkRadioSelected}>Save</button>
            </div>
          </div>
        </div>
        <p className="hepler_text">
          <p>**</p>{" "}
          <p>
            Cancellations are irrevocable. You may reschedule if you think the
            class may happen some other time.
          </p>
        </p>
      </div>
    </div>
  );
}

function Feedbackview({
  setFeedback,
  setMainBlurContainer,
  ViewFeedbackData,
  setAttendanceView,
  AttendanceView,
  AssignmentView,
  setAssignmentView,
  Blendedfeedback_view,
  setBlendedfeedback_view,
}) {
  const closeFeedback = () => {
    setFeedback(false);
    setMainBlurContainer(true);
  };
  return (
    <div className="view_feedback_container">
      <div className="viewFeedback_container">
        {AttendanceView && (
          <div className="attendance_view_container">
            <div className="attendance_view_block">
              <div className="heading">
                <h2>Attendance details</h2>
                <button
                  onClick={() => {
                    setAttendanceView(false);
                  }}
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>

              <div className="view_attendance_list_container">
                {ViewFeedbackData?.attendance?.map((item, index) => {
                  return (
                    <div className="view_attendance_list" key={index}>
                      <div>
                        <div>
                          <p>Meeting start date : {item?.meeting_start_date}</p>
                          <p>Meeting end date : {item?.meeting_end_date}</p>
                        </div>
                        <p>Total participant : {item?.total_participant}</p>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Join time</th>
                            <th>Leave time</th>
                            <th>Total mins</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.attendance_data?.map((item2, index2) => {
                            return (
                              <tr key={index2}>
                                <td>{item2?.display_name}</td>
                                <td>{item2?.email_address}</td>
                                <td>{item2?.join_time}</td>
                                <td>{item2?.leave_time}</td>
                                <td>
                                  {Math.floor(
                                    item2?.total_attendance_in_seconds / 60
                                  )}
                                  <span> mins</span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {AssignmentView && (
          <div className="assignment_view_conainer">
            <div className="assignment_view_block">
              <div className="heading">
                <h2>Assignments Details</h2>
                <button onClick={() => setAssignmentView(false)}>
                  <i className="fa fa-close"></i>
                </button>
              </div>

              <p>
                <b>Game : </b>
                {ViewFeedbackData?.assignments?.["game"]?.join()}
              </p>
              <p>
                <b>LWS : </b> {ViewFeedbackData?.assignments?.["lws"]?.join()}
              </p>
              <p>
                <b>PWS : </b>
                {ViewFeedbackData?.assignments?.["pws"]?.join()}
              </p>
            </div>
          </div>
        )}

        {Blendedfeedback_view && (
          <div className="blended_feedback_view_container">
            <div className="blended_feedback_view_block">
              <div className="heading">
                <h2>Blended feedback view</h2>
                <button onClick={() => setBlendedfeedback_view(false)}>
                  <i className="fa fa-close"></i>
                </button>
              </div>

              <table className="blended_view_table">
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>Topics</th>
                    <th>Recall</th>
                  </tr>
                </thead>
                <tbody>
                  {ViewFeedbackData?.blended_data?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.topic}</td>
                        <td>{item?.recall}</td>
                      </tr>
                    );
                  })}
                  {ViewFeedbackData?.blended_data?.length < 1 && <p>No data</p>}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className="feedback_heading">
          <h3>Feedback</h3>
          <button onClick={closeFeedback}>
            <i className="fa fa-close"></i>
          </button>
        </div>

        <div className="feedback_section">
          <div className="section_heading">
            <p>Tradiness</p>
            <div></div>
          </div>

          <p>
            <b>Attendance</b>{" "}
            <span>
              {ViewFeedbackData?.attendance__meta_desc}{" "}
              <button
                className="view_attendance_btn"
                onClick={() => {
                  setAttendanceView(true);
                }}
              >
                View attendance details
              </button>
            </span>
          </p>
          <p>
            <b>Punctuality</b>{" "}
            <span>{ViewFeedbackData?.punctuality__meta_desc}</span>
          </p>
        </div>
        <div className="feedback_section">
          <div className="section_heading">
            <p>Learning outcome</p>
            <div></div>
          </div>

          <p>
            <b>Topics covered</b>{" "}
            <span>{ViewFeedbackData?.topics_assigned}</span>
          </p>
          <p>
            <b>Other topics </b> <span>{ViewFeedbackData?.other_topics}</span>
          </p>
          <p>
            <b>Assignments</b>{" "}
            <span>
              {ViewFeedbackData?.assignments__meta_desc}
              <button
                className="view_attendance_btn"
                onClick={() => {
                  setAssignmentView(true);
                }}
              >
                View assignments details
              </button>
            </span>
          </p>
          <p>
            <b>Learning outcome</b>{" "}
            <span>{ViewFeedbackData?.learning_outcomes__meta_desc}</span>
          </p>
        </div>
        <div className="feedback_section">
          <div className="section_heading">
            <p>Learning outcome</p>
            <div></div>
          </div>

          <p>
            <b>Participation</b>{" "}
            <span>{ViewFeedbackData?.participation_level__meta_desc}</span>
          </p>
          <p>
            <b>Involvement</b>{" "}
            <span>{ViewFeedbackData?.involvement__meta_desc}</span>
          </p>
          <p>
            <b>Class readiness</b>{" "}
            <span>{ViewFeedbackData?.class_readiness__meta_desc}</span>
          </p>
        </div>
        <div className="feedback_section">
          <div className="section_heading">
            <p>Environment</p>
            <div></div>
          </div>

          <p>
            <b>Learning environment</b>{" "}
            <span>{ViewFeedbackData?.learning_enviornment__meta_desc}</span>
          </p>
          <p>
            <b>Internet connectivity</b>{" "}
            <span>{ViewFeedbackData?.internet_connectivity__meta_desc}</span>
          </p>
        </div>
        <div className="feedback_section">
          <div className="section_heading">
            <p>Blended feedback</p>
            <div></div>
          </div>

          <p>
            <b>Blended feedback</b>{" "}
            <span>
              <button
                className="view_attendance_btn"
                onClick={() => {
                  setBlendedfeedback_view(true);
                }}
              >
                View blended feedback
              </button>
            </span>
          </p>
        </div>
        <div className="feedback_section">
          <div className="section_heading">
            <p>Remarks</p>
            <div></div>
          </div>

          <p>
            <b>Remarks</b> <span>{ViewFeedbackData?.remarks}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

function ViewComments({
  setComments,
  setMainBlurContainer,
  ViewRescheduleData,
}) {
  return (
    <div className="view_comments_container">
      <div className="comments_section">
        <div className="comments_heading">
          <h3>Comments</h3>
          <button
            onClick={() => {
              setComments(false);
              setMainBlurContainer(true);
            }}
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
        <div className="comment_reason">
          <p>
            <b>Reason</b>
            <span>{ViewRescheduleData?.reason}</span>
          </p>
          <p>
            <b>Remarks</b>
            <span>{ViewRescheduleData?.remark}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
function RescheduledComments({
  setRescheduledModal,
  setMainBlurContainer,
  ViewRescheduleData,
}) {
  return (
    <div className="reschedule_view">
      <div className="reschedule_comments">
        <div className="comments_heading">
          <h3>Reschedule</h3>
          <button
            onClick={() => {
              setRescheduledModal(false);
              setMainBlurContainer(true);
            }}
          >
            <i className="fa fa-close"></i>
          </button>
        </div>

        <div className="rescheduled_section_view">
          <div className="reschedule_date_time_container">
            <p>
              <b>Date</b>
              <span>{ViewRescheduleData?.rescheduled_date}</span>
            </p>
            <p>
              <b>Time</b>
              <span>{ViewRescheduleData?.rescheduled_time}</span>
            </p>
          </div>

          <div className="reschedule_other_detail">
            <p>
              <b>Enabler name</b>
              <span>{ViewRescheduleData?.enabler_name}</span>
            </p>
            <p>
              <b>Reason</b>
              <span>{ViewRescheduleData?.reason}</span>
            </p>
            <p>
              <b>Remarks</b>
              <span>{ViewRescheduleData?.remark}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function Suspended({
  setSuspendedModal,
  setMainBlurContainer,
  setSuspendedData,
  SuspendedData,
  Send_Suspended,
}) {
  const SuspendedRef = useRef(0);
  return (
    <div className="suspended_main_container">
      <div className="suspended_center">
        <textarea
          name=""
          id=""
          ref={SuspendedRef}
          placeholder="Reason for suspended"
        ></textarea>

        <div className="suspended_btn">
          <button
            onClick={() => {
              setSuspendedModal(false);
              setMainBlurContainer(true);
            }}
          >
            No
          </button>
          <button
            onClick={() => {
              if (SuspendedRef.current.value != "") {
                setSuspendedData(
                  SuspendedData,
                  (SuspendedData.cancellation_reason =
                    SuspendedRef.current.value)
                );

                Send_Suspended();
              } else {
                toast.warn("Kindly fill the fields");
                toast.clearWaitingQueue();
              }
            }}
          >
            Yes
          </button>
        </div>
        <p
          style={{
            color: "grey",
            fontWeight: "600",
            textAlign: "center",
            fontSize: "13px",
          }}
        >
          ** Once this action is performed cannot be reverted back
        </p>
      </div>
    </div>
  );
}
export default IndividualSchedule;
