import { USER_DETAILS } from "../Actiontype";

const initialState = {
    role: '',
    is_notification: true,
    is_schedule: true,
    contact_number: '',
    email: '',
    username: ''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_DETAILS:
            return {
                ...state,
                role: action.payload?.role,
                is_notification: action.payload?.notification,
                is_schedule: action.payload?.schedule,
                contact_number: action.payload?.contact_number,
                email: action.payload?.email,
                username: action.payload?.username,

            }

        default:
            return state;
    }
}