import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { URL } from "../../DROPDOWNDATA";
import Topbar from "../childPage/topMenu/TopMenu";
import "./PTM.css";
import Ptmadd from "./Ptmadd/Ptmadd";
import PTMtable from "./PTMtable/PTMtable";
import Ptmview from "./Ptmview/Ptmview";
import usePost from "../../Hooks/usePost";
import FileSaver from "file-saver";

const PTM = ({ setBreadcrumItem }) => {
  const { id, childname } = useParams();
  const { Postdata, LoadingPost, Postcall } = usePost(`${URL}/ptm_table_excel`);
  const [PTM_table_data, setPTM_table_data] = useState([]);
  const [QuestionData, setQuestionData] = useState();
  const [Loader, setLoader] = useState(false);
  const [SelectedClass, setSelectedClass] = useState({
    id: "",
    view_type: "",
  });

  async function Get_PTM_table() {
    let item = {
      child_view_id: atob(id),
    };
    setLoader(true);
    await fetch(`${URL}/ptm_table`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        console.log(obj);
        setLoader(false);
        setPTM_table_data(obj.body);
      })
      .catch((err) => {
        setLoader(false);

        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  const DownloadPTM = async () => {
    let item = {
      child_view_id: atob(id),
    };
    setLoader(true);
    await fetch(`${URL}/ptm_table_excel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) => r.blob())
      .then((obj) => {
        setLoader(false);
        FileSaver.saveAs(obj, `${atob(childname)}_PTM.xlsx`);
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  useEffect(() => {
    Get_PTM_table();
  }, []);

  useEffect(() => {
    setBreadcrumItem([
      { name: "Student List", link: `/` },
      {
        name: atob(childname),
        link: `/child_details/${childname}/${id}`,
      },
    ]);
  }, [id]);
  return (
    <div>
      <Topbar id={id} childname={childname} />
      <div className="ptm_container">
        <div className="flex items-center justify-between pr-10">
          <h2>Parent teachers meeting</h2>
          <button
            onClick={DownloadPTM}
            className="bg-[#007872] px-3 py-2 rounded text-[white] font-semibold"
          >
            <i class="fa-solid fa-download"></i> Download
          </button>
        </div>
        {PTM_table_data && (
          <PTMtable
            PTM_table_data={PTM_table_data}
            setSelectedClass={setSelectedClass}
            setQuestionData={setQuestionData}
            setLoader={setLoader}
          />
        )}

        {/* ADD VIEW TYPE -ADD- ||-VIEW-*/}

        {SelectedClass?.view_type && (
          <div className="ptm_view_add_container">
            {SelectedClass?.view_type == "add" && (
              <Ptmadd
                QuestionData={QuestionData}
                SelectedClass={SelectedClass}
                setSelectedClass={setSelectedClass}
                setQuestionData={setQuestionData}
                setLoader={setLoader}
                Loader={Loader}
              />
            )}
            {SelectedClass?.view_type == "view" && (
              <Ptmview
                QuestionData={QuestionData}
                SelectedClass={SelectedClass}
                setSelectedClass={setSelectedClass}
              />
            )}
          </div>
        )}
      </div>
      {Loader && (
        <div className="add_enabler_loading_screen">
          <div className="circle_holder loadercircle">
            <div className="inner_circle loadercircle">
              <div className="most_inner_circle loadercircle">
                <div className="most_inner_most_circle loadercircle"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PTM;
