import React from "react";
import "./PTMtemplate.css";

const MQR_View = ({ Data }) => {
  return (
    <div className="MQR_view_cpmtainer">
      <h2>{Data?.main_question}</h2>

      <div className="MQR_answer_list_container">
        {Data?.question_list?.map((item, index) => {
          return (
            <div key={index} className="MQR_answer_block">
              <p>{item?.question_text}</p>
              <span>-</span>
              <p>{item?.given_answer_txt}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MQR_View;
