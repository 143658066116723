import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import CalenderCompoent from "../../components/CalenderCompoent/CalenderCompoent";
import HolidayList from "./HolidayList/HolidayList";
import TaskList from "./TaskList/TaskList";
import "./scheduling.css";
import { Autocomplete, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import useEnablerList from "../../customHooks/useEnablerList";
import Select from "react-select";
// import "bootstrap/dist/css/bootstrap.min.css";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { URL } from "../../DROPDOWNDATA";
import usePost from "../../Hooks/usePost";
const CalendarList = [
  { label: "Company Calendar", year: 1994 },
  { label: "Lucy Calendar", year: 1972 },
  { label: "Jharna Calendar", year: 1974 },
];

export default function Scheduling(props) {
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  const History = useNavigate();

  // Custom hooks enabler list
  const [enabler_list, setEnabler_List, getProgramme] = useEnablerList();

  const [toggleHolidayActiveState, setToggleHolidayActiveState] =
    useState(true);

  const [isLoading, setIsloading] = useState(false);

  const [selectedEventData, setEventData] = useState([]); // calling this from Calendar component
  const [isModalOPen, setIsModalOpen] = useState(true);
  const [postDate, setPostDate] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [showInput, setShowIput] = useState(true);

  const [Holidaylist, setHolidaylist] = useState();

  const [storeRadio_categoryHoliday, setstoreRadio_categoryHoliday] =
    useState(null);

  const [dailySchedule, setDailySchedule] = useState([]);
  const [editRescheduleHoliday, seteditRescheduleHoliday] = useState(false);
  // FILTER
  const [FilteredEnablerList, setFilteredEnablerList] = useState({
    date: "",
    intervention: 1,
    level_2: 1,
    enabler_id: "",
    school_id: "",
  });
  const Schoolid = useRef("");
  const Enablerid = useRef("");
  const Intervention_id = useRef(1);
  const Level_id = useRef(1);
  const [Schoolsearch, setSchoolsearch] = useState("");
  const [Schooldropdown, setSchooldropdown] = useState(false);

  const SchoolList = usePost(`${URL}/school_list?page_no=1`);

  const SchoolsearchHandler = (e) => {
    setSchoolsearch(e.target.value);
    SchoolList.Postcall({
      search: e.target.value,
      user_id: "",
    });
    if (e.target.value == "") {
      setFilteredEnablerList((prev) => ({ ...prev, school_id: "" }));
      let data = {
        date: FilteredEnablerList.date,
        intervention: FilteredEnablerList?.intervention,
        level_2: FilteredEnablerList.level_2,
        enabler_id: FilteredEnablerList.enabler_id,
        school_id: "",
      };
      DAILY_SCHEDULE(data);
    }
  };
  const Selectschool_Handler = (id, name) => {
    setSchoolsearch(name);
    Schoolid.current = id;
    setFilteredEnablerList((prev) => ({ ...prev, school_id: id }));
    let data = {
      date: FilteredEnablerList.date,
      intervention: FilteredEnablerList?.intervention,
      level_2: FilteredEnablerList.level_2,
      enabler_id: FilteredEnablerList.enabler_id,
      school_id: id,
    };
    DAILY_SCHEDULE(data);
  };
  async function Get_initial_date_list() {
    setIsloading(true);
    await fetch(URL + "/company_holiday", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setHolidaylist(obj.body);
        setIsloading(false);
      });
  }
  function go_to_scheduleSession() {
    async function ValidToken() {
      setIsloading(true);
      await fetch(URL + "/validate_token", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authKey,
        },
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          setIsloading(false);
          if (obj.body.error) {
            window.location.href = "https://dlearners.in/login.php";
          } else {
            props.setScheduleType(false);
            History("/scheduleSession");
            window.location.reload();
          }
        });
    }
    ValidToken();
  }
  function go_to_scheduleList() {
    props.setScheduleType(false);
    History("/scheduleList/0/all/1");
  }

  const radioChange = (value) => {
    if (value?.target?.value == 0) {
      setShowIput(false);
      setInputValue("working");
    } else {
      setShowIput(true);
      setInputValue("");
    }
  };
  let radio_checked_value;
  let radio_value = document.getElementsByName("datecategory");
  const send_Holiday = () => {
    for (let i = 0; i < radio_value.length; i++) {
      if (radio_value[i].checked) {
        radio_checked_value = radio_value[i].value;
        setstoreRadio_categoryHoliday(radio_value[i].value);
      }
    }

    if (showInput) {
      if (!inputValue.length) {
        toast.warn("Kindly fill the fields");
        return;
      }
    }

    if (!radio_checked_value) {
      console.log("not filled");
      toast.warn("Kindly fill the fields");
      return;
    } else {
      let data = {
        holiday_date: postDate[0],
        category: parseInt(radio_checked_value),
        holiday_name: inputValue,
      };

      if (selectedEventData.length == 0 && dailySchedule.length > 0) {
        console.log("its a fine working day with schedule");
        seteditRescheduleHoliday(true);
      } else {
        postDate_to_DB(data);
      }

      console.log(data);
    }
  };
  async function postDate_to_DB(data) {
    await fetch(URL + "/company_holiday", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        //   console.log("course data");
        //   console.log(obj.body);
        Get_initial_date_list();

        setInputValue("");
        for (let i = 0; i < radio_value.length; i++) {
          if (radio_value[i].checked) {
            radio_value[i].checked = false;
          }
        }
        setIsModalOpen(true);
        setShowIput(true);
        toast.success(obj.body.message);
        console.log(obj);
      });
  }
  const send_edit_Holiday = () => {
    let data = {
      holiday_date: postDate[0],
      category: parseInt(storeRadio_categoryHoliday),
      holiday_name: inputValue,
    };
    postDate_to_DB(data);

    fetch(URL + "/reschedule_multiple_class", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify({ given_date: postDate[0] }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        Get_initial_date_list();
        setInputValue("");
        for (let i = 0; i < radio_value.length; i++) {
          if (radio_value[i].checked) {
            radio_value[i].checked = false;
          }
        }
        setIsModalOpen(true);
        setShowIput(true);
        seteditRescheduleHoliday(false);
        toast.success(obj.body.message);
        console.log(obj);
      });
  };

  const DAILY_SCHEDULE = async (data) => {
    await fetch(URL + "/schedule_list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setDailySchedule(obj.body);
      });
  };

  const Daily_Schedule = (date) => {
    setFilteredEnablerList((prev) => ({ ...prev, date: date }))
    console.log(Schoolid.current);
    let data = {
      date: date,
      intervention: Intervention_id.current,
      level_2: Level_id.current,
      enabler_id: Enablerid.current,
      school_id: Schoolid.current,
    };

    DAILY_SCHEDULE(data);
  };
  const TYPELIST = [
    { label: "All", value: 1 },
    { label: "Intervention session", value: 2 },
    { label: "Level 2 screening", value: 3 },
  ];

  const Type_handle = (events) => {
    if (events.value == 1) {
      Intervention_id.current = 1;
      Level_id.current = 1;
      setFilteredEnablerList(
        FilteredEnablerList,
        (FilteredEnablerList.intervention = 1),
        (FilteredEnablerList.level_2 = 1)
      );
    }
    if (events.value == 2) {
      Intervention_id.current = 1;
      Level_id.current = 0;
      setFilteredEnablerList(
        FilteredEnablerList,
        (FilteredEnablerList.intervention = 1),
        (FilteredEnablerList.level_2 = 0)
      );
    }
    if (events.value == 3) {
      Intervention_id.current = 0;
      Level_id.current = 1;
      setFilteredEnablerList(
        FilteredEnablerList,
        (FilteredEnablerList.intervention = 0),
        (FilteredEnablerList.level_2 = 1)
      );
    }
    DAILY_SCHEDULE(FilteredEnablerList);
  };
  const filterEnabler = (events) => {
    console.log(events);
    let ARRAY_FILTER_ENABLER = [];
    const FilteredEnabler = events?.map((item) => {
      console.log(item.value);
      ARRAY_FILTER_ENABLER.push(item.value);
    });
    setFilteredEnablerList(
      FilteredEnablerList,
      (FilteredEnablerList.enabler_id = ARRAY_FILTER_ENABLER.join())
    );
    Enablerid.current = ARRAY_FILTER_ENABLER.join();
    DAILY_SCHEDULE(FilteredEnablerList);
  };
  useEffect(() => {
    Get_initial_date_list();
    props.setBreadcrumItem([{ name: "Scheduling", link: "/scheduling" }]);
    SchoolList.Postcall({
      search: "",
      user_id: "",
    });
  }, []);
  // useEffect(() => {
  //  if(SchoolList?.Postdata!=null){

  //  }
  // }, [SchoolList?.Postdata])

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      className={
        toggleHolidayActiveState
          ? "schedule_container holidayListAdded"
          : "schedule_container"
      }
    >
      <div className="holiday_list_container">
        {/* <div className="swipe_tab" onClick={ToggleHolidayList}>
                    <p><i className="fa-solid fa-right-from-bracket"></i></p>
                </div> */}

        <p>Holiday List</p>
        <div
          className={
            toggleHolidayActiveState
              ? "holder_list_holder active"
              : "holder_list_holder"
          }
        >
          {Holidaylist?.map((item, index) => {
            if (item.category == "Holiday") {
              return (
                <HolidayList
                  key={index}
                  name={item.holiday_name}
                  date={item.holiday_date}
                />
              );
            }
          })}
        </div>

        {/* <div className="holiday_elements">
          <button className="btn btn2">
            <i className="fa-solid fa-trash"></i>
            Delete History
          </button>
        </div> */}
      </div>

      <div className="calendar_container">
        <p>Calendar</p>

        <div className="calender_elements_holder">
          <div className="calender_elements w-[40%] relative">
            <div className="flex items-center border-2 rounded-md border-[#007872] w-full">
              <input
                type="text"
                value={Schoolsearch}
                onInput={SchoolsearchHandler}
                onFocus={() => setSchooldropdown(true)}
                onBlur={() => {
                  setTimeout(() => {
                    setSchooldropdown(false);
                  }, 300);
                }}
                placeholder="search school"
                className="school-search-input px-2 py-3 bg-[transparent]  outline-none w-[90%]"
              />
              {Schoolsearch?.length > 0 && (
                <button
                  onClick={() => {
                    Schoolid.current = "";
                    SchoolsearchHandler({ target: { value: "" } });
                    setSchooldropdown(false);
                  }}
                >
                  <i className=" fa-solid fa-xmark"></i>
                </button>
              )}
            </div>

            {Schooldropdown && (
              <div className="school-list-container flex absolute top-[100%] left-0 w-full flex-col z-[1] bg-[white] rounded-md shadow-lg max-h-[400px] overflow-y-auto overflow-x-hidden">
                {SchoolList?.Postdata?.body?.school_details?.map(
                  (item, index) => {
                    return (
                      <button
                        onClick={() =>
                          Selectschool_Handler(
                            item?.school_id,
                            item?.school_name
                          )
                        }
                        className="py-2 border-b-2 text-left px-2  font-medium"
                        key={item?.school_id}
                      >
                        {item?.school_name}
                      </button>
                    );
                  }
                )}
              </div>
            )}
          </div>

          <div className="calender_elements">
            <button className="btn btn1" onClick={go_to_scheduleList}>
              <i className="fas fa-eye"></i>
              View Schedule List
            </button>
          </div>
          <div className="calender_elements">
            <button className="btn btn1" onClick={go_to_scheduleSession}>
              <i className="fas fa-plus-circle"></i>
              Create new Schedule
            </button>
          </div>
        </div>

        {Holidaylist ? (
          <CalenderCompoent
            eventData={Holidaylist}
            setEventData={setEventData}
            isModalOPen={setIsModalOpen}
            setPostDate={setPostDate}
            Daily_Schedule={Daily_Schedule}
            Get_initial_date_list={Get_initial_date_list}
          />
        ) : (
          ""
        )}

        <div className="indication_container">
          <div className="working_indicator">
            <span></span>
            <p>Working</p>
          </div>
          <div className="working_indicator">
            <span></span>
            <p>Non-Working</p>
          </div>
          <div className="working_indicator">
            <span></span>
            <p>Holiday</p>
          </div>
          <div className="working_indicator">
            <span></span>
            <p>Today</p>
          </div>
        </div>
      </div>

      <div className="task_container">
        <p>Daily Schedule</p>

        <div className="taskList_holder">
          <div className="filter_elements">
            <div className="select_level_filter">
              <div className="select_container">
                <Select
                  options={TYPELIST}
                  onChange={Type_handle}
                  placeholder="Select Type"
                />
              </div>
              <div className="select_container">
                <Select
                  closeMenuOnSelect={true}
                  options={enabler_list?.map((e) => ({
                    label: e.users_firstname,
                    value: e.users_id,
                  }))}
                  isMulti
                  onChange={filterEnabler}
                  placeholder="Select Enabler"
                />
              </div>
            </div>
          </div>
          {dailySchedule?.length > 0 ? <div className="right_daily_schedule">
            {dailySchedule.map((item, index) => (
              <TaskList
                key={index}
                time={item.scheduled_time}
                childName={item.child_name}
                enablerName={item.enabler_name}
                sessionType={item.session_type}
                link={item?.meeting_url}
              />
            ))}
          </div> : <p className="text-center text-[18px] font-semibold my-5">No schedule</p>}


        </div>
      </div>

      <div
        className={
          isModalOPen
            ? "date_category_container active"
            : "date_category_container"
        }
      >
        <div className="category_radio_container">
          <div className="heading">
            <h2>
              {editRescheduleHoliday
                ? "Are you sure to Resechedule these classes?"
                : "Select category"}
            </h2>
            <button
              onClick={() => {
                setIsModalOpen(true);
                radioChange(1);
                Get_initial_date_list();
                seteditRescheduleHoliday(false);
              }}
            >
              <i className="fa-solid fa-circle-xmark"></i>
            </button>
          </div>

          {editRescheduleHoliday ? (
            <div className="edit_reschedule_holiday">
              <div className="holiday_reschedule_container">
                {dailySchedule.map((item, index) => (
                  <TaskList
                    key={index}
                    time={item.scheduled_time}
                    childName={item.child_name}
                    enablerName={item.enabler_name}
                    sessionType={item.session_type}
                    link={""}
                  />
                ))}
              </div>

              <div className="holiday_reeschedule_btn">
                <button
                  onClick={() => {
                    seteditRescheduleHoliday(false);
                  }}
                >
                  no
                </button>
                <button onClick={send_edit_Holiday}>yes</button>
              </div>
              <p
                style={{
                  color: "grey",
                  fontWeight: "600",
                  textAlign: "center",
                  padding: "10px 0",
                }}
              >
                ** Once this action is performed cannot be reverted back
              </p>
            </div>
          ) : (
            <div className="radio_input_container">
              <div className="radio_btn">
                <div className="radio_label">
                  <input
                    type="radio"
                    name="datecategory"
                    id="non_working"
                    value={1}
                    onChange={(value) => radioChange(value)}
                  />
                  <label htmlFor="non_working">Non - Working</label>
                </div>
                <div className="radio_label">
                  <input
                    type="radio"
                    name="datecategory"
                    id="holiday"
                    onChange={(value) => radioChange(value)}
                    value={2}
                  />
                  <label htmlFor="holiday">Holiday</label>
                </div>
                <div
                  className={
                    selectedEventData[0]?.category
                      ? "radio_label"
                      : "radio_label hideWorking"
                  }
                >
                  <input
                    type="radio"
                    name="datecategory"
                    id="working"
                    onChange={(value) => radioChange(value)}
                    value={0}
                  />
                  <label htmlFor="working">Working</label>
                </div>
              </div>

              <div
                className={
                  showInput
                    ? "holiday_name_input"
                    : "holiday_name_input hideWorking"
                }
              >
                <input
                  type="text"
                  placeholder="Enter holiday name"
                  // ref={inputValue}
                  value={inputValue}
                  onInput={(event) => setInputValue(event.target.value)}
                />
              </div>

              <button className="save_btn" onClick={send_Holiday}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
