import React, { useEffect } from 'react'
import Calendar from "color-calendar";
import 'color-calendar/dist/css/theme-basic.css';
import 'color-calendar/dist/css/theme-glass.css';
import './calenderCompoent.css';

// const CalendareventsData = [
//     {
//         id: 1,
//         name: "French class",
//         start: "2022-03-17T06:00:00",
//         end: "2022-03-17T06:00:00",
//         type: "holiday"
//     },
//     {
//         id: 2,
//         name: "Blockchain 101",
//         start: "2022-03-20T10:00:00",
//         end: "2022-03-20T10:00:00",
//         type: "non-working"
//     }
// ]


function CalenderCompoent(props) {

    let MC_DATA = [];
    function updateList() {
        // props.Get_initial_date_list()
        props.eventData?.map((item) => {
            // console.log(item);
            MC_DATA.push({
                start: `${item.holiday_date}T00:00:00`,
                end: `${item.holiday_date}T23:59:59`,
                category: item.category,
                event_name: item.holiday_name,
            });

        });
        // console.log("MC_DATA");
        // console.log(MC_DATA);

    }
    let Currentmonth = "";
    function ADD_BG() {
        let event_length = document.querySelectorAll('.calendar__day-event');
        let calendar__day = document.querySelectorAll('.calendar__day');
        let splitMonthDate = Currentmonth.split('-');
        var today = new Date();
        let todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let splitTodatDate = todayDate.split('-')

        for (let i = 0; i < event_length.length; i++) {

            // event_length[i].setAttribute('data-dd', `${splitMonthDate[0]}-${splitMonthDate[1]}-${event_length[i].getAttribute('data-date')}`)
            // console.log(event_length[i].getAttribute('data-value'));
            if (event_length[i].getAttribute('data-value') == 'Non-Working') {
                event_length[i].classList.add('non_working')
            }
            if (event_length[i].getAttribute('data-value') == 'Holiday') {
                event_length[i].classList.add('holiday_bg')
            }



        }

    }

    useEffect(() => {
        updateList();
        // console.log(props.eventData);
        new Calendar({
            id: "#myCal",
            theme: "glass",
            // weekdayType: "long-lower",
            weekdayDisplayType: "long-lower",
            monthDisplayType: "long",
            calendarSize: "large",
            headerBackgroundColor: "#007872",
            headerColor: "white",
            weekdaysColor: "#007872",
            primaryColor: "#007872",
            startWeekday: 0, // Sunday
            layoutModifiers: ["month-align-left"],
            eventsData: MC_DATA,

            dateChanged: (currentDate, events) => {
                // console.log("date change", currentDate, events);
                // props.isModalOPen(false)
                props.setEventData(events)
                var date = new Date(currentDate),
                    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                    day = ("0" + date.getDate()).slice(-2);
                let curntDate = [date.getFullYear(), mnth, day].join("-");
                props.setPostDate([curntDate, events]);
                // console.log(events)
                props.Daily_Schedule(curntDate)
                Currentmonth = curntDate;
                ADD_BG()
            },
            monthChanged: (currentDate, events) => {
                // console.log("month change", currentDate, events);
                var date = new Date(currentDate),
                    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                    day = ("0" + date.getDate()).slice(-2);
                let curntDate = [date.getFullYear(), mnth, day].join("-");
                Currentmonth = curntDate;
                ADD_BG()
                updateList()
            },
            selectedDateClicked: (currentDate, events) => {
                props.isModalOPen(true);
                var date = new Date(currentDate),
                    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                    day = ("0" + date.getDate()).slice(-2);
                let curntDate = [date.getFullYear(), mnth, day].join("-");

                ADD_BG()
                // props.Get_initial_date_list()
                updateList()
                // console.log(curntDate);

            }
        });

        ADD_BG()
    }, [props.eventData])



    return (<div id="myCal"></div>);

}

// https://github.com/PawanKolhe/color-calendar

export default CalenderCompoent;
