import React, { useState } from "react";
import { DateRange } from "react-date-range";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import Pagination from "react-js-pagination";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import OutsideClickHandler from "react-outside-click-handler";
import { URL } from "../../../DROPDOWNDATA";
import FileSaver from "file-saver";
const OverallScreening = ({
  setSpecificTable,
  OverallEnabler_Data,
  currentPage,
  setscurrentPage,
  setSearchValue,
  SearchValue,
  setAssessorID,
  setAssessorName,
  setFiterdate,
  Fiterdate,
  Get_Overall_data,
  setShowFilterdate,
}) => {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showFilter, setshowFilter] = useState(false);

  // document.addEventListener("mouseup", (e) => {
  //   if (e.target == document.querySelector(".filter_date_enabler")) {
  //     if (showFilter) {
  //       setshowFilter(true);
  //     } else {
  //       setshowFilter(true);
  //     }
  //   } else {
  //     setshowFilter(false);
  //   }
  // });

  const DownloadReport = async () => {
    let data;
    await fetch(`${URL}/screening_report_analysis_download`, {
      method: "POST",
      headers: {
        Authorization: authKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        (data = {
          start_date: Fiterdate?.start,
          end_date: Fiterdate?.end,
        })
      ),
    })
      .then((r) => r.blob())
      .then((obj) => {
        FileSaver.saveAs(obj, "screening.xlsx");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="screening_report_dashboard">
      {/* <OutsideClickHandler
        onOutsideClick={() => {
          setshowFilter(false);
        }}
      > */}
      <div className="screening_dashboard_filter">
        {SearchValue.length > 0 && (
          <button
            className="clear"
            style={{
              position: "absolute",

              backgroundColor: "transparent",
              color: "grey",
              border: "none",
              outline: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              setSearchValue("");
            }}
          >
            <i className="fa-solid fa-circle-xmark"></i>
          </button>
        )}
        <input
          type="text"
          placeholder="Enabler name"
          className="search-input-screening"
          onInput={(e) => {
            setscurrentPage(1);
            setSearchValue(e.target.value);
          }}
          value={SearchValue}
        />
        <button
          className="screening_filter_btn"
          onClick={() => {
            setshowFilter(!showFilter);
          }}
        >
          <p>
            {" "}
            <i className="fa-solid fa-filter"></i> Filter
          </p>
        </button>
        <button
          onClick={() => {
            DownloadReport();
          }}
          style={{ width: "150px" }}
          className="screening_filter_btn"
        >
          <i className="fa-solid fa-download"></i> Download
        </button>
        {showFilter && (
          <div className="filter_date_enabler">
            <p>* Filter is based on one-on-one screening completion date</p>
            <div className="input_container">
              <div>
                {/* <p>From </p> */}
                {/* <input
                    type="date"
                    name=""
                    id=""
                    max={new Date().toISOString().split("T")[0]}
                    value={Fiterdate?.start}
                    onInput={(e) => {
                      console.log(e.target.value);
                      setFiterdate({ ...Fiterdate, start: e.target.value });
                    }}
                  /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture={true}
                    label={"From date"}
                    value={Fiterdate?.start}
                    inputFormat={"dd/MM/yyyy"}
                    // onError={ErrorHandler}
                    onChange={(events) => {
                      setFiterdate((prev) => ({
                        ...prev,
                        start: formatDate(events),
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="off"
                        {...params}
                        helperText={params?.inputProps?.placeholder}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div>
                {/* <p>To</p>
                <input
                  type="date"
                  name=""
                  id=""
                  max={new Date().toISOString().split("T")[0]}
                  value={Fiterdate?.end}
                  onInput={(e) => {
                    setFiterdate({ ...Fiterdate, end: e.target.value });
                  }}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture={true}
                    label={"To date"}
                    value={Fiterdate?.end}
                    inputFormat={"dd/MM/yyyy"}
                    // onError={ErrorHandler}
                    onChange={(events) => {
                      setFiterdate((prev) => ({
                        ...prev,
                        end: formatDate(events),
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="off"
                        {...params}
                        helperText={params?.inputProps?.placeholder}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <button
              className="filter-apply-btn"
              style={{
                opacity:
                  Fiterdate?.start != "" && Fiterdate?.end != "" ? 1 : 0.2,
              }}
              onClick={() => {
                console.log(Fiterdate);
                Get_Overall_data();
                setShowFilterdate(true);
              }}
            >
              Apply
            </button>
          </div>
        )}
      </div>
      {/* </OutsideClickHandler> */}
      <table className="screening_report_table">
        <thead>
          <tr>
            <th>Enabler name</th>
            <th>No.of screening done</th>
            <th>No.of reports sent</th>
            <th>No.of reports pending</th>
            <th>Not started</th>
            <th>Pending with approver</th>
            <th>Pending with assessor</th>
            <th>Approved - Not sent to parent</th>
          </tr>
        </thead>
        <tbody>
          {OverallEnabler_Data?.details?.map((item, index) => {
            return (
              <tr key={index}>
                <td
                  className="enabler_name_click_btn"
                  onClick={() => {
                    setAssessorID(item?.accessor_id);
                    setSpecificTable(true);
                    setAssessorName(item?.accessor_name);
                  }}
                >
                  {item.accessor_name}
                </td>
                <td>{item?.no_of_screenings_done}</td>
                <td>{item?.no_of_reports_sent_to_parent}</td>
                <td>{item?.no_of_reports_pending}</td>
                <td>{item?.not_started}</td>
                <td>{item?.pending_with_approver}</td>
                <td>{item?.pending_with_accessor}</td>
                <td>{item?.aprroved_but_not_sent}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={10}
        totalItemsCount={OverallEnabler_Data?.count}
        pageRangeDisplayed={10}
        itemClass="paginationLi_new"
        prevPageText="Prev"
        nextPageText="Next"
        activeLinkClass="pagLi_sc"
        activeClass="pagLi_sc"
        itemClassFirst="go_first"
        itemClassLast="go_first"
        itemClassPrev="prev_btn"
        itemClassNext="next_btn"
        onChange={(e) => {
          console.log(e);
          setscurrentPage(e);
        }}
      />
    </div>
  );
};

export default OverallScreening;
