import React from "react";

const Aboutenabler = ({
  ActiveStep,
  Award_Input_count,
  RemoveAward_input,
  setAward_Input_count,
  AboutFav,
  setAboutFav,
  RCIno,
  setRCIno,
  setRCIfile,
  RCIfile,
  Hobbies_Input_count,
  RemoveHobbie_input,
  setHobbies_Input_count,
  AboutAccom,
  setAboutAccom,
  Passion_Input_count,
  RemovePassion_input,
  setPassion_Input_count,
  ADD_About,
}) => {
  return (
    <div
      className={
        ActiveStep == 2
          ? "about_details_container"
          : "about_details_container active"
      }
    >
      <div className="award_certificate_container">
        {Object.keys(Award_Input_count)?.map((item, index) => {
          return (
            <div className="section_input_wrapper" key={index}>
              <label htmlFor="">
                Enter Awards/Certificate {index + 1}{" "}
                {Object.keys(Award_Input_count)?.length > 1 && (
                  <button
                    onClick={() => {
                      RemoveAward_input(Object.keys(Award_Input_count)[index]);
                    }}
                  >
                    <i className="fa-solid fa-circle-minus"></i>
                  </button>
                )}
              </label>
              <input
                type="text"
                name={Object.keys(Award_Input_count)[index]}
                placeholder="Enter Awards/Certificate"
                defaultValue={
                  Award_Input_count[Object.keys(Award_Input_count)[index]] || ""
                }
                value={
                  Award_Input_count[Object.keys(Award_Input_count)[index]] || ""
                }
                onInput={(e) => {
                  setAward_Input_count({
                    ...Award_Input_count,
                    [e.target.name]: e.target.value.replace(
                      /[^a-zA-Z\s0-9.-]/g,
                      ""
                    ),
                  });
                }}
              />
            </div>
          );
        })}

        <button
          className="add_more_awards_btn"
          onClick={() => {
            if (Object.keys(Award_Input_count)?.length < 10) {
              setAward_Input_count({
                ...Award_Input_count,
                [Math.max(...Object.keys(Award_Input_count)) + 1]: "",
              });
            }
          }}
        >
          Add more <i className="fa-solid fa-circle-plus"></i>
        </button>
      </div>
      <div className="section_input_wrapper fav_rci_container">
        <div className="about_text_input">
          <label htmlFor="">What is their favourite thing in career?</label>
          <input
            type="text"
            placeholder="What is their favourite thing in career?"
            value={AboutFav}
            onInput={(e) => {
              setAboutFav(e.target.value);
            }}
          />
        </div>
        <div className="about_text_input">
          <label htmlFor="">RCI no</label>
          <input
            type="text"
            placeholder="RCI no"
            value={RCIno}
            onInput={(e) => {
              e.target.value = e.target.value?.replace(/[^a-zA-Z0-9 ]/, "");
              setRCIno(e.target.value);
            }}
          />
        </div>
        <div className="about_text_input">
          <label htmlFor="">RCI upload</label>
          <div className="rci_upload">
            <input
              type="file"
              name=""
              id="RCI_UPLOADS"
              accept=".png,.jpg,.jpeg,.pdf"
              onChange={(e) => setRCIfile(e.target.files[0])}
            />
            <label htmlFor="RCI_UPLOADS">
              Upload RCI <i className="fa-solid fa-file-import"></i>
            </label>
          </div>
        </div>

        {RCIfile && (
          <div className="rci_preview">
            {/* 234x113.jpg */}
            {RCIfile?.name ? (
              RCIfile?.name?.includes(".pdf") ? (
                <a
                  href={URL.createObjectURL(RCIfile)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-solid fa-eye"></i> PDF view
                </a>
              ) : (
                <img src={URL.createObjectURL(RCIfile)} alt="" />
              )
            ) : RCIfile?.includes(".pdf") ? (
              <a href={RCIfile} target="_blank" rel="noopener noreferrer">
                <i className="fa-solid fa-eye"></i> PDF view
              </a>
            ) : (
              <img src={RCIfile} alt="" />
            )}
            {/* <img src="https://dummyimage.com/234x113.jpg" alt="" /> */}
            {/* <a href="http" target="_blank" rel="noopener noreferrer">
          <i className="fa-solid fa-eye"></i> PDF view
        </a> */}
          </div>
        )}
      </div>
      <div className="award_certificate_container">
        {Object.keys(Hobbies_Input_count)?.map((item, index) => {
          return (
            <div className="section_input_wrapper" key={index}>
              <label htmlFor="">
                Hobbies {index + 1}{" "}
                {Object.keys(Hobbies_Input_count)?.length > 1 && (
                  <button
                    onClick={() => {
                      RemoveHobbie_input(
                        Object.keys(Hobbies_Input_count)[index]
                      );
                    }}
                  >
                    <i className="fa-solid fa-circle-minus"></i>
                  </button>
                )}
              </label>
              <input
                type="text"
                name={Object.keys(Hobbies_Input_count)[index]}
                placeholder="Enter hobbies"
                value={
                  Hobbies_Input_count[
                    Object.keys(Hobbies_Input_count)[index]
                  ] || ""
                }
                onInput={(e) => {
                  setHobbies_Input_count({
                    ...Hobbies_Input_count,
                    [e.target.name]: e.target.value.replace(
                      /[^a-zA-Z\s0-9.-]/g,
                      ""
                    ),
                  });
                }}
              />
            </div>
          );
        })}

        <button
          className="add_more_awards_btn"
          onClick={() => {
            if (Object.keys(Hobbies_Input_count)?.length < 10) {
              setHobbies_Input_count({
                ...Hobbies_Input_count,
                [Math.max(...Object.keys(Hobbies_Input_count)) + 1]: "",
              });
            }
          }}
        >
          Add more <i className="fa-solid fa-circle-plus"></i>
        </button>
      </div>
      <div className="section_input_wrapper">
        <label htmlFor="">Proudest accomplishment</label>
        <input
          type="text"
          placeholder="Proudest accomplishment"
          value={AboutAccom}
          onInput={(e) => {
            setAboutAccom(e.target.value);
          }}
        />
      </div>
      <div className="award_certificate_container">
        {Object.keys(Passion_Input_count)?.map((item, index) => {
          return (
            <div className="section_input_wrapper" key={index}>
              <label htmlFor="">
                Passion {index + 1}{" "}
                {Object.keys(Passion_Input_count)?.length > 1 && (
                  <button
                    onClick={() => {
                      RemovePassion_input(
                        Object.keys(Passion_Input_count)[index]
                      );
                    }}
                  >
                    <i className="fa-solid fa-circle-minus"></i>
                  </button>
                )}
              </label>
              <input
                type="text"
                name={Object.keys(Passion_Input_count)[index]}
                placeholder="Enter passion"
                value={
                  Passion_Input_count[
                    Object.keys(Passion_Input_count)[index]
                  ] || ""
                }
                onInput={(e) => {
                  setPassion_Input_count({
                    ...Passion_Input_count,
                    [e.target.name]: e.target.value.replace(
                      /[^a-zA-Z\s0-9.-]/g,
                      ""
                    ),
                  });
                }}
              />
            </div>
          );
        })}

        <button
          className="add_more_awards_btn"
          onClick={() => {
            if (Object.keys(Passion_Input_count)?.length < 10) {
              setPassion_Input_count({
                ...Passion_Input_count,
                [Math.max(...Object.keys(Passion_Input_count)) + 1]: "",
              });
            }
          }}
        >
          Add more <i className="fa-solid fa-circle-plus"></i>
        </button>
      </div>

      <button className="about_detail_save_btn" onClick={ADD_About}>
        Next
      </button>
    </div>
  );
};

export default Aboutenabler;
