import React, { useEffect, useState } from "react";
import "./Screening.css";
import OverallScreening from "./overallscreening";
import Enablerscreening from "./enablerscreening";
import { NavLink } from "react-router-dom";
import { URL } from "../../../DROPDOWNDATA";
import Loader from "../../../components/Loader/Loader";
import { useSelector } from "react-redux";
const Screening = () => {
  const UserRole = useSelector((store) => store.privilege);
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  const [SpecificTable, setSpecificTable] = useState(false);
  const [OverallEnabler_Data, setOverallEnabler_Data] = useState([]);
  // overall Screening
  const [currentPage, setscurrentPage] = useState(1);
  const [SearchValue, setSearchValue] = useState("");

  const [AssessorID, setAssessorID] = useState(0);
  const [AssessorName, setAssessorName] = useState("");
  const [loader, setloader] = useState(false);

  const [Fiterdate, setFiterdate] = useState({
    start: "",
    end: "",
  });

  const [ShowFilterdate, setShowFilterdate] = useState(false);

  async function Get_Overall_data() {
    setloader(true);
    let data;
    await fetch(`${URL}/screening_report_analysis?page_no=${currentPage}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify(
        (data = {
          search: SearchValue,
          start_date: Fiterdate?.start,
          end_date: Fiterdate?.end,
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setloader(false);
        // console.log(obj.body);
        setOverallEnabler_Data(obj.body);
      })
      .catch((err) => {
        setloader(false);
      });
  }
  async function Get_Overall_data2() {
    let data;
    await fetch(`${URL}/screening_report_analysis?page_no=${currentPage}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify(
        (data = {
          search: SearchValue,
          start_date: Fiterdate?.start,
          end_date: Fiterdate?.end,
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setloader(false);
        // console.log(obj.body);
        setOverallEnabler_Data(obj.body);
      })
      .catch((err) => {
        setloader(false);
      });
  }
  async function Get_Overall_data3(startdate, enddate) {
    let data;
    await fetch(`${URL}/screening_report_analysis?page_no=${currentPage}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify(
        (data = {
          search: SearchValue,
          start_date: startdate,
          end_date: enddate,
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        // console.log(obj.body);
        setOverallEnabler_Data(obj.body);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    Get_Overall_data();
  }, [currentPage]);
  useEffect(() => {
    Get_Overall_data2();
  }, [SearchValue]);
  if (loader) {
    return <Loader />;
  }
  return (
    <div className="screeing_report_dashboard_container">
      {SpecificTable && (
        <div className="screening_breadcrum">
          <button
            onClick={() => {
              setSpecificTable(false);
            }}
          >
            Screening <i className="fa-solid fa-angle-right"></i>{" "}
          </button>
          <button>{AssessorName}</button>
        </div>
      )}

      {ShowFilterdate && (
        <p className="screening_filter_date">
          <span>{Fiterdate?.start != "" ? "Filter date" : ""}</span>{" "}
          {Fiterdate?.start != "" && (
            <button
              style={{
                opacity: !SpecificTable ? 1 : 0,
                pointerEvents: !SpecificTable ? "all" : "none",
              }}
              onClick={() => {
                setFiterdate({
                  start: "",
                  end: "",
                });
                Get_Overall_data3("", "");
                setShowFilterdate(false);
              }}
            >
              Clear
            </button>
          )}
          <br />
          <p>
            <span> {Fiterdate?.start} </span>
            {Fiterdate?.end != "" ? (
              <span>
                {" "}
                <small>to</small> {Fiterdate?.end}
              </span>
            ) : (
              ""
            )}{" "}
          </p>
        </p>
      )}

      {!SpecificTable && OverallEnabler_Data && (
        <OverallScreening
          setSpecificTable={setSpecificTable}
          OverallEnabler_Data={OverallEnabler_Data}
          currentPage={currentPage}
          setscurrentPage={setscurrentPage}
          SearchValue={SearchValue}
          setSearchValue={setSearchValue}
          setAssessorID={setAssessorID}
          setAssessorName={setAssessorName}
          Fiterdate={Fiterdate}
          setFiterdate={setFiterdate}
          Get_Overall_data={Get_Overall_data}
          setShowFilterdate={setShowFilterdate}
        />
      )}
      {SpecificTable && (
        <Enablerscreening
          AssessorID={AssessorID}
          Fiterdate={Fiterdate}
          setFiterdate={setFiterdate}
        />
      )}

      {UserRole?.role != "schooladmin" && (
        <p style={{ fontWeight: 600, color: "grey", marginLeft: "30px" }}>
          * Default view shows data from 1st September 2022
        </p>
      )}
    </div>
  );
};

export default Screening;
