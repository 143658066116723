import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import usePost from "../../../Hooks/usePost";
import { URL } from "../../../DROPDOWNDATA";

const Coursedetails = ({ setBreadcrumItem }) => {
  const { id, pg_no } = useParams();
  const Navigate = useNavigate();
  const { Postdata, LoadingPost, Postcall } = usePost(
    `${URL}/teacher_reports?page_no=${pg_no}`
  );
  const [ActiveTab, setActiveTab] = useState(0);
  const TabHandler = (value) => {
    setActiveTab(value);
    Navigate(`/courses/${id}/1`);
    Postcall({
      view_type: value == 0 ? "Blended" : value == 1 ? "Game" : "LWS",
      teacher_id: id,
    });
  };
  useEffect(() => {
    setBreadcrumItem([
      { name: "Courses", link: "/courses" },
      { name: "Courses details", link: `/courses/${id}/${pg_no}` },
    ]);

    Postcall({
      view_type: ActiveTab == 0 ? "Blended" : ActiveTab == 1 ? "Game" : "LWS",
      teacher_id: id,
    });
  }, [id, pg_no]);

  return (
    <div>
      <div className="flex">
        <button
          onClick={() => TabHandler(0)}
          className={`py-3 px-3 font-semibold ${
            ActiveTab == 0 ? "bg-[#b5ead7]" : "bg-[#fff]"
          } `}
        >
          Blended session
        </button>
        <button
          onClick={() => TabHandler(1)}
          className={`py-3 px-3  font-semibold border-x-2 ${
            ActiveTab == 1 ? "bg-[#b5ead7]" : "bg-[#fff]"
          }`}
        >
          Immervise games
        </button>
        <button
          onClick={() => TabHandler(2)}
          className={`py-3 px-5  font-semibold ${
            ActiveTab == 2 ? "bg-[#b5ead7]" : "bg-[#fff]"
          }`}
        >
          LWS
        </button>
      </div>
      <div>
        <table
          className={`courses-table  ${
            ActiveTab != 0
              ? "courses-details-table"
              : "courses-details-table-blended"
          } w-[85%] mx-auto mt-4`}
        >
          <thead>
            <tr>
              <th>Si.no</th>
              <th>Date</th>
              <th>Level</th>
              <th>Concept</th>
              <th>Lesson</th>
              {ActiveTab != 0 && <th>Game name</th>}
            </tr>
          </thead>
          <tbody>
            {Postdata?.body?.details?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{(pg_no - 1) * 10 + (index + 1)}</td>
                  <td>{item?.session_time}</td>
                  <td>{item?.level_name}</td>
                  <td>{item?.course_name}</td>
                  <td>{item?.lesson_name}</td>
                  {ActiveTab != 0 && <td>{item?.game_name}</td>}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          activePage={parseInt(pg_no)}
          itemsCountPerPage={10}
          totalItemsCount={Math.ceil(Postdata?.body?.total_count)}
          pageRangeDisplayed={10}
          itemClass="paginationLi_new"
          prevPageText="Prev"
          nextPageText="Next"
          activeLinkClass="pagLi_sc"
          activeClass="pagLi_sc"
          itemClassFirst="go_first"
          itemClassLast="go_first"
          itemClassPrev="prev_btn"
          itemClassNext="next_btn"
          onChange={(e) => {
            // console.log(e);
            Navigate(`/courses/${id}/${e}`);
          }}
        />
      </div>
    </div>
  );
};

export default Coursedetails;
