import React, { useState, useEffect } from 'react'
import { URL } from '../DROPDOWNDATA';
const useFetch = (id, pg_no) => {
    const authKey = "Bearer " + localStorage.getItem("loggedToken");
    let data;
    const [Data, setData] = useState(null);
    async function getProgramme(value, pg_noo) {
        await fetch(URL + `/child_programme?page_no=${pg_noo}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: authKey,
            },
            body: JSON.stringify((data = { child_view_id: value })),
        })
            .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
            .then((obj) => {
                setData(obj.body);
            });
    }

    useEffect(() => {
        getProgramme(id, pg_no);
    }, [id])
    return [Data, setData, getProgramme]
}

export default useFetch