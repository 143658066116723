import React from "react";
import "./Individualenrollment.css";
import { useParams } from "react-router-dom";
import TopMenu from "../childPage/topMenu/TopMenu";
import Switch from "react-switch";
import { useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import { URL } from "../../DROPDOWNDATA";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";

const Individualenrollment = () => {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  function getDaysBetweenMonths(date1, date2) {
    let mydate1 = new Date(date1);
    let mydate2 = new Date(date2);

    let diff = mydate2.getTime() - mydate1.getTime();
    let msInDay = 1000 * 3600 * 24;

    return diff / msInDay;
  }
  const { id, childname } = useParams();
  const [IndividualEnrollment, setIndividualEnrollment] = useState({
    is_enrolled: false,
    is_renew: false,
    is_complete_view: false,
    is_complete: "",
    is_toggle: false,
    start_date: "",
    end_date: "",
    class: "",
    status: "",
    type: 0,
    enroll_id: "",
    renew_start_date: "",
    renew_end_date: "",
  });

  const [Error, setError] = useState({
    startdate: false,
    enddate: false,
  });
  // LOADER
  const [Loading, setLoading] = useState(false);
  // ENROLLMENT HISTORY DETAILS
  const [EnrollmentHistory, setEnrollmentHistory] = useState("");
  const [EnrollmentHistory_Modal, setEnrollmentHistory_Modal] = useState(false);
  const [EnrollmentEmiHistory, setEnrollmentEmiHistory] = useState(false);
  const [EnrollmentEmiData, setEnrollmentEmiData] = useState([]);

  // CALCULATE DAYS START
  const Get_End_Date = async (Startdate, clasess_no) => {
    let data;
    await fetch(URL + "/calculate_end_date", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(
        (data = {
          class_timings: [
            {
              day: 1,
              time: "13:00",
            },
            {
              day: 5,
              time: "13:00",
            },
          ],
          start_date: Startdate,
          number_of_classes: clasess_no,
        })
      ),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setIndividualEnrollment((prev) => ({
          ...prev,
          end_date: obj.body?.end_date?.split("-")?.reverse()?.join("-"),
        }));
      });
  };
  // CALCULATE DAYS END
  //   GET ENROLLMENT DETAILS START
  const Get_EnrollmentDetails = async () => {
    let item = {
      child_id: atob(id),
    };
    setLoading(true);
    await fetch(URL + "/view_transaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setLoading(false);

        if (obj.body?.message == "success") {
          setIndividualEnrollment((prev) => ({
            ...prev,
            start_date: obj.body?.transaction_details?.start_date,
            end_date: obj.body?.transaction_details?.end_date,
            type: obj.body?.transaction_details?.is_paid,
            class: obj.body?.transaction_details?.no_of_classess,
            is_enrolled: true,
            renew_start_date: obj.body?.transaction_details?.start_date,
            renew_end_date: obj.body?.transaction_details?.end_date,
            is_renew: false,
            is_complete_view: false,
          }));
          Get_Activestatus();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  //   GET ENROLLMENT DETAILS END
  //   CHECK ACTIVE STATUS START
  const Get_Activestatus = async () => {
    let item = {
      child_id: atob(id),
    };

    await fetch(URL + "/enrolment_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setIndividualEnrollment((prev) => ({
          ...prev,
          is_toggle: obj.body?.is_paused == 1 ? true : false,
          enroll_id: obj.body?.enrollment_id,
          is_complete: obj.body?.is_complete,
        }));
      });
  };
  //   CHECK ACTIVE STATUS END

  // START DATE HANDLER START
  function StartDateHandler(e) {
    setIndividualEnrollment((prev) => ({ ...prev, start_date: formatDate(e) }));
  }
  // START DATE HANDLER END
  // ERROR HANDLER START
  function ErrorHandler(e) {
    setError((prev) => ({ ...prev, startdate: e != null ? true : false }));
    if (e == null && IndividualEnrollment?.class != "") {
      Get_End_Date(
        IndividualEnrollment?.start_date,
        IndividualEnrollment?.class
      );
    }
  }
  // ERROR HANDLER END

  //   CLASS HANDLER START
  function Class_Handler(e) {
    e.target.value = e.target.value?.replace(/[^0-9]/g, "");
    let minvalue = e.target.value?.split("").pop();
    e.target.value = e.target.value > 200 ? minvalue?.join("") : e.target.value;

    setIndividualEnrollment((prev) => ({ ...prev, class: e.target.value }));
    if (!Error?.startdate) {
      Get_End_Date(IndividualEnrollment?.start_date, e.target.value);
    }
  }
  //   CLASS HANDLER END
  async function ActiveToggle_Handler(e) {
    let toggleItem = {
      enrollment_id: IndividualEnrollment?.enroll_id,
      is_paused: e ? 1 : 0,
    };
    if (e) {
      toast.success("User Activated");
      toast.clearWaitingQueue();
    } else {
      toast.success("User Deactivated");
      toast.clearWaitingQueue();
    }
    setIndividualEnrollment((prev) => ({
      ...prev,
      is_toggle: e,
    }));
    await fetch(URL + "/pause_or_resume", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(toggleItem),
    }).then((r) =>
      r.json().then((data) => ({
        status: r.status,
        body: data,
      }))
    );
  }
  //   RENEW ENROLL START
  function RenewEnroll_Handler() {
    setIndividualEnrollment((prev) => ({
      ...prev,
      is_enrolled: false,
      is_renew: true,
      class: "",
      type: 0,
      is_complete_view: true,
    }));
  }
  //   RENEW ENROLL END

  // CREATE ENROLLMENT HANDLER START
  function CreateEnroll_Handler() {
    if (Error?.startdate) {
      toast.warn("Invalid start date");
      toast.clearWaitingQueue();
      return;
    }
    if (
      IndividualEnrollment?.class == "" ||
      IndividualEnrollment?.class == undefined ||
      IndividualEnrollment?.class == null
    ) {
      toast.warn("Kindly fille the no of class");
      toast.clearWaitingQueue();
      return;
    }

    SubmitApi_Handler();
  }
  // CREATE ENROLLMENT HANDLER END
  // API START
  const SubmitApi_Handler = async () => {
    setLoading(true);

    let item = {
      child_id: atob(id),
      from_date: IndividualEnrollment?.start_date,
      to_date: IndividualEnrollment?.end_date,
      no_of_classess: IndividualEnrollment?.class,
      is_paid: IndividualEnrollment?.type,
    };
    let Api_Url = IndividualEnrollment?.is_renew
      ? URL + "/add_renewal_school_admin"
      : URL + "/add_transaction_school_admin";
    await fetch(Api_Url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setLoading(false);

        if (obj.body?.error) {
          toast.error(obj.body?.Detail);
          toast.clearWaitingQueue();
        } else {
          Get_EnrollmentDetails();
          toast.success(obj.body?.Detail);
          toast.clearWaitingQueue();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };
  // API END

  // ENROLLMENT HISTORY START
  const EnrollmentHistory_Handler = async () => {
    let data;
    setLoading(true);

    await fetch(URL + "/child_enrollment_history", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(
        (data = {
          child_id: atob(id),
        })
      ),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setLoading(false);

        setEnrollmentHistory(obj.body);
        setEnrollmentHistory_Modal(true);
      })
      .catch((err) => {
        setLoading(false);

        toast.error("Please try again");
        toast.clearWaitingQueue();
      });
  };
  // ENROLLMENT HISTORY END

  // EMI DETAILS START
  const ViewEmiDetails = async (si_no, view_type) => {
    let data;
    setLoading(true);

    await fetch(URL + "/view_old_emi_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(
        (data = {
          si_no,
          view_type,
        })
      ),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setLoading(false);

        setEnrollmentEmiData(obj.body);
        setEnrollmentEmiHistory(true);
      })
      .catch((err) => {
        setLoading(false);

        toast.error("Please try again");
        toast.clearWaitingQueue();
      });
  };
  // EMI DETAILS END

  // ADD NEW ENROLL HANDLER START
  function AddNewEnroll_Handler() {
    setIndividualEnrollment((prev) => ({
      ...prev,
      start_date: "",
      end_date: "",
      class: "",
      is_enrolled: false,
      is_renew: false,
      is_complete_view: true,
    }));
  }
  // ADD NEW ENROLL HANDLER END
  useEffect(() => {
    Get_EnrollmentDetails();
  }, []);

  return (
    <div>
      <TopMenu id={id} childname={childname} />

      <div className="single_enroll_container">
        <div className="se_header">
          <h2>Enrollment and payment</h2>
          {IndividualEnrollment?.is_enrolled && (
            <div className="se_active_toggle">
              <p>Active</p>
              <Switch
                //   className={IndividualEnrollment?.is_toggle ? "lock" : ""}
                onChange={ActiveToggle_Handler}
                checked={IndividualEnrollment?.is_toggle}
                onColor="#007872"
              />
            </div>
          )}
        </div>
        <div className="se_body">
          {!IndividualEnrollment?.is_enrolled && (
            <div className="se_body_block">
              {!IndividualEnrollment?.is_complete_view && (
                <div className="renew_date_block">
                  {IndividualEnrollment?.renew_start_date && (
                    <div className="renew_date_item">
                      <h6>Current start date</h6>
                      <p>
                        {IndividualEnrollment?.renew_start_date
                          ?.split("-")
                          ?.reverse()
                          ?.join("-")}
                      </p>
                    </div>
                  )}
                  {IndividualEnrollment?.renew_end_date && (
                    <div className="renew_date_item">
                      <h6>Current end date</h6>
                      <p>
                        {IndividualEnrollment?.renew_end_date
                          ?.split("-")
                          ?.reverse()
                          ?.join("-")}
                      </p>
                    </div>
                  )}
                </div>
              )}
              <div className="se_body_date">
                <div className="date_item">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={
                        IndividualEnrollment?.is_renew
                          ? getDaysBetweenMonths(
                              new Date().toISOString()?.split("T")[0],
                              IndividualEnrollment?.end_date
                            ) < 0
                            ? false
                            : true
                          : ""
                      }
                      disablePast={
                        getDaysBetweenMonths(
                          new Date().toISOString()?.split("T")[0],
                          IndividualEnrollment?.start_date
                        ) < 0
                          ? false
                          : true
                      }
                      label={"Start date"}
                      value={IndividualEnrollment?.start_date}
                      inputFormat={"dd/MM/yyyy"}
                      onError={ErrorHandler}
                      onChange={StartDateHandler}
                      renderInput={(params) => (
                        <TextField
                          autoComplete="off"
                          {...params}
                          helperText={params?.inputProps?.placeholder}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="date_item">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={true}
                      disablePast
                      label={"End date"}
                      value={IndividualEnrollment?.end_date}
                      inputFormat={"dd/MM/yyyy"}
                      renderInput={(params) => (
                        <TextField
                          autoComplete="off"
                          {...params}
                          helperText={params?.inputProps?.placeholder}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="se_body_classes">
                <label htmlFor="">No of classes</label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={IndividualEnrollment?.class}
                  onInput={Class_Handler}
                />
              </div>

              <div className="se_body_type">
                <p>Enrollment type</p>
                <div className="radio_block">
                  <div className="radio_item">
                    <input
                      type="radio"
                      name="enrollmentType"
                      id="enrollmentTypefree"
                      value={0}
                      checked={IndividualEnrollment?.type == 0}
                      onChange={(e) => {
                        setIndividualEnrollment((prev) => ({
                          ...prev,
                          type: e.target.value,
                        }));
                      }}
                    />
                    <label htmlFor="enrollmentTypefree">Free</label>
                  </div>
                  <div className="radio_item">
                    <input
                      type="radio"
                      name="enrollmentType"
                      id="enrollmentTypepaid"
                      disabled={true}
                    />
                    <label
                      htmlFor="enrollmentTypepaid"
                      style={{ opacity: 0.5 }}
                    >
                      Paid
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}

          {IndividualEnrollment?.is_enrolled && (
            <div className="se_body_block preview">
              <div className="se_preview">
                <table>
                  <thead>
                    <tr>
                      <th>Start date</th>
                      <th>End date</th>
                      <th>No of class</th>
                      <th>Status</th>
                      <th>Type</th>
                      <th>Enrollment history</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {IndividualEnrollment?.start_date
                          ?.split("-")
                          ?.reverse()
                          ?.join("-")}
                      </td>
                      <td>
                        {IndividualEnrollment?.end_date
                          ?.split("-")
                          ?.reverse()
                          ?.join("-")}
                      </td>
                      <td>{IndividualEnrollment?.class}</td>
                      <td>
                        {IndividualEnrollment?.is_toggle == 1
                          ? "Active"
                          : "Paused"}
                      </td>
                      <td>
                        {IndividualEnrollment?.type == 0 ? "Free" : "Paid"}
                      </td>
                      <td>
                        <button onClick={EnrollmentHistory_Handler}>
                          <i class="fa-solid fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div className="se_footer">
          <div className="se_btn_container">
            {IndividualEnrollment?.is_renew ||
              (IndividualEnrollment?.is_complete_view && (
                <button onClick={Get_EnrollmentDetails}>Cancel</button>
              ))}
            {!IndividualEnrollment?.is_enrolled && (
              <button onClick={CreateEnroll_Handler}>Save</button>
            )}

            {IndividualEnrollment?.is_enrolled && (
              <button onClick={RenewEnroll_Handler}>Renew enrollment</button>
            )}
            {IndividualEnrollment?.is_complete == 0 ? (
              !IndividualEnrollment?.is_complete_view ? (
                <button onClick={AddNewEnroll_Handler}>
                  Add new enrollment
                </button>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {EnrollmentHistory_Modal && (
        <div className="enrollment_history_container">
          <div className="enrollment_history_block">
            <div className="heading">
              <div className="back_btn_heading">
                {EnrollmentEmiHistory && (
                  <button
                    onClick={() => {
                      setEnrollmentEmiHistory(false);
                    }}
                  >
                    <i class="fa-solid fa-circle-arrow-left"></i>
                  </button>
                )}
                <h2>
                  {EnrollmentEmiHistory ? "Emi details" : "Enrollment history"}
                </h2>
              </div>
              <button
                onClick={() => {
                  setEnrollmentHistory_Modal(false);
                  setEnrollmentEmiHistory(false);
                }}
              >
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>

            {EnrollmentEmiHistory ? (
              <div className="emi_details_table_wrapper">
                <table className="emi_details_table">
                  <thead>
                    <tr>
                      <th>Payment</th>
                      <th>Paid on</th>
                      <th>Payment mode</th>
                    </tr>
                  </thead>
                  {EnrollmentEmiData?.length > 0 ? (
                    <tbody>
                      {EnrollmentEmiData?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.payment_no}</td>
                            <td>{item?.total_paid_on}</td>
                            <td>{item?.payment_mode}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <p>No data</p>
                    </tbody>
                  )}
                </table>
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>Created on</th>
                    <th>Course name</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Down payment</th>
                    <th>installment amount</th>
                    <th>Payment method</th>
                    <th>Payment mode</th>
                    <th>Amount paid</th>
                    <th>Enroll ID</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {EnrollmentHistory?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={item?.highlight == 1 ? "active" : ""}
                      >
                        <td>{item?.created_on}</td>
                        <td>{item?.course_name}</td>
                        <td>{item?.start_date}</td>
                        <td>{item?.end_date}</td>
                        <td>{item?.down_payment}</td>
                        <td>{item?.installment_amount}</td>
                        <td>{item?.payment_method}</td>
                        <td>{item?.payment_mode}</td>
                        <td>{item?.amount_paid}</td>
                        <td>{item?.enroll_id}</td>
                        <td>
                          <button
                            onClick={() =>
                              ViewEmiDetails(item?.si_no, item?.highlight)
                            }
                            disabled={
                              item?.payment_method == "Full payment"
                                ? true
                                : false
                            }
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}

      {Loading && <Loader />}
    </div>
  );
};

export default Individualenrollment;
