import React, { useState } from "react";
import { useEffect } from "react";
import usePost from "../../Hooks/usePost";
import { URL } from "../../DROPDOWNDATA";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const Addrole = ({ setBreadcrumItem }) => {
  const { type, id, school_role } = useParams();
  const Navigate = useNavigate();
  const [RoleList, setRoleList] = useState([]);
  const Postdata = usePost(`${URL}/user_role_count`);

  const RoleHandler = (userrole) => {
    if (school_role == "0") {
      Navigate(`/listview/${btoa(userrole)}/${id}/${school_role}`);
    } else {
      Navigate(`/listview/${type}/${id}/${btoa(userrole)}`);
    }
  };

  useEffect(() => {
    if (type == "all") {
      setBreadcrumItem([
        { name: "Manage role", link: `/addrole/${type}/${id}/${school_role}` },
      ]);
      Postdata.Postcall({
        user_id: "",
      });
    } else {
      setBreadcrumItem([
        { name: "Manage role", link: `/addrole/all/list/0` },
        { name: "School Admin list", link: `/listview/${type}/list/0` },
        { name: atob(type), link: `/addrole/${type}/${id}/${school_role}` },
      ]);
      Postdata.Postcall({
        user_id: atob(id),
      });
    }
    return () => {
      setRoleList([]);
    };
  }, [id]);

  // POST RESPONSE
  useEffect(() => {
    if (Postdata?.Postdata != null) {
      setRoleList(Postdata?.Postdata?.body);
    }
  }, [Postdata?.Postdata]);

  return (
    <div>
      <div className="flex items-center justify-end mb-3">
        <button
          className="bg-[#007872] text-[#fff] px-3 py-2 ml-auto rounded outline-none border-0"
          onClick={() => {
            Navigate("/addenabler/create");
          }}
        >
          <i className="fa-solid fa-circle-plus mr-3"></i>Add role
        </button>
      </div>
      <div className="flex items-center justify-start flex-col overflow-y-auto max-h-[90%]">
        {RoleList?.map((item, index) => {
          return (
            <div
              onClick={() => RoleHandler(item?.enabler_role)}
              key={item?.enabler_role}
              className="bg-[#FFDAC1] my-2 flex p-2 w-[60%] max-w-[800px] items-stretch cursor-pointer"
            >
              <div className="bg-[#FFFFFF] flex items-center justify-center p-2 py-3 rounded w-[15%]">
                <i className="fa-solid fa-users text-7xl text-[#3A3939]"></i>
              </div>
              <div className="w-[70%] px-2 flex flex-col justify-between h-[100%] border-r-4 border-[#0005]">
                <p className="font-semibold text-[20px]">
                  {item?.enabler_role}
                </p>
                <div className="flex border-t-2 border-[#0006] pt-1 mt-10 w-[80%]">
                  <p className="font-medium mr-4">
                    Active :{" "}
                    <span className="text-2xl">{item?.valid_count}</span>
                  </p>
                  <p className="font-medium ">
                    InActive :{" "}
                    <span className="text-2xl">{item?.invalid_count}</span>
                  </p>
                </div>
              </div>
              <div className="w-[15%] text-center">
                <p className=" font-semibold text-[22px]">Total</p>
                <p className="font-semibold text-[28px] my-2">
                  {item?.total_count}
                </p>
                <p className="text-medium text-[13px]"></p>
              </div>
            </div>
          );
        })}
        {Postdata.LoadingPost && <Loader />}
      </div>
    </div>
  );
};

export default Addrole;
