import React from "react";
import { useState } from "react";
import { NavLink, Redirect, useNavigate } from "react-router-dom";
import TaskAll from "./All/TaskAll";
import Taskcompleted from "./Completed/Taskcompleted";
import Taskdeclined from "./Declined/Taskdeclined";
import "./Notification.css";
import Taskpending from "./Pending/Taskpending";
import { URL } from "../../DROPDOWNDATA";
import Pagination from "react-js-pagination";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Set_Group_Reschedule,
  Set_Group_Schedule_Popup,
} from "../../Redux/Action/GroupschedulelistAction";
const Notification = ({
  MainBlurContainer,
  setMainBlurContainer,
  RescheduleModalbox,
  setRescheduleModalbox,
  Reschedule,
  setReschedule,
  setCancellationModalbox,
  CancellationModalbox,
  setMeetingID,
  MeetingID,
}) => {
  const History = useNavigate();
  const Dispatch = useDispatch();
  const [Task_Current, setTask_Current] = useState("all");
  const [ShowReschedule, setShowReschedule] = useState(false);
  const [ShowDeclinemodal, setShowDeclinemodal] = useState(false);
  const [ShowFilter, setShowFilter] = useState(0);
  const [Notification_Data, setNotification_Data] = useState([]);

  const [Cancel_or_reschedule, setCancel_or_reschedule] = useState(false); //TRUE IS RESCHEDULE;FALSE IS CANCEL
  const [Go_to_heading, setGo_to_heading] = useState("");
  const [Notification_Details, setNotification_Details] = useState({});

  const [Decline_userid, setDecline_userid] = useState("");
  const [DeclineInput, setDeclineInput] = useState("");

  const [Page_no, setPage_no] = useState(1);
  const [Sort_id, setSort_id] = useState(null);
  const [FilterValue, setFilterValue] = useState(0);
  const [SearchInput, setSearchInput] = useState("");

  // CHECKBOX STATE
  const [Showcheckbox_modal, setShowcheckbox_modal] = useState(false);
  const [Checkbox_state, setCheckbox_state] = useState({
    status: "",
    si_no: "",
  });

  // NOTIFICATION LIST
  const [List, setList] = useState([]);
  const [List_total, setList_total] = useState("");
  const [List_increment, setList_increment] = useState(1);
  const ChangeTask_switch = (value) => {
    setTask_Current(value);
  };

  const sort_filter_btn = (value) => {
    if (ShowFilter == 1) {
      setShowFilter(0);
    } else {
      setShowFilter(value);
    }
  };
  const filter_btn = (value) => {
    if (ShowFilter == 2) {
      setShowFilter(0);
    } else {
      setShowFilter(value);
    }
  };

  async function Get_Notification(value, pg_no, sort, filter, search) {
    let item = {
      status: value,
      sort: sort,
      cancellation: filter == 1 ? 1 : 0,
      reschedule: filter == 2 ? 1 : 0,
      search: search,
    };

    await fetch(`${URL}/action_notifications?page_no=${pg_no}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setNotification_Data([{}]);
        setNotification_Data(obj?.body);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  async function Get_details(id) {
    let item = {
      notification_id: id,
    };

    await fetch(`${URL}/view_notifications_details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setShowReschedule(true);
        setNotification_Details(obj.body);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  async function Actions_btn(status, si_no, reason) {
    setShowcheckbox_modal(true);
    setCheckbox_state({
      si_no: si_no,
      status: status,
    });
  }

  async function Checkbox_Post() {
    let item = {
      notification_id: Checkbox_state?.si_no,
      to_status: Checkbox_state?.status,
    };

    await fetch(`${URL}/update_notification_status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        toast.success("Status updated successfully");
        toast.clearWaitingQueue();
        setShowcheckbox_modal(false);
        setCheckbox_state({
          si_no: "",
          status: "",
        });
        Get_Notification(Task_Current, Page_no, Sort_id, FilterValue, "");
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }
  async function Checkbox_Post_NO() {
    setShowcheckbox_modal(false);
    let CHECKK = document.querySelector(
      `#${Checkbox_state?.status}${Checkbox_state?.si_no}`
    );

    if (CHECKK.checked) {
      CHECKK.checked = false;
    } else {
      CHECKK.checked = true;
    }
  }
  async function DeclinePost() {
    let item = {
      notification_id: Decline_userid,
      to_status: "denied",
      reason: DeclineInput,
    };

    await fetch(`${URL}/update_notification_status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        toast.success("Status updated successfully");
        toast.clearWaitingQueue();
        setShowDeclinemodal(false);
        Get_Notification(Task_Current, Page_no, Sort_id, FilterValue, "");
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }
  async function Notification_List(pg) {
    await fetch(`${URL}/non_action_notifications?page_no=${pg}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setList((prev) => {
          return [...prev, ...obj.body?.notifications];
        });

        setList_total(obj.body?.count);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 3 < e.target.clientHeight;
    // console.log("====================================");
    // console.log(
    //   e.target.scrollHeight - e.target.scrollTop - 3,
    //   e.target.clientHeight
    // );
    // console.log("====================================");
    if (bottom) {
      setList_increment(List_increment + 1);
      if (List_increment <= Math.ceil(List_total) / 10) {
        Notification_List(List_increment);
      }
    }
  };

  const RedirectHandle = async (data) => {
    let dataa = {
      notification_id: data?.si_no,
    };
    await fetch(`${URL}/update_seen`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(dataa),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        History(
          data?.redirect_key == "viewSchedule"
            ? `/${data?.redirect_key}`
            : `/${data?.redirect_key}/${btoa(data?.child_name)}/${btoa(
                data?.child_id
              )}`
        );
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  function Goto_Handler() {
    if (Cancel_or_reschedule) {
      if (
        Notification_Details?.group_id == null ||
        Notification_Details?.group_id == ""
      ) {
        setReschedule({
          si_no: Notification_Details?.meeting_record_id,
          class_no: "",
          meeting_date: Notification_Details?.meeting_date,
          meeting_time: Notification_Details?.meeting_time,
          meeting_day: Notification_Details?.meeting_day,
          enabler_id: Notification_Details?.enabler_id,
          enabler_name: Notification_Details?.enabler_name,
          meeting_status: "",
          call_link: "",
        });
        setMainBlurContainer(false);
        setRescheduleModalbox(true);
        History(`/individualSchedule/${btoa(Notification_Details?.child_id)}`);
      } else {
        Dispatch(
          Set_Group_Reschedule({
            call_link: Notification_Details?.call_link,
            class_no: "",
            enabler_id: Notification_Details?.enabler_id,
            enabler_name: Notification_Details?.enabler_name,
            meeting_date: Notification_Details?.meeting_date,
            meeting_day: Notification_Details?.meeting_day,
            meeting_status: "",
            meeting_time: Notification_Details?.meeting_time,
            si_no: Notification_Details?.meeting_record_id,
          })
        );
        Dispatch(
          Set_Group_Schedule_Popup({
            Main: true,
            Schedule: true,
          })
        );
        History(
          `/Individualgroupdetail/${btoa(
            Notification_Details?.group_id
          )}/false/false`
        );
      }
    } else {
      if (
        Notification_Details?.group_id == null ||
        Notification_Details?.group_id == ""
      ) {
        setMeetingID(Notification_Details?.meeting_record_id);
        setMainBlurContainer(false);
        setCancellationModalbox(true);
        History(`/individualSchedule/${btoa(Notification_Details?.child_id)}`);
      } else {
        Dispatch(
          Set_Group_Reschedule({
            call_link: "",
            class_no: "",
            enabler_id: "",
            enabler_name: "",
            meeting_date: "",
            meeting_day: "",
            meeting_status: "",
            meeting_time: "",
            si_no: Notification_Details?.meeting_record_id,
          })
        );
        Dispatch(
          Set_Group_Schedule_Popup({
            Main: true,
            Cancel: true,
          })
        );
        History(
          `/Individualgroupdetail/${btoa(
            Notification_Details?.group_id
          )}/false/false`
        );
      }
    }

    // History(`/individualSchedule/${btoa(Notification_Details?.child_id)}`);
  }
  useEffect(() => {
    Get_Notification(Task_Current, 1, Sort_id, FilterValue, "");
    Notification_List(1);
  }, []);

  return (
    <div className="notification_container">
      <div className="notification_task_container">
        <div className="task_header">
          <div className="task_header_switch_btn_container">
            <button
              onClick={() => {
                ChangeTask_switch("all");
                Get_Notification("all", 1, Sort_id, FilterValue, SearchInput);
                setPage_no(1);
              }}
            >
              All
            </button>
            <button
              onClick={() => {
                ChangeTask_switch("pending");
                Get_Notification(
                  "pending",
                  1,
                  Sort_id,
                  FilterValue,
                  SearchInput
                );
                setPage_no(1);
              }}
            >
              Pending
            </button>
            <button
              onClick={() => {
                ChangeTask_switch("approved");
                Get_Notification(
                  "approved",
                  1,
                  Sort_id,
                  FilterValue,
                  SearchInput
                );
                setPage_no(1);
              }}
            >
              Completed
            </button>
            <button
              onClick={() => {
                ChangeTask_switch("denied");
                Get_Notification(
                  "denied",
                  1,
                  Sort_id,
                  FilterValue,
                  SearchInput
                );
                setPage_no(1);
              }}
            >
              Declined
            </button>
          </div>
          <div className="task_input_container">
            <input
              type="text"
              placeholder="Search for Enabler, Child"
              defaultValue={SearchInput}
              onInput={(e) => {
                setSearchInput(e.target.value);
                if (e.target.value?.length > 2) {
                  Get_Notification(
                    Task_Current,
                    Page_no,
                    Sort_id,
                    FilterValue,
                    e.target.value
                  );
                }
                if (e.target.value == "") {
                  Get_Notification(
                    Task_Current,
                    Page_no,
                    Sort_id,
                    FilterValue,
                    ""
                  );
                }
              }}
            />
            <button>
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>

          <div className="task_sort_filter_container">
            <button
              onClick={() => {
                sort_filter_btn(1);
              }}
            >
              <i className="fa-solid fa-sort"></i> <p>Sort</p>
            </button>
            <button
              onClick={() => {
                filter_btn(2);
              }}
            >
              <i className="fa-solid fa-filter"></i>
              <p>Filter</p>
            </button>

            {ShowFilter == 1 && (
              <div className="sort_filter_container">
                <button
                  onClick={() => {
                    if (Sort_id == 0) {
                      setSort_id(null);
                      Get_Notification(
                        Task_Current,
                        1,
                        "",
                        FilterValue,
                        SearchInput
                      );
                    } else {
                      setSort_id(0);
                      Get_Notification(
                        Task_Current,
                        1,
                        0,
                        FilterValue,
                        SearchInput
                      );
                    }
                  }}
                  className={Sort_id == 0 ? "active" : ""}
                >
                  Latest first
                </button>
                <button
                  onClick={() => {
                    if (Sort_id == 1) {
                      setSort_id(null);
                      Get_Notification(
                        Task_Current,
                        1,
                        "",
                        FilterValue,
                        SearchInput
                      );
                    } else {
                      setSort_id(1);
                      Get_Notification(
                        Task_Current,
                        1,
                        1,
                        FilterValue,
                        SearchInput
                      );
                    }
                  }}
                  className={Sort_id == 1 ? "active" : ""}
                >
                  Oldest first
                </button>
              </div>
            )}
            {ShowFilter == 2 && (
              <div className="status_filter_container">
                <button
                  onClick={() => {
                    if (FilterValue == 1) {
                      setFilterValue(0);
                      Get_Notification(
                        Task_Current,
                        1,
                        Sort_id,
                        0,
                        SearchInput
                      );
                    } else {
                      setFilterValue(1);
                      Get_Notification(
                        Task_Current,
                        1,
                        Sort_id,
                        1,
                        SearchInput
                      );
                    }
                  }}
                  className={FilterValue == 1 ? "active" : ""}
                >
                  Cancellation
                </button>
                <button
                  onClick={() => {
                    if (FilterValue == 2) {
                      setFilterValue(0);
                      Get_Notification(
                        Task_Current,
                        1,
                        Sort_id,
                        0,
                        SearchInput
                      );
                    } else {
                      setFilterValue(2);
                      Get_Notification(
                        Task_Current,
                        1,
                        Sort_id,
                        2,
                        SearchInput
                      );
                    }
                  }}
                  className={FilterValue == 2 ? "active" : ""}
                >
                  Reschedule
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="task_divider_line">
          <span
            className="task_sub_divider"
            style={{
              left: `${
                Task_Current == "all"
                  ? 0
                  : Task_Current == "pending"
                  ? 10
                  : Task_Current == "approved"
                  ? 20
                  : Task_Current == "denied"
                  ? 30
                  : 0
              }% `,
            }}
          ></span>
        </div>

        <div className="task_switch_container">
          {Task_Current == "all" && (
            <TaskAll
              Notification_Data={Notification_Data}
              ShowReschedule={ShowReschedule}
              setShowReschedule={setShowReschedule}
              ShowDeclinemodal={ShowDeclinemodal}
              setShowDeclinemodal={setShowDeclinemodal}
              Get_details={Get_details}
              setCancel_or_reschedule={setCancel_or_reschedule}
              setGo_to_heading={setGo_to_heading}
              Actions_btn={Actions_btn}
              setDecline_userid={setDecline_userid}
              Showcheckbox_modal={Showcheckbox_modal}
              Checkbox_state={Checkbox_state}
            />
          )}
          {Task_Current == "pending" && (
            <Taskpending
              Notification_Data={Notification_Data}
              MainBlurContainer={MainBlurContainer}
              setMainBlurContainer={setMainBlurContainer}
              RescheduleModalbox={RescheduleModalbox}
              setRescheduleModalbox={setRescheduleModalbox}
              Reschedule={Reschedule}
              setReschedule={setReschedule}
              ShowReschedule={ShowReschedule}
              setShowReschedule={setShowReschedule}
              ShowDeclinemodal={ShowDeclinemodal}
              setShowDeclinemodal={setShowDeclinemodal}
              Get_details={Get_details}
              setCancel_or_reschedule={setCancel_or_reschedule}
              setGo_to_heading={setGo_to_heading}
              Actions_btn={Actions_btn}
              setDecline_userid={setDecline_userid}
            />
          )}
          {Task_Current == "approved" && (
            <Taskcompleted
              Notification_Data={Notification_Data}
              Actions_btn={Actions_btn}
            />
          )}
          {Task_Current == "denied" && (
            <Taskdeclined Notification_Data={Notification_Data} />
          )}

          <Pagination
            activePage={Page_no}
            itemsCountPerPage={5}
            totalItemsCount={Math.ceil(Notification_Data?.total_count)}
            pageRangeDisplayed={5}
            itemClass="paginationLi_new"
            prevPageText="Prev"
            nextPageText="Next"
            activeLinkClass="pagLi_sc"
            activeClass="pagLi_sc"
            itemClassFirst="go_first"
            itemClassLast="go_first"
            itemClassPrev="prev_btn"
            itemClassNext="next_btn"
            onChange={(e) => {
              setPage_no(e);
              Get_Notification(
                Task_Current,
                e,
                Sort_id,
                FilterValue,
                SearchInput
              );
            }}
          />
        </div>
      </div>

      <div className="notification_listout_container">
        <div className="heading">
          <h2>
            Notification <i className="fa-solid fa-bell"></i>
          </h2>
        </div>

        <div className="notification_listout_block" onScroll={handleScroll}>
          {List?.map((item, index) => {
            return (
              <div className="notification_list_set" key={index}>
                {/* <NavLink
                  to={
                    item?.redirect_key == "viewSchedule"
                      ? `/${item?.redirect_key}`
                      : `/${item?.redirect_key}/${btoa(
                          item?.child_name
                        )}/${btoa(item?.child_id)}`
                  }
                > */}
                <div
                  style={{
                    backgroundColor:
                      item?.is_seen == 0 ? "#fff9f5" : "#00000005",
                  }}
                  className="redirect"
                  onClick={() => {
                    RedirectHandle(item);
                  }}
                >
                  <div className="notification_list_avator">
                    <div className="notification_list_avator_wrapper">
                      <img
                        src="https://i.pinimg.com/280x280_RS/2e/45/66/2e4566fd829bcf9eb11ccdb5f252b02f.jpg"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="notification_list_message">
                    <p>
                      <b></b>
                      {item?.content}
                    </p>
                    <p className="date">
                      {item?.created_date} {item?.created_time}
                    </p>
                  </div>
                </div>
                {/* </NavLink> */}
              </div>
            );
          })}
        </div>
      </div>

      {ShowReschedule && (
        <div className="task_reschedule_container">
          <div className="task_reschedule_block">
            <div className="heading">
              <h3>{Go_to_heading}</h3>
              <button
                onClick={() => {
                  setShowReschedule(false);
                }}
              >
                <i className="fa-solid fa-circle-xmark"></i>
              </button>
            </div>

            <p className="date">
              <span>Schedule date :</span>{" "}
              <span>{Notification_Details?.meeting_date}</span>
            </p>
            <p className="date">
              <span>Schedule time :</span>{" "}
              <span>{Notification_Details?.meeting_time}</span>
            </p>

            {Cancel_or_reschedule &&
              Notification_Details?.suggested_date != null && (
                <div>
                  <p className="label">Request : </p>
                  <p className="date">
                    <span>Requested date :</span>{" "}
                    <span>{Notification_Details?.suggested_date}</span>
                  </p>
                  <p className="date">
                    <span>Requested time :</span>{" "}
                    <span>{Notification_Details?.suggested_time}</span>
                  </p>{" "}
                  {Notification_Details?.rescheduled_enabler != "" && (
                    <p className="date">
                      <span>Suggested enabler :</span>{" "}
                      <span>{Notification_Details?.rescheduled_enabler}</span>
                    </p>
                  )}
                </div>
              )}
            <p className="label">Description : </p>
            <p className="text">{Notification_Details?.content}</p>

            <p className="label">Reason : </p>
            <p className="text">{Notification_Details?.enabler_remark}</p>

            {Notification_Details?.is_complete == 0 && (
              <button
                className="reschedule_btn"
                onClick={() => {
                  Goto_Handler();
                }}
              >
                Go to
              </button>
            )}

            {Notification_Details?.is_bulk && (
              <p style={{ fontSize: "16px", fontWeight: "600", color: "red" }}>
                *Leave request
              </p>
            )}
          </div>
        </div>
      )}

      {ShowDeclinemodal && (
        <div className="task_decline_container">
          <div className="task_decline_block">
            <div className="heading">
              <h2>Decline</h2>
              <button
                onClick={() => {
                  setShowDeclinemodal(false);
                }}
              >
                <i className="fa-solid fa-circle-xmark"></i>
              </button>
            </div>

            <textarea
              name=""
              placeholder="Add reason for decline"
              id="task_decline_textarea"
              cols="30"
              rows="10"
              onInput={(e) => {
                setDeclineInput(e.target.value);
              }}
            ></textarea>

            <button id="task_decline_send_btn" onClick={DeclinePost}>
              Done
            </button>
          </div>
        </div>
      )}

      {Showcheckbox_modal && (
        <div className="checkbox_confirm_container">
          <div className="checkbox_confirm_block">
            <h2>
              {Checkbox_state?.status == "approved"
                ? "Task completed?"
                : "Are you sure to mark this task as incomplete?"}
            </h2>
            <div className="checkbox_confirm_btn">
              <button
                onClick={() => {
                  Checkbox_Post_NO();
                }}
              >
                No
              </button>
              <button onClick={Checkbox_Post}>Yes</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Notification;
