import React from "react";
import { useDispatch } from "react-redux";
import { Set_Group_Schedule_Popup } from "../../Redux/Action/GroupschedulelistAction";

const Bulkaction = ({ Data }) => {
  const Dispatch = useDispatch();

  const BulkReschedule = () => {
    Dispatch(
      Set_Group_Schedule_Popup({
        Schedule: true,
      })
    );
  };
  const BulkCancel = () => {
    Dispatch(
      Set_Group_Schedule_Popup({
        Cancel: true,
      })
    );
  };
  return (
    <div className="h-full flex items-center justify-center">
      <div className="bg-white p-2 rounded-md w-[50%]">
        <div className="flex items-center justify-between mb-2">
          <p className="text-2xl font-semibold">Bulk action</p>
          <button
            className="text-3xl text-[red]"
            onClick={() => {
              Dispatch(
                Set_Group_Schedule_Popup({
                  Main: false,
                  Bulkaction: false,
                })
              );
            }}
          >
            <i className="fa-regular fa-circle-xmark"></i>
          </button>
        </div>
        <div className="w-full h-[400px] !overflow-y-auto">
          <table className="w-full ">
            <thead className="sticky top-0">
              <tr className="!grid !grid-cols-3 w-full">
                <th className="!text-xl">Class no</th>
                <th className="!text-xl">Date</th>
                <th className="!text-xl">Time</th>
              </tr>
            </thead>
            <tbody className="">
              {Data?.map((item, index) => {
                return (
                  <tr key={item?.si_no} className="!grid !grid-cols-3 w-full">
                    <td>{item?.class_no}</td>
                    <td>{item?.meeting_date}</td>
                    <td>
                      {item?.scheduled_time?.split("T")[1]?.replace("Z", "")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-center my-2">
          <button
            onClick={BulkReschedule}
            className="mx-2 py-2 px-4 bg-[#718ae5] text-white font-semibold rounded-md"
          >
            Reschedule classes
          </button>
          <button
            onClick={BulkCancel}
            className="mx-2 py-2 px-4 bg-[#718ae5] text-white font-semibold rounded-md"
          >
            Cancel classes
          </button>
        </div>
      </div>
    </div>
  );
};

export default Bulkaction;
