import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Autocomplete, TextField } from '@mui/material';
import IndividualSchedule from './IndividualSchedule/IndividualSchedule';
import SidebarList from './SidebarList/SidebarList';
import "./scheduleSession.css"
import { URL } from '../../DROPDOWNDATA';
import { useNavigate } from 'react-router-dom';

function Tabpanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

Tabpanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function ScheduleSession({ setEditAll, EditAll, ScheduleType, setScheduleType }) {
    const authKey = "Bearer " + localStorage.getItem("loggedToken");
    const [value, setValue] = React.useState(0);
    const [ChlidList, setChildList] = useState([])

    const History = useNavigate()

    async function Get_childData() {
        await fetch(URL + "/pending_child_enabler_list", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: authKey,
            },
        })
            .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
            .then((obj) => {
                // setHolidaylist(obj.body);
                // setIsloading(false);
                // console.log(obj.body);
                setChildList(obj.body.child_list);

            });
    }

    useEffect(() => {


        // console.log(EditAll);

        if (EditAll?.meeting_details) {
            // console.log("not empty truck");
            setScheduleType(true)
            setChildList([{

                "child_view_id": EditAll.child_view_id,

                "child_name": EditAll.child_name

            }])
        } else {
            Get_childData()
            setScheduleType(false)
        }
    }, [])


    const handleChange = (event, newValue) => {
        if (newValue == 0) {
            setValue(newValue);
        } else {
            History(`/groupschedule/create/new`)
        }
    };

    return (
        <div className='scheduleSessionList'>

            <div className="schedule_session_tabLayout_container">
                <div className="schedule_session_tabLayout">

                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs sx={{ width: '100%' }} value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab sx={{ width: '100%' }} label="Individual" {...a11yProps(0)} />
                                <Tab sx={{ width: '100%' }} label="Group" {...a11yProps(1)} />
                                {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                            </Tabs>
                        </Box>


                        {/* Individual */}
                        <Tabpanel value={value} index={0} >
                            < IndividualSchedule ChlidList={ChlidList} ScheduleType={ScheduleType} EditAll={EditAll} setEditAll={setEditAll} setScheduleType={setScheduleType} />
                        </Tabpanel >

                        {/* Group */}
                        <Tabpanel value={value} index={1} >
                            < IndividualSchedule />
                        </Tabpanel >

                    </Box >

                </div>

                {/* <div className="create_schedule_btns_holder">
                    <button className='btn btn1'>Cancel</button>
                    <button className='btn btn2'>Create Schedule</button>
                </div> */}

            </div>

            <div className='sidebarList'>
                <SidebarList />
            </div>

        </div>
    )
}
