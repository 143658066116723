import React from "react";
import { Button, Modal, Select } from "antd";
const { Option } = Select;
const Modelbox = ({
  ModalDetails,
  handleCancel,
  handleOk,
  SelectHandler,
  Disable_Enabler,
}) => {
  return (
    <Modal
      title={
        ModalDetails?.type == "DISABLE"
          ? "Are you sure you want to disable enabler?"
          : ModalDetails?.type == "ENABLE"
          ? "Are you sure you want to enable enabler?"
          : "Are you sure you want to delete enabler?"
      }
      centered
      open={ModalDetails?.is_modal}
      // onOk={() => setOpen(false)}
      closeIcon={false}
      footer={[
        <Button key="back" type="link" onClick={handleCancel}>
          Close
        </Button>,
        <Button
          key="submit"
          loading={Disable_Enabler?.LoadingPost}
          onClick={handleOk}
        >
          Submit
        </Button>,
      ]}
      width={500}
    >
      {ModalDetails?.type != "ENABLE" ? (
        <Select
          showSearch
          size="large"
          value={ModalDetails?.selectvalue}
          onChange={SelectHandler}
          style={{ width: "90%", margin: "20px 0" }}
          placeholder="Search to Enabler"
        >
          <Option value="" label="Select alternate enabler">
            Select alternate enabler
          </Option>
          {Disable_Enabler?.Postdata?.body.length > 0 &&
            Disable_Enabler?.Postdata?.body?.map((item, index) => {
              return (
                <Option
                  key={item?.users_id}
                  value={item?.users_id}
                  label={item?.enabler_name}
                >
                  {item?.enabler_name}
                </Option>
              );
            })}
        </Select>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default Modelbox;
