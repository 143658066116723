import React, { useEffect } from "react";
import "./Vakt.css";
import Stackedbar from "../../../components/Stackedbar";
import Piechart from "../../../components/piechart";
import { URL } from "../../../DROPDOWNDATA";
import usePost from "../../../Hooks/usePost";

const Vakt = () => {
  const AssignmentData = usePost(`${URL}/assignment-summary`);

  useEffect(() => {
    AssignmentData.Postcall();
  }, []);

  return (
    <div className="w-[100%] h-full flex items-start justify-around">
      <div className="w-[35%]">
      <p className="font-semibold text-xl text-left">Assignment report</p>

        <Stackedbar
          d1={[
            AssignmentData?.Postdata?.body?.total_game_incompleted,
            AssignmentData?.Postdata?.body?.total_lws_incompleted,
            AssignmentData?.Postdata?.body?.total_pws_incompleted,
          ]}
          d2={[
            AssignmentData?.Postdata?.body?.total_game_completed,
            AssignmentData?.Postdata?.body?.total_lws_completed,
            AssignmentData?.Postdata?.body?.total_pws_completed,
          ]}
          d3={[
            AssignmentData?.Postdata?.body?.total_game_assigned,
            AssignmentData?.Postdata?.body?.total_lws_assigned,
            AssignmentData?.Postdata?.body?.total_pws_assigned,
          ]}
        />
      </div>
      <div className="w-[30%]">
        <p className="font-semibold text-xl text-left">In session report</p>
        <Piechart game={AssignmentData?.Postdata?.body?.in_session_games} lws={AssignmentData?.Postdata?.body?.in_session_lws} />
      </div>
    </div>
  );
};

export default Vakt;
