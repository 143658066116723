import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { URL } from "../../DROPDOWNDATA";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "./Manageenabler.css";

const Manageenabler = ({ breadCrumbs_item, setBreadcrumItem }) => {
  const [Disable_Popup, setDisable_Popup] = useState(false);

  const [Enabler_List_view, setEnabler_List_view] = useState([]);
  const [Loading, setLoading] = useState(false);

  const [Disable_enablerList, setDisable_enablerList] = useState([
    { users_id: "", enabler_name: "Select alternate enabler" },
  ]);

  // FIND THE STATE
  const [Is_action, setIs_action] = useState("");
  // SELECTED ENABLER
  const [SelectedEnabler, setSelectedEnabler] = useState("");
  // SELECTED ALTERNATE ENABLER
  const [AlternateEnabler, setAlternateEnabler] = useState("");
  const History = useNavigate();

  async function View_Enabler_List_view(value) {
    let data = {
      search: value,
    };
    setLoading(true);
    await fetch(URL + "/manage_enabler_view_enabler_list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        console.log(obj.body);
        setEnabler_List_view(obj.body);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
        console.log(err);
        setLoading(false);
      });
  }

  async function DisableHandler(id, value) {
    let data = {
      enabler_id: id,
    };
    setLoading(true);

    await fetch(URL + "/enabler_list_disable", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        console.log(obj.body);
        setIs_action(value);
        setDisable_enablerList((prev) => [
          { users_id: "", enabler_name: "Select alternate enabler" },
          ...obj.body,
        ]);
        setLoading(false);
        setDisable_Popup(true);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
        console.log(err);
        setLoading(false);
      });
  }
  async function DisableEnabler_handle() {
    let data;
    if (Is_action == "delete") {
      if (AlternateEnabler == "") {
        toast.warn("Kindly fill the alternate enabler");
        toast.clearWaitingQueue();
        return;
      }
      data = {
        enabler_id: SelectedEnabler,

        alternate_enabler: AlternateEnabler,
      };
    } else if (Is_action == "disable") {
      if (AlternateEnabler == "") {
        toast.warn("Kindly fill the alternate enabler");
        toast.clearWaitingQueue();
        return;
      }
      data = {
        enabler_id: SelectedEnabler,
        state: 0,
        alternate_enabler: AlternateEnabler,
      };
    } else if (Is_action == "enable") {
      data = {
        enabler_id: SelectedEnabler,
        state: 1,
        alternate_enabler: AlternateEnabler,
      };
    }

    setLoading(true);

    await fetch(
      Is_action == "delete"
        ? URL + "/delete_enabler"
        : URL + "/enable_disable_enabler",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify(data),
      }
    )
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        console.log(obj.body);

        setLoading(false);
        setDisable_Popup(false);
        View_Enabler_List_view();
        toast.success(
          `${
            Is_action == "delete"
              ? "Delete"
              : Is_action == "disable"
              ? "Disabled"
              : "Enabled"
          }  successfully`
        );
        toast.clearWaitingQueue();
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
        console.log(err);
        setLoading(false);
      });
  }
  useEffect(() => {
    setBreadcrumItem([{ name: "Manage role", link: "/manageenabler" }]);
    View_Enabler_List_view();
  }, []);

  return (
    <div className="manage_enabler_container">
      <div className="search_add_enabler_continaer">
        <input
          type="text"
          placeholder="Search for Enabler name,Mobile  no,Email.."
          onInput={(e) => {
            View_Enabler_List_view(e.target.value);
          }}
        />
        <button
          onClick={() => {
            History("/addenabler/create");
          }}
        >
          <i className="fa-solid fa-circle-plus"></i>Add role
        </button>
      </div>

      <div className="enabler_profile_list_container">
        {Enabler_List_view?.map((item, index) => {
          return (
            <div
              className="enabler_profile_container"
              key={index}
              style={{
                backgroundColor:
                  item?.Users_Valid == 0 ? "#e6e6e6fc" : "#fff7f0fc",
                cursor: item?.Users_Valid == 0 ? "not-allowed" : "default",
              }}
            >
              <div className="enabler_profile_delete">
                <button
                  title="Disable enabler"
                  onClick={() => {
                    // setDisable_Popup(true);
                    if (item?.Users_Valid == 0) {
                      setSelectedEnabler(item?.Users_id);
                      setIs_action("enable");
                      setDisable_Popup(true);
                    } else {
                      setSelectedEnabler(item?.Users_id);
                      DisableHandler(item?.Users_id, "disable");
                    }
                  }}
                >
                  <i className="fa-solid fa-ban"></i>
                </button>
                <button
                  title="Delete enabler"
                  onClick={() => {
                    setSelectedEnabler(item?.Users_id);
                    DisableHandler(item?.Users_id, "delete");
                  }}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </div>
              <div className="enabler_profile_img">
                <img
                  src={
                    item?.profile_img == null
                      ? "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"
                      : item?.profile_img
                  }
                  alt="enabler profile img"
                />
              </div>
              <div className="enabler_profile_userdetails">
                <p className="name">{item?.Users_FirstName}</p>
                <p className="role">{item?.enabler_role}</p>

                <p className="email">
                  <b>Email</b>: {item?.Users_Email}
                </p>
                <p className="mobile">
                  <b>Mobile</b>: {item?.Users_Mobile}
                </p>
              </div>

              <NavLink
                style={{
                  pointerEvents: item?.Users_Valid == 0 ? "none" : "all",
                  opacity: item?.Users_Valid == 0 ? 0.4 : 1,
                }}
                to={`/viewenabler/${item?.Users_id}`}
                className="view_profile_btn"
              >
                View profile
              </NavLink>
            </div>
          );
        })}
      </div>

      {Disable_Popup && (
        <div className="disable_enabler_container">
          <div className="disable_enabler_block">
            <h2>
              Are you sure you want to{" "}
              {Is_action == "delete"
                ? "delete"
                : Is_action == "disable"
                ? "disable"
                : "enable"}{" "}
              enabler?
            </h2>
            {Is_action != "enable" && (
              <Select
                closeMenuOnSelect={true}
                options={Disable_enablerList?.map((e) => ({
                  label: e.enabler_name,
                  value: e.users_id,
                }))}
                onChange={(events) => setAlternateEnabler(events?.value)}
                placeholder="Select alternate enabler"
              />
            )}

            <div className="disable_enabler_btn">
              <button
                onClick={() => {
                  setIs_action("");
                  setDisable_Popup(false);
                }}
              >
                No
              </button>
              <button onClick={DisableEnabler_handle}>Yes</button>
            </div>
          </div>
        </div>
      )}

      {Loading && (
        <div className="add_enabler_loading_screen">
          <div className="circle_holder loadercircle">
            <div className="inner_circle loadercircle">
              <div className="most_inner_circle loadercircle">
                <div className="most_inner_most_circle loadercircle"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Manageenabler;
