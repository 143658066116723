import { GROUP_RESCHEDULE, GROUP_RESCHEDULE_POPUP } from "../Actiontype"

export const Set_Group_Reschedule = (data) => {
    return {
        type: GROUP_RESCHEDULE,
        payload: data
    }
}
export const Set_Group_Schedule_Popup = (data) => {
    return {
        type: GROUP_RESCHEDULE_POPUP,
        payload: data
    }
}