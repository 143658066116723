import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import "./Groupschedulelist.css";

const Groupschedulelist = ({
  GroupScheduleList,
  GroupPaginationHandler,
  paginations_count,
}) => {
  return (
    <div className="group_schedule_list_container">
      <div className="student_schedule_list">
        <table>
          <thead>
            <tr>
              <th>S.no</th>
              <th>Group name</th>
              <th>Child name</th>
              <th>Days</th>
              <th>Time</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Enabler Name</th>
              <th>Call Link</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {GroupScheduleList?.meeting_list?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.si_no}</td>
                  <td>{item.group_name}</td>
                  <td>{item.child_name}</td>
                  <td>{item.days}</td>
                  <td>{item.start_time}</td>
                  <td>{item.start_date}</td>
                  <td>{item.end_date}</td>
                  <td>{item.enabler_name}</td>
                  <td>
                    <a href={item.meeting_url} target="_blank">
                      <i className="fas fa-phone"></i>
                    </a>
                  </td>
                  <td>
                    <NavLink
                      to={`/Individualgroupdetail/${btoa(
                        item?.group_id
                      )}/false/false`}
                    >
                      <button>
                        <i className="far fa-eye"></i>
                      </button>
                    </NavLink>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        activePage={paginations_count}
        itemsCountPerPage={10}
        totalItemsCount={Math.ceil(GroupScheduleList?.total_count)}
        pageRangeDisplayed={10}
        itemClass="paginationLi_new"
        prevPageText="Prev"
        nextPageText="Next"
        activeLinkClass="pagLi_sc"
        activeClass="pagLi_sc"
        itemClassFirst="go_first"
        itemClassLast="go_first"
        itemClassPrev="prev_btn"
        itemClassNext="next_btn"
        onChange={(e) => GroupPaginationHandler(e)}
      />
    </div>
  );
};

export default Groupschedulelist;
