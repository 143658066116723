import React from "react";
import Complete from "../TaskComponents/Complete";
import "./Taskcompleted.css";

const Taskcompleted = ({ Actions_btn, Notification_Data }) => {
  return (
    <div className="task_completed_container">
      {Notification_Data?.notification_list?.map((item, index) => {
        return <Complete item={item} key={index} Actions_btn={Actions_btn} />;
      })}
    </div>
  );
};

export default Taskcompleted;
