import React, { memo } from "react";
import { URL } from "../../../DROPDOWNDATA";
import { toast } from "react-toastify";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";

const GroupDate = ({ EnrollDetails, setEnrollDetails, ShowState }) => {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  function getDaysBetweenMonths(date1, date2) {
    let mydate1 = new Date(date1);
    let mydate2 = new Date(date2);

    let diff = mydate2.getTime() - mydate1.getTime();
    let msInDay = 1000 * 3600 * 24;

    return diff / msInDay;
  }
  const CalculateDays = async (Startdate, clasess_no) => {
    let data;
    if (Startdate != "" && clasess_no != "") {
      await fetch(URL + "/calculate_end_date", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify(
          (data = {
            class_timings: [
              {
                day: 1,
                time: "13:00",
              },
              {
                day: 5,
                time: "13:00",
              },
            ],
            start_date: Startdate,
            number_of_classes: clasess_no,
          })
        ),
      })
        .then((r) =>
          r.json().then((data) => ({ status: r.status, body: data }))
        )
        .then((obj) => {
          setEnrollDetails((prev) => ({
            ...prev,
            end_date: obj.body?.end_date?.split("-")?.reverse()?.join("-"),
          }));
        })
        .catch((err) => {
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        });
    }
  };
  function No_of_ClassessHandler(e) {
    e.target.value = e.target.value?.replace(/[^0-9]/g, "");
    let minvalue = e.target.value?.split("").pop();
    e.target.value = e.target.value > 200 ? minvalue?.join("") : e.target.value;
    setEnrollDetails((prev) => ({
      ...prev,
      no_of_class: e.target.value,
    }));
    CalculateDays(EnrollDetails?.start_date, e.target.value);
  }

  return (
    <div className="group_date_container">
      <div className="group_date_item">
        <label htmlFor="noofclasses">No of classes</label>
        <input
          type="text"
          name=""
          id="noofclasses"
          value={EnrollDetails?.no_of_class}
          onInput={No_of_ClassessHandler}
          maxLength={3}
          max={200}
          disabled={ShowState == "edit" ? true : false}
        />
      </div>
      <div>
        <div className="group_date_item">
          {/* <label htmlFor="groupstartdate">
            {ShowState == "renew" ? "New start date" : "Start date"}
          </label>
          <input
            type="date"
            defaultValue={EnrollDetails?.start_date}
            name="group date"
            id="groupstartdate"
            min={new Date().toISOString().split("T")[0]}
            onChange={(e) => {
              CalculateDays(e.target.value, EnrollDetails?.no_of_class);

              setEnrollDetails((prev) => ({
                ...prev,
                start_date: e.target.value,
              }));
            }}
            disabled={
              ShowState == "edit"
                ? EnrollDetails?.start_edit_allow
                : ShowState == "renew"
                ? getDaysBetweenMonths(
                    new Date().toISOString()?.split("T")[0],
                    EnrollDetails?.preview_end_date
                  ) < 0
                  ? false
                  : true
                : ""
            }
          /> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={
                ShowState == "edit"
                  ? EnrollDetails?.start_edit_allow
                  : ShowState == "renew"
                  ? getDaysBetweenMonths(
                      new Date().toISOString()?.split("T")[0],
                      EnrollDetails?.preview_end_date
                    ) < 0
                    ? false
                    : true
                  : ""
              }
              disablePast
              label={ShowState == "renew" ? "New start date" : "Start date"}
              value={EnrollDetails?.start_date}
              inputFormat={"dd/MM/yyyy"}
              error={true}
              onChange={(e) => {
                if (e != null || e != "Invalid Date") {
                  CalculateDays(formatDate(e), EnrollDetails?.no_of_class);
                  setEnrollDetails((prev) => ({
                    ...prev,
                    start_date: formatDate(e),
                  }));
                } else {
                  setEnrollDetails((prev) => ({
                    ...prev,
                    start_date: "",
                  }));
                }
              }}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  helperText={params?.inputProps?.placeholder}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="group_date_item">
          {/* <label htmlFor="groupenddate">
            {ShowState == "renew" ? "New end date" : "End date"}
          </label>
          <input
            value={EnrollDetails?.end_date}
            type="date"
            name="group date"
            id="groupenddate"
            min={new Date().toISOString().split("T")[0]}
            onChange={(e) => {
              setEnrollDetails((prev) => ({
                ...prev,
                end_date: e.target.value,
              }));
            }}
            disabled={true}
          /> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={true}
              label={ShowState == "renew" ? "New end date" : "End date"}
              value={EnrollDetails?.end_date}
              inputFormat={"dd/MM/yyyy"}
              error={true}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  helperText={params?.inputProps?.placeholder}
                />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default memo(GroupDate);
