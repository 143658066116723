// const URL = "http://20.120.84.12:5588";
// const URL = 'http://103.133.214.108:8880';
// const URL = 'https://mobile.dlearners.in';
// const URL = 'http://localhost:8000';
// const URL = 'https://adminlocal.dlearners.in';
// const URL = 'https://adminstaging.dlearners.in';
const URL = 'https://adminprod.dlearners.in';
// https://dlearners.in/Admin/
// http://20.120.84.12/DLAdmin/#/
// https://local.dlearners.in/Admin/
// https://staging.dlearners.in/Admin/
// https://admin.dlearners.in/

// VIKRAM LOCAL
// http://localhost:8000/
// http://localhost:8000/admin


// UAT LOCAL
// https://local.dlearners.in/Admin/
// https://adminlocal.dlearners.in/
const DAYS = [
    { label: "Sunday", id: 0 },
    { label: "Monday", id: 1 },
    { label: "Tuesday", id: 2 },
    { label: "Wednesday", id: 3 },
    { label: "Thursday", id: 4 },
    { label: "Friday", id: 5 },
    { label: "Saturday", id: 6 },
]


const ChildFilterStage = [
    {
        name: "Yet to Start",
        id: "yettostart"
    },
    {
        name: "Teacher evaluation",
        id: "Teacherevaluation"
    },
    {
        name: "Parent evaluation",
        id: "Parentevaluation"
    },
    {
        name: "Level 1 completed",
        id: "level1"
    },
    {
        name: "Level 2 completed",
        id: "level2"
    }
    , {
        name: "Screening report shared",
        id: "Screeningreportshared"
    },
    {
        name: "Enrolled for intervention",
        id: "Enrolledforintervention"
    },
    {
        name: "Enrollment suspended",
        id: "Interventionsuspended"
    },
    {
        name: "Intervention completed",
        id: "Interventioncompleted"
    },

]
const HolidayEventsData = {
    is_sunday_holiday: true,
    is_saturday_holiday: false,
    holiday_list: [
        {
            start: "2022-03-08T00:00:00",
            end: "2022-03-08T23:59:59",
            category: "Non-working",
            event_name: "Women's day",
        },
        {
            start: "2022-03-18T00:00:00",
            end: "2022-03-18T23:59:59",
            category: "Holiday",
            event_name: "Random holiday",
        },
        {
            start: "2022-04-08T00:00:00",
            end: "2022-04-08T23:59:59",
            category: "Non-working",
            event_name: "April day",
        },
        {
            start: "2022-04-18T00:00:00",
            end: "2022-04-19T23:59:59",
            category: "Holiday",
            event_name: "holiday",
        },
    ],
};

const DailyScheduleEvent = [
    {
        si_no: "1",
        scheduled_time: "09:30 am",
        name: "Blended Session",
        class_description: "Lucy - class with Samiksha",
    },
    {
        si_no: "1",
        scheduled_time: "10:30 am",
        name: "Blended Session",
        class_description: "Shardha - class with Ramya",
    },
];

export { DAYS, ChildFilterStage, URL }



// <Dropdown
// placeholder="Day"
// options={DAYS}
// value="day"
// onChange={(value) => {
//   setDownloadReport_date((prev) => ({
//     ...prev,
//     from_day: value.value,
//   }));
// }}
// className="download_report_select_day"
// />
// <Dropdown
// placeholder="month"
// options={MONTH}
// value="day"
// onChange={(value) => {
//   setDownloadReport_date((prev) => ({
//     ...prev,
//     from_month: value.value,
//   }));
// }}
// className="download_report_select_month"
// />
// <Dropdown
// placeholder="year"
// options={YEAR}
// value="day"
// onChange={(value) => {
//   setDownloadReport_date((prev) => ({
//     ...prev,
//     from_year: value.value,
//   }));
// }}
// className="download_report_select_year"
// />


// <Dropdown
//                   placeholder="day"
//                   options={DAYS}
//                   value="day"
//                   onChange={(value) => {
//                     setDownloadReport_date((prev) => ({
//                       ...prev,
//                       to_day: value.value,
//                     }));
//                   }}
//                   className="download_report_select_day"
//                 />
//                 <Dropdown
//                   placeholder="month"
//                   options={MONTH}
//                   value="day"
//                   onChange={(value) => {
//                     setDownloadReport_date((prev) => ({
//                       ...prev,
//                       to_month: value.value,
//                     }));
//                   }}
//                   className="download_report_select_month"
//                 />
//                 <Dropdown
//                   placeholder="year"
//                   options={YEAR}
//                   value="day"
//                   onChange={(value) => {
//                     setDownloadReport_date((prev) => ({
//                       ...prev,
//                       to_year: value.value,
//                     }));
//                   }}
//                   className="download_report_select_year"
//                 />