import React from "react";

const CoursesList = (props) => {
  function GET_COURSEDATA() {
    // window.location.reload();
    props.ResetALL();
    props.settotalAmount_main(
      props.course_details_list[props.indexValue].course_price
    );
    props.useStateSetCourseID(props.indexValue);

    // changing values when the admin changes the course
    props.setCurrentInstallmentValues({
      ...props.currentInstallmentValues,
      current_course_price:
        props.course_details_list[props.indexValue].course_price,
      current_down_payment:
        props.course_details_list[props.indexValue].down_payment_amount,
      current_balance:
        props.course_details_list[props.indexValue].course_price -
        props.course_details_list[props.indexValue].down_payment_amount,
      current_no_of_installment: 1,
      current_installmentInterval:
        props.getDaysBetweenMonths(
          props.startDateValue,
          props.addMonths(
            new Date(props.startDateValue),
            props.course_details_list[props.indexValue]?.duration_in_months
          )
        ) / 1,
      current_installmentAmount:
        (props.course_details_list[props.indexValue].course_price -
          props.course_details_list[props.indexValue].down_payment_amount) /
        1,
      // bug -> currentInstallmentValues.current_no_of_installment = 0 (infinity error)
      // current_discount_amount: 0 // if course changes discount will be empty
    });
  }
  return (
    <li
      key={props.indexValue}
      className={props.useStateCourseID == props.indexValue ? "active" : ""}
      onClick={() => {
        GET_COURSEDATA();
      }}
    >
      <div className="star_icon">
        <img src={props.fa} alt="" />
        <span>{props.course_name}</span>
      </div>
      <div>
        <p className="discount_amount">{props.discountAmount}</p>
        <p className="amount">
          {" "}
          <i>&#8377;</i> {props.amount}
        </p>
        <p className="duration">{props.duration} Months</p>
        <p className="class_count">{props.class_count} Classes</p>
      </div>
    </li>
  );
};

export default CoursesList;
