import React from "react";
import Complete from "../TaskComponents/Complete";
import Declined from "../TaskComponents/Declined";
import Pending from "../TaskComponents/Pending";
import "./TaskAll.css";

const TaskAll = ({
  ShowReschedule,
  setShowReschedule,
  ShowDeclinemodal,
  setShowDeclinemodal,
  Notification_Data,
  Get_details,
  setCancel_or_reschedule,
  Actions_btn,
  setDecline_userid,
  setGo_to_heading,
  Showcheckbox_modal,
  Checkbox_state,
}) => {
  return (
    <div className="task_all_container">
      {Notification_Data?.notification_list?.map((item, index) => {
        return (
          <div key={index}>
            {item?.notification_status == "Pending" && (
              <Pending
                setShowReschedule={setShowReschedule}
                setShowDeclinemodal={setShowDeclinemodal}
                item={item}
                Get_details={Get_details}
                setCancel_or_reschedule={setCancel_or_reschedule}
                Actions_btn={Actions_btn}
                setDecline_userid={setDecline_userid}
                setGo_to_heading={setGo_to_heading}
                Showcheckbox_modal={Showcheckbox_modal}
                Checkbox_state={Checkbox_state}
              />
            )}
            {item?.notification_status == "Completed" && (
              <Complete
                item={item}
                Actions_btn={Actions_btn}
                Showcheckbox_modal={Showcheckbox_modal}
                Checkbox_state={Checkbox_state}
              />
            )}
            {item?.notification_status == "Denied" && <Declined item={item} />}
          </div>
        );
      })}
    </div>
  );
};

export default TaskAll;
