import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import "./Groupscheduleadd.css";
import { useLayoutEffect } from "react";
import { URL } from "../../../DROPDOWNDATA";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";

const Groupscheduleadd = () => {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const { group_id, schedule_id } = useParams();
  const [Grouplist, setGrouplist] = useState([]);
  const History = useNavigate();

  // LOADER
  const [Loaderscreen, setLoaderscreen] = useState(false);
  // SCHEDILE DETAILS
  const [GroupscheduleDetails, setGroupscheduleDetails] = useState({
    Selected_groupID: "",
    Selected_groupName: "",
    startdate: "",
    enddate: "",
    selected_enabler_id: "",
    selected_enabler_name: "",
    no_of_classes: 1,
    per_week: 2,
    renew: false,
    Child_list: [],
    Enabler_list: [],
    same_time_is: false,
    link: "false",
  });
  // ENABLER LIST ARRAY
  const [AllEnbalerList, setAllEnbalerList] = useState([]);
  const [EnbalerList, setEnbalerList] = useState([]);

  // SELECTED DAYS
  const [SelectedDays, setSelectedDays] = useState([
    {
      day: 1,
      time: "13:00",
    },
    {
      day: 2,
      time: "13:00",
    },
  ]);

  // CANCEL BUTTON HANDLER
  function CancelHandler() {
    History(`/scheduling`);
    setSelectedDays([
      {
        day: 1,
        time: "13:00",
      },
      {
        day: 2,
        time: "13:00",
      },
    ]);
    setEnbalerList([]);
    setGroupscheduleDetails({
      Selected_groupID: "",
      Selected_groupName: "",
      startdate: "",
      enddate: "",
      selected_enabler_id: "",
      selected_enabler_name: "",
      no_of_classes: 1,
      per_week: 2,
      renew: false,
      Child_list: [],
      Enabler_list: [],
      same_time_is: false,
      link: "false",
    });
  }

  //   CHILD NAME HANDLER
  const ChildnameHandler = (event, value) => {
    console.log(value);
    if (value != null) {
      setGroupscheduleDetails((prev) => ({
        ...prev,
        Selected_groupID: value?.group_id,
        Selected_groupName: value?.group_name,
      }));

      if (value?.group_id) {
        Get_GroupDetails(value?.group_id, false);
      }
    }
  };
  // DATE HANDLER
  function DateHandler(e) {
    setGroupscheduleDetails((prev) => ({ ...prev, startdate: formatDate(e) }));
    if (GroupscheduleDetails?.no_of_classes != "") {
      CalculateDays(
        formatDate(e),
        GroupscheduleDetails?.no_of_classes,
        SelectedDays
      );
    }
  }

  // NO OF CLASS HANDLER
  function NoOf_ClassHandler(e) {
    e.target.value = e.target.value?.replace(/[^0-9]/g, "");
    let minvalue = e.target.value?.split("").pop();
    e.target.value = e.target.value > 200 ? minvalue?.join("") : e.target.value;
    setGroupscheduleDetails((prev) => ({
      ...prev,
      no_of_classes: e.target.value,
    }));
    if (GroupscheduleDetails?.startdate != "" && e.target.value != "") {
      CalculateDays(
        GroupscheduleDetails?.startdate,
        e.target.value,
        SelectedDays
      );
    }
  }

  // NO OF CLASS HANDLER
  function No_of_classHandler(e) {
    setGroupscheduleDetails((prev) => ({
      ...prev,
      per_week: e.target.value,
    }));
    // setGroupscheduleDetails((prev) => ({
    //   ...prev,
    //   no_of_classes: e.target.value,
    // }));
    setSelectedDays([]);
    let EmptyArray = [];
    for (let i = 0; i < e.target.value; i++) {
      EmptyArray.push({
        day: i,
        time: "13:00",
      });
    }
    setSelectedDays(EmptyArray);

    if (
      GroupscheduleDetails?.startdate != "" &&
      GroupscheduleDetails?.no_of_classes != ""
    ) {
      CalculateDays(
        GroupscheduleDetails?.startdate,
        GroupscheduleDetails?.no_of_classes,
        EmptyArray
      );
    }
  }
  // DAYS HANDLER
  function DayHandler(e, index) {
    let DuplicateDaysArray = SelectedDays;
    DuplicateDaysArray[index] = {
      day: e.target.value,
      time: DuplicateDaysArray[index].time,
    };
    setSelectedDays((prev) => [...DuplicateDaysArray]);
  }
  // TIME HANDLER
  function TimeHandler(e, index) {
    let DuplicateDaysArray = SelectedDays;
    DuplicateDaysArray[index] = {
      day: DuplicateDaysArray[index].day,
      time: e.target.value,
    };
    setSelectedDays(() => [...DuplicateDaysArray]);
  }
  // ENABLER HANDLER
  function EnablerHandler(event, value) {
    setGroupscheduleDetails((prev) => ({
      ...prev,
      selected_enabler_id: value?.users_id,
      selected_enabler_name: value?.users_firstname,
    }));
  }
  // SAME TIME HANDLER
  function SameTimeHandler(e) {
    if (e.target.checked) {
      setGroupscheduleDetails((prev) => ({ ...prev, same_time_is: true }));
      let DuplicateDaysArray = SelectedDays;
      DuplicateDaysArray.map((item, index) => {
        return {
          day: item.day,
          time: (item.time = DuplicateDaysArray[0].time),
        };
      });
      setSelectedDays(() => [...DuplicateDaysArray]);
    } else {
      setGroupscheduleDetails((prev) => ({ ...prev, same_time_is: false }));
    }
  }
  // VALIDATION START
  function Validations(value, message) {
    if (value == "") {
      toast.warn(message);
      toast.clearWaitingQueue();
      return false;
    } else {
      return true;
    }
  }
  // VALIDATION END
  // SUBMIT HANDLER BTN
  function SubmitHandler() {
    if (Validations(GroupscheduleDetails?.Selected_groupID, "Select any group"))
      if (Validations(GroupscheduleDetails?.no_of_classes, "Add no of classes"))
        if (
          Validations(
            GroupscheduleDetails?.selected_enabler_id,
            "Select any enabler"
          )
        ) {
          if (GroupscheduleDetails?.no_of_classes != 0) {
            if (GroupscheduleDetails?.renew) {
              if (GroupscheduleDetails?.link != "") {
                SubmitData();
              } else {
                toast.warn("Select the link type");
                toast.clearWaitingQueue();
              }
            } else {
              SubmitData();
            }
          } else {
            toast.warn("Class number can't be 0");
            toast.clearWaitingQueue();
          }
        }
  }

  // GET GROUP LIST
  const GetGroupList = async (value) => {
    setLoaderscreen(true);
    let data;
    await fetch(URL + "/list_unscheduled", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(
        (data = {
          search: value,
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setGrouplist([]);
        setGrouplist(obj?.body);
        GetEnablerList();
      })
      .catch((err) => {
        setLoaderscreen(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };
  // GET GROUP DETAILS
  const Get_GroupDetails = async (id, is_Editall) => {
    setLoaderscreen(true);
    let data;
    await fetch(URL + "/group_schedule_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(
        (data = {
          group_id: id,
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setGroupscheduleDetails((prev) => ({
          ...prev,
          Selected_groupID: id,
          startdate: obj.body?.start_date,
          Selected_groupName: obj.body?.group_name,
          enddate: obj.body?.end_date,
          no_of_classes: obj.body?.total_classes || "",
          Child_list: obj.body?.child_list,
          Enabler_list: obj.body?.enabler_list,
          renew: obj.body?.renew,
          per_week:
            obj.body?.class_timings?.length == 0
              ? 2
              : obj.body?.class_timings?.length,
        }));
        if (is_Editall) {
          setSelectedDays(() => obj.body?.class_timings);
          setGroupscheduleDetails((prev) => ({
            ...prev,
            per_week: obj.body?.class_timings?.length,
          }));
        } else {
          setSelectedDays(() => [
            {
              day: 1,
              time: "13:00",
            },
            {
              day: 2,
              time: "13:00",
            },
          ]);
        }

        setEnbalerList(obj.body?.enabler_list);
        setLoaderscreen(false);
      })
      .catch((err) => {
        setLoaderscreen(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };
  // CALCULATE DATE API
  const CalculateDays = async (Startdate, clasess_no, SelectedDays) => {
    let data;
    await fetch(URL + "/calculate_end_date", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(
        (data = {
          class_timings: SelectedDays,
          start_date: Startdate,
          number_of_classes: clasess_no,
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setGroupscheduleDetails((prev) => ({
          ...prev,
          enddate: obj.body?.end_date?.split("-")?.reverse()?.join("-"),
        }));
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  // ENABLER CHECKBOX
  function MergeEnablerHandler(e) {
    if (e.target.checked) {
      setEnbalerList(() => [...AllEnbalerList]);
    } else {
      setEnbalerList(() => [...GroupscheduleDetails?.Enabler_list]);
    }
  }

  // GET ALL ENABLERS
  const GetEnablerList = async () => {
    await fetch(URL + "/pending_child_enabler_list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setAllEnbalerList(obj.body?.enabler_list);
        setLoaderscreen(false);
      })
      .catch((err) => {
        setLoaderscreen(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  // SUBMIT DATA
  const SubmitData = async () => {
    setLoaderscreen(true);
    let data = {
      group_id: GroupscheduleDetails?.Selected_groupID,
      enabler_id: GroupscheduleDetails?.selected_enabler_id,
      class_timings: SelectedDays,
      start_date: GroupscheduleDetails?.startdate,
      end_date: GroupscheduleDetails?.enddate,
      number_of_classes: GroupscheduleDetails?.no_of_classes,
      renew: GroupscheduleDetails?.link,
    };

    await fetch(
      URL +
        (group_id == "create" ? "/create_group_schedule" : "/group_edit_all"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify(data),
      }
    )
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setLoaderscreen(false);
        toast.success("Schedule created successfully");
        toast.clearWaitingQueue();

        History(
          `/Individualgroupdetail/${btoa(
            GroupscheduleDetails?.Selected_groupID
          )}/false/false`
        );
      })
      .catch((err) => {
        setLoaderscreen(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };
  useLayoutEffect(() => {
    if (group_id == "create") {
      GetGroupList("");
    } else {
      Get_GroupDetails(group_id, true);
      GetEnablerList();
    }
  }, []);

  return (
    <div className="group_schedule_container">
      <div className="group_schedule_heading">
        {group_id == "create" ? (
          <div className="group_schedule_childlist">
            <Autocomplete
              disablePortal
              id="combo-box Default_name"
              options={Grouplist}
              getOptionLabel={(option) => {
                return option.group_name;
              }}
              value={{
                group_id: GroupscheduleDetails?.Selected_groupID,
                group_name: GroupscheduleDetails?.Selected_groupName,
              }}
              onChange={ChildnameHandler}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select Group" />
              )}
            />
          </div>
        ) : (
          <div className="group_schedule_name_preview">
            <p>Group name</p>
            <p>{GroupscheduleDetails?.Selected_groupName}</p>
          </div>
        )}
        {GroupscheduleDetails?.startdate && (
          <div className="group_schedule_details_container">
            <div className="group_schedule_date_item">
              {/* <label htmlFor="groupScheduleStartDate">Start date</label>
              <input
                type="date"
                name="Start date"
                id="groupScheduleStartDate"
                value={GroupscheduleDetails?.startdate}
                onChange={DateHandler}
              />
              <p>MM/DD/YYYY</p> */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={"Start date"}
                  value={GroupscheduleDetails?.startdate}
                  inputFormat={"dd/MM/yyyy"}
                  // onError={ErrorHandler}
                  onChange={DateHandler}
                  renderInput={(params) => (
                    <TextField
                      autoComplete="off"
                      {...params}
                      helperText={params?.inputProps?.placeholder}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="group_schedule_date_item">
              {/* <label htmlFor="groupScheduleEndDate">End date</label>
              <input
                type="date"
                name="End date"
                id="groupScheduleEndDate"
                value={GroupscheduleDetails?.enddate}
                disabled
              />
              <p>MM/DD/YYYY</p> */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={"End date"}
                  value={GroupscheduleDetails?.enddate}
                  inputFormat={"dd/MM/yyyy"}
                  disabled={true}
                  renderInput={(params) => (
                    <TextField
                      autoComplete="off"
                      {...params}
                      helperText={params?.inputProps?.placeholder}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="group_schedule_date_item">
              <label htmlFor="groupScheduleClasses">No of classess</label>
              <input
                type="text"
                name="No of classess"
                id="groupScheduleClasses"
                value={GroupscheduleDetails?.no_of_classes}
                onChange={NoOf_ClassHandler}
                max={200}
                maxLength={3}
              />
            </div>
          </div>
        )}
      </div>

      {/* CHILD LIST AND DAY SELECTION START */}
      <div className="group_schedule_body">
        {/* CHILD LIST START */}
        <div className="group_schedule_childlist">
          <h3>Group child list</h3>
          {GroupscheduleDetails?.Child_list?.map((item, index) => {
            return (
              <p key={index}>
                {" "}
                {item?.child_name} - {item?.child_view_id}
              </p>
            );
          })}
        </div>
        {/* CHILD LIST END */}

        {/* DAY SELECTION START */}
        <div className="group_schedule_day_selection">
          <div className="group_day_selection_heading">
            <label htmlFor="classes_per_week">No of classes per week</label>
            <select
              name="classes_per_week"
              id="classes_per_week"
              onChange={No_of_classHandler}
              value={GroupscheduleDetails?.per_week}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
            </select>
          </div>
          {/* LABEL  START*/}
          <div className="group_schedule_label">
            <p>Days</p>
            <p>Select days</p>
            <p>Select time</p>
          </div>

          {/* LABEL  END*/}
          <div className="group_day_selection_body">
            {SelectedDays?.map((item, index) => {
              return (
                <div className="group_day_selection_item" key={index}>
                  <p>Day {index + 1}</p>
                  <div className="group_day_selection_item_select">
                    <select
                      name=""
                      id=""
                      value={parseInt(item?.day)}
                      onChange={(e) => DayHandler(e, index)}
                    >
                      <option value="" disabled>
                        Select day
                      </option>
                      <option value="0">Sunday</option>
                      <option value="1">Monday</option>
                      <option value="2">Tuesday</option>
                      <option value="3">Wednesday</option>
                      <option value="4">Thursday</option>
                      <option value="5">Friday</option>
                      <option value="6">Saturday</option>
                    </select>
                  </div>
                  <div className="group_day_selection_item_input">
                    <input
                      type="time"
                      name=""
                      id=""
                      value={item?.time}
                      disabled={GroupscheduleDetails?.same_time_is}
                      onChange={(e) => TimeHandler(e, index)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* DAY SELECTION END */}
      </div>
      {/* USE LINK TYPE AND SAME TIME START */}
      <div className="group_day_selection_footer">
        <div className="group_day_footer_input">
          {/* SELECT ENABLER START */}
          <div className="group_day_footer_enabler">
            <div className="group_day_footer_enabler_select">
              <Autocomplete
                disablePortal
                id="combo-box Default_name"
                options={EnbalerList}
                getOptionLabel={(option) => {
                  return option.users_firstname;
                }}
                onChange={EnablerHandler}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Enabler" />
                )}
              />
            </div>
            <div className="group_day_footer_enabler_input">
              <input
                type="checkbox"
                name=""
                id="MergeEnablerHandler"
                onChange={MergeEnablerHandler}
              />
              <label htmlFor="MergeEnablerHandler">
                Select from all enablers
              </label>
            </div>
          </div>
          {/* SELECT ENABLER END */}

          {GroupscheduleDetails?.renew && group_id == "create" && (
            <div className="group_day_footer_input_item">
              <input
                type="radio"
                name="group_link"
                id="group_link_existing"
                defaultChecked={true}
                onChange={() => {
                  setGroupscheduleDetails((prev) => ({
                    ...prev,
                    link: "false",
                  }));
                }}
              />
              <label htmlFor="group_link_existing">Use existing link</label>
            </div>
          )}
          {GroupscheduleDetails?.renew && group_id == "create" && (
            <div className="group_day_footer_input_item">
              <input
                type="radio"
                name="group_link"
                id="group_link_new"
                onChange={() => {
                  setGroupscheduleDetails((prev) => ({
                    ...prev,
                    link: "true",
                  }));
                }}
              />
              <label htmlFor="group_link_new">New link</label>
            </div>
          )}
          <div className="group_day_footer_input_item">
            <input
              type="checkbox"
              name="group_same_time"
              id="group_same_time"
              onChange={SameTimeHandler}
            />
            <label htmlFor="group_same_time">Same time for all</label>
          </div>
        </div>

        <div className="group_day_sumbit_btn">
          <button onClick={CancelHandler}>Cancel</button>
          <button onClick={SubmitHandler}>Create schedule</button>
        </div>
      </div>
      {/* USE LINK TYPE AND SAME TIME END */}

      {/* CHILD LIST AND DAY SELECTION END */}

      {/* LOADER START */}
      {Loaderscreen && <Loader />}
      {/* LOADER END */}
    </div>
  );
};

export default Groupscheduleadd;
