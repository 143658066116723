import React from "react";
import { toast } from "react-toastify";
import { URL } from "../../../DROPDOWNDATA";
import "./PTMtable.css";

const PTMtable = ({
  PTM_table_data,
  setSelectedClass,
  setQuestionData,
  setLoader,
}) => {
  const ClassData = async (id, si_no) => {
    let item = {
      view_no: si_no,
    };
    setLoader(true);
    await fetch(`${URL}/ptm_view_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        // console.log(obj);
        setLoader(false);

        setSelectedClass({
          id: si_no,
          view_type: "view",
        });
        setQuestionData(obj.body);
      })
      .catch((err) => {
        setLoader(false);

        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };
  return (
    <div>
      <table className="ptm_table_container">
        <thead>
          <tr>
            <th>PTM class no</th>
            <th>Schedule call</th>
            <th>PTM conducted by</th>
            <th>Call link</th>
            <th>No.of reschedule</th>
            <th>PTM form</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {PTM_table_data?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item?.class_no}</td>
                <td>{item?.scheduled_time}</td>
                <td>{item?.conducted_by}</td>
                <td>
                  <a
                    href={item?.meeting_url}
                    className="text-[22px] text-[#007872]"
                    target="_blank"
                  >
                    <i class="fa-solid fa-video"></i>
                  </a>
                </td>
                <td>{item?.ptm_schedule_class_count}</td>
                <td>
                  <button
                    style={{
                      opacity: item?.ptm_status != "Upcoming" ? 1 : 0.3,
                      pointerEvents:
                        item?.ptm_status != "Upcoming" ? "all" : "none",
                    }}
                    onClick={() => {
                      ClassData(item?.class_no, item?.si_no);
                    }}
                  >
                    View
                  </button>
                </td>
                <td>{item?.ptm_status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PTMtable;
