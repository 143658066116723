import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

const useGet = (url) => {
    var myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        `Bearer ${localStorage?.getItem('loggedToken')}`
    );
    myHeaders.append("Content-Type", "application/json");
    const [GetData, setGetData] = useState(null);
    const [LoadingGet, setLoadingGet] = useState(false)

    const Getcall = useCallback(() => {
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        setLoadingGet(true)
        fetch(url, requestOptions)
            .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
            .then((obj) => {
                switch (obj.status) {
                    case 200:
                        setGetData(obj);
                        break;
                    case 401:
                        toast.error("UnAuthorized User");
                        toast.clearWaitingQueue();
                        break;
                    case 403:

                        toast.error("User Forbidden");
                        toast.clearWaitingQueue();
                        break;
                    default:
                        toast.error("Oops! An Error Accured status");
                        toast.clearWaitingQueue();
                        break;
                }
                setLoadingGet(false)

            }).catch((err) => {
                setLoadingGet(false)
                toast.error("Oops! An Error Accured");
                toast.clearWaitingQueue();
            })
    }, [url]);

    return { GetData, LoadingGet, Getcall };
};

export default useGet;