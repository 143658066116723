import { GROUP_RESCHEDULE_POPUP } from "../Actiontype";

const initialState = {
    Main: false,
    Schedule: false,
    Cancel: false,
    Feedback: false,
    Comments: false,
    MarkComplete: false,
    MarkSuspend: false,
    ScheduleComments: false,
    FeedbackComments: false,
    AttendanceComments: false,
    AssignmentsComments: false,
    BlendedComments: false,
    Bulkaction: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GROUP_RESCHEDULE_POPUP:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};
