import React from "react";

const Declined = ({ item }) => {
  return (
    <div className="pending_list_block declined">
      <div className="pending_checkbox">
        <input type="checkbox" name="pending" id="ch1" checked disabled />
        <label htmlFor="ch1">Done</label>
      </div>
      <div className="pending_heading">
        <div className="pending_title">
          <h2>{item?.notification_name}</h2>
          <p>{item?.created_date}</p>
          <p className="dot">
            <i className="fa-solid fa-circle"></i>
          </p>
          <p>{item?.created_time}</p>
        </div>

        <div className="pending_child">
          {item?.group_id != null ? (
            <p>
              <span className="label">Group</span> : {item?.group_name}
            </p>
          ) : (
            <p>
              <span className="label">Child</span> : {item?.child_name}
            </p>
          )}
          <p className="dot">
            <i className="fa-solid fa-circle"></i>
          </p>
          <p>
            <span className="label">Requested by {item?.user_role}</span> : {item?.username}
          </p>
        </div>
      </div>

      <div className="task_completed_status">
        <p>{item?.notification_status}</p>
      </div>
    </div>
  );
};

export default Declined;
