import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URL } from "../../DROPDOWNDATA";
import TopMenu from "../childPage/topMenu/TopMenu";
import Courseitem from "./Courselist/Courseitem";
import "./RenewEnroll.css";

const RenewEnroll = () => {
  const { id, childname } = useParams();
  const History = useNavigate();
  function addMonths(date, months, additional) {
    let d1 = new Date(date.setMonth(date.getMonth() + months));
    return new Date(d1.setDate(d1.getDate() + additional));
  }
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  function getDaysBetweenMonths(date1, date2) {
    // let mydate1 = new Date(date1)
    // let mydate2 = new Date(date2)

    let mydate1 = new Date(formatDate(date1));
    let mydate2 = new Date(formatDate(date2));

    let diff = mydate2.getTime() - mydate1.getTime();
    let msInDay = 1000 * 3600 * 24;

    // console.log(diff / msInDay)

    return diff / msInDay;
  }
  // LOADER
  const [Loader, setLoader] = useState(false);
  // PREVIOUS ENROLL DATA
  const [PreviousEnroll, setPreviousEnroll] = useState(null);
  const [CheckEnrollDate, setCheckEnrollDate] = useState({
    between_P_S_E_days: 0,
    between_C_E_S_days: 0,
  });
  //  COURSE DATAs
  const [CourseList, setCourseList] = useState([]);
  const [SelectedCourse, setSelectedCourse] = useState(null);
  const [SelectedCourseDetails, setSelectedCourseDetails] = useState(null);

  // ERROR DETAILS
  const [ErrorAnalysis, setErrorAnalysis] = useState({
    discount: false,
    downpayemnt: false,
    downpayemnt_msg: "",
    installment: false,
    installment_msg: "",
    interval: false,
    interval_msg: "",
    transaction_ref: true,
    transaction_ref_msg: "fill the field",
    transaction_comment: true,
    transaction_comment_msg: "fill the field",
  });

  // Method SElected
  const [MethodOptions, setMethodOptions] = useState({
    Payment_method: "payinfull",
    Transaction_method: "",
  });

  // DISACOUNT DETAILS
  const [DisacountData, setDisacountData] = useState({
    discount: "",
    discount_description: "",
    discount_modal: false,
  });

  // GENERATE LINK
  const [GenerateHandle, setGenerateHandle] = useState({
    is_generate_link: true,
    link: "",
  });

  //  COURSE DETAILS SELECTED
  const [RenewDetails, setRenewDetails] = useState({
    renew_start_data: new Date(),
    renew_end_data: "",
    course_price: "",
    downpayment: "",
    balance: "",
    no_installment: "",
    interval: "",
    installment_amount: "",
    discount: 0,
    discount_remark: "",
    transaction_date: new Date(),
    transaction_reference: "",
    transaction_comment: "",
  });

  // START DATE HANDLER
  const StartDateHandler = (date, endDate_month) => {
    setRenewDetails((prev) => ({
      ...prev,
      renew_start_data: date,
      renew_end_data: addMonths(
        new Date(date),
        endDate_month,
        CheckEnrollDate?.between_C_E_S_days <= 0
          ? 0
          : CheckEnrollDate?.between_P_S_E_days
      ),
    }));
  };

  // DISCOUNT HANDLER
  const DiscountHandler = (e) => {
    setDisacountData((prev) => ({ ...prev, discount: e.target.value }));

    if (e.target.value > SelectedCourseDetails?.course_price / 2) {
      setErrorAnalysis((prev) => ({ ...prev, discount: true }));
    } else {
      setErrorAnalysis((prev) => ({ ...prev, discount: false }));
    }
  };
  // DISCOUNT APPLY HANDLER
  const DiscountApply_Handler = () => {
    setDisacountData((prev) => ({
      ...prev,
      discount_modal: true,
      discount_description: "",
    }));
  };

  // DISCOUNT REMARKS CANCEL
  const DiscountRemarks_Cancel_Handler = () => {
    setDisacountData((prev) => ({
      ...prev,
      discount_modal: false,
      discount_description: "",
    }));
  };
  // DISCOUNT REMARKS SUBMIT HANDLER
  const DiscountRemark_submit_Handler = () => {
    Update_Enrollment_Details(
      SelectedCourseDetails?.course_price,
      RenewDetails?.downpayment,
      RenewDetails?.no_installment,
      DisacountData?.discount,
      SelectedCourseDetails?.duration_in_months
    );

    setRenewDetails((prev) => ({
      ...prev,
      discount: DisacountData?.discount,
      discount_remark: DisacountData?.discount_description,
    }));
    setDisacountData((prev) => ({
      discount: "",
      discount_modal: false,
      discount_description: "",
    }));
    toast.success("Discount Remarks Saved");
    toast.clearWaitingQueue();
  };
  // PAY IN FULL HANDLER
  const Payinfull_handler = () => {
    setMethodOptions((prev) => ({
      Transaction_method: "",
      Payment_method: "payinfull",
    }));
    setRenewDetails((prev) => ({
      ...prev,
      transaction_date: new Date(),
      transaction_reference: "",
      transaction_comment: "",
    }));
  };
  // INSTALLMENT HANDLER
  const Installment_handler = () => {
    setMethodOptions((prev) => ({
      Transaction_method: "",
      Payment_method: "installment",
    }));
    setRenewDetails((prev) => ({
      ...prev,
      transaction_date: new Date(),
      transaction_reference: "",
      transaction_comment: "",
    }));
  };

  // DOWNPAYMENT HANDLER
  const Downpayment_Handler = (value) => {
    if (value > SelectedCourseDetails.course_price) {
      setErrorAnalysis((prev) => ({
        ...prev,
        downpayemnt: true,
        downpayemnt_msg: "Down payment cant be more than Course price",
      }));
      Update_Enrollment_Details(
        SelectedCourseDetails?.course_price,
        value,
        RenewDetails?.no_installment,
        RenewDetails?.discount,
        SelectedCourseDetails?.duration_in_months
      );
    } else if (value < SelectedCourseDetails?.down_payment_amount) {
      setErrorAnalysis((prev) => ({
        ...prev,
        downpayemnt: true,
        downpayemnt_msg: "Amount cant be less than Down payment",
      }));
      Update_Enrollment_Details(
        SelectedCourseDetails?.course_price,
        value,
        RenewDetails?.no_installment,
        RenewDetails?.discount,
        SelectedCourseDetails?.duration_in_months
      );
    } else {
      setErrorAnalysis((prev) => ({
        ...prev,
        downpayemnt: false,
        downpayemnt_msg: "",
      }));
      Update_Enrollment_Details(
        SelectedCourseDetails?.course_price,
        value,
        RenewDetails?.no_installment,
        RenewDetails?.discount,
        SelectedCourseDetails?.duration_in_months
      );
    }
  };
  // NO OF INSTALLMENTS
  const No_of_installmentHandler = (value) => {
    setRenewDetails((prev) => ({ ...prev, no_installment: value }));
    if (value == "") {
      setErrorAnalysis((prev) => ({
        ...prev,
        installment: true,
        installment_msg: "installment count can't be empty",
      }));
    } else if (value == 0) {
      setErrorAnalysis((prev) => ({
        ...prev,
        installment: true,
        installment_msg: "installment count can't be 0",
      }));
    } else if (value > SelectedCourseDetails?.duration_in_months - 1) {
      setErrorAnalysis((prev) => ({
        ...prev,
        installment: true,
        installment_msg: "installment count can't be more than course duration",
      }));
    } else if (value < 0) {
      setErrorAnalysis((prev) => ({
        ...prev,
        installment: true,
        installment_msg: "installment count can't be negative values",
      }));
    } else {
      setErrorAnalysis((prev) => ({
        ...prev,
        installment: false,
        installment_msg: "",
      }));
      Update_Enrollment_Details(
        SelectedCourseDetails?.course_price,
        RenewDetails?.downpayment,
        value,
        RenewDetails?.discount,
        SelectedCourseDetails?.duration_in_months
      );
    }
  };
  // INTERVAL DAYS HANDLER
  const IntervalDay_Handler = (value) => {
    let Intervaldays = Math.floor(
      getDaysBetweenMonths(
        RenewDetails.renew_start_data,
        addMonths(
          new Date(RenewDetails.renew_start_data),
          SelectedCourseDetails?.duration_in_months,
          CheckEnrollDate?.between_C_E_S_days <= 0
            ? 0
            : CheckEnrollDate?.between_P_S_E_days
        )
      ) / RenewDetails?.no_installment
    );
    setRenewDetails((prev) => ({
      ...prev,
      interval: value,
    }));
    if (value <= 0) {
      setErrorAnalysis((prev) => ({
        ...prev,
        interval: true,
        interval_msg: "Interval days cannot less than 0",
      }));
    } else if (value > Intervaldays) {
      setErrorAnalysis((prev) => ({
        ...prev,
        interval: true,
        interval_msg: `Interval days cannot greater than ${Intervaldays}`,
      }));
    } else {
      setErrorAnalysis((prev) => ({
        ...prev,
        interval: false,
        interval_msg: "",
      }));
    }
  };

  // TRANSACTION DATE HANDLER
  const TransactionDate_Handler = (value) => {
    setRenewDetails((prev) => ({
      ...prev,
      transaction_date: formatDate(value),
    }));
  };

  // TRANSACTION REFERENCE HANDLER
  const TransactionReference_Handler = (value) => {
    if (value.match(/[^a-zA-Z0-9]+/)) {
      setErrorAnalysis((prev) => ({
        ...prev,
        transaction_ref: true,
        transaction_ref_msg: "Cant use Special chars!",
      }));
    } else if (value == "") {
      setErrorAnalysis((prev) => ({
        ...prev,
        transaction_ref: true,
        transaction_ref_msg: "fill the field",
      }));
    } else {
      setErrorAnalysis((prev) => ({
        ...prev,
        transaction_ref: false,
        transaction_ref_msg: "",
      }));
      setRenewDetails((prev) => ({
        ...prev,
        transaction_reference: value,
      }));
    }
  };
  // TRANSACTION COMMENT HANDLER
  const TransactionComment_Handler = (value) => {
    if (value.match(/[^a-zA-Z ]+/)) {
      setErrorAnalysis((prev) => ({
        ...prev,
        transaction_comment: true,
        transaction_comment_msg: "Cant use Special chars!",
      }));
    } else if (value == "") {
      setErrorAnalysis((prev) => ({
        ...prev,
        transaction_comment: true,
        transaction_comment_msg: "fill the field",
      }));
    } else {
      setErrorAnalysis((prev) => ({
        ...prev,
        transaction_comment: false,
        transaction_comment_msg: "",
      }));
      setRenewDetails((prev) => ({
        ...prev,
        transaction_comment: value,
      }));
    }
  };

  // ADD TRANSACTION HANDLER
  const Add_Transaction_Handler = () => {
    setRenewDetails((prev) => ({
      ...prev,
      transaction_date: new Date(),
      transaction_reference: "",
      transaction_comment: "",
    }));
    setMethodOptions((prev) => ({
      ...prev,
      Transaction_method: "transaction",
    }));
  };

  // OPEN GENERATE LINK MODAL
  const Generate_Link_Handler = () => {
    setRenewDetails((prev) => ({
      ...prev,
      transaction_date: new Date(),
      transaction_reference: "",
      transaction_comment: "",
    }));
    setMethodOptions((prev) => ({
      ...prev,
      Transaction_method: "generate",
    }));
  };

  // CANCEL GENERATE GENERATE LINK
  const CancelGenereate_Link_handler = () => {
    setMethodOptions((prev) => ({
      ...prev,
      Transaction_method: "",
    }));
    setRenewDetails((prev) => ({
      ...prev,
      transaction_date: new Date(),
      transaction_reference: "",
      transaction_comment: "",
    }));
  };

  const Submit_generate_link_Handler = async () => {
    let item = {
      child_id: atob(id),
      is_installment: MethodOptions.Payment_method == "installment" ? 1 : 0,
      from_date: formatDate(RenewDetails?.renew_start_data),
      to_date: formatDate(RenewDetails?.renew_end_data),
      course_id: SelectedCourse + 1,
      amount:
        MethodOptions.Payment_method == "installment"
          ? RenewDetails?.downpayment
          : RenewDetails?.course_price,
      total_amount_to_be_paid: RenewDetails?.course_price,
      discount_amount: RenewDetails?.discount,
      discount_remarks: RenewDetails?.discount_remark,
      total_installment: RenewDetails?.no_installment,
      installment_duration: RenewDetails?.interval,
      payment_reference: RenewDetails?.transaction_reference,
      payment_comment: RenewDetails?.transaction_comment,
      payment_date: formatDate(RenewDetails?.transaction_date),
      down_payment:
        MethodOptions.Payment_method == "installment"
          ? RenewDetails?.downpayment
          : RenewDetails?.course_price,
      balance:
        MethodOptions.Payment_method == "installment"
          ? RenewDetails?.balance
          : RenewDetails?.course_price,
      per_installment_amount:
        MethodOptions.Payment_method == "installment"
          ? Math.floor(parseInt(RenewDetails?.installment_amount))
          : RenewDetails?.course_price,
    };
    setLoader(true);
    await fetch(URL + "/add_renewal_link", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setLoader(false);
        if (obj.body?.message == "success") {
          setGenerateHandle({
            is_generate_link: false,
            link: obj.body?.payment_url,
          });
        } else {
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  // TRANSACTION SUBMIT
  const Transaction_submit_Handler = async () => {
    let item = {
      child_id: atob(id),
      is_installment: MethodOptions.Payment_method == "installment" ? 1 : 0,
      from_date: formatDate(RenewDetails?.renew_start_data),
      to_date: formatDate(RenewDetails?.renew_end_data),
      course_id: SelectedCourse + 1,
      amount:
        MethodOptions.Payment_method == "installment"
          ? RenewDetails?.downpayment
          : RenewDetails?.course_price,
      total_amount_to_be_paid: RenewDetails?.course_price,
      discount_amount: RenewDetails?.discount,
      discount_remarks: RenewDetails?.discount_remark,
      total_installment: RenewDetails?.no_installment,
      installment_duration: RenewDetails?.interval,
      payment_reference: RenewDetails?.transaction_reference,
      payment_comment: RenewDetails?.transaction_comment,
      payment_date: formatDate(RenewDetails?.transaction_date),
      down_payment:
        MethodOptions.Payment_method == "installment"
          ? RenewDetails?.downpayment
          : RenewDetails?.course_price,
      balance:
        MethodOptions.Payment_method == "installment"
          ? RenewDetails?.balance
          : RenewDetails?.course_price,
      per_installment_amount:
        MethodOptions.Payment_method == "installment"
          ? Math.floor(parseInt(RenewDetails?.installment_amount))
          : RenewDetails?.course_price,
    };
    setLoader(true);
    await fetch(URL + "/add_renewal", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setLoader(false);
        if (obj.body.message == "error") {
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        } else {
          History(`/child_enrollment_payment/${childname}/${id}`);
          toast.success("Transaction added Successfully");
          toast.clearWaitingQueue();
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };
  // API CALL -> GET COURSE LISTS
  const getCourseData = async () => {
    setLoader(true);
    await fetch(URL + "/enrollment_list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setCourseList(obj.body);
        Get_Previous_enrollData();
      })
      .catch((err) => {
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
        setLoader(false);
      });
  };

  const Get_Previous_enrollData = async () => {
    let item = {
      child_id: atob(id),
    };

    await fetch(URL + "/view_transaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setLoader(false);
        setPreviousEnroll(obj.body?.transaction_details);

        setCheckEnrollDate({
          between_P_S_E_days: getDaysBetweenMonths(
            obj.body?.transaction_details?.start_date,
            obj.body?.transaction_details?.end_date
          ),
          between_C_E_S_days: getDaysBetweenMonths(
            new Date(),
            obj.body?.transaction_details?.end_date
          ),
        });
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  // MANIPULATING ENROLLMENT
  function Update_Enrollment_Details(
    course_price,
    downpayment,
    no_install,
    discount,
    duration
  ) {
    setRenewDetails((prev) => ({
      ...prev,
      course_price: course_price - discount,
      downpayment: downpayment,
      no_installment: no_install,
      interval: Math.floor(
        getDaysBetweenMonths(
          RenewDetails.renew_start_data,
          addMonths(
            new Date(RenewDetails.renew_start_data),
            duration,
            CheckEnrollDate?.between_C_E_S_days <= 0
              ? 0
              : CheckEnrollDate?.between_P_S_E_days
          )
        ) / no_install
      ),
      balance:
        Math.round(course_price - downpayment - discount) < 0
          ? 0
          : Math.round(course_price - downpayment - discount),
      installment_amount:
        Math.round((course_price - downpayment - discount) / no_install) < 0
          ? 0
          : Math.round((course_price - downpayment - discount) / no_install),
    }));
  }
  useEffect(() => {
    getCourseData();
  }, []);

  useEffect(() => {
    if (SelectedCourse != null) {
      setSelectedCourseDetails(CourseList[SelectedCourse]);
      StartDateHandler(
        CheckEnrollDate?.between_C_E_S_days <= 0
          ? new Date()
          : new Date(PreviousEnroll?.start_date),
        CourseList[SelectedCourse]?.duration_in_months
      );
    }
    setDisacountData({
      discount: "",
      discount_modal: false,
      discount_description: "",
    });
    Update_Enrollment_Details(
      CourseList[SelectedCourse]?.course_price,
      CourseList[SelectedCourse]?.down_payment_amount,
      1,
      0,
      CourseList[SelectedCourse]?.duration_in_months
    );
    setRenewDetails((prev) => ({
      ...prev,
      discount: 0,
      discount_remark: "",
    }));
  }, [SelectedCourse]);

  return (
    <div>
      <TopMenu id={id} childname={childname} />

      <div className="renew_heading">
        <h2>Renew enrollment</h2>
      </div>

      <div className="renew_enroll_discount">
        <div>
          <div className="course_and_discount_container">
            <div className="course_list_contain">
              {CourseList?.map((item, index) => {
                return (
                  <Courseitem
                    item={item}
                    index={index}
                    SelectedCourse={SelectedCourse}
                    setSelectedCourse={setSelectedCourse}
                  />
                );
              })}
            </div>
          </div>

          <div className="previous_enroll_data_container">
            <h2>Last enrollment status</h2>
            <div className="date_d_block">
              <div>
                <label htmlFor="">Start date :</label>
                <p>
                  {PreviousEnroll?.start_date?.split("-")?.reverse()?.join("-")}
                </p>
              </div>
              <div>
                <label htmlFor="">End date :</label>
                <p>
                  {" "}
                  {PreviousEnroll?.end_date?.split("-")?.reverse()?.join("-")}
                </p>
              </div>
            </div>
          </div>
        </div>

        {SelectedCourse != null && (
          <div className="renew_discount_container">
            <p>Add discount</p>
            {ErrorAnalysis?.discount && (
              <p className="discount_error_msg">
                Can't be more than 50% of course price
              </p>
            )}
            <div className="discount_input_wrapper">
              <input
                type="number"
                value={DisacountData?.discount}
                onInput={DiscountHandler}
                max={SelectedCourseDetails?.course_price / 2}
              />
              <button
                className={
                  ErrorAnalysis?.discount || DisacountData?.discount <= 0
                    ? "active"
                    : ""
                }
                onClick={DiscountApply_Handler}
              >
                Apply
              </button>
            </div>

            {RenewDetails?.discount != 0 && (
              <p>
                {" "}
                <i class="fas fa-tag"></i>
                {RenewDetails?.discount} Discount Applied
              </p>
            )}

            {RenewDetails?.discount_remark && (
              <div className="renew_discount_remarks">
                <h3>Discount remarks</h3>
                <p>{RenewDetails?.discount_remark}</p>
              </div>
            )}
          </div>
        )}
      </div>

      {SelectedCourse != null && (
        <div className="add_renew_enroll_container">
          <h2>Renew enrollment</h2>

          <div className="enrollment_input_details">
            {/* SELECT PAYMENT METHOD */}
            <div className="renew_select_payment">
              <p>
                Select payment method - <span>1</span>
              </p>
              <div className="payment_item_container">
                <button
                  onClick={Payinfull_handler}
                  className={
                    MethodOptions?.Payment_method == "payinfull" ? "active" : ""
                  }
                >
                  Pay in full <i className="fas fa-money-bill"></i>
                </button>
                <button
                  onClick={Installment_handler}
                  className={
                    MethodOptions?.Payment_method == "installment"
                      ? "active"
                      : ""
                  }
                >
                  Installments <i className="fas fa-donate"></i>
                </button>
              </div>
            </div>
            {/* SELECT DURATION */}
            <div className="renew_select_duration">
              <p>
                Select course duration - <span>2</span>
              </p>

              <div className="renew_duration_input_container">
                <div className="duration_input_item">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={
                        CheckEnrollDate?.between_C_E_S_days <= 0 ? false : true
                      }
                      // disablePast
                      label={"Start Date"}
                      // error={false}
                      value={RenewDetails?.renew_start_data}
                      inputFormat={"dd/MM/yyyy"}
                      onChange={(newValue) => {
                        StartDateHandler(
                          newValue,
                          SelectedCourseDetails?.duration_in_months
                        );
                      }}
                      onError={(error) => {}}
                      renderInput={(params) => (
                        <TextField
                          autoComplete="off"
                          {...params}
                          helperText={params?.inputProps?.placeholder}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="duration_input_item">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={false}
                      // disablePast
                      label={"End Date"}
                      // error={false}
                      value={RenewDetails?.renew_end_data}
                      inputFormat={"dd/MM/yyyy"}
                      onChange={(newValue) => {
                        console.log(newValue);
                      }}
                      onError={(error) => {}}
                      readOnly
                      renderInput={(params) => (
                        <TextField
                          autoComplete="off"
                          {...params}
                          helperText={params?.inputProps?.placeholder}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

            {/* SELECT TRANSACTION METHOD */}

            <div className="renew_select_transaction">
              <p>
                Select transaction method - <span>3</span>
              </p>

              <div className="transaction_method_container">
                <div className="transaction_method_item">
                  <button
                    className={
                      MethodOptions?.Transaction_method == "transaction"
                        ? "active"
                        : ""
                    }
                    onClick={Add_Transaction_Handler}
                  >
                    Add Transaction
                  </button>
                  <p>
                    <span>*</span> Offline transaction
                  </p>
                </div>
                <div className="transaction_method_item">
                  <button onClick={Generate_Link_Handler}>Generate Link</button>
                  <p>
                    <span>*</span> Online transaction
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="installment_transaction_container">
        {MethodOptions?.Payment_method == "installment" && (
          <div className="renew_installment_container">
            <p>Installment details</p>
            <div className="down_balance_container">
              <div>
                <TextField
                  type="number"
                  autoComplete="off"
                  id="outlined-basic"
                  label="Down Payment"
                  InputProps={{
                    inputProps: {
                      min: SelectedCourseDetails?.down_payment_amount,
                      max: SelectedCourseDetails?.course_price,
                    },
                  }}
                  variant="outlined"
                  error={ErrorAnalysis?.downpayemnt}
                  helperText={ErrorAnalysis?.downpayemnt_msg}
                  value={RenewDetails?.downpayment}
                  onChange={(event) => {
                    Downpayment_Handler(event.target.value);
                  }}
                />
              </div>
              <div>
                <TextField
                  type="number"
                  autoComplete="off"
                  id="outlined-basic"
                  label="Balance"
                  readOnly
                  variant="outlined"
                  error={false}
                  value={RenewDetails?.balance}
                  onChange={(event) => {}}
                />
              </div>
            </div>
            <div className="down_balance_container">
              <div>
                <TextField
                  type="number"
                  autoComplete="off"
                  id="outlined-basic"
                  label="No. of installment"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: SelectedCourseDetails?.duration_in_months - 1,
                    },
                  }}
                  variant="outlined"
                  error={ErrorAnalysis?.installment}
                  helperText={ErrorAnalysis?.installment_msg}
                  value={RenewDetails?.no_installment}
                  onChange={(event) => {
                    No_of_installmentHandler(event.target.value);
                  }}
                />
              </div>
              <div>
                <TextField
                  type="number"
                  autoComplete="off"
                  id="outlined-basic"
                  label="Installment Interval"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: Math.floor(
                        getDaysBetweenMonths(
                          RenewDetails.renew_start_data,
                          addMonths(
                            new Date(RenewDetails.renew_start_data),
                            SelectedCourseDetails?.duration_in_months,
                            CheckEnrollDate?.between_C_E_S_days <= 0
                              ? 0
                              : CheckEnrollDate?.between_P_S_E_days
                          )
                        ) / RenewDetails?.no_installment
                      ),
                    },
                  }}
                  variant="outlined"
                  error={ErrorAnalysis?.interval}
                  helperText={ErrorAnalysis?.interval_msg}
                  value={RenewDetails?.interval}
                  onChange={(event) => {
                    IntervalDay_Handler(event.target.value);
                  }}
                />
              </div>
              <div>
                <TextField
                  type="number"
                  autoComplete="off"
                  id="outlined-basic"
                  label="Installment Amount"
                  variant="outlined"
                  error={false}
                  helperText={""}
                  value={RenewDetails?.installment_amount}
                  onChange={(event) => {}}
                />
              </div>
            </div>
          </div>
        )}

        {MethodOptions?.Transaction_method == "transaction" && (
          <div className="renew_transaction_container">
            <p>Enter transaction details</p>

            <div className="renew_transction_input_container">
              <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    inputFormat={"dd/MM/yyyy"}
                    label="Enter transaction date"
                    value={RenewDetails?.transaction_date}
                    onChange={(newValue) => {
                      TransactionDate_Handler(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="off"
                        {...params}
                        helperText={params?.inputProps?.placeholder}
                      />
                    )}
                    PopoverProps={{
                      anchorOrigin: {
                        horizontal: "right",
                        vertical: "top",
                      },
                      transformOrigin: {
                        horizontal: "right",
                        vertical: "top",
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <TextField
                  autoComplete="off"
                  className="textfield"
                  id="outlined-basic"
                  label="Enter transaction reference"
                  variant="outlined"
                  error={ErrorAnalysis?.transaction_ref}
                  helperText={ErrorAnalysis?.transaction_ref_msg}
                  onChange={(newValue) => {
                    TransactionReference_Handler(newValue.target.value);
                  }}
                />
              </div>
              <div>
                <TextField
                  autoComplete="off"
                  className="textfield"
                  id="outlined-basic"
                  label="Enter Comment"
                  variant="outlined"
                  error={ErrorAnalysis?.transaction_comment}
                  helperText={ErrorAnalysis?.transaction_comment_msg}
                  onInput={(newValue) => {
                    TransactionComment_Handler(newValue.target.value);
                  }}
                />
              </div>
              <button
                onClick={Transaction_submit_Handler}
                className={
                  !ErrorAnalysis?.transaction_comment &&
                  !ErrorAnalysis?.transaction_ref
                    ? ""
                    : "active"
                }
              >
                {" "}
                Done <i className="fas fa-paper-plane"></i>
              </button>
            </div>
          </div>
        )}

        {SelectedCourseDetails?.course_price && (
          <div className="renew_summary_container">
            <h2>Summary</h2>
            <div>
              <p>Initial amount</p>
              <p>
                {MethodOptions?.Payment_method == "payinfull"
                  ? SelectedCourseDetails?.course_price
                  : RenewDetails?.downpayment}
                /-
              </p>
            </div>
            <div>
              <p>Discount amount</p>
              <p>{RenewDetails?.discount} /-</p>
            </div>
            <div>
              <p>Final amount</p>
              <p>
                {MethodOptions?.Payment_method == "payinfull"
                  ? RenewDetails?.course_price
                  : RenewDetails?.downpayment}
                /-
              </p>
            </div>
          </div>
        )}
      </div>

      {/* GENERATE LINK MODAL */}
      {MethodOptions?.Transaction_method == "generate" && (
        <div className="renew_generate_link_container">
          {GenerateHandle?.is_generate_link && (
            <div className="generate_link_confirm_block">
              <h2>Are you sure want to generate payment link?</h2>
              <div className="btn_container">
                <button onClick={CancelGenereate_Link_handler}>No</button>
                <button onClick={Submit_generate_link_Handler}>Yes</button>
              </div>
            </div>
          )}
          {!GenerateHandle?.is_generate_link && (
            <div className="generator_link_center">
              <h2>Payment Link</h2>
              <div className="url_container">
                <TextField
                  autoComplete="off"
                  id="outlined-basic"
                  label="Payment Link"
                  variant="outlined"
                  value={GenerateHandle?.link}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <button
                  onClick={() => {
                    if (window.location.protocol != "http:") {
                      navigator.clipboard.writeText(GenerateHandle?.link);
                      toast.success("Link Copied");
                      toast.clearWaitingQueue();
                    } else {
                      toast.warn(
                        "you are using HTTP server,please copy it manually"
                      );
                      toast.clearWaitingQueue();
                    }
                  }}
                >
                  Copy
                </button>
              </div>

              <NavLink to={`/child_enrollment_payment/${childname}/${id}`}>
                Ok
              </NavLink>
            </div>
          )}
        </div>
      )}

      {DisacountData?.discount_modal && (
        <div className="renew_discount_remark_container">
          <div className="renew_discount_block">
            <div className="heading">
              <i class="fas fa-tag"></i> <h2>Discount Remarks</h2>
            </div>

            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              value={DisacountData.discount_description}
              onChange={(e) => {
                setDisacountData((prev) => ({
                  ...prev,
                  discount_description: e.target.value,
                }));
              }}
            ></textarea>

            <div className="btn_container">
              <button onClick={DiscountRemarks_Cancel_Handler}>Cancel</button>
              <button onClick={DiscountRemark_submit_Handler}>
                {" "}
                Save <i class="fas fa-save"></i>
              </button>
            </div>
          </div>
        </div>
      )}
      {Loader && (
        <div className="add_enabler_loading_screen">
          <div className="circle_holder loadercircle">
            <div className="inner_circle loadercircle">
              <div className="most_inner_circle loadercircle">
                <div className="most_inner_most_circle loadercircle"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RenewEnroll;
