import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

const Assignrole = ({
  ActiveStep,
  setSeletedRole,
  setSelectedPrivileges,
  SeletedRole,
  setSelectedMasterenabler,
  SelectedMasterenabler,
  MasterEnablerList,
  ROLES,
  SelectedPrivileges,
  PrivilegesHandle,
  ADD_Role,
  Schoollist,
  SelectedSchool,
  setSelectedSchool,
  AddSchool_Handler,
  SelectedSchoollist,
  Remove_SelectedSchool_Handler,
  SchoolnameHandler,
  School_privileges,
  Schooladmin_privileges,
  School_PrivilegesHandle,
}) => {
  const UserRole = useSelector((store) => store.privilege);
  const [Dropdown, setDropdown] = useState(false);
  document.addEventListener("mouseup", (e) => {
    if (
      e.target == document.querySelector("#enablerschool_role") ||
      e.target.parentNode == e.target.closest(".school_list_dropdown")
    ) {
      setDropdown(true);
    } else {
      setDropdown(false);
    }
  });

  return (
    <div
      className={
        ActiveStep == 3
          ? "add_enabler_assign_role_container"
          : "add_enabler_assign_role_container active"
      }
    >
      <p className="heading">Choose enabler's role</p>

      <div className="choose_enabler_container">
        <div className="radio_container">
          <input
            type="radio"
            name="assignrole"
            id="masterenabler"
            value="master"
            onChange={(e) => {
              setSeletedRole("master");
              setSelectedPrivileges([108, 109, 110, 111, 112, 113, 115, 116]);
            }}
            defaultChecked={SeletedRole == "master" ? true : false}
            checked={SeletedRole == "master" ? true : false}
          />
          <label htmlFor="masterenabler">Master enabler</label>
        </div>
        <div className="radio_container">
          <input
            type="radio"
            name="assignrole"
            id="Enabler"
            value="enabler"
            onChange={(e) => {
              setSeletedRole("enabler");
              setSelectedPrivileges([108, 110, 112, 114, 116, 118]);
            }}
            defaultChecked={SeletedRole == "enabler" ? true : false}
            checked={SeletedRole == "enabler" ? true : false}
          />
          <label htmlFor="Enabler">Enabler</label>
        </div>
        <div className="radio_container">
          <input
            type="radio"
            name="assignrole"
            id="assessorscreening"
            value="assessor"
            onChange={(e) => {
              setSeletedRole("Assessor - screening");
              setSelectedPrivileges([15, 106, 19, 117, 119]);
            }}
            defaultChecked={
              SeletedRole == "Assessor - screening" ? true : false
            }
            checked={SeletedRole == "Assessor - screening" ? true : false}
          />
          <label htmlFor="assessorscreening">Assessor - screening</label>
        </div>
        <div className="radio_container">
          <input
            type="radio"
            name="assignrole"
            id="Approverscreening"
            value="approver"
            onChange={(e) => {
              setSeletedRole("Approver - screening");
              setSelectedPrivileges([107]);
            }}
            defaultChecked={
              SeletedRole == "Approver - screening" ? true : false
            }
            checked={SeletedRole == "Approver - screening" ? true : false}
          />
          <label htmlFor="Approverscreening">Approver - screening</label>
        </div>
        {/* <div className="radio_container">
          <input
            type="radio"
            name="assignrole"
            id="Aggregate"
            value="aggregate"
            onChange={(e) => {
              setSeletedRole("Aggregate");
              setSelectedPrivileges([]);
            }}
            disabled
            defaultChecked={SeletedRole == "Aggregate" ? true : false}
            checked={SeletedRole == "Aggregate" ? true : false}
          />
          <label htmlFor="Aggregate" style={{ opacity: 0.6 }}>
            Aggregate enabler
          </label>
        </div> */}
        {UserRole?.role != "schooladmin" ? (
          <div className="radio_container">
            <input
              type="radio"
              name="assignrole"
              id="schooladmin"
              value="School admin"
              onChange={(e) => {
                setSeletedRole("School admin");
                setSelectedPrivileges([]);
              }}
              disabled={UserRole?.role == "schooladmin" ? true : false}
              defaultChecked={SeletedRole == "School admin" ? true : false}
              checked={SeletedRole == "School admin" ? true : false}
            />
            <label htmlFor="schooladmin">School admin</label>
          </div>
        ) : (
          ""
        )}
        {UserRole?.role != "schooladmin" ? (
          <div className="radio_container">
            <input
              type="radio"
              name="assignrole"
              id="External approver"
              value="External approver"
              onChange={(e) => {
                setSeletedRole("External approver");
                setSelectedPrivileges([]);
              }}
              disabled={UserRole?.role == "schooladmin" ? true : false}
              defaultChecked={SeletedRole == "External approver" ? true : false}
              checked={SeletedRole == "External approver" ? true : false}
            />
            <label htmlFor="External approver">External approver</label>
          </div>
        ) : (
          ""
        )}
        {UserRole?.role != "schooladmin" ? (
          <div className="radio_container">
            <input
              type="radio"
              name="assignrole"
              id="External creator"
              value="External creator"
              onChange={(e) => {
                setSeletedRole("External creator");
                setSelectedPrivileges([]);
              }}
              disabled={UserRole?.role == "schooladmin" ? true : false}
              defaultChecked={SeletedRole == "External creator" ? true : false}
              checked={SeletedRole == "External creator" ? true : false}
            />
            <label htmlFor="External creator">External creator</label>
          </div>
        ) : (
          ""
        )}
        {UserRole?.role == "schooladmin" ? (
          <div className="radio_container">
            <input
              type="radio"
              name="assignrole"
              id="School enabler"
              value="School enabler"
              onChange={(e) => {
                setSeletedRole("School enabler");
                setSelectedPrivileges([
                  106, 19, 107, 108, 109, 110, 111, 112, 114, 116, 117, 118,
                ]);
              }}
              defaultChecked={SeletedRole == "School enabler" ? true : false}
              checked={SeletedRole == "School enabler" ? true : false}
            />
            <label htmlFor="School enabler">School enabler</label>
          </div>
        ) : (
          ""
        )}
        {UserRole?.role == "schooladmin" ? (
          <div className="radio_container">
            <input
              type="radio"
              name="assignrole"
              id="School co-ordinater"
              value="School co-ordinater"
              onChange={(e) => {
                setSeletedRole("School co-ordinater");
                setSelectedPrivileges([]);
              }}
              defaultChecked={
                SeletedRole == "School co-ordinater" ? true : false
              }
              checked={SeletedRole == "School co-ordinater" ? true : false}
            />
            <label htmlFor="School co-ordinater">School co-ordinater</label>
          </div>
        ) : (
          ""
        )}

        {UserRole?.role == "DL Admin" && (
          <div className="radio_container">
            <input
              type="radio"
              name="assignrole"
              id="School principal"
              value="School principal"
              onChange={(e) => {
                setSeletedRole("School principal");
                setSelectedPrivileges([]);
              }}
              defaultChecked={SeletedRole == "School principal" ? true : false}
              checked={SeletedRole == "School principal" ? true : false}
            />
            <label htmlFor="School principal">School principal</label>
          </div>
        )}

        {(UserRole?.role == "DL Admin" || UserRole?.role == "schooladmin") && (
          <div className="radio_container">
            <input
              type="radio"
              name="assignrole"
              id="School coordinator"
              value="School coordinator"
              onChange={(e) => {
                setSeletedRole("School coordinator");
                setSelectedPrivileges([]);
              }}
              defaultChecked={
                SeletedRole == "School coordinator" ? true : false
              }
              checked={SeletedRole == "School coordinator" ? true : false}
            />
            <label htmlFor="School coordinator">School coordinator</label>
          </div>
        )}
      </div>

      {(SeletedRole == "enabler" || SeletedRole == "master") && (
        <div className="master_enabler_select_container">
          <label htmlFor="">Select master enabler</label>
          <select
            name=""
            id=""
            onChange={(e) => {
              setSelectedMasterenabler(e.target.value);
            }}
            defaultValue={SelectedMasterenabler}
            value={SelectedMasterenabler}
          >
            <option value="">Select master enabler</option>
            {MasterEnablerList?.map((item, index) => {
              return (
                <option value={item?.enabler_id} key={index}>
                  {item?.enabler_name}
                </option>
              );
            })}
          </select>
        </div>
      )}

      {SeletedRole == "School admin" && (
        <div className="enabler_school_select_container">
          <p>Add schools</p>
          <div className="enabler_school_select_input">
            <div className="school_select_input">
              <input
                type="text"
                name=""
                id="enablerschool_role"
                placeholder="Search school name"
                value={SelectedSchool?.school_name}
                onClick={() => {
                  setDropdown(true);
                }}
                onInput={SchoolnameHandler}
              />
              {Dropdown && (
                <div className="school_list_dropdown">
                  {Schoollist?.map((item, index) => {
                    return (
                      <button
                        onClick={() => {
                          setSelectedSchool(item);
                          setDropdown(false);
                        }}
                        key={index}
                      >
                        {item?.school_name}
                      </button>
                    );
                  })}
                </div>
              )}
              <i className="fa-solid fa-angle-down"></i>
            </div>
            <button onClick={AddSchool_Handler}>Add</button>
          </div>

          <div className="list_enabler_school_list_container">
            {SelectedSchoollist?.map((item, index) => {
              return (
                <div className="enabler_school_list_block" key={index}>
                  <p>{item?.school_name}</p>
                  <button onClick={() => Remove_SelectedSchool_Handler(index)}>
                    <i className="fa-solid fa-circle-xmark"></i>
                  </button>
                </div>
              );
            })}
          </div>
          <p className="heading">Choose privileges</p>

          <div className="school_admin_privilege_container">
            <div className="previleges_checkbox_container">
              {School_privileges?.map((item, index) => {
                return (
                  <div className="previleges_checkbox" key={index}>
                    <input
                      type="checkbox"
                      name=""
                      id={item?.name}
                      value={item?.id}
                      checked={Schooladmin_privileges?.includes(item?.id)}
                      // defaultChecked={SelectedPrivileges?.includes(item?.id)}
                      onChange={() => {
                        School_PrivilegesHandle(item?.id);
                      }}
                    />
                    <label htmlFor={item?.name}>{item?.name}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {SeletedRole != "School admin" &&
        SeletedRole != "External approver" &&
        SeletedRole != "School co-ordinater" &&
        SeletedRole != "School principal" &&
        SeletedRole != "School coordinator" &&
        SeletedRole != "External creator" && (
          <p className="heading">Choose privileges</p>
        )}

      {SeletedRole != "School admin" &&
        SeletedRole != "External approver" &&
        SeletedRole != "School co-ordinater" &&
        SeletedRole != "School principal" &&
        SeletedRole != "School coordinator" &&
        SeletedRole != "External creator" && (
          <div className="previleges_checkbox_container">
            {ROLES?.map((item, index) => {
              if (
                (UserRole?.role == "schooladmin" && item?.value != 15) ||
                item?.value != 159 ||
                item?.value != 160
              ) {
                return (
                  <div className="previleges_checkbox" key={index}>
                    <input
                      type="checkbox"
                      name=""
                      id={item?.name}
                      value={item?.value}
                      checked={SelectedPrivileges?.includes(item?.value)}
                      defaultChecked={SelectedPrivileges?.includes(item?.value)}
                      onChange={() => {
                        PrivilegesHandle(item?.value);
                      }}
                    />
                    <label htmlFor={item?.name}>{item?.name}</label>
                  </div>
                );
              }
            })}
          </div>
        )}

      {SeletedRole == "School principal" ? (
        <div className="enabler_school_select_container">
          <h3 className="my-2 text-[18px] text-[#007872] font-semibold">
            Add school
          </h3>
          <div className="enabler_school_select_input">
            <div className="school_select_input">
              <input
                type="text"
                name=""
                id="enablerschool_role"
                placeholder="Search school name"
                value={SelectedSchool?.school_name}
                onClick={() => {
                  setDropdown(true);
                }}
                onInput={SchoolnameHandler}
              />
              {Dropdown && (
                <div className="school_list_dropdown">
                  {Schoollist?.map((item, index) => {
                    if (item?.is_available == 0) {
                      return (
                        <button
                          onClick={() => {
                            setSelectedSchool(item);
                            setDropdown(false);
                          }}
                          key={index}
                        >
                          {item?.school_name}
                        </button>
                      );
                    }
                  })}
                </div>
              )}
              <i className="fa-solid fa-angle-down"></i>
            </div>
            <button onClick={AddSchool_Handler}>Add</button>
          </div>

          <div className="list_enabler_school_list_container">
            {SelectedSchoollist?.map((item, index) => {
              return (
                <div className="enabler_school_list_block" key={index}>
                  <p>{item?.school_name}</p>
                  <button onClick={() => Remove_SelectedSchool_Handler(index)}>
                    <i className="fa-solid fa-circle-xmark"></i>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}

      {SeletedRole == "School co-ordinater" && (
        <div className="min-h-[370px]"></div>
      )}
      <button onClick={ADD_Role} className="Add_role_btn">
        Next
      </button>
    </div>
  );
};

export default Assignrole;
