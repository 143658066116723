import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import "./Graphpie.css";
const Graphpie = ({ data, heading, totalcount }) => {
  var CanvasJS = CanvasJSReact.CanvasJS;
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  CanvasJS.addColorSet("greenShades", [
    "#3A3939",
    "#2DA3DC",
    "#BCC3DD",
    "#F79221",
  ]);
  const options = {
    theme: "dark2",
    width: 250,
    height: 200,
    // colorSet: "greenShades",
    animationEnabled: true,
    exportFileName: "",
    exportEnabled: false,
    backgroundColor: "#fff",
    title: {
      text: heading,
      fontColor: "#000",
      fontSize: totalcount == 0 ? 24 : 16,
      fontWeight: totalcount == 0 ? 600 : 500,
      verticalAlign: totalcount == 0 ? "center" : "top",
    },
    legend: {
      fontColor: "black",
      fontSize: 14,
      //   dockInsidePlotArea: true,
      markerMargin: 10,
      //   itemMaxWidth: 60,
    },
    toolTip: {
      fontSize: 16,
    },
    data: [
      {
        type: "pie",
        showInLegend: false,

        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y}</strong>",
        indexLabel: "{y}",
        indexLabelPlacement: "inside",
        // dataPoints: [
        //   {
        //     y: 32,
        //     label: "Health",
        //     indexLabelFontSize: 22,
        //     // indexLabel: "{label}-{y}%",
        //     // indexLabelFontWeight: "bold",
        //   },
        //   { y: 22, label: "Finance" },
        //   { y: 15, label: "Education" },
        //   { y: 19, label: "Career" },
        // ],
        dataPoints: data,
      },
    ],
  };
  return (
    <div className="graphpie-container">
      {totalcount == 0 ? (
        <p className="text-center font-semibold">{heading}</p>
      ) : (
        <CanvasJSChart options={options} />
      )}
      <div className={`graphpie-legend`}>
        {data?.map((item, index) => {
          return (
            <div
              key={index}
              className={`graphpie-legend-title  ${
                totalcount == 0 ? "opacity-0" : ""
              }`}
            >
              <div style={{ background: item?.color }}></div>
              <p>{item?.label}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Graphpie;
