import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Pending from "../TaskComponents/Pending";
import "./Taskpending.css";

const Taskpending = ({
  MainBlurContainer,
  setMainBlurContainer,
  RescheduleModalbox,
  setRescheduleModalbox,
  Reschedule,
  setReschedule,
  ShowReschedule,
  setShowReschedule,
  ShowDeclinemodal,
  setShowDeclinemodal,
  Notification_Data,
  Get_details,
  setCancel_or_reschedule,
  Actions_btn,
  setDecline_userid,
  setGo_to_heading,
}) => {
  return (
    <div className="pending_task_container">
      {Notification_Data?.notification_list?.map((item, index) => {
        return (
          <Pending
            key={index}
            setShowReschedule={setShowReschedule}
            setShowDeclinemodal={setShowDeclinemodal}
            item={item}
            Get_details={Get_details}
            setCancel_or_reschedule={setCancel_or_reschedule}
            Actions_btn={Actions_btn}
            setDecline_userid={setDecline_userid}
            setGo_to_heading={setGo_to_heading}
          />
        );
      })}
    </div>
  );
};

export default Taskpending;
