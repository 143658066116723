import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { URL } from "../../DROPDOWNDATA";
import Topbar from "../childPage/topMenu/TopMenu";
import "./Gradeddetails.css";
import Listening from "./Listening/Listening";
import Reading from "./Reading/Reading";
import Spelling from "./Spelling/Spelling";
import Writing from "./Writing/Writing";
import { useParams } from "react-router-dom";
import Readingcomprension from "./readingcomp";

const Gradeddetails = () => {
  const { id, childname } = useParams();
  const [CurrentGrade, setCurrentGrade] = useState(1);
  const [Data, setData] = useState([]);
  async function Get_grade_details(type) {
    let Data = {
      view_type: type,
      child_id: atob(id),
    };
    await fetch(URL + `/graded_tool_data_view`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setData(obj.body);
      })
      .catch((err) => {
        toast.error("something went wrong");
        toast.clearWaitingQueue();
      });
  }
  useEffect(() => {
    Get_grade_details("Reading");
  }, []);

  return (
    <div className="graded_details_container">
      <Topbar id={id} childname={childname} />
      <h2 className="graded_details_title font-semibold text-[22px]">
        Graded details
      </h2>
      <div className="graded_tab_switch">
        <button
          className={CurrentGrade == 1 ? "active" : ""}
          onClick={() => {
            Get_grade_details("Reading");
            setCurrentGrade(1);
          }}
        >
          Reading
        </button>
        <button
          className={CurrentGrade == 2 ? "active" : ""}
          onClick={() => {
            Get_grade_details("Writing");
            setCurrentGrade(2);
          }}
        >
          Writing
        </button>
        <button
          className={CurrentGrade == 3 ? "active" : ""}
          onClick={() => {
            Get_grade_details("Spelling");
            setCurrentGrade(3);
          }}
        >
          Spelling
        </button>
        <button
          className={CurrentGrade == 4 ? "active" : ""}
          onClick={() => {
            Get_grade_details("Listening");
            setCurrentGrade(4);
          }}
        >
          Listening
        </button>
        <button
          className={CurrentGrade == 5 ? "active" : ""}
          onClick={() => {
            Get_grade_details("Reading comprehension");
            setCurrentGrade(5);
          }}
        >
          Reading comprehension
        </button>
      </div>

      <div className="graded_table_details_container">
        {CurrentGrade == 1 && <Reading Data={Data} />}
        {CurrentGrade == 2 && <Writing Data={Data} />}
        {CurrentGrade == 3 && <Spelling Data={Data} />}
        {CurrentGrade == 4 && <Listening Data={Data} />}
        {CurrentGrade == 5 && <Readingcomprension Data={Data} />}
      </div>
    </div>
  );
};

export default Gradeddetails;
