import React, { forwardRef, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { URL } from "../../../DROPDOWNDATA";
import "./PTMtemplate.css";

const SQR = forwardRef(
  (
    {
      Data,
      SelectedClass,
      setCurrentQuestionIndex,
      CurrentQuestionIndex,
      setLoader,
      reloadQuestion,
    },
    ref
  ) => {
    const [is_otherSelected, setis_otherSelected] = useState(false);
    const [OtherInput, setOtherInput] = useState("");
    const [FirstFive, setFirstFive] = useState([]);
    const [Lastone, setLastone] = useState([]);
    let SQR_first = document.getElementsByName(`${Data?.question_id}`);
    let SQR_last = document.getElementsByName("SQR_last");

    const SQRHandle = async () => {
      let First_value = [];
      let Last_value = false;
      let data;
      for (let i = 0; i < SQR_first.length; i++) {
        if (SQR_first[i]?.checked) {
          if (SQR_first[i]?.getAttribute("data-value") == "other") {
            Last_value = true;
            First_value.push(SQR_first[i]?.value);
          } else {
            Last_value = false;
            First_value.push(SQR_first[i]?.value);
          }
        }
      }

      if (Last_value) {
        if (OtherInput == "") {
          toast.error("Kindly fill the other field");
          toast.clearWaitingQueue();
          return;
        }
        data = {
          view_no: SelectedClass.id,
          q_a: [
            {
              question_id: Data?.question_id,
              given_answer: First_value?.join(),
              others: OtherInput,
            },
          ],
        };
      } else {
        data = {
          view_no: SelectedClass.id,
          q_a: [
            {
              question_id: Data?.question_id,
              given_answer: First_value?.join(),
              others: "",
            },
          ],
        };
      }
      setLoader(true);
      await fetch(`${URL}/add_update_analysis`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify(data),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          console.log(obj.body);
          setCurrentQuestionIndex(CurrentQuestionIndex + 1);
          reloadQuestion();
        })
        .catch((err) => {
          setLoader(false);
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        });
    };

    useEffect(() => {
      setLastone([]);
      setFirstFive([]);
      Data?.option_list?.map((item, index) => {
        if (item?.option_text == "Other") {
          setLastone((prev) => [...prev, item]);
        } else {
          setFirstFive((prev) => [...prev, item]);
        }
      });
      if (Data?.others != "") {
        setis_otherSelected(true);
        setOtherInput(Data?.others);
      }

      return () => {
        setLastone([]);
        setFirstFive([]);
      };
    }, [Data]);

    if (Data) {
      return (
        <div className="SQR_container">
          <h3>{Data?.main_question}</h3>

          <div className="sqr_radio_container">
            <div
              className={
                is_otherSelected ? "sqr_type1_container" : "sqr_type1_container"
              }
            >
              {FirstFive?.map((item, index) => {
                return (
                  <div className="sqr_radio_block" key={index}>
                    <input
                      type="radio"
                      name={Data?.question_id}
                      id={item?.option_id}
                      data-value={"regular"}
                      value={item?.option_id}
                      defaultChecked={item?.is_selected == 1 ? true : false}
                    />
                    <label htmlFor={item?.option_id}>{item?.option_text}</label>
                  </div>
                );
              })}
            </div>
            <div className="sqr_type2_container">
              {Lastone?.map((item, index) => {
                return (
                  <div>
                    <div className="sqr_radio_block" key={index}>
                      <input
                        type="radio"
                        name={Data?.question_id}
                        id={item?.option_id}
                        value={item?.option_id}
                        data-value={"other"}
                        defaultChecked={item?.is_selected == 1 ? true : false}
                        onChange={(e) => {
                          setis_otherSelected(!is_otherSelected);
                        }}
                      />
                      <label htmlFor={item?.option_id}>
                        {item?.option_text}
                      </label>
                    </div>
                    {is_otherSelected && (
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        value={OtherInput}
                        onInput={(e) => {
                          setOtherInput(e.target.value);
                        }}
                      ></textarea>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <button ref={ref} onClick={SQRHandle}></button>
        </div>
      );
    }
  }
);

export default SQR;
