import React, { useEffect, useState, useRef } from "react";
import "./ScheduleList.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import LoaderScreen from "../Loader/Loader";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../customHooks/searchLayout";
import { URL } from "../../DROPDOWNDATA";

import Pagination from "react-js-pagination";
import Groupschedulelist from "../../pages/Groupscheduleview/Groupschedulelist";
import FileSaver from "file-saver";

function Tabpanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
const ScheduleList = (props) => {
  const History = useNavigate();
  const { type, user_id, page_no } = useParams();
  const SearchInput = useRef(0);
  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  const [value, setValue] = React.useState(0);
  const [ClassSchedule, setClassSchedule] = useState([]);
  const [ScreeningSchedule, setScreeningSchedule] = useState([]);
  const [GroupScheduleList, setGroupScheduleList] = useState();
  const [Loader, setLoader] = useState(true);

  const [Left_total_list, setLeft_total_list] = useState({
    title_1: "sometext",
    title_1_count: 3,
    title_2: "sometext",
    title_2_count: 3,
    title_3: "sometext",
    title_3_count: 3,
  });

  // NEW PAGINATION
  const [paginations_count, setpaginations_count] = useState(1);

  Tabpanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    SearchInput.current.value = "";
    // setValue(newValue);
    History(`/scheduleList/${newValue}/${user_id}/1`);
  };

  async function Get_classSchedule(pgno, loading) {
    if (loading) setLoader(true);
    await fetch(URL + `/class_schedule?page_no=${Number(page_no)}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify({
        search_string: SearchInput.current.value,
        user_id: user_id == "all" ? "" : atob(user_id),
      }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setClassSchedule(obj.body);
        setLeft_total_list({
          title_1: "Total no.of children",
          title_1_count: obj.body.total_child_enrolled,
          title_2: "No. of classess completed",
          title_2_count: obj.body.total_no_of_class_completed,
          title_3: "",
          title_3_count: "",
        });
        if (loading) setLoader(false);
      })
      .catch((err) => {
        if (loading) setLoader(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }
  async function Get_ScreeningSchedule(pg_no, loading) {
    if (loading) setLoader(true);
    await fetch(URL + `/evaluation_cnfm_slot?page_no=${Number(page_no)}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify({
        search_string: SearchInput.current.value,
        user_id: user_id == "all" ? "" : atob(user_id),
      }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setScreeningSchedule(obj.body);
        setLeft_total_list({
          title_1: "Total no.of screening",
          title_1_count: obj.body.screening_scheduled_count,
          title_2: "No. of report sent to parent",
          title_2_count: obj.body.report_sent_to_parent_count,
          title_3: "",
          title_3_count: "",
        });
        if (loading) setLoader(false);
      })
      .catch((err) => {
        if (loading) setLoader(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }
  const GetGroupSchedulrDetails = async (pg_no, loader) => {
    if (loader) setLoader(true);

    await fetch(URL + `/list_group_schedule?page_no=${Number(page_no)}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify({
        search: SearchInput.current.value,
        user_id: user_id == "all" ? "" : atob(user_id),
      }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setGroupScheduleList(obj.body);
        setLeft_total_list({
          title_1: "Total no. of groups enrolled",
          title_1_count: obj.body.total_child_enrolled,
          title_2: "Total no. of group classes completed",
          title_2_count: obj.body.total_no_of_class_completed,
          title_3: "Total no. of students in groups",
          title_3_count: obj.body.total_number_of_students,
        });
        if (loader) setLoader(false);
      })
      .catch((err) => {
        if (loader) setLoader(false);

        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };
  const handlePageClick = async (event) => {
    let go_to = event;
    setpaginations_count(go_to);
    // Get_ScreeningSchedule(go_to, false);
    History(`/scheduleList/${type}/${user_id}/${go_to}`);
  };
  const handlePage_classSchedule = async (event) => {
    let go_to = event;
    setpaginations_count(go_to);
    // Get_classSchedule(go_to, false);
    History(`/scheduleList/${type}/${user_id}/${go_to}`);
  };

  function GroupPaginationHandler(event) {
    let go_to = event;
    setpaginations_count(go_to);
    // GetGroupSchedulrDetails(go_to, false);
    History(`/scheduleList/${type}/${user_id}/${go_to}`);
  }

  const HandleSearch = (values) => {
    if (value == 0) {
      setpaginations_count(1);
      Get_classSchedule(1, false);
    } else if (value == 1) {
      setpaginations_count(1);
      Get_ScreeningSchedule(1, false);
    } else {
      setpaginations_count(1);
      GetGroupSchedulrDetails(1, false);
    }
  };

  const DownloadIntervention = async () => {
    setLoader(true);
    await fetch(URL + `/intervention_schedule_download`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
      body: JSON.stringify({ view_type: value == 0 ? "Individual" : "Group" }),
    })
      .then((r) => r.blob())
      .then((obj) => {
        setLoader(false);
        FileSaver.saveAs(obj, `Intervention_Attendance.xlsx`);
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };
  useEffect(() => {
    Get_classSchedule(paginations_count, true);
    props.setBreadcrumItem([
      { name: "Scheduling", link: "/scheduling" },
      {
        name: "Schedule List",
        link: `/scheduleList/${type}/${user_id}/${page_no}`,
      },
    ]);
  }, []);
  useEffect(() => {
    setValue(Number(type));
    setpaginations_count(Number(page_no));
    if (type == 0) {
      Get_classSchedule(Number(page_no), false);
    } else if (type == 1) {
      Get_ScreeningSchedule(Number(page_no), false);
    } else if (type == 2) {
      GetGroupSchedulrDetails(Number(page_no), false);
    }
  }, [type, user_id, page_no]);
  if (Loader) {
    return <LoaderScreen />;
  }

  return (
    <div className="all_schedule_container">
      <div className="schedule_session_tabLayout">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{ width: "70%" }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{ width: "20%" }}
                label="Intervention schedule"
                {...a11yProps(0)}
                onClick={() => {
                  // setpaginations_count(1);
                  // Get_classSchedule(1, false);
                }}
              />
              <Tab
                sx={{ width: "20%" }}
                label="Screening schedule"
                {...a11yProps(1)}
                onClick={() => {
                  // setpaginations_count(1);
                  // Get_ScreeningSchedule(1, false);
                }}
              />
              <Tab
                sx={{ width: "20%" }}
                label="Group schedule"
                {...a11yProps(2)}
                onClick={() => {
                  // setpaginations_count(1);
                  // GetGroupSchedulrDetails(1, false);
                }}
              />
            </Tabs>

            <Search>
              <SearchIconWrapper>
                <h2>
                  <i className="fas fa-search"></i>
                </h2>
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onInput={(e) => {
                  HandleSearch(e.target.value);
                }}
                inputRef={SearchInput}
              />
            </Search>
            {value != 1 && (
              <button
                className="intervention-report-download"
                onClick={DownloadIntervention}
              >
                Attendance <i class="fa-solid fa-download"></i>
              </button>
            )}
          </Box>

          {/* classs */}
          <Tabpanel value={value} index={0}>
            <div className="student_schedule_list">
              <table>
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>Learner name</th>
                    <th>Days</th>
                    <th>Time</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Enabler Name</th>
                    <th>Call Link</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ClassSchedule?.meeting_list?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.si_no}</td>
                        <td>{item.child_name}</td>
                        <td>{item.days}</td>
                        <td>{item.start_time}</td>
                        <td>{item.start_date}</td>
                        <td>{item.end_date}</td>
                        <td>{item.enabler_name}</td>
                        <td>
                          <a href={item.meeting_url} target="_blank">
                            <i className="fas fa-phone"></i>
                          </a>
                        </td>
                        <td>
                          <NavLink
                            to={`/individualSchedule/${btoa(
                              item?.child_view_id
                            )}`}
                          >
                            <button>
                              <i className="far fa-eye"></i>
                            </button>
                          </NavLink>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              activePage={paginations_count}
              itemsCountPerPage={10}
              totalItemsCount={Math.ceil(ClassSchedule?.total_count)}
              pageRangeDisplayed={10}
              itemClass="paginationLi_new"
              // pageClassName="paginationLi"
              // pageLinkClassName="paginationLink"
              // previousClassName="prevBtn"
              // nextClassName="nextBtn"
              // previousLinkClassName="prevLink"
              // nextLinkClassName="nextLink"
              // breakClassName="dots"
              // disabledClassName="disabled"
              // disabledLinkClassName="disableBtn"
              // className="pagination"
              prevPageText="Prev"
              nextPageText="Next"
              activeLinkClass="pagLi_sc"
              activeClass="pagLi_sc"
              itemClassFirst="go_first"
              itemClassLast="go_first"
              itemClassPrev="prev_btn"
              itemClassNext="next_btn"
              onChange={(e) => handlePage_classSchedule(e)}
            />
          </Tabpanel>

          {/* screening */}
          <Tabpanel value={value} index={1}>
            <div className="screening_schedule_list">
              <table>
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>Learner name</th>
                    <th>Screening Date</th>

                    <th>Time</th>
                    <th>Enabler Name</th>
                    <th>Call Link</th>
                  </tr>
                </thead>
                <tbody>
                  {ScreeningSchedule?.meeting_details?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.si_no}</td>
                        <td>{item?.child_name}</td>
                        <td>{item.date}</td>

                        <td>{item.time}</td>
                        <td>{item.enabler_name}</td>
                        <td>
                          <a href={item.call_link} target="_blank">
                            <i className="fas fa-phone"></i>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <Pagination
              activePage={paginations_count}
              itemsCountPerPage={10}
              totalItemsCount={Math.ceil(ScreeningSchedule?.total_count)}
              pageRangeDisplayed={10}
              itemClass="paginationLi_new"
              prevPageText="Prev"
              nextPageText="Next"
              activeLinkClass="pagLi_sc"
              activeClass="pagLi_sc"
              itemClassFirst="go_first"
              itemClassLast="go_first"
              itemClassPrev="prev_btn"
              itemClassNext="next_btn"
              onChange={(e) => handlePageClick(e)}
            />
          </Tabpanel>
          <Tabpanel value={value} index={2}>
            <Groupschedulelist
              GroupScheduleList={GroupScheduleList}
              GroupPaginationHandler={GroupPaginationHandler}
              paginations_count={paginations_count}
            />
          </Tabpanel>
        </Box>
      </div>

      <div className="no_of_classes">
        <ClassCount
          title={Left_total_list.title_1}
          value={Left_total_list.title_1_count}
        />

        {Left_total_list?.title_3 != "" && (
          <ClassCount
            title={Left_total_list.title_3}
            value={Left_total_list.title_3_count}
          />
        )}
        <ClassCount
          title={Left_total_list.title_2}
          value={Left_total_list.title_2_count}
        />

        <NavLink to="/scheduling">
          <button
            onClick={() => {
              props.setScheduleType(false);
            }}
            className="reDirect_btn"
          >
            Go to Calendar
          </button>
        </NavLink>

        <button
          onClick={() => {
            props.setScheduleType(false);
            async function ValidToken() {
              await fetch(URL + "/validate_token", {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: authKey,
                },
              })
                .then((r) =>
                  r.json().then((data) => ({ status: r.status, body: data }))
                )
                .then((obj) => {
                  if (obj.body.error) {
                    window.location.href = "https://dlearners.in/login.php";
                  } else {
                    props.setScheduleType(false);
                    History("/scheduleSession");
                    window.location.reload();
                  }
                });
            }
            ValidToken();
          }}
          className="reDirect_btn"
        >
          Create new schedule
        </button>
      </div>
    </div>
  );
};
function ClassCount({ title, value }) {
  return (
    <div className="classes">
      <p className="total_class">{title}</p>
      <div className="class_count">{value}</div>
    </div>
  );
}
export default ScheduleList;
