import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { URL } from "../../../DROPDOWNDATA";
import Addremarks from "../PTMtemplates/Addremarks";
import MQR from "../PTMtemplates/MQR";
import SQC from "../PTMtemplates/SQC";
import SQR from "../PTMtemplates/SQR";
import "./Ptmadd.css";

const Ptmadd = ({
  QuestionData,
  SelectedClass,
  setSelectedClass,
  setQuestionData,
  setLoader,
  Loader,
}) => {
  const Child_next_btn = useRef(null);
  const [CurrentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const ClassData = async () => {
    let item = {
      view_no: SelectedClass?.id,
    };
    setLoader(true);
    await fetch(`${URL}/ptm_view_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        // console.log(obj);
        setLoader(false);

        setSelectedClass({
          id: SelectedClass?.id,
          view_type: "add",
        });
        setQuestionData(obj.body);
      })
      .catch((err) => {
        setLoader(false);

        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  const GenerateNextQues = () => {
    Child_next_btn.current.click();
  };
  if (Loader) {
    // RENDER THIS WHILE DATA IS PASSING BCOZ RADIO NEED TO UPDATE
    return <p>--</p>;
  }

  return (
    <div className="PTM_add_container">
      <div className="heading">
        <h2>Question</h2>
        <button
          onClick={() => {
            setSelectedClass({
              id: "",
              view_type: "",
            });
          }}
        >
          <i class="fa-solid fa-circle-xmark"></i>
        </button>
      </div>
      {CurrentQuestionIndex <=
      QuestionData?.question_and_options?.length - 1 ? (
        <div className="PTM_list_question_container">
          {QuestionData?.question_and_options[CurrentQuestionIndex]
            ?.question_type == "sqc" && (
            <SQC
              ref={Child_next_btn}
              Data={QuestionData?.question_and_options[CurrentQuestionIndex]}
              SelectedClass={SelectedClass}
              CurrentQuestionIndex={CurrentQuestionIndex}
              setCurrentQuestionIndex={setCurrentQuestionIndex}
              reloadQuestion={ClassData}
              setLoader={setLoader}
            />
          )}
          {QuestionData?.question_and_options[CurrentQuestionIndex]
            ?.question_type == "mqr" && (
            <MQR
              ref={Child_next_btn}
              Data={QuestionData?.question_and_options[CurrentQuestionIndex]}
              SelectedClass={SelectedClass}
              CurrentQuestionIndex={CurrentQuestionIndex}
              setCurrentQuestionIndex={setCurrentQuestionIndex}
              reloadQuestion={ClassData}
              setLoader={setLoader}
            />
          )}
          {QuestionData?.question_and_options[CurrentQuestionIndex]
            ?.question_type == "sqr" && (
            <SQR
              ref={Child_next_btn}
              Data={QuestionData?.question_and_options[CurrentQuestionIndex]}
              SelectedClass={SelectedClass}
              CurrentQuestionIndex={CurrentQuestionIndex}
              setCurrentQuestionIndex={setCurrentQuestionIndex}
              reloadQuestion={ClassData}
              setLoader={setLoader}
            />
          )}
        </div>
      ) : (
        <div className="PTM_list_question_container">
          <Addremarks
            ref={Child_next_btn}
            Data={QuestionData}
            SelectedClass={SelectedClass}
            CurrentQuestionIndex={CurrentQuestionIndex}
            setCurrentQuestionIndex={setCurrentQuestionIndex}
            role={QuestionData?.role}
            setSelectedClass={setSelectedClass}
            setLoader={setLoader}
          />
        </div>
      )}
      <div className="PTM_add_question_btn_container">
        <button
          onClick={() => {
            setCurrentQuestionIndex(CurrentQuestionIndex - 1);
            ClassData();
          }}
        >
          Prev
        </button>
        <button
          onClick={() => {
            GenerateNextQues();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Ptmadd;
