import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import "./breadcrumbs.css"

// function getLink(myValue) {
//     let myList = [{
//         name: "Dashboard",
//         link: '/'
//     }, {
//         name: "Student List",
//         link: '/student_list'
//     }, {
//         name: "Enrollment & Payment",
//         link: '/child_enrollment_payment'
//     }]
// }

function Breadcrumb_element(props) {
    const Navigate = useNavigate()
    return (
        <p onClick={() => Navigate(props.link)} className='breadcrumb_link' >
            <span>{props.title} <i className="fas fa-caret-right"></i></span>
        </p>
    )
}

export default function Breadcrumbs(props) {
    return (
        <div className='breadcrumb_container'>

            <p>
                {
                    props.breadCrumbs_item.map((item, index) => {
                        return (
                            <Breadcrumb_element title={item.name} link={item.link} key={index} />
                        )
                    })
                }

                {/* <Breadcrumb_element title="Container" />
                <Breadcrumb_element title="Navbar" />
                <Breadcrumb_element title="Dashboard" /> */}



                {/* <Breadcrumb_element title={props.breadCrumbs_item[1]} /> */}


            </p>

            <span className={localStorage.getItem('childID') ? "" : "none"}>Child ID: <b>{localStorage.getItem('childID')}</b></span>

        </div>
    )
}
