import React from "react";
import "./tasklist.css";
import { toast } from "react-toastify";

export default function TaskList({
  sessionType,
  enablerName,
  childName,
  time,
  link,
}) {
  const Navigate = (link) => {
    window.open(link);
  };
  const Copylink = (link) => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied");
    toast.clearWaitingQueue();
  };
  return (
    <div className="task border-b-2">
      <p className="time">{time}</p>
      <div className="session_data">
        <p className="class_details">
          {enablerName} - class with {childName}
        </p>
        <p className="session_details">{sessionType}</p>
        {link != "" && (
          <div className="flex w-full justify-end items-center my-2">
            <button
              onClick={() => Copylink(link)}
              className="mx-5 text-[22px] text-[#007872]"
              title="Copy"
            >
              <i class="fa-regular fa-copy"></i>
            </button>
            <button
              onClick={() => Navigate(link)}
              className="ml-5 text-[22px] text-[#007872]"
              title="Call"
            >
              <i class="fa-solid fa-phone"></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
