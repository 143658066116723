import React, { useState } from "react";
import MQR_View from "../PTMtemplates/MQR_View";
import SQC_View from "../PTMtemplates/SQC_View";
import SQR_View from "../PTMtemplates/SQR_View";
import Viewremarks from "../PTMtemplates/Viewremarks";
import "./Ptmview.css";

const Ptmview = ({
  QuestionData,
  SelectedClass,
  setSelectedClass,
  setQuestionData,
}) => {
  const [PTM_view_count, setPTM_view_count] = useState(0);
  return (
    <div className="PTM_view_container">
      <div className="heading">
        <h2>Question</h2>
        <button
          onClick={() => {
            setSelectedClass({
              id: "",
              view_type: "",
            });
          }}
        >
          <i class="fa-solid fa-circle-xmark"></i>
        </button>
      </div>

      {PTM_view_count <= QuestionData?.question_and_options?.length - 1 ? (
        <div className="PTM_question_view_container">
          {/* SINGLE QUESTION CHECKBOX */}
          {QuestionData?.question_and_options[PTM_view_count]?.question_type ==
            "sqc" && (
            <SQC_View
              Data={QuestionData?.question_and_options[PTM_view_count]}
            />
          )}
          {QuestionData?.question_and_options[PTM_view_count]?.question_type ==
            "mqr" && (
            <MQR_View
              Data={QuestionData?.question_and_options[PTM_view_count]}
            />
          )}
          {QuestionData?.question_and_options[PTM_view_count]?.question_type ==
            "sqr" && (
            <SQR_View
              Data={QuestionData?.question_and_options[PTM_view_count]}
            />
          )}
        </div>
      ) : (
        <div className="PTM_question_view_container">
          <Viewremarks Data={QuestionData} />
        </div>
      )}

      <div className="PTM_view_btn_conatiner">
        <button
          className={PTM_view_count == 0 ? "active" : ""}
          onClick={() => {
            setPTM_view_count(PTM_view_count - 1);
          }}
        >
          Prev
        </button>
        <button
          onClick={() => {
            setSelectedClass({
              id: SelectedClass?.id,
              view_type: "add",
            });
          }}
        >
          Edit
        </button>
        <button
          className={
            PTM_view_count == QuestionData?.question_and_options?.length
              ? "active"
              : ""
          }
          onClick={() => {
            setPTM_view_count(PTM_view_count + 1);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Ptmview;
