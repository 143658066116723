import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { URL as Path } from "../../../DROPDOWNDATA";
import { ToastContainer, toast } from "react-toastify";
import "./Addenabler.css";
import Header from "./Header";
import Generealdetails from "./Generealdetails";
import Aboutenabler from "./Aboutenabler";
import Assignrole from "./Assignrole";
import Enablerschedule from "./Enablerschedule";
import { useSelector } from "react-redux";

const Addenabler = ({ breadCrumbs_item, setBreadcrumItem }) => {
  const History = useNavigate();
  function tConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }
  const UserRole = useSelector((store) => store.privilege);

  const [Loading, setLoading] = useState(false);
  const [ActiveStep, setActiveStep] = useState(1);
  const [EnablerID, setEnablerID] = useState("");

  const [GeneralDetails_Data, setGeneralDetails_Data] = useState({
    enabler_name: "",
    enabler_mobile_number: "",
    email: "",
    qualification: "",
    specialisation: "",
    dob: "",
    experience: "",
  });
  // Profile image
  const [ProfileImage, setProfileImage] = useState("");
  const [SignatureImage, setSignatureImage] = useState("");
  // GENERAL DETAILS Language DATA
  const [LanguageData, setLanguageData] = useState([
    {
      lang: "",
      read: "",
      speak: "",
      write: "",
    },
  ]);
  // ABOUT STATE DATA
  const [Award_Input_count, setAward_Input_count] = useState({
    0: "",
  });
  const [Hobbies_Input_count, setHobbies_Input_count] = useState({
    0: "",
  });
  const [Passion_Input_count, setPassion_Input_count] = useState({
    0: "",
  });

  const [AboutFav, setAboutFav] = useState("");
  const [RCIno, setRCIno] = useState("");
  const [RCIfile, setRCIfile] = useState("");
  const [AboutAccom, setAboutAccom] = useState("");

  // ADD ROLE LIST
  const ROLES = [
    {
      name: "Direct child",
      value: 15,
    },
    {
      name: "Screening",
      value: 106,
    },
    {
      name: "Screening report preparation",
      value: 19,
    },
    {
      name: "Screening report approval",
      value: 107,
    },
    {
      name: "Blended / LWS /Games approval",
      value: 29,
    },
    {
      name: "Conducting graded assessment",
      value: 108,
    },
    {
      name: "Graded tools view",
      value: 109,
    },
    {
      name: "PLP preparation",
      value: 110,
    },
    {
      name: "PLP approval",
      value: 111,
    },
    {
      name: "Intervention program",
      value: 112,
    },
    {
      name: "Monitoring assigned enablers activities",
      value: 113,
    },
    {
      name: "Conducting PTM's",
      value: 114,
    },
    {
      name: "Conducting milestone PTM's",
      value: 115,
    },
    {
      name: "Group intervention",
      value: 116,
    },
    {
      name: "Trial plan",
      value: 117,
    },
    {
      name: "Trial session",
      value: 118,
    },
    {
      name: "Report explanation video upload",
      value: 119,
    },
    {
      name: "Content Approver",
      value: 160,
    },
    {
      name: "Content Creator",
      value: 159,
    },
    // {
    //   name: "Child Journal",
    //   value: 169,
    // },
    // {
    //   name: "Schedules visibility with call link and feedback",
    //   value: 170,
    // },
    // {
    //   name: "Show assigned immersive and LWS",
    //   value: 171,
    // },
    // {
    //   name: "PWS upload and view",
    //   value: 172,
    // },
    // {
    //   name: "Overall and daily schedules visibility with class link",
    //   value: 173,
    // },
    // {
    //   name: "PTM",
    //   value: 174,
    // },
  ];
  const School_privileges = [
    {
      id: 161,
      name: "Enrollment / Schedule",
    },
    {
      id: 162,
      name: "Admin notification",
    },
  ];

  const School_Enabler = [{}];
  const [MasterEnablerList, setMasterEnablerList] = useState([]);
  const [SelectedPrivileges, setSelectedPrivileges] = useState([]);
  const [SeletedRole, setSeletedRole] = useState("");
  const [SelectedMasterenabler, setSelectedMasterenabler] = useState("");
  const [Schoollist, setSchoollist] = useState([]);
  const [SelectedSchool, setSelectedSchool] = useState({
    school_name: "",
    school_id: "",
  });
  const [SelectedSchoollist, setSelectedSchoollist] = useState([]);
  const [Schooladmin_privileges, setSchooladmin_privileges] = useState([]);

  const [SchoolPrincipalList, setSchoolPrincipalList] = useState([
    {
      id: "1",
      name: "one",
    },
    {
      id: "2",
      name: "two one",
    },
    {
      id: "3",
      name: "three one",
    },
    {
      id: "4",
      name: "four one",
    },
  ]);
  const [SelectedSchoolPricipal, setSelectedSchoolPricipal] = useState([
    {
      id: "3",
      name: "three one",
    },
    {
      id: "4",
      name: "four one",
    },
  ]);
  const { id } = useParams();

  // TIME SLOTBOOKING
  const [is_everyDay, setis_everyDay] = useState(false);
  const [Selected_currentDay, setSelected_currentDay] = useState("");
  const [Currentbooking_slot, setCurrentbooking_slot] = useState([]);
  const [SelectedDays, setSelectedDays] = useState([]);
  const [Start_and_end_time, setStart_and_end_time] = useState({
    start_time: "",
    end_time: "",
  });
  const [Slots, setSlots] = useState([
    {
      day: " ",
      slot_timings: [],
    },
    {
      day: 1,
      slot_timings: [],
    },
    {
      day: 2,
      slot_timings: [],
    },
    {
      day: 3,
      slot_timings: [],
    },
    {
      day: 4,
      slot_timings: [],
    },
    {
      day: 5,
      slot_timings: [],
    },
    {
      day: 6,
      slot_timings: [],
    },
    {
      day: 7,
      slot_timings: [],
    },
  ]);

  const RemoveLanguage = (id) => {
    const RemoveLanguageArray = LanguageData.filter((item, index) => {
      return id != index;
    });

    setLanguageData(RemoveLanguageArray);
  };

  const RemoveAward_input = (keyvalue) => {
    if (Object.keys(Award_Input_count)?.length > 1) {
      let Duplicate = { ...Award_Input_count };

      delete Duplicate[keyvalue];

      setAward_Input_count(Duplicate);

      console.log("====================================");
      console.log(Award_Input_count);
      console.log("====================================");
    }
  };
  const RemoveHobbie_input = (keyvalue) => {
    if (Object.keys(Hobbies_Input_count)?.length > 1) {
      let Duplicate = { ...Hobbies_Input_count };

      delete Duplicate[keyvalue];

      setHobbies_Input_count(Duplicate);

      console.log("====================================");
      console.log(Hobbies_Input_count);
      console.log("====================================");
    }
  };
  const RemovePassion_input = (keyvalue) => {
    if (Object.keys(Passion_Input_count)?.length > 1) {
      let Duplicate = { ...Passion_Input_count };

      delete Duplicate[keyvalue];

      setPassion_Input_count(Duplicate);

      console.log("====================================");
      console.log(Passion_Input_count);
      console.log("====================================");
    }
  };

  // SLOT BOOKING FUNCTIONS
  const Weekday_Toogle = (checked, value) => {
    if (checked) {
      setCurrentbooking_slot([]);
      let Current_ = [...SelectedDays];
      Current_.push(value);
      setSelectedDays(Current_);
      setSelected_currentDay(value);
    } else {
      let NewArray = [...Slots];
      NewArray[value].slot_timings = [];
      setSlots(NewArray);
      const RemoveSelectedDays = SelectedDays?.filter((item) => {
        return value != item;
      });
      setSelectedDays(RemoveSelectedDays);
      setSelected_currentDay("");
    }
  };

  const Add_time = () => {
    console.log("====================================");
    console.log(Start_and_end_time);
    console.log("====================================");

    if (
      Start_and_end_time?.start_time == "" ||
      Start_and_end_time.end_time == ""
    ) {
      toast.warn("Kindly select the time");
      toast.clearWaitingQueue();
    } else {
      if (Selected_currentDay == "") {
        toast.warn("Days not selected");
        toast.clearWaitingQueue();
      } else {
        let SlotArray = [];

        SlotArray.push(Start_and_end_time);
        // setCurrentbooking_slot(SlotArray);
        if (Selected_currentDay == "everyday") {
          let SlotUpdate = [];
          SlotUpdate = [...Slots];
          SlotUpdate[0].slot_timings = [...Slots[0].slot_timings, ...SlotArray];
          setis_everyDay(true);
          setSlots(SlotUpdate);
        } else {
          let SlotUpdate = [];
          SlotUpdate = [...Slots];
          SlotUpdate[Selected_currentDay].slot_timings = [
            ...Slots[Selected_currentDay].slot_timings,
            ...SlotArray,
          ];
          setSlots(SlotUpdate);
        }

        setStart_and_end_time({
          start_time: "",
          end_time: "",
        });
      }
    }
  };

  const RemoveSlot = (id) => {
    if (Selected_currentDay == "everyday") {
      let RemoveSlots = [...Slots[0].slot_timings];
      let UpdateAllSlots = [...Slots];
      RemoveSlots.splice(id, 1);
      UpdateAllSlots[0].slot_timings = RemoveSlots;

      setSlots(UpdateAllSlots);
    } else {
      let RemoveSlots = [...Slots[Selected_currentDay].slot_timings];
      let UpdateAllSlots = [...Slots];
      RemoveSlots.splice(id, 1);
      UpdateAllSlots[Selected_currentDay].slot_timings = RemoveSlots;

      setSlots(UpdateAllSlots);
      // setSlots(SlotUpdate);
    }
  };
  const ApplyAllSlots = () => {
    console.log("====================================");
    console.log(SelectedDays);
    console.log("====================================");
    // if(SelectedDays == '')
    let NewArray = [...Slots];
    for (let i = 0; i < SelectedDays.length; i++) {
      NewArray[SelectedDays[i]].slot_timings =
        Slots[Selected_currentDay]?.slot_timings;
    }
    setSlots(NewArray);
    toast.success("Applyed for Selected days");
    toast.clearWaitingQueue();
    console.log("====================================");
    console.log(Slots);
    console.log("====================================");
  };
  // SLOT BOOKING END

  const Get_Edit_enabler_Data = async () => {
    let data = {
      enabler_id: id,
    };
    setLoading(true);
    await fetch(Path + "/manage_enabler_edit_view_enabler_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        console.log(obj.body);

        setGeneralDetails_Data({
          enabler_name: obj.body?.users_firstname || "",
          enabler_mobile_number: obj.body?.users_mobile || "",
          email: obj.body?.users_email || "",
          qualification: obj.body?.qualification || "",
          specialisation: obj.body?.specialisation || "",
          dob: obj.body?.dob || "",
          experience: obj.body?.experience || "",
        });

        if (obj.body?.enabler_languages?.length > 0) {
          setLanguageData(obj.body?.enabler_languages);
        } else {
          setLanguageData([
            {
              lang: "",
              read: "",
              speak: "",
              write: "",
            },
          ]);
        }
        setProfileImage(obj.body?.profile_img);
        setSignatureImage(obj.body?.user_signature);

        // ABOUT ENABLER
        // award
        let Award_obj = {};
        let Award_array = obj.body?.awards_certificates?.split(",");
        if (Award_array?.length > 0) {
          for (let i = 0; i < Award_array.length; i++) {
            Award_obj[i] = Award_array[i];
          }
          setAward_Input_count(Award_obj);
        } else {
          setAward_Input_count({
            0: "",
          });
        }
        // HObbies
        let hobbies_obj = {};
        let hobbies_array = obj.body?.hobbies?.split(",");
        if (hobbies_array?.length > 0) {
          for (let i = 0; i < hobbies_array.length; i++) {
            hobbies_obj[i] = hobbies_array[i];
          }
          setHobbies_Input_count(hobbies_obj);
        } else {
          setHobbies_Input_count({
            0: "",
          });
        }
        // Passion
        let life_passion_obj = {};
        let life_passion_array = obj.body?.life_passion?.split(",");
        if (life_passion_array?.length > 0) {
          for (let i = 0; i < life_passion_array.length; i++) {
            life_passion_obj[i] = life_passion_array[i];
          }
          setPassion_Input_count(life_passion_obj);
        } else {
          setPassion_Input_count({
            0: "",
          });
        }
        setAboutFav(obj.body?.fav_thing_in_career);
        setAboutAccom(obj.body?.proudest_accomplishment);
        setRCIno(obj.body?.rci_no);
        setRCIfile(obj.body?.rci_doc);
        // ABOUT END

        // ROLE
        if (obj.body?.enabler_role == "School principal") {
          setSelectedSchoollist(obj.body?.assigned_school);
        }
        if (obj.body?.enabler_role == "School admin") {
          setSchooladmin_privileges(obj.body?.privileges);
          setSelectedSchoollist(obj.body?.assigned_school);
        } else {
          setSelectedPrivileges(obj.body?.privileges);
        }
        setSeletedRole(obj.body?.enabler_role);
        setSelectedMasterenabler(obj.body?.master_enabler_id);

        // SLOTS
        if (obj.body?.enabler_slot_timings[7]?.slot_timings?.length > 0) {
          setis_everyDay(true);
        }
        setSlots([
          obj.body?.enabler_slot_timings[7],
          obj.body?.enabler_slot_timings[0],
          obj.body?.enabler_slot_timings[1],
          obj.body?.enabler_slot_timings[2],
          obj.body?.enabler_slot_timings[3],
          obj.body?.enabler_slot_timings[4],
          obj.body?.enabler_slot_timings[5],
          obj.body?.enabler_slot_timings[6],
        ]);
        let duplicate_selected = [];
        for (let i = 0; i < 7; i++) {
          if (obj.body?.enabler_slot_timings[i]?.slot_timings?.length > 0) {
            duplicate_selected.push(obj.body?.enabler_slot_timings[i].day);
          }
        }
        setSelectedDays(duplicate_selected);
        if (obj.body?.enabler_slot_timings[7]?.slot_timings?.length > 0) {
          setSelected_currentDay("everyday");
        } else if (
          obj.body?.enabler_slot_timings[0]?.slot_timings?.length > 0
        ) {
          setSelected_currentDay(1);
        } else if (
          obj.body?.enabler_slot_timings[1]?.slot_timings?.length > 0
        ) {
          setSelected_currentDay(2);
        } else if (
          obj.body?.enabler_slot_timings[2]?.slot_timings?.length > 0
        ) {
          setSelected_currentDay(3);
        } else if (
          obj.body?.enabler_slot_timings[3]?.slot_timings?.length > 0
        ) {
          setSelected_currentDay(4);
        } else if (
          obj.body?.enabler_slot_timings[4]?.slot_timings?.length > 0
        ) {
          setSelected_currentDay(5);
        } else if (
          obj.body?.enabler_slot_timings[5]?.slot_timings?.length > 0
        ) {
          setSelected_currentDay(6);
        } else if (
          obj.body?.enabler_slot_timings[6]?.slot_timings?.length > 0
        ) {
          setSelected_currentDay(7);
        }

        // general
        setEnablerID(id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  async function UploadProfileImage(userid) {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("loggedToken")
    );

    var formdata = new FormData();
    formdata.append("enabler_id", userid);
    formdata.append(
      "image_name",
      `${id}.${
        ProfileImage?.name?.split(".")[
          ProfileImage?.name?.split(".")?.length - 1
        ]
      }`
    );
    formdata.append("uploadfiles", ProfileImage);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(Path + "/upload_profile_img", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("====================================");
        console.log(result);
        console.log("====================================");
        // toast.success("File added successfully");
        // toast.clearWaitingQueue();
        if (SignatureImage?.name) {
          Uploadsignature(userid);
        } else {
          toast.success("Added successfully");
          toast.clearWaitingQueue();
          setActiveStep(2);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to upload");
        toast.clearWaitingQueue();
      });
  }
  async function UploadRCI_file() {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("loggedToken")
    );

    var formdata = new FormData();
    formdata.append("enabler_id", id == "create" ? EnablerID : id);
    formdata.append(
      "image_name",
      `${id == "create" ? EnablerID : id}_RCI_${Math.round(
        +new Date() / 1000
      )}.${RCIfile?.name?.split(".")?.pop()}`
    );
    formdata.append(
      "images",
      RCIfile,
      `${id == "create" ? EnablerID : id}_RCI_${Math.round(
        +new Date() / 1000
      )}.${RCIfile?.name?.split(".")?.pop()}`
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(Path + "/rci_upload", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("====================================");
        console.log(result);
        console.log("====================================");
        setActiveStep(3);
        setLoading(false);
        toast.success("Added successfully");
        toast.clearWaitingQueue();
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to upload");
        toast.clearWaitingQueue();
      });
  }
  async function Uploadsignature(user_id) {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("loggedToken")
    );

    var formdata = new FormData();
    formdata.append("enabler_id", user_id);
    formdata.append(
      "image_name",
      `${id == "create" ? EnablerID : id}_RCI_${Math.round(
        +new Date() / 1000
      )}.${SignatureImage?.name?.split(".")?.pop()}`
    );
    formdata.append(
      "images",
      SignatureImage,
      `${id == "create" ? EnablerID : id}_RCI_${Math.round(
        +new Date() / 1000
      )}.${SignatureImage?.name?.split(".")?.pop()}`
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(Path + "/signature_upload", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("====================================");
        console.log(result);
        console.log("====================================");
        setLoading(false);
        toast.success("Added successfully");
        toast.clearWaitingQueue();
        setActiveStep(2);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to upload");
        toast.clearWaitingQueue();
      });
  }

  async function ADD_Enabler() {
    console.log(LanguageData);

    if (
      GeneralDetails_Data.enabler_name == "" ||
      GeneralDetails_Data.enabler_name == null ||
      GeneralDetails_Data.dob == "" ||
      GeneralDetails_Data.dob == null ||
      GeneralDetails_Data.email == "" ||
      GeneralDetails_Data.email == null ||
      GeneralDetails_Data.enabler_mobile_number == "" ||
      GeneralDetails_Data.enabler_mobile_number == null ||
      GeneralDetails_Data.experience == "" ||
      GeneralDetails_Data.experience == null ||
      GeneralDetails_Data?.qualification == "" ||
      GeneralDetails_Data.qualification == null
    ) {
      toast.warn("Kindly fill the fields");
      toast.clearWaitingQueue();
      return;
    } else {
      if (GeneralDetails_Data.enabler_mobile_number?.length != 10) {
        toast.warn("Mobile number invalid");
        toast.clearWaitingQueue();
        return;
      } else if (!GeneralDetails_Data.email.includes("@")) {
        toast.warn("Email id invalid");
        toast.clearWaitingQueue();
        return;
      } else {
        for (let i = 0; i < LanguageData.length; i++) {
          if (
            LanguageData[i].lang == "" ||
            LanguageData[i].read == "" ||
            LanguageData[i].speak == "" ||
            LanguageData[i].write == ""
          ) {
            toast.warn("Kindly fill the language fields");
            toast.clearWaitingQueue();
            return;
          }
        }

        let data = {
          enabler_name: GeneralDetails_Data?.enabler_name,
          enabler_mobile_number: GeneralDetails_Data?.enabler_mobile_number,
          email: GeneralDetails_Data?.email,
          qualification: GeneralDetails_Data?.qualification,
          specialisation: GeneralDetails_Data?.specialisation,
          dob: GeneralDetails_Data?.dob,
          experience: GeneralDetails_Data?.experience,
          languages_known: LanguageData,
          enabler_id: id == "create" ? EnablerID : id,
        };
        setLoading(true);
        await fetch(Path + "/add_enabler", {
          method: id == "create" ? (EnablerID == "" ? "POST" : "PUT") : "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("loggedToken"),
          },
          body: JSON.stringify(data),
        })
          .then((r) =>
            r.json().then((data) => ({ status: r.status, body: data }))
          )
          .then((obj) => {
            console.log(obj.body);
            if (obj.body?.error) {
              setLoading(false);
              toast.error("Enabler already added");
              toast.clearWaitingQueue();
            } else {
              setEnablerID(obj.body?.user_id);

              if (ProfileImage?.name) {
                UploadProfileImage(obj.body?.user_id);
              } else {
                if (SignatureImage?.name) {
                  Uploadsignature(obj.body?.user_id);
                } else {
                  setLoading(false);
                  toast.success("Added successfully");
                  toast.clearWaitingQueue();
                  setActiveStep(2);
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error("Something went wrong");
            toast.clearWaitingQueue();
          });
      }
    }
  }
  async function ADD_About() {
    let Award_array = [];
    let Hobbie_array = [];
    let Passion_array = [];
    for (let i = 0; i < Object.keys(Award_Input_count).length; i++) {
      if (Award_Input_count[Object.keys(Award_Input_count)[i]] != "") {
        Award_array.push(Award_Input_count[Object.keys(Award_Input_count)[i]]);
      }
    }

    for (let i = 0; i < Object.keys(Hobbies_Input_count).length; i++) {
      if (Hobbies_Input_count[Object.keys(Hobbies_Input_count)[i]] != "") {
        Hobbie_array.push(
          Hobbies_Input_count[Object.keys(Hobbies_Input_count)[i]]
        );
      }
    }

    for (let i = 0; i < Object.keys(Passion_Input_count).length; i++) {
      if (Passion_Input_count[Object.keys(Passion_Input_count)[i]] != "") {
        Passion_array.push(
          Passion_Input_count[Object.keys(Passion_Input_count)[i]]
        );
      }
    }
    let data = {
      enabler_id: id == "create" ? EnablerID : id,

      awards_certificates: Award_array?.join(),

      fav_thing_in_career: AboutFav,

      hobbies: Hobbie_array?.join(),

      proudest_accomplishment: AboutAccom,

      life_passion: Passion_array?.join(),
      rci_no: RCIno,
    };
    setLoading(true);
    await fetch(Path + "/update_about", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        console.log(obj.body);
        if (RCIfile?.name) {
          UploadRCI_file();
        } else {
          setActiveStep(3);
          setLoading(false);
          toast.success("Added successfully");
          toast.clearWaitingQueue();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  const PrivilegesHandle = (id) => {
    if (SelectedPrivileges?.includes(id)) {
      const RemovePreviliges = SelectedPrivileges?.filter((item) => {
        return id != item;
      });

      setSelectedPrivileges(RemovePreviliges);
    } else {
      setSelectedPrivileges((prev) => {
        return [...prev, id];
      });
    }
  };

  // GET SCHOOL LIST
  const SchoolnameHandler = async (e) => {
    setSchoollist([]);
    setSelectedSchool({
      school_name: e.target.value,
      school_id: "",
    });
    let Data = {
      search: e.target.value,
    };
    await fetch(Path + `/school_list?page_no=1`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setSchoollist(obj.body?.school_details);
      })
      .catch((err) => {
        toast.error("something went wrong");
        toast.clearWaitingQueue();
      });
  };
  // ADD SCHOOLS
  function AddSchool_Handler() {
    let DuplicateArray = SelectedSchoollist;
    if (SelectedSchool?.school_id != "") {
      let Is_added = false;
      DuplicateArray?.forEach((item, index) => {
        if (item?.school_id == SelectedSchool?.school_id) {
          toast.warn("School already added");
          toast.clearWaitingQueue();
          Is_added = true;
          return;
        }
      });
      if (!Is_added) {
        DuplicateArray.push(SelectedSchool);
        setSelectedSchoollist(() => [...DuplicateArray]);
        SchoolnameHandler({ target: { value: "" } });
      }
    }
  }
  // REMOVE SCHOOL
  function Remove_SelectedSchool_Handler(index) {
    let DuplicateArray = SelectedSchoollist;
    const RemoveArray = DuplicateArray?.filter((item, id) => {
      return index != id;
    });
    setSelectedSchoollist(() => [...RemoveArray]);
  }
  // SCHOOL ADMIN PRIVILEGES
  function School_PrivilegesHandle(id) {
    let DuplicateArray = Schooladmin_privileges;
    if (DuplicateArray?.includes(id)) {
      const RemoveArray = DuplicateArray.filter((item, index) => {
        return item != id;
      });
      DuplicateArray = RemoveArray;
    } else {
      DuplicateArray.push(id);
    }

    setSchooladmin_privileges(() => [...DuplicateArray]);
  }
  async function ADD_Role() {
    if (SeletedRole == "") {
      toast.error("Kindly select the role");
      toast.clearWaitingQueue();
      return false;
    }
    if (UserRole?.role != "schooladmin") {
      if (SeletedRole == "enabler" || SeletedRole == "master") {
        if (SelectedMasterenabler == "" || SelectedMasterenabler == null) {
          toast.error("Kindly select one master enabler");
          toast.clearWaitingQueue();
          return false;
        }
      }
    }

    if (
      SeletedRole != "School admin" &&
      SeletedRole != "External approver" &&
      SeletedRole != "School co-ordinater" &&
      SeletedRole != "School principal" &&
      SeletedRole != "School coordinator" &&
      SeletedRole != "External creator"
    ) {
      if (SelectedPrivileges?.length == 0) {
        toast.error("Kindly select any privileges");
        toast.clearWaitingQueue();
        return false;
      }
    }

    let SchoolList_Id = [];

    SelectedSchoollist?.forEach((item) => {
      SchoolList_Id.push(item?.school_id);
    });
    let data = {
      enabler_id: id == "create" ? EnablerID : id,
      enabler_role: SeletedRole,
      priveledge:
        SeletedRole == "School admin"
          ? Schooladmin_privileges
          : SelectedPrivileges,
      school_list:
        SeletedRole == "School admin" || SeletedRole == "School principal"
          ? SchoolList_Id
          : [],
      master_enabler:
        SeletedRole == "enabler" || SeletedRole == "master"
          ? SelectedMasterenabler
          : "",
    };

    setLoading(true);
    await fetch(Path + "/assign_role", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        toast.success("Added successfully");
        setLoading(false);
        toast.clearWaitingQueue();
        if (
          SeletedRole == "School co-ordinater" ||
          SeletedRole == "School enabler"
        ) {
          History(`/viewenabler/${id == "create" ? EnablerID : id}`);
        } else {
          setActiveStep(4);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }
  async function Get_master_enabler_list() {
    let data = {};
    setLoading(true);
    await fetch(Path + "/super_enabler_list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        console.log(obj.body);
        setLoading(false);
        setMasterEnablerList(obj.body);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  }

  const AddSlots = async () => {
    console.log("====================================");
    console.log(Slots);
    console.log("====================================");

    if (
      Slots[0].slot_timings?.length == 0 &&
      Slots[1].slot_timings?.length == 0 &&
      Slots[2].slot_timings?.length == 0 &&
      Slots[3].slot_timings?.length == 0 &&
      Slots[4].slot_timings?.length == 0 &&
      Slots[5].slot_timings?.length == 0 &&
      Slots[6].slot_timings?.length == 0 &&
      Slots[7].slot_timings?.length == 0
    ) {
      toast.warn("Kindly fill any slot");
      toast.clearWaitingQueue();
      return;
    }
    let SendArray = [...Slots];
    let RemoveFirstone = SendArray.shift();
    console.log(RemoveFirstone);
    let data = {
      enabler_id: id == "create" ? EnablerID : id,
      slots: is_everyDay ? [Slots[0]] : SendArray,
    };

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    setLoading(true);
    await fetch(Path + "/create_update_slot", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        console.log(obj.body);
        setLoading(false);
        toast.success("Added successfully");
        toast.clearWaitingQueue();
        History(`/viewenabler/${id == "create" ? EnablerID : id}`);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error("Something went wrong");
        toast.clearWaitingQueue();
      });
  };

  useEffect(() => {
    setBreadcrumItem([
      { name: "Manage enabler", link: "/addrole/all/list/0" },
      {
        name: id == "create" ? "Add role" : "Edit role",
        link: `/addenabler/${id}`,
      },
    ]);
    // setEnablerID(id);
    Get_master_enabler_list();
    SchoolnameHandler({ target: { value: "" } });
  }, []);

  useLayoutEffect(() => {
    if (id != "create") {
      // setEnablerID(id);
      Get_Edit_enabler_Data();
    }
  }, []);

  function RenderView() {
    return (
      <div className="add_enabler_container">
        <Header
          setActiveStep={setActiveStep}
          ActiveStep={ActiveStep}
          SeletedRole={SeletedRole}
        />
        <div className="add_enabler_steps_container">
          <Generealdetails
            ActiveStep={ActiveStep}
            GeneralDetails_Data={GeneralDetails_Data}
            setGeneralDetails_Data={setGeneralDetails_Data}
            LanguageData={LanguageData}
            setLanguageData={setLanguageData}
            RemoveLanguage={RemoveLanguage}
            ProfileImage={ProfileImage}
            setProfileImage={setProfileImage}
            SignatureImage={SignatureImage}
            setSignatureImage={setSignatureImage}
            ADD_Enabler={ADD_Enabler}
          />

          <Aboutenabler
            ActiveStep={ActiveStep}
            Award_Input_count={Award_Input_count}
            RemoveAward_input={RemoveAward_input}
            setAward_Input_count={setAward_Input_count}
            AboutFav={AboutFav}
            setAboutFav={setAboutFav}
            RCIno={RCIno}
            setRCIno={setRCIno}
            setRCIfile={setRCIfile}
            RCIfile={RCIfile}
            Hobbies_Input_count={Hobbies_Input_count}
            RemoveHobbie_input={RemoveHobbie_input}
            setHobbies_Input_count={setHobbies_Input_count}
            AboutAccom={AboutAccom}
            setAboutAccom={setAboutAccom}
            Passion_Input_count={Passion_Input_count}
            RemovePassion_input={RemovePassion_input}
            setPassion_Input_count={setPassion_Input_count}
            ADD_About={ADD_About}
          />

          <Assignrole
            ActiveStep={ActiveStep}
            setSeletedRole={setSeletedRole}
            setSelectedPrivileges={setSelectedPrivileges}
            SeletedRole={SeletedRole}
            setSelectedMasterenabler={setSelectedMasterenabler}
            SelectedMasterenabler={SelectedMasterenabler}
            MasterEnablerList={MasterEnablerList}
            ROLES={ROLES}
            SelectedPrivileges={SelectedPrivileges}
            PrivilegesHandle={PrivilegesHandle}
            ADD_Role={ADD_Role}
            Schoollist={Schoollist}
            SelectedSchool={SelectedSchool}
            setSelectedSchool={setSelectedSchool}
            AddSchool_Handler={AddSchool_Handler}
            SelectedSchoollist={SelectedSchoollist}
            Remove_SelectedSchool_Handler={Remove_SelectedSchool_Handler}
            SchoolnameHandler={SchoolnameHandler}
            School_privileges={School_privileges}
            Schooladmin_privileges={Schooladmin_privileges}
            School_PrivilegesHandle={School_PrivilegesHandle}
            // PRINCIPLE
            SchoolPrincipalList={SchoolPrincipalList}
            SelectedSchoolPricipal={SelectedSchoolPricipal}
            setSelectedSchoolPricipal={setSelectedSchoolPricipal}
          />

          <Enablerschedule
            ActiveStep={ActiveStep}
            Selected_currentDay={Selected_currentDay}
            setis_everyDay={setis_everyDay}
            setSelected_currentDay={setSelected_currentDay}
            setSelectedDays={setSelectedDays}
            SelectedDays={SelectedDays}
            Slots={Slots}
            setCurrentbooking_slot={setCurrentbooking_slot}
            is_everyDay={is_everyDay}
            Weekday_Toogle={Weekday_Toogle}
            Start_and_end_time={Start_and_end_time}
            setStart_and_end_time={setStart_and_end_time}
            Add_time={Add_time}
            tConv24={tConv24}
            RemoveSlot={RemoveSlot}
            ApplyAllSlots={ApplyAllSlots}
            AddSlots={AddSlots}
          />
        </div>
        {/* LOADING SCREEN */}
        {Loading && (
          <div className="add_enabler_loading_screen">
            <div className="circle_holder loadercircle">
              <div className="inner_circle loadercircle">
                <div className="most_inner_circle loadercircle">
                  <div className="most_inner_most_circle loadercircle"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  if (id != "create") {
    if (EnablerID != "") {
      return RenderView();
    }
  } else {
    return RenderView();
  }
};

export default Addenabler;
