import { TextField } from "@mui/material";
import React, { useState } from "react";
import "./loginPage.css";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";
import { URL } from "../../DROPDOWNDATA";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

export default function LoginPage({ setIsLogged }) {
  const Histrory = useNavigate();
  localStorage.clear();
  const [Is_loading, setIs_loading] = useState(false);
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    showNewpassword: false,
    showConfirmPassword: false,
  });

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
    otp: "",
  });

  const [InputState, setInputState] = useState("signin");
  const [Confirmpassword, setConfirmpassword] = useState({
    newpassword: "",
    confirmpassword: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });

    setLoginDetails({ ...loginDetails, password: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function checkLoginCred() {
    console.log("loginDetails");
    console.log(loginDetails);

    if (loginDetails.email == "") {
      toast.success("Please Enter Email Address");
      toast.clearWaitingQueue();
      return;
    } else if (loginDetails.password == "") {
      toast.success("Please Enter Password");
      toast.clearWaitingQueue();
      return;
    }

    console.log("check logining DB");
    let item = {
      email: loginDetails.email,
      password: loginDetails.password,
    };

    console.log(item);
    await fetch(URL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        switch (obj.status) {
          case 201:
            toast.success("Login Successfull");
            toast.clearWaitingQueue();

            localStorage.setItem("loggedToken", obj.body.token);
            setIsLogged(true);
            Histrory("/");
            window.location.reload();
            break;
          case 400:
            toast.warn("Wrong username or password");
            toast.clearWaitingQueue();

            console.log("wrong password");
            break;
        }
      });
  }

  //   GET OTP
  const GetOtpHandler = async () => {
    if (loginDetails?.email != '') {
      setIs_loading(true)
      await fetch(URL + "/forget_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          "email": loginDetails?.email
        }),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          switch (obj.status) {
            case 200:
              setInputState("verifyotp");
              break;
            case 400:
              toast.warn("Invalid user !Try again");
              toast.clearWaitingQueue();
              break;
          }
          setIs_loading(false)
        }).catch((err) => {
          setIs_loading(false)
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        })
    } else {
      toast.warn("Kindly add email");
      toast.clearWaitingQueue();
    }


  };
  // VERIFY OTP
  const VerifyOtpHandler = async () => {
    if (loginDetails?.otp != '') {
      setIs_loading(true)
      await fetch(URL + "/validate_otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          "email": loginDetails?.email,
          "otp": loginDetails?.otp
        }),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          switch (obj.status) {
            case 200:
              setInputState("newpassword");
              setLoginDetails((prev) => ({ ...prev, otp: "" }))
              break;
            case 400:
              toast.warn("Invalid Otp");
              toast.clearWaitingQueue();
              break;
            default:
              toast.warn("Invalid Otp");
              toast.clearWaitingQueue();
              break;
          }
          setIs_loading(false)
        }).catch((err) => {
          setIs_loading(false)
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        })
    } else {
      toast.warn("Kindly enter Otp");
      toast.clearWaitingQueue();
    }


  };

  //   CreatenewPassword
  const CreatenewPassword = async () => {
    if (Confirmpassword?.newpassword == Confirmpassword?.confirmpassword) {
      setIs_loading(true)
      await fetch(URL + "/change_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          "email": loginDetails?.email,
          "password": Confirmpassword?.newpassword
        }),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          switch (obj.status) {
            case 200:
              setConfirmpassword((prev) => ({ ...prev, confirmpassword: '', newpassword: '' }))
              toast.success("Password changed successfully");
              toast.clearWaitingQueue();
              setInputState("signin");
              break;
            case 400:
              toast.warn("Something went wrong");
              toast.clearWaitingQueue();
              break;
            default:
              toast.warn("Something went wrong");
              toast.clearWaitingQueue();
              break;
          }
          setIs_loading(false)
        }).catch((err) => {
          setIs_loading(false)
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        })
    } else {
      toast.warn("Pawwsord doesn't match");
      toast.clearWaitingQueue();
    }
  };
  return (
    <div className="login_container">
      <div className="login_holder">
        {/* SIGN IN  */}
        {InputState == "signin" && (
          <div className="login_block active">
            <p className="welcomeTxt">Welcome !</p>
            <span>Sign in to your account</span>
            <TextField
              className="textfield"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={loginDetails?.email}
              onChange={(e) => {
                setLoginDetails({ ...loginDetails, email: e.target.value });
              }}
            />
            <FormControl className="passwordfield" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            <button
              className="forget_password"
              onClick={() => {
                setInputState("getotp");
              }}
            >
              Forgot Password?
            </button>

            <button className="btn btn2" onClick={checkLoginCred}>
              Sign In
            </button>
          </div>
        )}
        {/* GET OTP */}
        {InputState == "getotp" && (
          <div className="login_block active login-get-otp">
            <p className="welcomeTxt">Welcome !</p>
            <span>Verify Email address</span>
            <TextField
              className="textfield"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={loginDetails?.email}
              onChange={(e) => {
                setLoginDetails({ ...loginDetails, email: e.target.value });
              }}
            />
            <button className="btn btn2" onClick={GetOtpHandler}>
              Get OTP
            </button>

            <button
              className="forget_password"
              onClick={() => {
                setInputState("signin");
              }}
            >
              Cancel
            </button>
          </div>
        )}

        {InputState == "verifyotp" && (
          <div className="login_block active login-otp-verify">
            <p className="welcomeTxt">Welcome !</p>
            <span>Verify OTP</span>
            <TextField
              className="textfield"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={loginDetails?.email}
              disabled
              onChange={(e) => {
                setLoginDetails({ ...loginDetails, email: e.target.value });
              }}
            />
            <TextField
              className="textfield"
              id="outlined-basic"
              label="OTP"
              variant="outlined"
              value={loginDetails?.otp}
              placeholder="Enter OTP"
              type="number"
              onChange={(e) => {
                setLoginDetails({
                  ...loginDetails,
                  otp: e.target.value?.slice(0, 4),
                });
              }}
            />
            <button className="btn btn2" onClick={VerifyOtpHandler}>
              Verify
            </button>

            <button
              className="forget_password"
              onClick={() => {
                setInputState("signin");
              }}
            >
              Cancel
            </button>
          </div>
        )}

        {InputState == "newpassword" && (
          <div className="login_block active login-newpassword">
            <p className="welcomeTxt">Welcome !</p>
            <span>Enter new password</span>
            <FormControl className="passwordfield" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showNewpassword ? "text" : "password"}
                value={Confirmpassword.newpassword}
                onChange={(e) => {
                  setConfirmpassword((prev) => ({
                    ...prev,
                    newpassword: e.target.value,
                  }));
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setValues((prev) => ({
                          ...prev,
                          showNewpassword: !values.showNewpassword,
                        }));
                      }}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showNewpassword ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <FormControl className="passwordfield" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showConfirmPassword ? "text" : "password"}
                value={Confirmpassword.confirmpassword}
                onChange={(e) => {
                  setConfirmpassword((prev) => ({
                    ...prev,
                    confirmpassword: e.target.value,
                  }));
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setValues((prev) => ({
                          ...prev,
                          showConfirmPassword: !values.showConfirmPassword,
                        }));
                      }}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showConfirmPassword ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <button className="btn btn2" onClick={CreatenewPassword}>
              Create password
            </button>

            <button
              className="forget_password"
              onClick={() => {
                setValues((prev) => ({
                  ...prev,
                  showConfirmPassword: false,
                  showNewpassword: false,
                }));
                setInputState("signin");
                setConfirmpassword((prev) => ({
                  ...prev,
                  newpassword: "",
                  confirmpassword: "",
                }));
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      {Is_loading && <Loader />}
    </div>
  );
}
