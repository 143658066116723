import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { URL } from "../../DROPDOWNDATA";
import "./Manageschool.css";
import Select from "react-select";
import Detailview from "./Detailview/Detailview";
import { NavLink, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import useGet from "../../Hooks/useGet";
import usePost from "../../Hooks/usePost";
const Manageschool = ({ setBreadcrumItem, breadCrumbs_item }) => {
  const { user_id } = useParams();
  const UserRole = useSelector((store) => store.privilege);
  const [ManageSchool_tab, setManageSchool_tab] = useState(1);
  const [Search_input, setSearch_input] = useState("");
  const [TableData, setTableData] = useState([]);
  const [Table_data_total, setTable_data_total] = useState();
  const [page_no, setpage_no] = useState(1);
  const [IsLoader, setIsLoader] = useState(false);
  const [ShowEnabler_modal, setShowEnabler_modal] = useState(false);

  const [Enabler_name, setEnabler_name] = useState("");

  const [EnablerList_new, setEnablerList_new] = useState([]);
  const [Enabler_List1, setEnabler_List_one] = useState([]);

  const [Edit_add_school, setEdit_add_school] = useState({
    school_id: "",
    edit: false,
    school_name: "",
    school_address: "",
    school_spoc: "",
    school_mobile: "",
    City_name: "",
    Email: "",
    pincode: "",
    enabler: [],
    school_admin: [],
    school_coordinator: [],
    project: "",
    state: "",
    district: "",
    school_enrollment_type: "",
    total_amount: "",
    transaction_date: "",
    transaction_comment: "",
    transaction_reference: "",
  });
  const [Index, setIndex] = useState([]);
  const [SchoolIndex, setSchoolIndex] = useState([]);

  // SCHOOL ADMIN
  const [SchoolAdminList, setSchoolAdminList] = useState([]);
  const [Schoolcoordinator, setSchoolcoordinator] = useState([]);

  // GET LIST
  const ProjectGet = useGet(`${URL}/project_list`);
  const StateGet = usePost(`${URL}/state_list`);
  const DistrictPost = usePost(`${URL}/district_list`);

  async function Get_manage_school_table(value, pgno) {
    let Data = {
      search: value,
      user_id: user_id == "all" ? "" : atob(user_id),
    };
    await fetch(URL + `/school_list?page_no=${pgno}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(Data),
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        setTable_data_total(obj.body?.total_count);
        setTableData(obj.body?.school_details);
      })
      .catch((err) => {
        toast.error("something went wrong");
        toast.clearWaitingQueue();
      });
  }

  const Get_SchoolAdminList = async () => {
    setSchoolAdminList([]);
    await fetch(URL + `/school_admin_list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        obj.body?.forEach((item) => {
          setSchoolAdminList((prev) => [
            ...prev,
            { label: item?.enabler_name, value: item?.enabler_id },
          ]);
        });
      })
      .catch((err) => {
        toast.error("something went wrong");
        toast.clearWaitingQueue();
      });
  };
  const Get_school_coordinator_list = async () => {
    setSchoolcoordinator([]);
    await fetch(URL + `/school_coordinator_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
    })
      .then((r) =>
        r.json().then((data) => ({
          status: r.status,
          body: data,
        }))
      )
      .then((obj) => {
        obj.body?.forEach((item) => {
          setSchoolcoordinator((prev) => [
            ...prev,
            { label: item?.coordinator_name, value: item?.users_id },
          ]);
        });
      })
      .catch((err) => {
        toast.error("something went wrong");
        toast.clearWaitingQueue();
      });
  };
  async function getProgramme1() {
    await fetch(URL + "/enabler_search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        // console.log(obj.body);
        setEnabler_List_one(obj.body);
      });
  }
  const edit_school_btn = (data) => {
    setEdit_add_school({
      ...Edit_add_school,
      school_id: data?.school_id,
      edit: true,
      school_name: data?.school_name,
      school_address: data?.school_address_line1,
      school_spoc: data?.school_spoc_name,
      school_mobile: data?.school_spoc_mobile,
      City_name: data?.school_city,
      Email: data?.school_spoc_emailid,
      pincode: data?.school_pincode,
      enabler: data?.enabler_id?.split(","),
      school_admin: [],
      school_coordinator: [],
      state: data?.school_state,
      district: data?.school_district,
      project: data?.school_project,
      school_enrollment_type: data?.school_enrollment_type,
      total_amount: data?.total_amount,
      transaction_date: data?.transaction_date,
      transaction_comment: data?.transaction_comment,
      transaction_reference: data?.transaction_reference,
    });

    if (data?.school_state != "") {
      DistrictPost.Postcall({ state_id: data?.school_state });
    }

    data?.enabler_id?.split(",")?.forEach((item, index) => {
      // console.log(item);
      Enabler_List1?.forEach((item2, index2) => {
        if (item2.users_id == item) {
          setIndex((prev) => {
            return [...prev, index2];
          });
        }
      });
    });
    data?.school_admin?.split(",")?.forEach((item, index) => {
      // console.log(item);
      SchoolAdminList?.forEach((item2, index2) => {
        if (item2.value == item) {
          setSchoolIndex((prev) => {
            return [...prev, index2];
          });
        }
      });
    });
    data?.school_coordinator_id?.split(",")?.forEach((item, index) => {
      // console.log(item);
      Schoolcoordinator?.forEach((item2, index2) => {
        if (item2.value == item) {
          setSchoolIndex((prev) => {
            return [...prev, index2];
          });
        }
      });
    });

    setManageSchool_tab(2);
  };

  async function Add_Edit_school() {
    // console.log(Edit_add_school);
    if (
      Edit_add_school?.school_name == "" ||
      Edit_add_school?.school_spoc == "" ||
      Edit_add_school?.school_mobile == "" ||
      Edit_add_school?.school_address == "" ||
      Edit_add_school?.pincode == "" ||
      Edit_add_school?.Email == "" ||
      Edit_add_school?.City_name == ""
    ) {
      toast.error("Kindly fill the fields");
      toast.clearWaitingQueue();
      return;
    } else {
      if (!Edit_add_school?.school_mobile?.length == 10) {
        toast.error("Mobile no invalid");
        toast.clearWaitingQueue();
        return;
      }
      if (Number(Edit_add_school?.pincode) <= 0) {
        toast.error("Pincode invalid");
        toast.clearWaitingQueue();
        return;
      }
      if (Edit_add_school?.project == 1) {
        if (Edit_add_school?.state == "" || Edit_add_school?.district == "") {
          toast.error("Kindly select State and District");
          toast.clearWaitingQueue();
          return;
        }
      }
      if (Edit_add_school?.school_enrollment_type === "") {
        toast.error("Kindly select Enrollment type");
        toast.clearWaitingQueue();
        return;
      }
      if (Edit_add_school?.school_enrollment_type == 174) {
        if (
          Edit_add_school?.total_amount == "" ||
          Edit_add_school?.transaction_date == "" ||
          Edit_add_school?.transaction_reference == ""
        ) {
          toast.error("Kindly fill transaction details");
          toast.clearWaitingQueue();
          return;
        }
      }
      let Data = {
        school_name: Edit_add_school?.school_name,
        school_address_line: Edit_add_school?.school_address,
        school_city: Edit_add_school?.City_name,
        school_pincode: Edit_add_school?.pincode,
        school_spoc_name: Edit_add_school?.school_spoc,
        school_spoc_emailid: Edit_add_school?.Email,
        school_id: Edit_add_school?.school_id,
        school_spoc_mobile: Edit_add_school?.school_mobile,
        enablers: Edit_add_school?.enabler,
        school_admin: Edit_add_school?.school_admin,
        school_coordinator: Edit_add_school?.school_coordinator,
        school_state: Edit_add_school?.state,
        school_district: Edit_add_school?.district,
        school_project: Edit_add_school?.project,
        school_enrollment_type: Edit_add_school?.school_enrollment_type,
        total_amount: Edit_add_school?.total_amount,
        transaction_date: Edit_add_school?.transaction_date,
        transaction_comment: Edit_add_school?.transaction_comment,
        transaction_reference: Edit_add_school?.transaction_reference,
      };
      setIsLoader(true);
      await fetch(URL + `/add_school`, {
        method: Edit_add_school?.edit ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("loggedToken"),
        },
        body: JSON.stringify(Data),
      })
        .then((r) =>
          r.json().then((data) => ({
            status: r.status,
            body: data,
          }))
        )
        .then((obj) => {
          setIsLoader(false);
          if (obj.body?.message == "error") {
            toast.error("something went wrong");
            toast.clearWaitingQueue();
            return;
          }

          if (obj.body.message == "Please add atleast one senior enabler") {
            toast.error(obj.body.message);
            toast.clearWaitingQueue();
            return;
          }
          if (Edit_add_school?.edit) {
            toast.success("School updated successfully");
            toast.clearWaitingQueue();
          } else {
            toast.success("New School added successfully");
            toast.clearWaitingQueue();
          }
          setEdit_add_school({
            school_id: "",
            edit: false,
            school_name: "",
            school_address: "",
            school_spoc: "",
            school_mobile: "",
            City_name: "",
            Email: "",
            pincode: "",
            school_enrollment_type: "",
            enabler: [],
            school_admin: [],
            school_coordinator: [],
            total_amount: "",
            transaction_date: "",
            transaction_comment: "",
            transaction_reference: "",
          });
          setIndex([]);
          setSchoolIndex([]);
          setpage_no(1);
          Get_manage_school_table("", 1);
          setManageSchool_tab(1);
        })
        .catch((err) => {
          setIsLoader(false);
          toast.error("Something went wrong");
          toast.clearWaitingQueue();
        });
    }
  }

  const Download_report = (id) => {
    const link = document.createElement("a");
    link.href = `https://dlearners.in/donboscoschool_export_admin.php?school_id=${id}`;
    link.setAttribute(
      "download",
      `https://dlearners.in/donboscoschool_export_admin.php?school_id=${id}`
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  // PROJECT HANDLER
  const ProjectHandler = (e) => {
    setEdit_add_school((prev) => ({ ...prev, project: e.target.value }));
  };
  // STATE HANDLER
  const StateHandler = (e) => {
    setEdit_add_school((prev) => ({ ...prev, state: e.target.value }));
    if (e.target.value) DistrictPost.Postcall({ state_id: e.target.value });
  };
  // DISTRICT HANDLER
  const DistrictHandler = (e) => {
    setEdit_add_school((prev) => ({ ...prev, district: e.target.value }));
  };
  // ENROLLTYPE
  const EnrollTypeHandler = (e) => {
    setEdit_add_school((prev) => ({
      ...prev,
      school_enrollment_type: e.target.value,
    }));
  };
  // GET STATE
  useEffect(() => {
    if (StateGet.Postdata != null) {
    } else {
      StateGet.Postcall();
    }
  }, [StateGet.Postdata]);
  // GET PROJECT
  useEffect(() => {
    if (ProjectGet.GetData != null) {
    } else {
      ProjectGet.Getcall();
    }
  }, [ProjectGet.GetData]);
  // POST DISTRICT
  useEffect(() => {
    if (DistrictPost.Postdata != null) {
    } else {
    }
  }, [DistrictPost.Postdata]);

  useEffect(() => {
    Get_manage_school_table("", 1);
  }, [user_id]);

  useEffect(() => {
    setBreadcrumItem([
      { name: "Manage school", link: `/manageschool/${user_id}` },
    ]);
    Get_manage_school_table("", 1);

    Enabler_List1?.forEach((item, index) => {
      setEnablerList_new((prev) => {
        return [
          ...prev,
          {
            label: `${item?.users_firstname} - ${item?.users_enabler_classification}`,
            value: item?.users_id,
          },
        ];
      });
    });
  }, [Enabler_List1]);
  useEffect(() => {
    getProgramme1();
    Get_SchoolAdminList();
    Get_school_coordinator_list();
  }, []);

  return (
    <div>
      <div className="manage_school_container">
        {!Edit_add_school?.edit && (
          <div className="manage_school_btn_container">
            <button
              className={`${ManageSchool_tab == 1 ? "active" : ""}`}
              onClick={() => {
                setManageSchool_tab(1);
              }}
            >
              Schools
            </button>
            {UserRole?.role != "School principal" && (
              <button
                className={`${ManageSchool_tab == 2 ? "active" : ""}`}
                onClick={() => {
                  setManageSchool_tab(2);
                }}
              >
                Add school
              </button>
            )}
          </div>
        )}

        <div className="manage_school_body">
          {ManageSchool_tab == 1 && (
            <div className="manage_school_table">
              <input
                type="text"
                placeholder="Search.."
                defaultValue={Search_input}
                onInput={(e) => {
                  setSearch_input(e.target.value);
                  Get_manage_school_table(e.target.value, page_no);
                }}
              />
              <table className="manage_school_table_container">
                <thead>
                  <tr>
                    <th>School Name</th>
                    <th>Address</th>
                    <th>City</th>
                    <th>Pincode</th>
                    <th>SPOC</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    {UserRole?.role != "School principal" && <th>Enabler</th>}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {TableData?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.school_name}</td>
                        <td>{item?.school_address_line1}</td>
                        <td>{item?.school_city}</td>
                        <td>{item?.school_pincode}</td>
                        <td>{item?.school_spoc_name}</td>
                        <td>
                          {item?.school_spoc_mobile != null
                            ? item?.school_spoc_mobile
                            : "-"}
                        </td>
                        <td>{item?.school_spoc_emailid}</td>
                        {UserRole?.role != "School principal" && (
                          <td
                            className={
                              item?.school_name?.trim() == "Direct children"
                                ? "manage_school_table_btn active"
                                : "manage_school_table_btn"
                            }
                          >
                            <button
                              onClick={() => {
                                setEnabler_name(item?.enabler_name);
                                setShowEnabler_modal(true);
                              }}
                            >
                              <i className="fa-solid fa-eye"></i>
                            </button>
                          </td>
                        )}
                        {/* className="manage_school_table_btn" */}
                        <td
                          className={
                            item?.school_name?.trim() == "Direct children"
                              ? "manage_school_table_btn active"
                              : "manage_school_table_btn"
                          }
                        >
                          <NavLink
                            to={`/schoolDetails/${item?.school_id}/${user_id}`}
                          >
                            <i className="fa-solid fa-eye"></i>
                          </NavLink>
                          {UserRole?.role != "School principal" && (
                            <button
                              onClick={() => Download_report(item?.school_id)}
                            >
                              <i className="fa-solid fa-download"></i>
                            </button>
                          )}
                          <button
                            onClick={() => {
                              edit_school_btn(item);
                            }}
                          >
                            <i className="fa-solid fa-pen"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                activePage={page_no}
                itemsCountPerPage={10}
                totalItemsCount={Math.ceil(Table_data_total)}
                pageRangeDisplayed={10}
                itemClass="paginationLi_new"
                prevPageText="Prev"
                nextPageText="Next"
                activeLinkClass="pagLi_sc"
                activeClass="pagLi_sc"
                itemClassFirst="go_first"
                itemClassLast="go_first"
                itemClassPrev="prev_btn"
                itemClassNext="next_btn"
                onChange={(e) => {
                  // console.log(e);
                  setpage_no(e);
                  Get_manage_school_table(Search_input, e);
                }}
              />
            </div>
          )}
          {ManageSchool_tab == 2 && (
            <div className="manage_school_add_container">
              {Edit_add_school?.edit && (
                <h2 className="edit_school_heading font-semibold text-[22px]">
                  Edit school
                </h2>
              )}
              <div className="add_school_input_container">
                <div className="flex flex-col">
                  <label htmlFor="" className="pl-5 font-semibold">
                    School name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter School Name Here"
                    defaultValue={Edit_add_school?.school_name}
                    value={Edit_add_school?.school_name}
                    onInput={(e) => {
                      setEdit_add_school({
                        ...Edit_add_school,
                        school_name: e.target.value.replace(
                          /[^a-zA-Z. ,-\d]/gi,
                          ""
                        ),
                      });
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="" className="pl-5 font-semibold">
                    School spoc
                  </label>
                  <input
                    type="text"
                    placeholder="School Spoc"
                    defaultValue={Edit_add_school?.school_spoc}
                    value={Edit_add_school?.school_spoc}
                    onInput={(e) => {
                      setEdit_add_school({
                        ...Edit_add_school,
                        school_spoc: e.target.value.replace(
                          /[^a-zA-Z\s-]/g,
                          ""
                        ),
                      });
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="" className="pl-5 font-semibold">
                    School Address
                  </label>
                  <input
                    type="text"
                    placeholder="Enter School Address Here"
                    defaultValue={Edit_add_school?.school_address}
                    value={Edit_add_school?.school_address}
                    onInput={(e) => {
                      setEdit_add_school({
                        ...Edit_add_school,
                        school_address: e.target.value.replace(
                          /[^a-zA-Z. ,-/\d]/gi,
                          ""
                        ),
                      });
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="" className="pl-5 font-semibold">
                    School Mobile
                  </label>
                  <input
                    type="text"
                    placeholder="School Mobile"
                    maxLength={10}
                    defaultValue={Edit_add_school?.school_mobile}
                    value={Edit_add_school?.school_mobile}
                    onInput={(e) => {
                      setEdit_add_school({
                        ...Edit_add_school,
                        school_mobile: e.target.value.replace(/[^0-9\d]/gi, ""),
                      });
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="" className="pl-5 font-semibold">
                    City name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter city name"
                    defaultValue={Edit_add_school?.City_name}
                    value={Edit_add_school?.City_name}
                    onInput={(e) => {
                      setEdit_add_school({
                        ...Edit_add_school,
                        City_name: e.target.value.replace(/[^a-zA-Z\s-]/g, ""),
                      });
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="" className="pl-5 font-semibold">
                    School email
                  </label>
                  <input
                    type="text"
                    placeholder="School Email"
                    defaultValue={Edit_add_school?.Email}
                    value={Edit_add_school?.Email}
                    onInput={(e) => {
                      setEdit_add_school({
                        ...Edit_add_school,
                        Email: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="" className="pl-5 font-semibold">
                    School pincode
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Pincode"
                    defaultValue={Edit_add_school?.pincode}
                    value={Edit_add_school?.pincode}
                    maxLength={6}
                    onInput={(e) => {
                      setEdit_add_school({
                        ...Edit_add_school,
                        pincode: e.target.value.replace(/[^0-9\d]/gi, ""),
                      });
                    }}
                  />
                </div>
                {UserRole?.role != "School principal" && (
                  <div className="add_school_assign_enabler">
                    <label htmlFor="" className="font-semibold">
                      Select enabler
                    </label>
                    <Select
                      defaultMenuIsOpen={false}
                      className="select_options mt-1"
                      closeMenuOnSelect={false}
                      options={EnablerList_new}
                      isMulti
                      onChange={(e) => {
                        let EmptyList = [];
                        e?.forEach((item, index) => {
                          EmptyList.push(item?.value);
                        });
                        setEdit_add_school({
                          ...Edit_add_school,
                          enabler: EmptyList,
                        });
                      }}
                      defaultValue={Index?.map((item) => {
                        return EnablerList_new[item];
                      })}
                      placeholder="Select enabler"
                    />
                  </div>
                )}
                {UserRole?.role != "schooladmin" &&
                  UserRole?.role != "School principal" && (
                    <div className="add_school_assign_enabler">
                      <label htmlFor="" className="font-semibold">
                        Select school admin
                      </label>
                      <Select
                        defaultMenuIsOpen={false}
                        className="select_options mt-1"
                        closeMenuOnSelect={false}
                        options={SchoolAdminList}
                        isMulti
                        onChange={(e) => {
                          let EmptyList = [];
                          e?.forEach((item, index) => {
                            EmptyList.push(item?.value);
                          });
                          setEdit_add_school({
                            ...Edit_add_school,
                            school_admin: EmptyList,
                          });
                        }}
                        defaultValue={SchoolIndex?.map((item) => {
                          return SchoolAdminList[item];
                        })}
                        placeholder="Select school admin"
                      />
                    </div>
                  )}
                {(UserRole?.role == "schooladmin" ||
                  UserRole?.role == "DL Admin") && (
                  <div className="add_school_assign_enabler">
                    <label htmlFor="" className="font-semibold">
                      Select school coordinator
                    </label>
                    <Select
                      defaultMenuIsOpen={false}
                      className="select_options mt-1"
                      closeMenuOnSelect={false}
                      options={Schoolcoordinator}
                      isMulti
                      onChange={(e) => {
                        let EmptyList = [];
                        e?.forEach((item, index) => {
                          EmptyList.push(item?.value);
                        });
                        setEdit_add_school({
                          ...Edit_add_school,
                          school_coordinator: EmptyList,
                        });
                      }}
                      defaultValue={SchoolIndex?.map((item) => {
                        return Schoolcoordinator[item];
                      })}
                      placeholder="Select school coordinator"
                    />
                  </div>
                )}
              </div>

              {/* PROJECT<STATE<DISTRICT */}
              {UserRole?.role != "School principal" && (
                <div className="w-[93%] flex flex items-start border-t-2 mt-3">
                  <div className="flex flex-col w-[50%]">
                    <label htmlFor="" className="font-semibold">
                      Select project
                    </label>
                    <select
                      onChange={ProjectHandler}
                      value={Edit_add_school?.project}
                      className="w-[50%] my-2 border-2 border-[#007872] outline-none rounded-md px-2 py-1"
                    >
                      <option value="">Select project</option>
                      {ProjectGet.GetData?.body?.map((item, index) => {
                        return (
                          <option
                            key={item?.project_id}
                            value={item?.project_id}
                          >
                            {item?.project_name}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor="" className="font-semibold">
                      Select state
                    </label>
                    <select
                      value={Edit_add_school?.state}
                      onChange={StateHandler}
                      className="w-[50%] my-2 border-2 border-[#007872] outline-none rounded-md px-2 py-1"
                    >
                      <option value="">Select state</option>
                      {StateGet.Postdata?.body?.map((item, index) => {
                        return (
                          <option key={item?.state_id} value={item?.state_id}>
                            {item?.state_name}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor="" className="font-semibold">
                      Select district
                    </label>
                    <select
                      value={Edit_add_school?.district}
                      onChange={DistrictHandler}
                      className="w-[50%] my-2 border-2 border-[#007872] outline-none rounded-md px-2 py-1"
                    >
                      <option value="">Select district</option>
                      {DistrictPost.Postdata?.body?.map((item, index) => {
                        return (
                          <option
                            key={item?.district_id}
                            value={item?.district_id}
                          >
                            {item?.district_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="flex flex-col w-[50%]">
                    <label htmlFor="" className="font-semibold">
                      Enrollment type
                    </label>
                    <select
                      onChange={EnrollTypeHandler}
                      value={Edit_add_school?.school_enrollment_type || ""}
                      className="w-[50%] my-2 border-2 border-[#007872] outline-none rounded-md px-2 py-1"
                    >
                      <option value="" disabled>
                        Select type
                      </option>
                      <option value="174">Paid</option>
                      <option value="175">Sponsored</option>
                    </select>
                    {Edit_add_school?.school_enrollment_type == 174 && (
                      <div className="w-full grid grid-cols-2 enroll-payment-details">
                        <div className="flex flex-col">
                          <label htmlFor="" className="font-semibold">
                            Amount paid
                          </label>
                          <input
                            type="text"
                            className="!mx-0"
                            placeholder="Enter Amount paid"
                            defaultValue={Edit_add_school?.total_amount}
                            value={Edit_add_school?.total_amount}
                            maxLength={6}
                            onInput={(e) => {
                              setEdit_add_school({
                                ...Edit_add_school,
                                total_amount: e.target.value.replace(
                                  /[^0-9\d]/gi,
                                  ""
                                ),
                              });
                            }}
                          />
                        </div>
                        <div className="flex flex-col">
                          <label htmlFor="" className="font-semibold">
                            Reference ID
                          </label>
                          <input
                            type="text"
                            className="!mx-0"
                            placeholder="Enter Reference ID"
                            defaultValue={
                              Edit_add_school?.transaction_reference
                            }
                            value={Edit_add_school?.transaction_reference}
                            onInput={(e) => {
                              setEdit_add_school({
                                ...Edit_add_school,
                                transaction_reference: e.target.value.replace(
                                  /[^0-9a-zA-Z\d]/gi,
                                  ""
                                ),
                              });
                            }}
                          />
                        </div>
                        <div className="flex flex-col">
                          <label htmlFor="" className="font-semibold">
                            Transaction date
                          </label>
                          <input
                            type="date"
                            className="!mx-0"
                            placeholder="Enter Transaction date"
                            defaultValue={Edit_add_school?.transaction_date}
                            value={Edit_add_school?.transaction_date}
                            onInput={(e) => {
                              setEdit_add_school({
                                ...Edit_add_school,
                                transaction_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="flex flex-col">
                          <label htmlFor="" className="font-semibold">
                            Remarks
                          </label>
                          <input
                            type="text"
                            className="!mx-0"
                            placeholder="Enter Remarks"
                            defaultValue={Edit_add_school?.transaction_comment}
                            value={Edit_add_school?.transaction_comment}
                            onInput={(e) => {
                              setEdit_add_school({
                                ...Edit_add_school,
                                transaction_comment: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* PROJECT<STATE<DISTRICT */}

              <div>
                {Edit_add_school?.edit && (
                  <button
                    className="add_school_done_btn"
                    onClick={() => {
                      setEdit_add_school({
                        school_id: "",
                        edit: false,
                        school_name: "",
                        school_address: "",
                        school_spoc: "",
                        school_mobile: "",
                        City_name: "",
                        Email: "",
                        pincode: "",
                        school_enrollment_type: "",
                        enabler: [],
                        school_admin: [],
                        total_amount: "",
                        transaction_date: "",
                        transaction_comment: "",
                        transaction_reference: "",
                      });
                      setIndex([]);
                      setSchoolIndex([]);
                      setManageSchool_tab(1);
                    }}
                  >
                    Cancel
                  </button>
                )}
                <button
                  className="add_school_done_btn"
                  onClick={() => {
                    Add_Edit_school();
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          )}
        </div>

        {ShowEnabler_modal && (
          <div className="add_school_enabler_view_container">
            <div className="add_school_enabler_view_block">
              <h2>Enabler list</h2>
              <ol>
                {Enabler_name?.split(",")?.map((item, index) => {
                  return (
                    <li key={index}>
                      {index + 1} . {item}
                    </li>
                  );
                })}
              </ol>
              <button
                onClick={() => {
                  setShowEnabler_modal(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>

      {IsLoader && (
        <div className="loader_screen">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Manageschool;
