import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import useSearch from "../../../customHooks/useSearch";
import { NavLink } from "react-router-dom";
import { URL } from "../../../DROPDOWNDATA";
function PendingChildren({ childDetail }) {
  return (
    <div className="list-holder">
      <div className="avatar"></div>

      <div className="name">
        <p>{childDetail.group_name}</p>
      </div>

      <div className="value">
        <p></p>
      </div>
    </div>
  );
}

function AvaliableEnablers({ enablerDetail }) {
  return (
    <div className="list-holder">
      <div className="avatar"></div>

      <div className="name">
        <p>{enablerDetail.users_firstname}</p>
        {/* <span>9:00 am - 13:00 pm</span> */}
      </div>

      <div className="value">{/* <p>Mon - Fri</p> */}</div>
    </div>
  );
}
// let URL = "http://20.120.84.12:5588";
export default function Sidebarlist() {
  const [EnablerSearch_Data, setEnablerSearch_Data, getEnabler_Search] =
    useSearch();
  const [pendingChild, setPendingChild] = useState([]);
  const [pendingEnablers, setPendingEnablers] = useState([]);

  const authKey = "Bearer " + localStorage.getItem("loggedToken");
  async function Get_initial_Enabler_and_childDAta() {
    await fetch(URL + "/pending_child_enabler_list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authKey,
      },
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setPendingEnablers(obj.body.enabler_list);
      });
  }
  const SearchEnabler = (event) => {
    if (event.target.value != "") {
      getEnabler_Search(event.target.value);
      setPendingEnablers(EnablerSearch_Data);
    } else {
      Get_initial_Enabler_and_childDAta();
    }
  };

  // const SearchChild = (value) => {
  //   let data;
  //   async function Search_childData(value) {
  //     await fetch(URL + "/list_unscheduled", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         Authorization: authKey,
  //       },
  //       body: JSON.stringify((data = { search: value })),
  //     })
  //       .then((r) =>
  //         r.json().then((data) => ({ status: r.status, body: data }))
  //       )
  //       .then((obj) => {
  //         setPendingChild(obj.body);
  //       });
  //   }
  //   Search_childData(value);
  // };
  // GET GROUP LIST
  const GetGroupList = async (value) => {
    // setLoaderscreen(true);
    let data;
    await fetch(URL + "/list_unscheduled", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("loggedToken"),
      },
      body: JSON.stringify(
        (data = {
          search: value,
        })
      ),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setPendingChild([]);
        setPendingChild(obj?.body);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    Get_initial_Enabler_and_childDAta();
    getEnabler_Search("");
    GetGroupList("");
  }, []);

  return (
    <>
      <p className="heading">Avaliable Group</p>

      <TextField
        label="Search Child"
        fullWidth
        className="enablerInput"
        onInput={(event) => GetGroupList(event.target.value)}
      />

      <div className="pending_Child_container">
        {pendingChild?.map((item, index) => {
          return <PendingChildren key={index} childDetail={item} />;
        })}
      </div>
      <div>
        <p className="heading">Avaliable Enablers</p>

        <TextField
          fullWidth
          label="Search Enabler"
          className="enablerInput"
          onInput={(value) => SearchEnabler(value)}
        />

        <div className="pending_enabler_container">
          {EnablerSearch_Data?.map((item, index) => {
            return <AvaliableEnablers key={index} enablerDetail={item} />;
          })}
        </div>
      </div>

      <div className="view-schedule-btn-holder">
        <NavLink to={"/scheduleList/0/all/1"} className="btn btn2">
          View Schedule
        </NavLink>
      </div>
    </>
  );
}
