import React from "react";
import { useNavigate } from "react-router-dom";

const Count = ({ title, count, path }) => {
  const Navigate = useNavigate();
  return (
    <div className="flex p-5 bg-[#FFFFFF] rounded-xl items-center mx-2 border-2 border-[#007872]">
      <div className="mr-[50px]">
        <p className="font-bold text-[22px] max-w-[100px]">{title}</p>
        {path != "" ? (
          <button
            onClick={() => Navigate(`/${path}`)}
            className="font-medium text-[16px] my-1"
          >
            View more <i class="fa-regular fa-eye ml-1"></i>
          </button>
        ) : (
          ""
        )}
      </div>
      <div className="font-bold text-[38px]">{count}</div>
    </div>
  );
};

export default Count;
