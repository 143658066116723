import React from "react";

const Enablerschedule = ({
  ActiveStep,
  Selected_currentDay,
  setis_everyDay,
  setSelected_currentDay,
  setSelectedDays,
  SelectedDays,
  Slots,
  setCurrentbooking_slot,
  is_everyDay,
  Weekday_Toogle,
  Start_and_end_time,
  setStart_and_end_time,
  Add_time,
  tConv24,
  RemoveSlot,
  ApplyAllSlots,
  AddSlots,
}) => {
  return (
    <div
      className={
        ActiveStep == 4
          ? "add_enabler_slotbook_container"
          : "add_enabler_slotbook_container active"
      }
    >
      <div className="time_slot_booking_container">
        <div className="time_slot_available_day_container">
          <p>Choose available days</p>

          <div className="slot_checkbox_container">
            <div
              className={
                Selected_currentDay == "everyday"
                  ? "slot_checkbox active"
                  : "slot_checkbox"
              }
            >
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setis_everyDay(true);
                      setSelected_currentDay("everyday");
                      setSelectedDays(["everyday"]);
                    } else {
                      const RemoveSelectedDays = SelectedDays?.filter(
                        (item) => {
                          return "everyday" != item;
                        }
                      );
                      setSelectedDays(RemoveSelectedDays);
                      setSelected_currentDay("");
                      setis_everyDay(false);
                    }
                  }}
                  // checked={Slots[0].slot_timings?.length > 0 ? true : false}
                  defaultChecked={
                    Slots[0]?.slot_timings?.length > 0 ? true : false
                  }
                  id="input_everyday"
                />
                <span className="slider round"></span>
              </label>

              <label
                htmlFor="input_everyday"
                onClick={() => {
                  setCurrentbooking_slot([]);
                  setSelected_currentDay("everyday");
                }}
              >
                Every day
              </label>
            </div>
            <div className="weekdays_slot_container">
              <div
                className={
                  is_everyDay
                    ? "weekdays_slot_disable_layer disable"
                    : "weekdays_slot_disable_layer"
                }
              ></div>
              <div
                className={
                  Selected_currentDay == 1
                    ? "slot_checkbox active"
                    : "slot_checkbox"
                }
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      Weekday_Toogle(e.target.checked, 1);
                    }}
                    // checked={
                    //   Slots[1].slot_timings?.length > 0 ? true : false
                    // }
                    defaultChecked={
                      Slots[1]?.slot_timings?.length > 0 ? true : false
                    }
                    id="input_monday"
                  />
                  <span className="slider round"></span>
                </label>

                <label
                  htmlFor={
                    Slots[1]?.slot_timings?.length > 0 ? "" : "input_monday"
                  }
                  onClick={() => {
                    setCurrentbooking_slot([]);
                    setSelected_currentDay(1);
                  }}
                >
                  Monday
                </label>
              </div>
              <div
                className={
                  Selected_currentDay == 2
                    ? "slot_checkbox active"
                    : "slot_checkbox"
                }
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      Weekday_Toogle(e.target.checked, 2);
                    }}
                    // checked={
                    //   Slots[2].slot_timings?.length > 0 ? true : false
                    // }
                    defaultChecked={
                      Slots[2]?.slot_timings?.length > 0 ? true : false
                    }
                    id="input_tuesday"
                  />
                  <span className="slider round"></span>
                </label>

                <label
                  htmlFor={
                    Slots[2]?.slot_timings?.length > 0 ? "" : "input_tuesday"
                  }
                  onClick={() => {
                    setCurrentbooking_slot([]);
                    setSelected_currentDay(2);
                  }}
                >
                  Tuesday
                </label>
              </div>
              <div
                className={
                  Selected_currentDay == 3
                    ? "slot_checkbox active"
                    : "slot_checkbox"
                }
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      Weekday_Toogle(e.target.checked, 3);
                    }}
                    defaultChecked={
                      Slots[3]?.slot_timings?.length > 0 ? true : false
                    }
                    id="input_wednesday"
                  />
                  <span className="slider round"></span>
                </label>

                <label
                  htmlFor={
                    Slots[3]?.slot_timings?.length > 0 ? "" : "input_wednesday"
                  }
                  onClick={() => {
                    setCurrentbooking_slot([]);
                    setSelected_currentDay(3);
                  }}
                >
                  Wednesday
                </label>
              </div>
              <div
                className={
                  Selected_currentDay == 4
                    ? "slot_checkbox active"
                    : "slot_checkbox"
                }
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      Weekday_Toogle(e.target.checked, 4);
                    }}
                    // checked={
                    //   Slots[4].slot_timings?.length > 0 ? true : false
                    // }
                    defaultChecked={
                      Slots[4]?.slot_timings?.length > 0 ? true : false
                    }
                    id="input_thrusday"
                  />
                  <span className="slider round"></span>
                </label>

                <label
                  htmlFor={
                    Slots[4]?.slot_timings?.length > 0 ? "" : "input_thrusday"
                  }
                  onClick={() => {
                    setCurrentbooking_slot([]);
                    setSelected_currentDay(4);
                  }}
                >
                  Thursday
                </label>
              </div>
              <div
                className={
                  Selected_currentDay == 5
                    ? "slot_checkbox active"
                    : "slot_checkbox"
                }
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      Weekday_Toogle(e.target.checked, 5);
                    }}
                    // checked={
                    //   Slots[5].slot_timings?.length > 0 ? true : false
                    // }
                    defaultChecked={
                      Slots[5]?.slot_timings?.length > 0 ? true : false
                    }
                    id="input_friday"
                  />
                  <span className="slider round"></span>
                </label>

                <label
                  htmlFor={
                    Slots[5]?.slot_timings?.length > 0 ? "" : "input_friday"
                  }
                  onClick={() => {
                    setCurrentbooking_slot([]);
                    setSelected_currentDay(5);
                  }}
                >
                  Friday
                </label>
              </div>
              <div
                className={
                  Selected_currentDay == 6
                    ? "slot_checkbox active"
                    : "slot_checkbox"
                }
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      Weekday_Toogle(e.target.checked, 6);
                    }}
                    // checked={
                    //   Slots[6].slot_timings?.length > 0 ? true : false
                    // }
                    defaultChecked={
                      Slots[6]?.slot_timings?.length > 0 ? true : false
                    }
                    id="input_saturday"
                  />
                  <span className="slider round"></span>
                </label>

                <label
                  htmlFor={
                    Slots[6]?.slot_timings?.length > 0 ? "" : "input_saturday"
                  }
                  onClick={() => {
                    setCurrentbooking_slot([]);
                    setSelected_currentDay(6);
                  }}
                >
                  Saturday
                </label>
              </div>
              <div
                className={
                  Selected_currentDay == 7
                    ? "slot_checkbox active"
                    : "slot_checkbox"
                }
              >
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      Weekday_Toogle(e.target.checked, 7);
                    }}
                    // checked={
                    //   Slots[7].slot_timings?.length > 0 ? true : false
                    // }
                    defaultChecked={
                      Slots[7]?.slot_timings?.length > 0 ? true : false
                    }
                    id="input_sunday"
                  />
                  <span className="slider round"></span>
                </label>

                <label
                  htmlFor={
                    Slots[7]?.slot_timings?.length > 0 ? "" : "input_sunday"
                  }
                  onClick={() => {
                    setCurrentbooking_slot([]);
                    setSelected_currentDay(7);
                  }}
                >
                  Sunday
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="time_slot_choose_time_container">
          <p>Choose time slots</p>

          <div className="time_seleting_container">
            {Selected_currentDay != "" && (
              <div className="time_seleting_container_block">
                <h2>
                  {Selected_currentDay == 1
                    ? "Monday"
                    : Selected_currentDay == 2
                    ? "Tuesday"
                    : Selected_currentDay == 3
                    ? "Wednesday"
                    : Selected_currentDay == 4
                    ? "Thursday"
                    : Selected_currentDay == 5
                    ? "Friday"
                    : Selected_currentDay == 6
                    ? "Saturday"
                    : Selected_currentDay == 7
                    ? "Sunday"
                    : Selected_currentDay == "everyday"
                    ? "Everyday"
                    : ""}
                </h2>

                <div className="time_selecting_input_container">
                  <div>
                    <label htmlFor="">Start time</label>
                    <input
                      type="time"
                      name=""
                      id=""
                      value={Start_and_end_time?.start_time}
                      defaultValue={Start_and_end_time?.start_time}
                      onChange={(e) => {
                        setStart_and_end_time({
                          ...Start_and_end_time,
                          start_time: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <p>To</p>
                  <div>
                    <label htmlFor="">End time</label>
                    <input
                      type="time"
                      name=""
                      id=""
                      value={Start_and_end_time?.end_time}
                      defaultValue={Start_and_end_time?.end_time}
                      onChange={(e) => {
                        setStart_and_end_time({
                          ...Start_and_end_time,
                          end_time: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <button onClick={Add_time}>Add </button>
                </div>

                <div className="time_slot_booked_container">
                  {Slots[
                    Selected_currentDay == "everyday" ? 0 : Selected_currentDay
                  ]?.slot_timings?.map((item, index) => {
                    return (
                      <div className="time_slot_booked_list" key={index}>
                        <p>
                          {tConv24(item?.start_time)} -{" "}
                          {tConv24(item?.end_time)}
                        </p>
                        <button
                          onClick={() => {
                            RemoveSlot(index);
                          }}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {Selected_currentDay == "" && (
              <div className="select_any_day">
                <h3>Select any day</h3>
              </div>
            )}
          </div>

          {!is_everyDay &&
            Selected_currentDay != "" &&
            Selected_currentDay != "everyday" && (
              <div className="apply_time_slot_container">
                {/* <input type="checkbox" name="" id="applytimeslot" />
                    <label htmlFor="applytimeslot">
                      Apply same time slots for all selected days
                    </label> */}
                <button onClick={ApplyAllSlots}>
                  {" "}
                  Apply same time slots for all selected days
                </button>
              </div>
            )}
        </div>
      </div>

      <div className="slot_btn_container">
        <button onClick={AddSlots}>Save</button>
      </div>
    </div>
  );
};

export default Enablerschedule;
